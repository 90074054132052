<!--#region Toolbar -->
<mat-toolbar class="app-toolbar">
    <span>{{title}}</span>
    <span class="example-spacer"></span>
    <button mat-icon-button (click)="closeDialog()" onclick="this.blur()">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>
<!--#endregion -->
<mat-progress-bar *ngIf="isShowProgressBar" mode="indeterminate"></mat-progress-bar>
<form style="padding: 20px;font-size: 14.5px !important;" [formGroup]="formGroup" (ngSubmit)="saveUser()">
    <mat-form-field appearance="legacy" *ngIf="data.mode == 2">
        <mat-label>User Id</mat-label>
        <input matInput type="text" required formControlName="userId">
    </mat-form-field>

    <table style="width:100%" cellspacing="0">
        <tr>
            <td style="padding-right: 10px;">
                <mat-form-field appearance="legacy">
                    <mat-label>First Name</mat-label>
                    <input matInput type="text" required formControlName="firstName">
                </mat-form-field>
            </td>
            <td style="padding-left: 10px;">
                <mat-form-field appearance="legacy">
                    <mat-label>Last Name</mat-label>
                    <input matInput type="text" required formControlName="lastName">
                </mat-form-field>
            </td>
        </tr>
    </table>

    <mat-form-field appearance="legacy">
        <mat-label>Email Id</mat-label>
        <input matInput type="email" required formControlName="emailId">
    </mat-form-field>

    <mat-form-field appearance="legacy">
        <mat-label>Phone Number</mat-label>
        <input matInput type="text" formControlName="phoneNumber">
    </mat-form-field>

    <mat-form-field appearance="legacy">
        <mat-label>Access Modules</mat-label>
        <mat-select multiple formControlName="accessModules">
            <mat-option *ngFor="let module of accessModules" [value]="module.MODULE_NAME"> {{module.MODULE_DESCRIPTION}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="legacy" *ngIf="loggedInCustomerId == 1">
        <mat-label>Access Customers</mat-label>
        <mat-select multiple formControlName="accessCustomers">
            <span *ngFor="let customer of accessCustomers">
                <mat-option *ngIf="customer.CUSTOMER_ID !=1" [value]="customer.CUSTOMER_ID">
                    {{customer.NAME}}
                </mat-option>
            </span>
        </mat-select>
    </mat-form-field>

    <div style="text-align:center;padding-top:10px">
        <button mat-raised-button [ngClass]="formGroup.valid ? 'primary-button' : ''" [disabled]="!formGroup.valid">
            <span *ngIf="data.mode == 1"> Create </span>
            <span *ngIf="data.mode == 2"> Update </span>
        </button>
    </div>

    <div *ngIf="errorMsg.length> 0" style="text-align: center;color: rgb(228, 0, 43)">
        <p>{{errorMsg}}</p>
    </div>
</form>