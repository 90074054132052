import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { PartService } from 'src/app/services/part.service';
import { ActionType, AppConstants } from 'src/app/shared/AppConstants';
import { ErrorAlertComponent } from '../error alert/error-alert.component';

export interface DialogData {
  mode: number;
  item: any;
}

@Component({
  selector: 'app-parts-list',
  templateUrl: './parts-list.component.html',
  styleUrls: ['./parts-list.component.scss'],
})
export class PartsListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: any;
  @ViewChild(MatSort, { static: false }) sort: any;

  public isShowProgressBar: boolean = false;
  public title: string;
  public errorMsg: string;
  public paginatorLength: number = 0;
  public partsLength = 0;
  public constants: AppConstants;
  public partSelection = new SelectionModel<any>(true, []);
  public partDataSource = new MatTableDataSource<any>([]);
  public partColumns: string[] = ['select', 'PART_ID', 'NAME'];

  constructor(
    public dialog: MatDialog,
    public partService: PartService,
    public authService: AuthService,
    public router: Router,
    public partListDialogRef: MatDialogRef<PartsListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.constants = new AppConstants();
    this.errorMsg = '';
    this.title =
      this.data.mode == ActionType.Add ? 'Select Parts' : 'Select Parts';
    this.paginatorLength = 5;
  }

  ngOnInit(): void {
    this.getParts(5, 0, '', '', '', 'PART');

    if (this.data.item && this.data.item.length > 0) {
      for (let e = 0; e < this.data.item.length; e++) {
        this.partSelection.select(this.data.item[e]);
      }
    }
  }

  closeDialog(): void {
    this.partListDialogRef.close(false);
  }

  getParts(
    limit: number,
    offset: number,
    searchText?: any,
    sortField?: any,
    sortDirection?: any,
    type?: any
  ) {
    this.isShowProgressBar = true;
    this.partService
      .getParts(limit, offset, searchText, sortField, sortDirection, type)
      .subscribe(
        (response: any) => {
          // Success callback
          if (response.status === 200) {
            if (response.body) {
              // Assign the data to the data source for the table to render
              if (response.body && response.body?.PARTS.length > 0) {
                for (let p = 0; p < response.body.PARTS.length; p++) {
                  if (!response.body.PARTS[p].hasOwnProperty('BOM_PART_ID')) {
                    response.body.PARTS[p]['BOM_PART_ID'] =
                      response.body.PARTS[p]['PART_ID'];
                  }
                }
              }
              this.partDataSource = new MatTableDataSource(
                response.body?.PARTS
              );
              this.partsLength = response.body?.PARTS_COUNT;
            }
            this.isShowProgressBar = false;
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.isShowProgressBar = false;
            this.showErrorDialog(response.error);
          } else {
            this.isShowProgressBar = false;
            this.showErrorDialog(response.message);
          }
        },
          (error) => {
            if (error.status === 401) {
              // Redirect to login for unauthorized
              localStorage.removeItem('token');
              localStorage.removeItem('user-details');
              localStorage.removeItem('customerName');
              localStorage.removeItem('customerId');
              localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
              this.authService.loggedIn.next(false);
              this.router.navigate(['login']);
            } else if (error.status === 403) {
              this.isShowProgressBar = false;
              this.showErrorDialog(error.error);
            } else {
              this.isShowProgressBar = false;
              this.showErrorDialog(error.error);
            }
          }
      );
  }

  showErrorDialog(msg: string) {
    const dialogRef = this.dialog.open(ErrorAlertComponent, {
      width: '400px',
      data: {
        errorMsg: msg,
      },
    });
  }

  //Paginator page items handle
  handlePartPaginator(e: any) {
    this.paginatorLength = e.pageSize;
    let limit = e.pageSize;
    let offset = e.pageIndex * limit;
    this.paginator.pageIndex = e.pageIndex;
    if (this.sort.active) {
      this.getParts(
        limit,
        offset,
        '',
        this.sort.active,
        this.sort._direction,
        'PART'
      );
    } else {
      this.getParts(limit, offset, '', '', '', 'PART');
    }
  }

  sortPartfields(e: any) {
    let offset = this.paginator._pageIndex * this.paginator._pageSize;
    this.getParts(
      this.paginatorLength,
      offset,
      '',
      e.active,
      e.direction,
      'PART'
    );
    this.paginator.pageIndex = this.paginator._pageIndex;
  }

  selectedPart(row: any): boolean {
    if (
      this.partSelection?.selected &&
      this.partSelection.selected.length > 0
    ) {
      let temp = false;
      for (let i = 0; i < this.partSelection.selected.length; i++) {
        if (this.partSelection.selected[i].BOM_PART_ID == row.BOM_PART_ID) {
          temp = true;
        }
      }
      return temp;
    } else {
      return false;
    }
  }

  toggelePartSelection(ev: any, row: any) {
    if (ev.checked) {
      let ar = [
        ...new Map(
          this.partSelection.selected.map((item) => [item.BOM_PART_ID, item])
        ).values(),
      ];

      if (ar?.length > 0) {
        for (let i = 0; i < ar.length; i++) {
          this.partSelection.select(row);
        }
      } else {
        this.partSelection.select(row);
      }
    } else {
      let temp = this.partSelection.selected;
      if (temp && temp.length > 0) {
        temp.splice(
          temp.findIndex((a) => a.BOM_PART_ID === row.BOM_PART_ID),
          1
        );
        this.partSelection.clear();
        for (let t = 0; t < temp?.length; t++) {
          this.partSelection.select(temp[t]);
        }
      }
    }
  }

  isPartAllSelected() {
    const numSelected = this.partSelection.selected.length;
    const numRows = this.partDataSource.data.length;
    return numSelected === numRows;
  }

  partMasterToggle() {
    this.isPartAllSelected()
      ? this.partSelection.clear()
      : this.partDataSource.data.forEach((row) =>
          this.partSelection.select(row)
        );
  }

  saveParts() {
    if (this.partSelection.selected && this.partSelection.selected.length > 0) {
      for (let p = 0; p < this.partSelection.selected.length; p++) {
        if (!this.partSelection.selected[p].hasOwnProperty('QTY')) {
          this.partSelection.selected[p]['QTY'] = 1;
        }
        if (!this.partSelection.selected[p].hasOwnProperty('BOM_PART_ID')) {
          this.partSelection.selected[p]['BOM_PART_ID'] =
            this.partSelection.selected[p]['PART_ID'];
        }
      }
      this.partListDialogRef.close(this.partSelection.selected);
    } else {
      this.partListDialogRef.close([]);
    }
  }
}
