import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddNewExtensionFieldComponent } from 'src/app/components/add-new-extension-field/add-new-extension-field.component';
import { AddNewExtensionSectionComponent } from 'src/app/components/add-new-extension-section/add-new-extension-section.component';
import { ErrorAlertComponent } from 'src/app/components/error alert/error-alert.component';
import { SaveExtensionsAlertComponent } from 'src/app/components/save-extensions-alert/save-extensions-alert.component';
import { AuthService } from 'src/app/services/auth.service';
import { ExtensionsService } from 'src/app/services/extensions.service';
import { UtilService } from 'src/app/services/util.service';
import { AppConstants } from 'src/app/shared/AppConstants';
import { Location } from '@angular/common';
import { CustomerSelectComponent } from 'src/app/components/customer-select/customer-select.component';

@Component({
  selector: 'app-extensions',
  templateUrl: './extensions.component.html',
  styleUrls: ['./extensions.component.scss'],
})
export class ExtensionsComponent implements OnInit {
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  public version: string;
  public release: string;
  public constants: AppConstants;
  public isShowCategoryProgressBar: boolean = false;
  public selectedCategoryIndex: any = null;
  public categoryList: any = {};
  public selectedCategoryItem: any = {};
  public tempExtensions: any = {};
  public selectedExtCategory: string = '';
  public choiceList: any = {};
  // public tempChangedExtensions: any = {};
  public appropriateClass:string = '';
  public events: string[] = [];
  
  @HostListener('window:resize', ['$event'])
  getScreenHeight(event?: any){
    if(window.innerHeight<=700){
      this.appropriateClass = 'bottomRelative';
    }else{
      this.appropriateClass = 'bottomStick';
    }
  }
  constructor(
    private _location: Location,
    public authService: AuthService,
    public utilService: UtilService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private extensionsService: ExtensionsService,
    public dialog: MatDialog
  ) {
    this.getScreenHeight();
    this.constants = new AppConstants();
    this.version = this.constants.APP_RELEASE_NUMBER;
    this.release = this.constants.APP_RELEASE_DATE;
  }

  async ngOnInit(): Promise<void> {
    await this.getExtensions();
  }

  logout() {
    this.authService.logout();
  }

  async getExtensions() {
    this.isShowCategoryProgressBar = true;
    await this.extensionsService
      .getExtensions()
      .toPromise()
      .then(
        (response: any) => {
          this.isShowCategoryProgressBar = false;
          if (response.status === 200) {
            if (response?.body) {
              this.choiceList = response?.body?.CHOICE;
              this.categoryList = JSON.parse(
                JSON.stringify(response?.body?.EXTENSIONS)
              );
              this.tempExtensions = JSON.parse(
                JSON.stringify(response?.body?.EXTENSIONS)
              );

              // let keys = Object.keys(response?.body?.EXTENSIONS);
              // if (keys && keys.length > 0) {
              //   this.createStaticExtensionsObj(keys);
              // }

              if (this.selectedCategoryIndex != null) {
                this.selectedCategoryItem =
                  this.categoryList[this.selectedExtCategory];
              }
            } else {
              this.categoryList = {};
            }
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.isShowCategoryProgressBar = false;
            this.showErrorAlertDialog(response.error);
          } else {
            this.isShowCategoryProgressBar = false;
            this.showErrorAlertDialog(response.message);
          }
        },
        (error) => {
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.isShowCategoryProgressBar = false;
            this.showErrorAlertDialog(error.message);
          } else {
            this.isShowCategoryProgressBar = false;
            this.showErrorAlertDialog(error.message);
          }
        }
      );
  }

  showErrorAlertDialog(msg: string) {
    const dialogRef = this.dialog.open(ErrorAlertComponent, {
      width: '400px',
      data: {
        errorMsg: msg,
      },
    });
  }

  async refreshExtensions() {
    this.selectedCategoryItem = {};
    this.choiceList = {};
    this.selectedCategoryIndex = null;
    this.selectedExtCategory = '';
    await this.getExtensions();
  }

  selectedCategory(index: any, value: any, key: any) {
    this.selectedCategoryIndex = index;
    this.selectedCategoryItem = value;
    this.selectedExtCategory = key;
  }

  addNewField(key: any, values: any) {
    const dialogRef = this.dialog.open(AddNewExtensionFieldComponent, {
      width: '400px',
      panelClass: 'add-user-dialog',
      data: { item: values },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.selectedCategoryItem[key].push(result);
        // if (this.tempChangedExtensions[this.selectedExtCategory][key]) {
        //   this.tempChangedExtensions[this.selectedExtCategory][key].push(
        //     result
        //   );
        // } else {
        //   this.tempChangedExtensions[this.selectedExtCategory][key] = [];
        //   this.tempChangedExtensions[this.selectedExtCategory][key].push(
        //     result
        //   );
        // }
      }
    });
  }

  addNewSection(): void {
    const dialogRef = this.dialog.open(AddNewExtensionSectionComponent, {
      width: '400px',
      panelClass: 'add-user-dialog',
      data: { item: this.selectedCategoryItem },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.length > 0) {
        this.selectedCategoryItem[result] = [];
        this.categoryList[this.selectedExtCategory][result] = [];
      }
    });
  }

  removeField(key: any, item: any) {
    let temp = this.selectedCategoryItem[key].filter((cat: any) => cat != item);
    this.selectedCategoryItem[key] = temp;

    // let temp1 = this.tempChangedExtensions[this.selectedExtCategory][
    //   key
    // ].filter((cat: any) => cat != item);
    // this.tempChangedExtensions[this.selectedExtCategory][key] = temp1;
  }

  removeSection(key: any) {
    delete this.selectedCategoryItem[key];
    if (Object.keys(this.selectedCategoryItem).length > 0) {
    } else {
      this.selectedCategoryItem = {};
    }
    // delete this.tempChangedExtensions[this.selectedExtCategory][key];
  }

  isShowDelectSection(key: any): boolean {
    // let tempOriginalExtKeys = Object.keys(
    //   this.tempExtensions[this.selectedExtCategory]
    // );
    // if (tempOriginalExtKeys.includes(key)) {
    //   return false;
    // } else
    return true;
  }

  saveExtensions() {
    const dialogRef = this.dialog.open(SaveExtensionsAlertComponent, {
      width: '400px',
      data: { item: this.categoryList },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.getExtensions();
      }
    });
  }

  isShowSaveButton(): boolean {
    if (
      JSON.stringify(this.categoryList) === JSON.stringify(this.tempExtensions)
    ) {
      return false;
    } else return true;
  }

  goBack() {
    this._location.back();
  }

  // createStaticExtensionsObj(item: any) {
  //   this.tempChangedExtensions = {};
  //   for (let i = 0; i < item.length; i++) {
  //     this.tempChangedExtensions[item[i]] = {};
  //   }
  // }

  resetExtensions() {
    this.categoryList = {};
    this.categoryList = JSON.parse(JSON.stringify(this.tempExtensions));
    this.selectedCategoryItem = this.categoryList[this.selectedExtCategory];
  }

  switchCompany() {
    let userTenants: any = localStorage.getItem('userTenants');
    if (userTenants && userTenants != null) {
      userTenants = JSON.parse(userTenants);
      const dialogRef = this.dialog.open(CustomerSelectComponent, {
        panelClass: 'add-customer-dialog',
        width: '550px',
        height: '380px',
        disableClose: false,
        data: { mode: 0, item: userTenants },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.resetExtensions();
        }
      });
    }
  }
}
