<mat-toolbar class="app-toolbar">
    <span>Add New Field</span>
    <span class="example-spacer"></span>
    <button mat-icon-button onclick="this.blur()" (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>

<div style="margin:15px" [formGroup]="formGroup">
    <mat-form-field appearance="outline">
        <mat-label>Field Type</mat-label>
        <mat-select formControlName="type" required>
            <mat-option [value]="type.value" *ngFor="let type of typeList">
                {{type.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="formGroup.controls.type.value == 'CHOICE'">
        <mat-label>Choice Type</mat-label>
        <mat-select formControlName="choiceType" required>
            <mat-option [value]="choice" *ngFor="let choice of choiceList">
                {{choice}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>Field Name</mat-label>
        <input matInput formControlName="name" placeholder="Enter Field Name" required>
    </mat-form-field>

    <div fxLayout="row" style="margin-bottom:15px">
        <div fxFlex="50" style="margin: auto">
            <mat-checkbox formControlName="isMandatory">
                Is Mandatory
            </mat-checkbox>
        </div>
        <div fxFlex="50">
            <button style="float: right;" [disabled]="!formGroup.valid" (click)="add()"
                [ngClass]="formGroup.valid ? 'success-button' : ''" mat-raised-button>&nbsp;
                &nbsp; Add &nbsp;
                &nbsp;</button>
        </div>
    </div>

    <div *ngIf="errorMsg.length> 0" style="text-align: center;color: rgb(228, 0, 43)">
        <p>{{errorMsg}}</p>
    </div>
</div>