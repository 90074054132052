<mat-toolbar layout="row">
    <span> {{title}} </span>
    <span class="example-spacer"></span>
    <button mat-icon-button (click)="closeDialog()" onclick="this.blur()">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>
<mat-sidenav-container style="height: 92vh;">
    <mat-progress-bar *ngIf="isShowProgressBar" class="primary-color" mode="indeterminate">
    </mat-progress-bar>
    <form [formGroup]="formGroup">
        <mat-form-field appearance="legacy" *ngIf="data.mode == 0">
            <mat-label>Id</mat-label>
            <input autocomplete="off" matInput type="text" formControlName="equipmentId">
        </mat-form-field>

        <mat-form-field appearance="legacy">
            <mat-label>Name</mat-label>
            <input autocomplete="off" matInput type="text" required formControlName="name">
        </mat-form-field>

        <mat-form-field appearance="legacy">
            <mat-label>BOM Id</mat-label>
            <mat-select formControlName="bomId" required placeholder="BOM Id" disableOptionCentering>
                <mat-option *ngFor="let bomId of bomIdList" [value]="bomId.PART_ID">
                    {{bomId.PART_ID}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="legacy">
            <mat-label>Tag</mat-label>
            <input autocomplete="off" matInput type="text" required formControlName="tag">
        </mat-form-field>

        <div style="display:flex">
            <mat-form-field appearance="legacy">
                <mat-label>Category</mat-label>
                <mat-select formControlName="category" required placeholder="Category" disableOptionCentering>
                    <mat-option *ngFor="let cat of locationCategories" [value]="cat.CATEGORY_ID">
                        {{cat.NAME}}</mat-option>
                </mat-select>
            </mat-form-field>

            <button mat-icon-button matTooltip="Add Category" (click)="addCategory();$event.stopPropagation();">
                <mat-icon style="color:#00629b;font-size: 28px;" class="material-icons-outlined">add
                </mat-icon>
            </button>

            <button mat-icon-button matTooltip="Edit Category" (click)="editCategory();$event.stopPropagation();"
                [disabled]="!formGroup.controls.category.valid">
                <mat-icon style="color:#00629b;" class="material-icons-outlined">edit
                </mat-icon>
            </button>
        </div>

        <mat-form-field appearance="legacy" style="margin-bottom: -15px;">
            <mat-label>Description</mat-label>
            <textarea matInput autocomplete="off" type="text" formControlName="description"> </textarea>
        </mat-form-field>

        <!-- <extension-fields> </extension-fields> -->
        <div *ngIf="serviceRequestExtensions">
            <div class="section" *ngFor="let items of serviceRequestExtensions | keyvalue">
                <span class="label">{{items.key}}</span>
                <hr />
                <span *ngFor="let item of items.value| extnPipe">
                    <p class="field-name"> {{item.FIELD_NAME}} <span *ngIf="item.FIELD_MANDATORY == 'Y'"
                            style="color:rgb(228, 0, 43)">*</span></p>

                    <div class="text" *ngIf="item.FIELD_CONTROL == 'TEXT'">
                        <input [(ngModel)]="item.txtFld1" [ngModelOptions]="{standalone: true}" matInput>
                    </div>

                    <div class="text" *ngIf="item.FIELD_CONTROL == 'TEXTAREA'">
                        <textarea matInput [(ngModel)]="item.txtAreaFld1"
                            [ngModelOptions]="{standalone: true}"> </textarea>
                    </div>

                    <div class="text" *ngIf="item.FIELD_CONTROL == 'NUMBER'">
                        <input matInput [(ngModel)]="item.numFld1" [ngModelOptions]="{standalone: true}">
                    </div>

                    <div class="text" *ngIf="item.FIELD_CONTROL == 'CHOICE'">
                        <mat-select disableOptionCentering [(ngModel)]="item.chFld1"
                            [ngModelOptions]="{standalone: true}">
                            <mat-option [value]="item.CHOICE_VALUE" *ngFor="let item of customChoices">
                                {{item.CHOICE_NAME}}</mat-option>
                        </mat-select>
                    </div>

                    <div class="text" *ngIf="item.FIELD_CONTROL == 'TOGGLE'">
                        <mat-button-toggle-group [(ngModel)]="item.tofFld1" [ngModelOptions]="{standalone: true}">
                            <mat-button-toggle value="Y">True</mat-button-toggle>
                            <mat-button-toggle value="N">False</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </span>
            </div>
        </div>

        <div class="submit-button-div">
            <button *ngIf="data.mode == 1" mat-raised-button (click)="saveEquipment()"
                [ngClass]="formGroup.valid ? 'primary-button' : ''" [disabled]="!formGroup.valid">
                <span> &nbsp; Save &nbsp;</span>
            </button>
            <button *ngIf="data.mode == 0" mat-raised-button (click)="updateEquipment()"
                [ngClass]="formGroup.valid ? 'primary-button' : ''" [disabled]="!formGroup.valid">
                <span> Update </span>
            </button>
        </div>
        <div *ngIf="errorMsg.length> 0" class="error-msg-div">
            <p>{{errorMsg}}</p>
        </div>
    </form>
</mat-sidenav-container>