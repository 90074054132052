<mat-toolbar layout="row">
    <span> {{title}} </span>
    <span class="example-spacer"></span>
    <button mat-icon-button (click)="closeDialog()" onclick="this.blur()">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>

<ng-container>
    <mat-progress-bar *ngIf="isShowProgressBar" mode="indeterminate">
    </mat-progress-bar>
    <form style="padding: 15px;font-size: 14.5px !important;" [formGroup]="formGroup">
        <mat-form-field appearance="legacy" style="margin-top: 10px;">
            <mat-label>Category ID</mat-label>
            <input matInput type="text" required formControlName="category">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Select BOMS</mat-label>
            <mat-select formControlName="boms" multiple required>
                <mat-option *ngFor="let item of bomsList" [value]="item.PART_ID">{{item.NAME}}</mat-option>
            </mat-select>
        </mat-form-field>
        <div style="text-align:center;padding-top:10px">
            <button mat-raised-button [ngClass]="formGroup.valid ? 'success-button' : ''" (click)="save()"
                [disabled]="!formGroup.valid || isShowProgressBar">
                <span>&nbsp;&nbsp;&nbsp;&nbsp; Add &nbsp;&nbsp;&nbsp;&nbsp;</span>
            </button>
        </div>

        <div *ngIf="errorMsg.length> 0" style="text-align: center;color: rgb(228, 0, 43)">
            <p>{{errorMsg}}</p>
        </div>
    </form>
</ng-container>