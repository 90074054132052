import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '../app.config';
import { AppConstants } from '../shared/AppConstants';

@Injectable({
  providedIn: 'root',
})
export class ServiceRequestService {
  public filterDetails: any;
  public constants: AppConstants;
  public serviceRequestPageStateDetails: any;

  constructor(
    private appConfigService: AppConfigService,
    private http: HttpClient
  ) {
    this.constants = new AppConstants();
  }

  // Download Service Requests from server
  getServiceRequests(
    limit: number,
    offset: number,
    searchText?: any,
    sortField?: any,
    sortDirection?: any,
    status?: any,
    barcode?: any,
    part?: any,
    unit?: any,
    task?: any,
    location?: any,
    equipmentName?: any,
    scheduleId?: any,
    requestType?: any,
    safety?:any
  ) {
    let tempParams: any = {
      client: 'admin',
      limit: limit,
      offset: offset,
    };
    if (scheduleId) {
      tempParams.refId = scheduleId;
    }
    if (searchText) {
      tempParams.search = searchText;
    }
    if (sortField) {
      tempParams.sortingField = sortField;
    }
    if (sortDirection) {
      tempParams.sortingOrder = sortDirection;
    }
    if (status) {
      tempParams.status = status;
    } else {
      tempParams.status = 'OPEN'
    }
    if (barcode) {
      tempParams.barcode = barcode;
    }
    if (part) {
      tempParams.partno = part;
    }
    if (unit) {
      tempParams.unittype = unit;
    }
    if (task) {
      tempParams.tasktype = task;
    }
    if (location) {
      tempParams.locationId = location;
    }
    if (equipmentName) {
      tempParams.equipmentName = equipmentName;
    }
    if (requestType) {
      tempParams.requestType = requestType;
    }
    if (safety) {
      tempParams.safety = safety;
    }
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      params: tempParams,
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(
      url + this.constants.GET_SERVICE_REQUESTS_URL,
      httpOptions
    );
  }

  // Download Service Request by id from server
  getServiceRequestById(id: any) {
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      params: { client: 'admin' },
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(
      url + this.constants.GET_SERVICE_REQUESTS_URL + `/${id}`,
      httpOptions
    );
  }

  // Update Service Request
  updateServiceRequest(body: any) {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
      params: { client: 'admin' },
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.put<any>(
      url + this.constants.UPDATE_SERVICE_REQUESTS_URL,
      body,
      httpOptions
    );
  }

  // Download Equipment logs by equipment id
  getEquipmentLogsByEquipmentId(id: any) {
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      params: { equipmentId: id },
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(
      url + this.constants.GET_SERVICE_REQUESTS_LOGS_URL,
      httpOptions
    );
  }

  // Download Service Request filter data
  getServiceRequestFilterData() {
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(
      url + this.constants.GET_SERVICE_REQUESTS_FILTER_DATA_URL,
      httpOptions
    );
  }

  // Download Service Request equipment by equipment id
  getEquipmentDetailsByEquipmentId(id: any) {
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      params: { EQUIPMENT_ID: id },
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(
      url + this.constants.GET_SERVICE_REQUESTS_EQUIPMENT_DETAILS_URL,
      httpOptions
    );
  }

  getServiceRequestLogsByRequestId(id: any) {
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      params: { requestId: id },
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(
      url + this.constants.GET_SERVICE_REQUESTS_LOGS_URL,
      httpOptions
    );
  }

  getFleetsAndSubFleets() {
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(url + this.constants.GET_FLEETS_URL, httpOptions);
  }
}
