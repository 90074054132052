import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { BudgetService } from 'src/app/services/budget.service';
import { ConfigService } from 'src/app/services/config.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { ActionType } from 'src/app/shared/AppConstants';
export interface DialogData {
  mode: number;
  item: any;
}
@Component({
  selector: 'app-add-invoice',
  templateUrl: './add-invoice.component.html',
  styleUrls: ['./add-invoice.component.scss']
})
export class AddInvoiceComponent implements OnInit {
  public title: string;
  public formGroup: FormGroup;
  public errorMsg: string;
  public accessModules: any;
  public accessCustomers: any[] = [];
  public loggedInCustomerId: any;
  public isShowProgressBar: boolean = false;
  public file: File[] = [];
  public fleetsList: any[] = [];
  public groupList: any[] = [];
  public budgetStartDate: string = '';
  public fYDateRangeList: any[] = [];

  constructor(
    public invoiceService: InvoiceService,
    public authService: AuthService,
    public router: Router,
    public userDialogRef: MatDialogRef<AddInvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public configService: ConfigService,
    public budgetService: BudgetService
  ) {
    this.errorMsg = '';
    this.title =
      this.data.mode == ActionType.Add ? 'Create Invoice' : 'Update Invoice';

    if (this.data.mode == ActionType.Add) {
      this.formGroup = new FormGroup({
        INVOICE_NUMBER: new FormControl('', [Validators.required]),
        INVOICE_DATE: new FormControl('', [Validators.required]),
        INVOICE_AMOUNT: new FormControl('', [Validators.required]),
        COMMENTS: new FormControl(''),
        PART_GROUP_NAME: new FormControl(''),
      });
    } else {
      this.formGroup = new FormGroup({
        INVOICE_NUMBER: new FormControl(this.data.item.INVOICE_NUMBER, [
          Validators.required
        ]),
        INVOICE_DATE: new FormControl(this.data.item.INVOICE_DATE.replace('Z', ''), [
          Validators.required,
        ]),
        INVOICE_AMOUNT: new FormControl(this.data.item.INVOICE_AMOUNT, [
          Validators.required,
        ]),
        COMMENTS: new FormControl(this.data.item.COMMENTS),
        PART_GROUP_NAME: new FormControl(this.data.item.PART_GROUP_NAME),
      });
    }

    this.formGroup.valueChanges.subscribe((x) => {
      this.errorMsg = '';
    });
  }

  async ngOnInit(): Promise<void> {
    this.loggedInCustomerId = localStorage.getItem('customerId');
    await this.getFYDateRange();
    if (this.data.mode === ActionType.Add) { } else {
      if (this.data.item.INVOICE_DOCUMENT && this.data.item.INVOICE_DOCUMENT.length > 0) {
        var blob = this.base64toBlob(this.data.item.INVOICE_DOCUMENT, 'application/pdf');
        this.file.push(new File([blob], `Invoice_${this.data.item.INVOICE_NUMBER}`, { type: 'application/pdf' }))
      }
      let res = this.isDateInRange(this.formGroup.controls.INVOICE_DATE.value);
      if (res) {
        await this.getBudgetparts(res);
      } else {
        await this.getBudgetparts(new Date(this.formGroup.controls.INVOICE_DATE.value).getFullYear());
      }
    }
  }

  isDateInRange(selectedDate: Date): boolean {
    return this.fYDateRangeList.find(range => new Date(selectedDate) >= new Date(range.FY_START) && new Date(selectedDate) <= new Date(range.FY_END))?.FY || false;
  }

  closeDialog(): void {
    this.userDialogRef.close(false);
  }

  async saveUser() {
    this.isShowProgressBar = true;
    let date = new Date(this.formGroup.controls.INVOICE_DATE.value);
    let removedDate = this.removeTime(date);
    let body: any = {
      INVOICE_NUMBER: this.formGroup.controls.INVOICE_NUMBER.value,
      INVOICE_DATE: removedDate,
      INVOICE_AMOUNT: this.formGroup.controls.INVOICE_AMOUNT.value,
      COMMENTS: this.formGroup.controls.COMMENTS.value,
      PART_GROUP_NAME: this.formGroup.controls.PART_GROUP_NAME.value,
      INVOICE_DOCUMENT: ''
    };

    if (this.file.length > 0) {
      await this.fileToBase64(this.file[0])
        .then(async (base64) => {
          let bString = base64.split("data:application/pdf;base64,");
          body.INVOICE_DOCUMENT = bString[1];
        })
        .catch((err) => {
          this.errorMsg = err.message;
          this.isShowProgressBar = false;
        });
    }

    if (this.data.mode === ActionType.Add) {
      await this.invoiceService
        .createInvoice(body)
        .toPromise()
        .then(
          (response) => {
            this.isShowProgressBar = false;
            if (response.status === 204) {
              this.userDialogRef.close(true);
            } else {
              this.errorMsg = response.message;
            }
          },
          (error) => {
            this.isShowProgressBar = false;
            this.errorMsg = error.error;
          }
        );
    } else {
      body.INVOICE_ID = this.data.item.INVOICE_ID;
      await this.invoiceService
        .updateInvoice(body)
        .toPromise()
        .then(
          (response: any) => {
            this.isShowProgressBar = false;
            if (response.status === 204) {
              this.userDialogRef.close(true);
            } else {
              this.errorMsg = response.message;
            }
          },
          (error) => {
            this.isShowProgressBar = false;
            this.errorMsg = error.error;
          }
        );
    }
  }

  removeTime(date = new Date()) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const twoDigitMonth = month.toString().padStart(2, '0');
    const twoDigitDay = day.toString().padStart(2, '0')
    let finalDate = `${year}-${twoDigitMonth}-${twoDigitDay}T00:00:00Z`;
    return finalDate;
  }

  onSelect(event: any) {
    this.errorMsg = '';
    this.file = [];
    this.fleetsList = [];
    this.file.push(...event.addedFiles);
  }

  onRemove(event: any) {
    this.errorMsg = '';
    this.fleetsList = [];
    this.file.splice(this.file.indexOf(event), 1);
  }

  async onDateChange(event: any): Promise<void> {
    let res = this.isDateInRange(event.value);
    if (res) {
      await this.getBudgetparts(res);
    } else {
      await this.getBudgetparts(new Date(event.value).getFullYear());
    }
  }

  async getFYDateRange() {
    this.isShowProgressBar = true;
    await this.configService
      .getFYDateRange()
      .toPromise()
      .then(
        (response: any) => {
          this.isShowProgressBar = false;
          if (response.status === 200) {
            if (response?.body) {
              this.fYDateRangeList = response?.body;
            } else { this.fYDateRangeList = [] }
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug'); localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.errorMsg = response.error;
          } else {
            this.errorMsg = response.message;
          }
        },
        (error) => {
          this.isShowProgressBar = false;
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug'); localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.errorMsg = error.message;
          } else {
            this.errorMsg = error.message;
          }
        }
      );
  }

  async getBudgetparts(year: any) {
    this.isShowProgressBar = true;
    await this.budgetService
      .getBudgets(999, 0, '', '', '', year)
      .toPromise()
      .then(
        (response: any) => {
          // Success callback

          if (response.status === 200) {
            if (response.body) {
              if (response.body.BUDGETS && response.body.BUDGETS.length > 0) {
                this.groupList = response.body.BUDGETS;
              } else {
                // this.errorMsg = 'Budget is not available for the selected fiscal year.'
              }
            }
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug'); localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.errorMsg = response.error;
          } else {
            this.errorMsg = response.error;
          }
        }
      );
    this.isShowProgressBar = false;
  }

  private fileToBase64(file: any): Promise<any> {
    const reader = new FileReader();
    const future = new Promise((resolve, reject) => {
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result);
        },
        false
      );
      reader.addEventListener(
        'error',
        function (event) {
          reject(event);
        },
        false
      );

      reader.readAsDataURL(file);
    });
    return future;
  }

  base64toBlob(base64Data: string, contentType: string) {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
}
