import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { BillableReportService } from 'src/app/services/billable-report.service';
import { ConfigService } from 'src/app/services/config.service';
import { CustomerService } from 'src/app/services/customer.service';
import { ActionType } from 'src/app/shared/AppConstants';
import { WarningComponent } from '../warning/warning.component';

export interface DialogData {
  mode: number;
  item: any;
}

@Component({
  selector: 'app-add-new-report-type',
  templateUrl: './add-new-report-type.component.html',
  styleUrls: ['./add-new-report-type.component.scss']
})
export class AddNewReportTypeComponent implements OnInit {

  public title: string;
  public errorMsg: string;
  public accessModules: any;
  public accessCustomers: any[] = [];
  public loggedInCustomerId: any;
  public isShowProgressBar: boolean = false;
  public equipmentCategoryList: any[] = [];
  public filteredEquipmentCategoryList: any[] = [];
  public attributesList: any[] = [];
  public filteredattributesList: any[] = [];
  public reportType: string = '';
  public partColumns: string[] = ['No', 'Attribute', 'Category', 'Actions'];
  public partDataSource = new MatTableDataSource<any>([]);

  constructor(
    public customerService: CustomerService,
    public authService: AuthService,
    public router: Router,
    public billableService: BillableReportService,
    public configService: ConfigService,
    public reportDialogRef: MatDialogRef<AddNewReportTypeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
  ) {
    this.errorMsg = '';
    this.title =
      this.data.mode == ActionType.Add ? 'Create Report Type' : 'Update Report Type';

    if (this.data.mode == ActionType.Edit) {
      this.reportType = this.data?.item?.REPORT_NAME;
      console.log(this.data?.item?.REPORT_DEFN)
      let tempArr: any[] = [];
      this.data?.item?.REPORT_DEFN.forEach((element: {
        ATTRIBUTES: any; CATEGORIES: any;
      }) => {
        let tempObj = { ATTRIBUTE_NAME: element.ATTRIBUTES, CATEGORY_ID: element.CATEGORIES, AttributeList: [], CategoryList: [] };
        tempArr.push(tempObj)
      });
      this.partDataSource = new MatTableDataSource<any>(
        tempArr
      );
    } else {
      let tempArr = [
        { ATTRIBUTE_NAME: '', CATEGORY_ID: '', AttributeList: [], CategoryList: [] },
      ]
      this.partDataSource = new MatTableDataSource<any>(
        tempArr
      );
    }
  }

  async ngOnInit(): Promise<void> {
    await this.getEquipmentCategoryAttributes();

    if (this.data.mode == ActionType.Edit) {
      this.partDataSource.data.forEach(element => {
        let distinctCat = this.attributesList.filter((v) => element.ATTRIBUTE_NAME.includes(v.ATTRIBUTE_NAME));
        let finaldistinctAttributes = distinctCat.filter((v, i, a) => a.findIndex(t => (t.CATEGORY_ID === v.CATEGORY_ID)) === i);
        element.CategoryList = finaldistinctAttributes;
      });
    }
  }

  closeDialog(): void {
    this.reportDialogRef.close(false);
  }

  async saveReport() {
    const attributeNames = this.partDataSource.data.map(item => item.ATTRIBUTE_NAME);
    function findDuplicates(arr1: string[], arr2: string[]): string[] {
      return arr1.filter(value => arr2.includes(value));
    }
    let duplicates = []
    for (let i = 0; i < attributeNames.length; i++) {
      for (let j = i + 1; j < attributeNames.length; j++) {
        duplicates = findDuplicates(attributeNames[i], attributeNames[j]);
      }
    }

    if (duplicates.length > 0) {
      this.errorMsg = 'Please make sure there are no duplicate attributes';
      return;
    } else {
      this.isShowProgressBar = true;
      let list: { ATTRIBUTES: any; CATEGORIES: any; }[] = []
      this.partDataSource.data.forEach(element => {
        if (element.ATTRIBUTE_NAME && element.ATTRIBUTE_NAME.length > 0) {
          let obj = {
            ATTRIBUTES: element.ATTRIBUTE_NAME,
            CATEGORIES: element.CATEGORY_ID
          }
          list.push(obj)
        }
      });

      let body: any = {
        REPORT_NAME: this.reportType,
        REPORT_DEFN: JSON.stringify(list)
      }

      if (this.data.mode === ActionType.Add) {
        await this.billableService
          .createReportType(body)
          .toPromise()
          .then(
            (response) => {
              this.isShowProgressBar = false;
              if (response.status === 204) {
                this.reportDialogRef.close(true);
              } else {
                this.errorMsg = response.message;
              }
            },
            (error) => {
              this.isShowProgressBar = false;
              this.errorMsg = error.error;
            }
          );
      } else {
        body.REPORT_ID = this.data.item.REPORT_ID;
        await this.billableService
          .updateReportType(body)
          .toPromise()
          .then(
            (response: any) => {
              this.isShowProgressBar = false;
              if (response.status === 204) {
                this.reportDialogRef.close(true);
              } else {
                this.errorMsg = response.message;
              }
            },
            (error) => {
              this.isShowProgressBar = false;
              this.errorMsg = error.error;
            }
          );
      }
    }
  }

  async getEquipmentCategoryAttributes() {
    this.attributesList = [];
    this.filteredattributesList = [];
    this.isShowProgressBar = true;
    await this.configService
      .getAllEquipmentCategoryAttributes()
      .toPromise()
      .then(
        (response: any) => {
          this.isShowProgressBar = false;
          if (response.status === 200) {
            this.attributesList = response.body;
            this.filteredattributesList = this.attributesList;
            let distinctAttributes = this.attributesList.filter((v, i, a) => a.findIndex(t => (t.ATTRIBUTE_NAME === v.ATTRIBUTE_NAME)) === i);
            for (let i = 0; i < this.partDataSource.data.length; i++) {
              this.partDataSource.data[i].AttributeList = distinctAttributes;
            }
          } else {
            this.isShowProgressBar = false;
            this.errorMsg = response.message;
          }
        },
        (error) => {
          this.isShowProgressBar = false;
          this.errorMsg = error.error;
        }
      );
  }

  addNewRow() {
    let distinctAttributes = this.attributesList.filter((v, i, a) => a.findIndex(t => (t.ATTRIBUTE_NAME === v.ATTRIBUTE_NAME)) === i);
    let obj = { ATTRIBUTE_NAME: '', CATEGORY_ID: '', AttributeList: distinctAttributes, CategoryList: [] };
    let list: any = this.partDataSource.data;
    list.push(obj)
    this.partDataSource = new MatTableDataSource<any>([]);
    this.partDataSource = new MatTableDataSource<any>(
      list
    );
  }

  deleteRow(removeIndex: number) {
    this.partDataSource.data = this.partDataSource.data.filter((i, index) => index !==
      removeIndex);
  }

  attributefilter(search: string, parts: any, index: number) {
    let distinctAttributes = this.attributesList.filter((v, i, a) => a.findIndex(t => (t.ATTRIBUTE_NAME === v.ATTRIBUTE_NAME)) === i);
    if (!parts) {
      this.partDataSource.data[index]['AttributeList'] = distinctAttributes;
      return;
    }
    if (!search) {
      this.partDataSource.data[index]['AttributeList'] = distinctAttributes;
      return;
    }
    search = search.toLowerCase();
    parts = distinctAttributes.filter((attr: any) => attr.ATTRIBUTE_NAME.toLowerCase().indexOf(search) > -1);
    this.partDataSource.data[index]['AttributeList'] = parts;
  }

  categoryFilter(search: string, catList: any, index: number) {
    let distinctAttributes = this.attributesList.filter((v, i, a) => a.findIndex(t => (t.CATEGORY_NAME === v.CATEGORY_NAME)) === i);
    if (!catList) {
      this.partDataSource.data[index]['CategoryList'] = distinctAttributes;
      return;
    }
    if (!search) {
      this.partDataSource.data[index]['CategoryList'] = distinctAttributes;
      return;
    }
    search = search.toLowerCase();
    catList = distinctAttributes.filter((attr: any) => attr.CATEGORY_NAME.toLowerCase().indexOf(search) > -1);
    this.partDataSource.data[index]['CategoryList'] = catList;
  }

  disableAttribute(attr: string) {
    return this.partDataSource.data.some(function (el) {
      return el.ATTRIBUTE_NAME.includes(attr);
    });
  }

  disableCategory(attr: string) {
    return this.partDataSource.data.some(function (el) {
      return el.CATEGORY_ID.includes(attr);
    });
  }

  attributeChange(attr: any, index: number) {
    this.errorMsg = '';
    let distinctAttributes = this.attributesList.filter((v, i, a) => attr.includes(v.ATTRIBUTE_NAME));
    let finaldistinctAttributes = distinctAttributes.filter((v, i, a) => a.findIndex(t => (t.CATEGORY_ID === v.CATEGORY_ID)) === i);
    this.partDataSource.data[index].CATEGORY_ID = '';
    this.partDataSource.data[index].CategoryList = finaldistinctAttributes;
  }

  async deleteReport() {
    const dialogRef = this.dialog.open(WarningComponent, {
      width: '400px',
      data: { errorMsg: 'Are you sure you want to delete this report definition?' },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result.data) {
        this.isShowProgressBar = true;
        let body: any = { REPORT_ID: this.data.item.REPORT_ID };
        await this.billableService
          .deleteReportType(body)
          .toPromise()
          .then(
            (response: any) => {
              this.isShowProgressBar = false;
              if (response.status === 204) {
                this.reportDialogRef.close(true);
              } else {
                this.errorMsg = response?.message;
              }
            },
            (error) => {
              this.isShowProgressBar = false;
              this.errorMsg = error?.message;
            }
          );
      }
    });
  }
}
