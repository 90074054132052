import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ErrorAlertComponent } from '../error alert/error-alert.component';
import { ChoicesService } from 'src/app/services/choices.service';
import { ConfigService } from 'src/app/services/config.service';

export interface DialogData {
  mode: number;
  item: any;
}

@Component({
  selector: 'app-customer-select',
  templateUrl: './customer-select.component.html',
  styleUrls: ['./customer-select.component.scss'],
})
export class CustomerSelectComponent implements OnInit {
  public selectedTenant: any;
  public isShowProgressBar: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<CustomerSelectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public authService: AuthService,
    public router: Router,
    public dialog: MatDialog,
    public choiceService: ChoicesService,
    public configService: ConfigService
  ) { }

  ngOnInit(): void {
    this.selectedTenant = localStorage.getItem('customerId');
  }

  selectedCustomer(customer: any) {
    if (this.data.mode == 0) {
      this.isShowProgressBar = true;
      this.authService.getSwitchtenant(customer.CUSTOMER_ID).subscribe(
        async (response: any) => {
          if (response.status === 200) {
            if (response?.body) {
              if (response?.body?.USER?.MODULE?.length > 0) {
                if (response.body.USER.MODULE.includes('ADMIN')) {
                  // Setting user details to local storage.
                  localStorage.removeItem('token');
                  localStorage.removeItem('email');
                  localStorage.removeItem('name');
                  localStorage.removeItem('user-details');
                  localStorage.removeItem('customerName');
                  localStorage.removeItem('customerId');
                  localStorage.setItem('token', response.body.token);
                  localStorage.setItem('email', response.body.USER.EMAIL);
                  localStorage.setItem('name', response.body.USER.FIRST_NAME);
                  localStorage.setItem('customerName', response.body.CUSTOMER_NAME);
                  localStorage.setItem('customerId', response.body.USER.CUSTOMER_ID);
                  localStorage.setItem('user-details', JSON.stringify(response.body.USER));
                  await this.getCustomization();
                  await this.getConfigurations();
                  this.authService.loggedIn.next(true);
                  if (response?.body?.USER?.CUSTOMER_TYPE == null) {
                    this.isShowProgressBar = false;
                    this.dialogRef.close(true);
                    this.router.navigate(['/users']);
                  } else {
                    this.isShowProgressBar = false;
                    this.dialogRef.close(true);
                  }
                } else {
                  this.isShowProgressBar = false;
                  this.showErrorDialog('You do not have access to the Admin App, check with your Administrator to get access')
                }
              } else {
                this.isShowProgressBar = false;
                this.showErrorDialog('You do not have access to the Admin App, check with your Administrator to get access')
              }
            } else {
              this.isShowProgressBar = false;
              this.showErrorDialog('No response from server');
            }
          } else {
            this.isShowProgressBar = false;
            this.showErrorDialog(response);
          }
        },
        (error) => {
          this.isShowProgressBar = false;
          if (error.status === 401) {
            this.showErrorDialog(error.error);
          } else {
            this.showErrorDialog(error.message);
          }
        }
      );
    } else {
      this.dialogRef.close(customer);
    }
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  showErrorDialog(msg: string) {
    this.dialog.open(ErrorAlertComponent, {
      width: '400px',
      data: {
        errorMsg: msg,
      },
    });
  }

  async getCustomization() {
    await this.choiceService
      .getCustomization()
      .toPromise()
      .then(
        (response: any) => {
          // Success callback
          if (response.status === 200) {
            if (response?.body?.DashboardUrl) {
              localStorage.removeItem('dashboardSlug');
              localStorage.setItem(
                'dashboardSlug',
                response?.body?.DashboardUrl
              );
            }
          }
        })
  }

  async getConfigurations() {
    await this.configService
      .getConfigurations()
      .toPromise()
      .then(
        (response: any) => {
          if (response.status === 200) {
            if (response?.body) {
              if (Object.keys(response?.body).length > 0) {
                let obj = response?.body;
                if (obj?.timezone) {
                  localStorage.removeItem('userTimeZone');
                  localStorage.setItem('userTimeZone', obj?.timezone || '');
                }
              }
            }
          }
        }
      );
  }
}
