import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExtensionsService } from 'src/app/services/extensions.service';

export interface DialogData {
  item: any;
}

@Component({
  selector: 'app-save-extensions-alert',
  templateUrl: './save-extensions-alert.component.html',
  styleUrls: ['./save-extensions-alert.component.scss'],
})
export class SaveExtensionsAlertComponent implements OnInit {
  public errorMsg: string = '';
  public isShowProgressBar: boolean = false;

  constructor(
    public extensionsService: ExtensionsService,
    public dialogRef: MatDialogRef<SaveExtensionsAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {}

  cancel(): void {
    this.dialogRef.close(false);
  }

  async save() {
    this.errorMsg = '';
    this.isShowProgressBar = true;
    let body = {
      EXTENSION: this.data.item,
    };
    await this.extensionsService
      .addExtensions(body)
      .toPromise()
      .then(
        (response) => {
          this.isShowProgressBar = false;
          if (response.status === 204) {
            this.dialogRef.close(true);
          } else {
            this.errorMsg = response.message;
          }
        },
        (error) => {
          this.isShowProgressBar = false;
          this.errorMsg = error.error;
        }
      );
  }
}
