import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { UsersComponent } from './pages/users/users.component';
import { CustomersComponent } from './pages/customers/customers.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AlertComponent } from './components/alert/alert.component';
import { ServiceRequestsComponent } from './pages/service-requests/service-requests.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { AuditsComponent } from './pages/audits/audits.component';
import { AppConfigService } from './app.config';
import { CustomerComponent } from './components/customer/customer.component';
import { ErrorComponent } from './components/error/error.component';
import { UserComponent } from './components/user/user.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ServiceRequestFilterComponent } from './components/service-request-filter/service-request-filter.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ServiceLogsComponent } from './components/service-logs/service-logs.component';
import { CommentComponent } from './components/comment/comment.component';
import { FullScreenImageComponent } from './components/full-screen-image/full-screen-image.component';
import {
  ExtnJsonParserFilterPipe,
  ExtnPipe,
} from './shared/filters/extn-fields-json-filter';
import { JsonParserFilterPipe } from './shared/filters/json-parser-filter';
import { TimeConvertFilterPipe } from './shared/filters/time-convert-filter';
import { EquipmentDetailsComponent } from './components/equipment-details/equipment-details.component';
import {
  ServiceLogIconColorFilterPipe,
  ServiceLogIconFilterPipe,
} from './shared/filters/service-log-icon-filter';
import { AuditEquipmentsComponent } from './components/audit-equipments/audit-equipments.component';
import {
  AuditEquipmentIconColorFilterPipe,
  AuditEquipmentIconFilterPipe,
} from './shared/filters/audit-equipment-icon-filter';
import { AuditFilterComponent } from './components/audit-filter/audit-filter.component';
import { ErrorAlertComponent } from './components/error alert/error-alert.component';
import { AuditScheduleComponent } from './pages/audit-schedule/audit-schedule.component';
import { AuditScheduleDetailsComponent } from './components/audit-schedule-details/audit-schedule-details.component';
import { WarningComponent } from './components/warning/warning.component';
import { UppercaseToLowerCaseFilterPipe } from './shared/filters/upper-case-to-lower-case-filter';
import { LocationsComponent } from './pages/locations/locations.component';
import { NgDialogAnimationService } from 'ng-dialog-animation';
import { AddLocationCategoryComponent } from './components/add-location-category/add-location-category.component';
import { AddSubLocationComponent } from './components/add-sub-location/add-sub-location.component';
import { ExtensionFieldsComponent } from './components/extension-fields/extension-fields.component';
import { AddEquipmentsComponent } from './components/add-equipments/add-equipments.component';
import { CustomerSelectComponent } from './components/customer-select/customer-select.component';
import { AddEquipmentCategoryComponent } from './components/add-equipment-category/add-equipment-category.component';
import { PartsComponent } from './pages/parts/parts.component';
import { AddNewPartComponent } from './components/add-new-part/add-new-part.component';
import { PartsListComponent } from './components/parts-list/parts-list.component';
import { InventoryComponent } from './components/inventory/inventory.component';
import { PartFilterComponent } from './components/part-filter/part-filter.component';
import { LopaComponent } from './pages/lopa/lopa.component';
import { UploadLopaFileComponent } from './components/upload-lopa-file/upload-lopa-file.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { UploadPdfComponent } from './components/upload-pdf/upload-pdf.component';
import { SuccessDialogComponent } from './components/success-dialog/success-dialog.component';
import { LopaDetailsComponent } from './components/lopa-details/lopa-details.component';
import { LopaMessageComponent } from './components/lopa-message/lopa-message.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { ChoicesComponent } from './pages/choices/choices.component';
import { AddChoiceValueComponent } from './components/add-choice-value/add-choice-value.component';
import { ExtensionsComponent } from './pages/extensions/extensions.component';
import { AddNewExtensionFieldComponent } from './components/add-new-extension-field/add-new-extension-field.component';
import { AddNewExtensionSectionComponent } from './components/add-new-extension-section/add-new-extension-section.component';
import { SaveExtensionsAlertComponent } from './components/save-extensions-alert/save-extensions-alert.component';
import { AddNewChoiceCategoryComponent } from './components/add-new-choice-category/add-new-choice-category.component';
import { ScheduledServiceRequestComponent } from './pages/scheduled-service-request/scheduled-service-request.component';
import { AddScheduleServiceRequestComponent } from './components/add-schedule-service-request/add-schedule-service-request.component';
import { MatNativeDateModule } from '@angular/material/core';
import { ConfigManagementComponent } from './pages/config-management/config-management.component';
import { SafePipe } from './shared/filters/safe-url-filter';
import { BudgetComponent } from './pages/budget/budget.component';
import { BudgetDetailsComponent } from './components/budget-details/budget-details.component';
import { ReportDashboardComponent } from './pages/report-dashboard/report-dashboard.component';
import { TestEmailComponent } from './components/test-email/test-email.component';
import { EmailCofirmationComponent } from './components/email-cofirmation/email-cofirmation.component';

import { ScheduledServiceRequestFilterComponent } from './components/scheduled-service-request-filter/scheduled-service-request-filter.component';
import { NofificationsFilterComponent } from './components/nofifications-filter/nofifications-filter.component';
import { SubAuditsComponent } from './pages/sub-audits/sub-audits.component';
import { EquipmentCategoryComponent } from './pages/equipment-category/equipment-category.component';
import { AddAttributeComponent } from './components/add-attribute/add-attribute.component';
import { TimedServicesComponent } from './pages/timed-services/timed-services.component';
import { ManageSlaComponent } from './components/manage-sla/manage-sla.component';
import { InvoiceComponent } from './pages/invoice/invoice.component';
import { AddInvoiceComponent } from './components/add-invoice/add-invoice.component';
import { AddNewBomComponent } from './components/add-new-bom/add-new-bom.component';
import { PartOrdersComponent } from './pages/part-orders/part-orders.component';
import { AddNewPartOrderComponent } from './components/add-new-part-order/add-new-part-order.component';
import { ReceivePartComponent } from './components/receive-part/receive-part.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { BillableReportsComponent } from './pages/billable-reports/billable-reports.component';
import { AddNewReportTypeComponent } from './components/add-new-report-type/add-new-report-type.component'
import { DatePipe } from '@angular/common';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    UsersComponent,
    CustomersComponent,
    AlertComponent,
    ServiceRequestsComponent,
    NotificationsComponent,
    AuditsComponent,
    CustomerComponent,
    ErrorComponent,
    UserComponent,
    PageNotFoundComponent,
    ResetPasswordComponent,
    ServiceRequestFilterComponent,
    TimeConvertFilterPipe,
    JsonParserFilterPipe,
    ExtnJsonParserFilterPipe,
    ServiceLogIconFilterPipe,
    ServiceLogIconColorFilterPipe,
    AuditEquipmentIconFilterPipe,
    AuditEquipmentIconColorFilterPipe,
    UppercaseToLowerCaseFilterPipe,
    SafePipe,
    ExtnPipe,
    ServiceLogsComponent,
    CommentComponent,
    FullScreenImageComponent,
    EquipmentDetailsComponent,
    AuditEquipmentsComponent,
    AuditFilterComponent,
    ErrorAlertComponent,
    AuditScheduleComponent,
    AuditScheduleDetailsComponent,
    WarningComponent,
    LocationsComponent,
    AddLocationCategoryComponent,
    AddSubLocationComponent,
    ExtensionFieldsComponent,
    AddEquipmentsComponent,
    CustomerSelectComponent,
    AddEquipmentCategoryComponent,
    PartsComponent,
    AddNewPartComponent,
    PartsListComponent,
    InventoryComponent,
    PartFilterComponent,
    LopaComponent,
    UploadLopaFileComponent,
    UploadPdfComponent,
    SuccessDialogComponent,
    LopaDetailsComponent,
    LopaMessageComponent,
    SettingsComponent,
    ChoicesComponent,
    AddChoiceValueComponent,
    ExtensionsComponent,
    AddNewExtensionFieldComponent,
    AddNewExtensionSectionComponent,
    SaveExtensionsAlertComponent,
    AddNewChoiceCategoryComponent,
    ScheduledServiceRequestComponent,
    AddScheduleServiceRequestComponent,
    ConfigManagementComponent,
    BudgetComponent,
    BudgetDetailsComponent,
    ReportDashboardComponent,
    TestEmailComponent,
    EmailCofirmationComponent,
    ScheduledServiceRequestFilterComponent,
    NofificationsFilterComponent,
    SubAuditsComponent,
    EquipmentCategoryComponent,
    AddAttributeComponent,
    TimedServicesComponent,
    ManageSlaComponent,
    InvoiceComponent,
    AddInvoiceComponent,
    AddNewBomComponent,
    PartOrdersComponent,
    AddNewPartOrderComponent,
    ReceivePartComponent,
    BillableReportsComponent,
    AddNewReportTypeComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    FlexLayoutModule,
    NgxDropzoneModule,
    MatNativeDateModule,
    NgxMatSelectSearchModule
  ],
  entryComponents: [AlertComponent, CustomerComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AppConfigService],
      useFactory: (appConfigService: AppConfigService) => {
        return () => {
          return appConfigService.loadAppConfig();
        };
      },
    },
    NgDialogAnimationService,
    DatePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
