import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { AppConstants } from 'src/app/shared/AppConstants';
import { Location } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfigService } from 'src/app/services/config.service';
import { ErrorAlertComponent } from 'src/app/components/error alert/error-alert.component';
import { getTimeZones} from "@vvo/tzdb";
import { ManageSlaComponent } from 'src/app/components/manage-sla/manage-sla.component';
import { CustomerSelectComponent } from 'src/app/components/customer-select/customer-select.component';
@Component({
  selector: 'app-config-management',
  templateUrl: './config-management.component.html',
  styleUrls: ['./config-management.component.scss'],
})
export class ConfigManagementComponent implements OnInit {
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  public version: string;
  public release: string;
  public constants: AppConstants;
  public isShowProgressBar: boolean = false;
  public formGroup: FormGroup;
  public errorMsg: string;
  public selectedCustomer: any;
  public isErrorMsg: boolean = true;
  public dateAndMonths: any[] = [];
  public dates:string[] = [];
  public timeZonesList: any[] = [];
  public appropriateClass:string = '';
  public events: string[] = [];
  
  @HostListener('window:resize', ['$event'])
  getScreenHeight(event?: any){
    if(window.innerHeight<=700){
      this.appropriateClass = 'bottomRelative';
    }else{
      this.appropriateClass = 'bottomStick';
    }
  }
  constructor(
    private _location: Location,
    public authService: AuthService,
    public utilService: UtilService,
    public configService: ConfigService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.getScreenHeight();
    this.constants = new AppConstants();
    this.version = this.constants.APP_RELEASE_NUMBER;
    this.release = this.constants.APP_RELEASE_DATE;
    this.errorMsg = '';
    this.formGroup = new FormGroup({
      // sla: new FormControl(''),
      part1: new FormControl(''),
      part2: new FormControl(''),
      part3: new FormControl(''),
      dashboardUrl: new FormControl(''),
      budgetMonth: new FormControl('' , [Validators.required]),
      budgetDate: new FormControl('', [Validators.required]),
      timezone: new FormControl(''),
    });
  }

  async ngOnInit() {
    this.selectedCustomer = localStorage.getItem('customerId');
    if (this.selectedCustomer && this.selectedCustomer != null) {
      this.authService.isAFSUser = this.selectedCustomer == 1 ? true : false;
    }

    this.formGroup.valueChanges.subscribe((x) => {
      this.errorMsg = '';
    });
    let timeZones = getTimeZones();
    timeZones = timeZones.sort((a, b) => a.name < b.name ? -1 : (a.name > b.name ? 1 : 0));
    this.timeZonesList = timeZones;
    this.loadDateAndMonths();
    await this.getConfigurations();
  }

  monthChange(ev: any) {
    this.formGroup.controls.budgetDate.setValue('');
    this.dates = [];
    let selectedMonth = this.dateAndMonths.find(date => date.value == ev?.value);
    for (let i = 0; i < selectedMonth?.dateRange; i++) {
      let d = (i < 9 ? '0' + (i + 1) : i + 1).toString();
      this.dates.push(d);
    }
  }

  isDateSelected(): boolean {
    if(this.formGroup.controls.budgetMonth.value && !this.formGroup.controls.budgetDate.value) { return false}
    else if(!this.formGroup.controls.budgetMonth.value && this.formGroup.controls.budgetDate.value) { return false}
    else if(this.formGroup.controls.budgetMonth.value && this.formGroup.controls.budgetDate.value) { return true}
    else return true;
  }

  loadDateAndMonths() {
    this.dateAndMonths = [
      {
        name: 'January',
        value: '01',
        dateRange: 31
      },
      {
        name: 'February',
        value: '02',
        dateRange: 28
      }, {
        name: 'March',
        value: '03',
        dateRange: 31
      }, {
        name: 'April',
        value: '04',
        dateRange: 30
      }, {
        name: 'May',
        value: '05',
        dateRange: 31
      }, {
        name: 'June',
        value: '06',
        dateRange: 30
      }, {
        name: 'July',
        value: '07',
        dateRange: 31
      }, {
        name: 'August',
        value: '08',
        dateRange: 31
      }, {
        name: 'September',
        value: '09',
        dateRange: 30
      }, {
        name: 'October',
        value: '10',
        dateRange: 31
      }, {
        name: 'November',
        value: '11',
        dateRange: 30
      }, {
        name: 'December',
        value: '12',
        dateRange: 31
      }
    ];
  }

  logout() {
    this.authService.logout();
  }

  goBack() {
    this._location.back();
  }

  async getConfigurations() {
    this.isShowProgressBar = true;
    await this.configService
      .getConfigurations()
      .toPromise()
      .then(
        (response: any) => {
          this.isShowProgressBar = false;
          if (response.status === 200) {
            if (response?.body) {
              if (Object.keys(response?.body).length > 0) {
                let obj = response?.body;
                if (obj?.Part1) {
                  this.formGroup.controls.part1.setValue(obj?.Part1);
                }
                if (obj?.Part2) {
                  this.formGroup.controls.part2.setValue(obj?.Part2);
                }
                if (obj?.Part3) {
                  this.formGroup.controls.part3.setValue(obj?.Part3);
                }
                // if (obj?.sla) {
                //   this.formGroup.controls.sla.setValue(obj?.sla);
                // }
                if (obj?.DashboardUrl) {
                  this.formGroup.controls.dashboardUrl.setValue(
                    obj?.DashboardUrl
                  );
                }
                if (obj?.budgetStartDate) {
                 let arr = obj?.budgetStartDate.split('-');
                 this.formGroup.controls.budgetMonth.setValue(arr[0]);


                this.dates = [];
                let selectedMonth = this.dateAndMonths.find(date => date.value == arr[0]);
                 for (let i = 0; i < selectedMonth?.dateRange; i++) {
                  let d = (i < 9 ? '0' + (i + 1) : i + 1).toString();
                  this.dates.push(d);
                 }
                 this.formGroup.controls.budgetDate.setValue(arr[1]);
                }

                if (obj?.timezone) {
                  let getLocation = this.timeZonesList.find(({ name }) => name === obj?.timezone);
                  if (getLocation == undefined) {
                    this.timeZonesList.push({ name: obj?.timezone });
                    this.timeZonesList = this.timeZonesList.sort((a, b) => a.name < b.name ? -1 : (a.name > b.name ? 1 : 0));
                  }
                  this.formGroup.controls['timezone'].setValue(obj?.timezone);
                }
              }
            }
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.router.navigate(['dashboard']);
          } else {
            this.showErrorAlertDialog(response.message);
          }
        },
        (error) => {
          this.isShowProgressBar = false;
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.router.navigate(['dashboard']);
          } else {
            this.showErrorAlertDialog(error.message);
          }
        }
      );
  }

  showErrorAlertDialog(msg: string) {
    const dialogRef = this.dialog.open(ErrorAlertComponent, {
      width: '400px',
      data: {
        errorMsg: msg,
      },
    });
  }

  async save() {
    this.isShowProgressBar = true;
    let body = {
      // sla: this.formGroup.controls.sla.value,
      DashboardUrl: this.formGroup.controls.dashboardUrl.value
        ? this.formGroup.controls.dashboardUrl.value
        : '',
      Part1: this.formGroup.controls.part1.value ? this.formGroup.controls.part1.value : '',
      Part2: this.formGroup.controls.part2.value ? this.formGroup.controls.part2.value : '',
      Part3: this.formGroup.controls.part3.value ? this.formGroup.controls.part3.value : '',
      budgetStartDate: '',
      timezone: this.formGroup.controls.timezone.value ? this.formGroup.controls.timezone.value : ''
    };
    localStorage.removeItem('userTimeZone');
    localStorage.setItem('userTimeZone', this.formGroup.controls.timezone.value);
    if (this.formGroup.controls.budgetMonth.value && this.formGroup.controls.budgetDate.value) {
      let selectedMonth = this.dateAndMonths.find(date => date.value == this.formGroup.controls.budgetMonth.value);
      let month = selectedMonth.value;
      let date = this.formGroup.controls.budgetDate.value;
      body.budgetStartDate = `${month}-${date}`;
    }

    await this.configService
      .saveConfigurations({ CONFIG: JSON.stringify(body) })
      .toPromise()
      .then(
        (response) => {
          this.isShowProgressBar = false;
          if (response.status === 204) {
            this.isErrorMsg = false;
            this.errorMsg = 'Updated configurations successfully.';
            setTimeout(() => {
              this.errorMsg = '';
              this.isErrorMsg = true;
            }, 2000);
          } else {
            this.isErrorMsg = true;
            this.errorMsg = response.message;
          }
        },
        (error) => {
          this.isErrorMsg = true;
          this.isShowProgressBar = false;
          this.errorMsg = error.error;
        }
      );
  }

  getCurrentTimeZone() {
    let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let getLocation = this.timeZonesList.find(({ name }) => name === timeZone);
    if (getLocation == undefined) {
      this.timeZonesList.push({ name: timeZone });
      this.timeZonesList = this.timeZonesList.sort((a, b) => a.name < b.name ? -1 : (a.name > b.name ? 1 : 0));
    }
    this.formGroup.controls['timezone'].setValue(timeZone);
  }

  manageSla() {
    const dialogRef = this.dialog.open(ManageSlaComponent, {
      width: '50%',
      panelClass: 'add-user-dialog'
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {}
    });
  }

  switchCompany() {
    let userTenants: any = localStorage.getItem('userTenants');
    if (userTenants && userTenants != null) {
      userTenants = JSON.parse(userTenants);
      const dialogRef = this.dialog.open(CustomerSelectComponent, {
        panelClass: 'add-customer-dialog',
        width: '550px',
        height: '380px',
        disableClose: false,
        data: { mode: 0, item: userTenants },
      });

      dialogRef.afterClosed().subscribe(async (result) => {
        if (result) {
          this.selectedCustomer = localStorage.getItem('customerId');
          if (this.selectedCustomer && this.selectedCustomer != null) {
            this.authService.isAFSUser = this.selectedCustomer == 1 ? true : false;
          }
      
          this.formGroup.valueChanges.subscribe((x) => {
            this.errorMsg = '';
          });
          let timeZones = getTimeZones();
          timeZones = timeZones.sort((a, b) => a.name < b.name ? -1 : (a.name > b.name ? 1 : 0));
          this.timeZonesList = timeZones;
          this.loadDateAndMonths();
          await this.getConfigurations();
        }
      });
    }
  }
}
