import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from '../app.config';
import { AppConstants } from '../shared/AppConstants';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  public constants: AppConstants;
  constructor(
    private appConfigService: AppConfigService,
    private http: HttpClient
  ) {
    this.constants = new AppConstants();
  }

  // Download customers from servevr
  getCustomers(
    limit: number,
    offset: number,
    searchText?: any,
    sortField?: any,
    sortDirection?: any
  ) {
    let tempParams: any = {
      limit: limit,
      offset: offset,
    };
    if (searchText) {
      tempParams.search = searchText;
    }
    if (sortField) {
      tempParams.sortingField = sortField;
    }
    if (sortDirection) {
      tempParams.sortingOrder = sortDirection;
    }
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      params: tempParams,
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(
      url + this.constants.GET_CUSTOMERS_URL,
      httpOptions
    );
  }

  // Create new customer
  createCustomer(body: any): Observable<any> {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.post<any>(
      url + this.constants.UPDATE_CUSTOMER_URL,
      body,
      httpOptions
    );
  }

  // Update customer
  updateCustomer(body: any) {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.put<any>(
      url + this.constants.UPDATE_CUSTOMER_URL,
      body,
      httpOptions
    );
  }
}
