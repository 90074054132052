<mat-toolbar>
  <span>{{title}}</span>
  <span class="example-spacer"></span>
  <button mat-icon-button (click)="closeDialog()" onclick="this.blur()">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>
<mat-progress-bar *ngIf="isShowProgressBar" mode="indeterminate"></mat-progress-bar>

<mat-dialog-content>
  <form style="font-size: 14px !important;" [formGroup]="formGroup">

    <table style="width:100%" cellspacing="0">
      <tr>
        <td style="padding-right: 10px;">
          <mat-form-field appearance="legacy">
            <mat-label>Name</mat-label>
            <input matInput type="text" required formControlName="name">
          </mat-form-field>
        </td>
        <td style="padding-left: 10px;">
          <mat-form-field appearance="legacy">
            <mat-label>Type</mat-label>
            <mat-select formControlName="type" disableOptionCentering>
              <mat-option value="AIRLINE">AIRLINE</mat-option>
              <mat-option value="AIRPORT">AIRPORT</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </tr>
    </table>

    <mat-form-field appearance="legacy" *ngIf="data.mode == 2">
      <mat-label>Customer Id</mat-label>
      <input matInput type="text" required formControlName="customerId">
    </mat-form-field>

    <mat-form-field appearance="legacy">
      <mat-label>Address</mat-label>
      <textarea matInput formControlName="address"></textarea>
    </mat-form-field>

    <table style="width:100%" cellspacing="0">
      <tr>
        <td style="padding-right: 10px;">
          <mat-form-field appearance="legacy">
            <mat-label>City</mat-label>
            <input matInput type="text" required formControlName="city">
          </mat-form-field>
        </td>
        <td style="padding-left: 10px;">
          <mat-form-field appearance="legacy">
            <mat-label>State</mat-label>
            <input matInput type="text" required formControlName="state">
          </mat-form-field>
        </td>
      </tr>
    </table>

    <table style="width:100%" cellspacing="0">
      <tr>
        <td style="padding-right: 10px;">
          <mat-form-field appearance="legacy">
            <mat-label>Country</mat-label>
            <input matInput type="text" required formControlName="country">
          </mat-form-field>
        </td>
        <td style="padding-left: 10px;">
          <mat-form-field appearance="legacy">
            <mat-label>Zipcode</mat-label>
            <input matInput type="text" required formControlName="zipcode">
          </mat-form-field>
        </td>
      </tr>
    </table>

    <mat-card *ngIf="data.mode == 1">
      <mat-card-subtitle style="color: rgb(92, 92, 92);">Administrator</mat-card-subtitle>
      <mat-card-content>
        <table style="width:100%" cellspacing="0">
          <tr>
            <td style="width: 48%;">
              <mat-form-field appearance="legacy" style="margin-bottom: -5px">
                <mat-label>First Name</mat-label>
                <input matInput type="text" required formControlName="adminFirstName">
              </mat-form-field>
            </td>
            <td style="padding-left: 20px;">
              <mat-form-field appearance="legacy" style="margin-bottom: -5px">
                <mat-label>Last Name</mat-label>
                <input matInput type="text" required formControlName="adminLastName">
              </mat-form-field>
            </td>
          </tr>
        </table>
        <mat-form-field appearance="legacy" style="margin-bottom: -5px" style="width: 100%;">
          <mat-label>Email</mat-label>
          <input matInput type="email" required formControlName="adminEmail">
        </mat-form-field>
      </mat-card-content>
    </mat-card>

    <div *ngIf="errorMsg.length> 0" style="text-align: center;color: rgb(228, 0, 43)">
      <p>{{errorMsg}}</p>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button (click)="saveCustomer()" mat-raised-button [ngClass]="formGroup.valid ? 'primary-button' : ''"
    [disabled]="!formGroup.valid">
    <span *ngIf="data.mode == 1"> Create </span>
    <span *ngIf="data.mode == 2"> Update </span>
  </button>
</mat-dialog-actions>