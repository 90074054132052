import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from '../app.config';
import { AppConstants } from '../shared/AppConstants';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public constants: AppConstants;
  constructor(
    private appConfigService: AppConfigService,
    private http: HttpClient
  ) {
    this.constants = new AppConstants();
  }

  // Download users from servevr
  getUsers(
    limit: number,
    offset: number,
    searchText?: any,
    sortField?: any,
    sortDirection?: any
  ) {
    let tempParams: any = {
      limit: limit,
      offset: offset,
    };
    if (searchText) {
      tempParams.search = searchText;
    }
    if (sortField) {
      tempParams.sortingField = sortField == 'NAME'? 'FIRST_NAME': sortField;
    }
    if (sortDirection) {
      tempParams.sortingOrder = sortDirection;
    }
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      params: tempParams,
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(url + this.constants.GET_USERS_URL, httpOptions);
  }

  // Create new User
  createUser(body: any): Observable<any> {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.post<any>(
      url + this.constants.UPDATE_USER_URL,
      body,
      httpOptions
    );
  }

  // Update User
  updateUser(body: any) {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.put<any>(
      url + this.constants.UPDATE_USER_URL,
      body,
      httpOptions
    );
  }

  getModules() {
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(
      url + this.constants.GET_USER_ACCESS_MODULES_URL,
      httpOptions
    );
  }

  testEmail(body: any) {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.post<any>(
      url + this.constants.TEST_EMAIL_URL,
      body,
      httpOptions
    );
  }
}
