import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AuthService } from 'src/app/services/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { CustomerService } from 'src/app/services/customer.service';
import { CustomerComponent } from 'src/app/components/customer/customer.component';
import { ActionType, AppConstants } from 'src/app/shared/AppConstants';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { Router } from '@angular/router';
import { ErrorAlertComponent } from 'src/app/components/error alert/error-alert.component';
import { CustomerSelectComponent } from 'src/app/components/customer-select/customer-select.component';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
})
export class CustomersComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator!: any;
  @ViewChild(MatSort, { static: true }) sort!: any;

  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  public tableColumns: string[] = [
    'CUSTOMER_ID',
    'NAME',
    'TYPE',
    'ADDRESS',
    'CITY',
    'STATE',
    'COUNTRY',
    'ZIPCODE',
    'Actions',
  ];

  public customersDataSource: MatTableDataSource<any>;
  public searchTerm: string;
  public isSearchHasValue: boolean;
  public isShowCustomersProgressBar: boolean;
  public usersLength: number;
  public paginatorLength: number;
  public constants: AppConstants;
  public subscriptions: Subscription[] = [];
  public loggedInCustomerId: any;
  public timeout: any = null;
  public version: string;
  public release: string;
  public appropriateClass:string = '';
  public events: string[] = [];
  
  @HostListener('window:resize', ['$event'])
  getScreenHeight(event?: any){
    if(window.innerHeight<=700){
      this.appropriateClass = 'bottomRelative';
    }else{
      this.appropriateClass = 'bottomStick';
    }
  }
  constructor(
    public authService: AuthService,
    private breakpointObserver: BreakpointObserver,
    public utilService: UtilService,
    public dialog: MatDialog,
    public customerService: CustomerService,
    public router: Router
  ) {
    this.getScreenHeight();
    this.constants = new AppConstants();
    this.version = this.constants.APP_RELEASE_NUMBER;
    this.release = this.constants.APP_RELEASE_DATE;
    this.searchTerm = '';
    this.usersLength = 0;
    this.paginatorLength = this.constants.INITIAL_PAGINATOR_LIMIT;
    this.isSearchHasValue = false;
    this.isShowCustomersProgressBar = false;
    this.customersDataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    let customerId: any = localStorage.getItem('customerId');
    if (customerId && customerId != null) {
      if (customerId == 1) {
        this.authService.isAFSUser = true;
      } else {
        this.authService.isAFSUser = false;
      }
    }
    let user: any = localStorage.getItem('user-details');
    if (user && user != null) {
      user = JSON.parse(user);
      this.loggedInCustomerId = user.CUSTOMER_ID;
    }

    this.getCustomers(this.paginatorLength, 0);
  }

  ngOnDestroy() {
    this.cancelOutstandingRequests();
  }

  // Logout from app
  logout() {
    this.authService.logout();
  }

  // Download customers from server
  getCustomers(
    limit: number,
    offset: number,
    searchText?: any,
    sortField?: any,
    sortDirection?: any
  ) {
    this.isShowCustomersProgressBar = true;
    let customerSubscription = this.customerService
      .getCustomers(limit, offset, searchText, sortField, sortDirection)
      .subscribe(
        (response: any) => {
          // Success callback
          if (response.status === 200) {
            if (response.body) {
              // Assign the data to the data source for the table to render
              this.customersDataSource = new MatTableDataSource(
                response.body.CUSTOMERS
              );
              this.usersLength = response.body.CUSTOMERS_COUNT;
            }
            this.isShowCustomersProgressBar = false;
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.isShowCustomersProgressBar = false;
            // this.showErrorDialog(response.error);
            this.router.navigate(['dashboard']);
          } else {
            this.isShowCustomersProgressBar = false;
            this.showErrorDialog(response.error);
          }
        },
        (error) => {
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.isShowCustomersProgressBar = false;
            // this.showErrorDialog(error.error);
            this.router.navigate(['dashboard']);
          } else {
            this.isShowCustomersProgressBar = false;
            this.showErrorDialog(error.message);
          }
        }
      );
    this.subscriptions.push(customerSubscription);
  }

  // Setting paginator and sort for table
  ngAfterViewInit() {}

  sortfields(e: any) {
    let offset = this.paginator._pageIndex * this.paginator._pageSize;
    this.getCustomers(
      this.paginatorLength,
      offset,
      this.searchTerm != null && this.searchTerm != '' ? this.searchTerm : '',
      e.active,
      e.direction
    );
    this.paginator.pageIndex = this.paginator._pageIndex;
  }

  //Paginator page items handle
  handlePaginator(e: any) {
    this.paginatorLength = e.pageSize;
    let limit = e.pageSize;
    let offset = e.pageIndex * limit;
    this.paginator.pageIndex = e.pageIndex;
    if (this.sort.active) {
      this.getCustomers(
        limit,
        offset,
        this.searchTerm != null && this.searchTerm != '' ? this.searchTerm : '',
        this.sort.active,
        this.sort._direction
      );
    } else {
      this.getCustomers(
        limit,
        offset,
        this.searchTerm != null && this.searchTerm != '' ? this.searchTerm : ''
      );
    }
  }

  refreshCustomers() {
    this.searchTerm = '';
    this.cancelOutstandingRequests();
    this.paginator.pageIndex = 0;
    this.paginatorLength = this.constants.INITIAL_PAGINATOR_LIMIT;
    this.getCustomers(this.paginatorLength, 0);
  }

  // Search filter
  searchCustomerFilter(event: any) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if ($this.sort.active) {
        $this.getCustomers(
          $this.paginatorLength,
          0,
          event.target.value,
          $this.sort.active,
          $this.sort._direction
        );
      } else {
        $this.getCustomers($this.paginatorLength, 0, event.target.value);
      }
      $this.paginator.pageIndex = 0;
    }, 1000);
  }

  clearSearch() {
    this.cancelOutstandingRequests();
    this.searchTerm = '';
    this.paginator.pageIndex = 0;
    this.getCustomers(this.paginatorLength, 0);
  }

  // Create new customer
  addNewCustomer() {
    const dialogRef = this.dialog.open(CustomerComponent, {
      width: '700px',
      data: { mode: ActionType.Add, item: {} },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.paginator.pageIndex = 0;
        this.getCustomers(this.paginatorLength, 0);
      }
    });
  }

  // Update customer
  updateCustomer(customer: any) {
    const dialogRef = this.dialog.open(CustomerComponent, {
      width: '600px',
      data: { mode: ActionType.Edit, item: customer },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let offset = this.paginator._pageIndex * this.paginator._pageSize;
        this.paginator.pageIndex = this.paginator._pageIndex;

        if (this.sort.active) {
          this.getCustomers(
            this.paginatorLength,
            offset,
            this.searchTerm != null && this.searchTerm != ''
              ? this.searchTerm
              : '',
            this.sort.active,
            this.sort._direction
          );
        } else {
          this.getCustomers(
            this.paginatorLength,
            offset,
            this.searchTerm != null && this.searchTerm != ''
              ? this.searchTerm
              : ''
          );
        }
      }
    });
  }

  // Soft deactivate existing customer
  deactivateCustomer(customer: any) {
    const dialogRef = this.dialog.open(AlertComponent, {
      width: '400px',
      data: { errorMsg: 'Are you sure you want to deactivate this customer?' },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result.data) {
        let body = {
          CUSTOMER_ID: customer.CUSTOMER_ID,
          DISABLED: 'Y',
        };

        await this.customerService
          .updateCustomer(body)
          .toPromise()
          .then(
            (response: any) => {
              if (response.status === 204) {
                let offset =
                  this.paginator._pageIndex * this.paginator._pageSize;
                this.paginator.pageIndex = this.paginator._pageIndex;
                if (this.sort.active) {
                  this.getCustomers(
                    this.paginatorLength,
                    offset,
                    this.searchTerm != null && this.searchTerm != ''
                      ? this.searchTerm
                      : '',
                    this.sort.active,
                    this.sort._direction
                  );
                } else {
                  this.getCustomers(
                    this.paginatorLength,
                    offset,
                    this.searchTerm != null && this.searchTerm != ''
                      ? this.searchTerm
                      : ''
                  );
                }
              } else {
                this.showErrorDialog(response?.message);
              }
            },
            (error) => {
              this.showErrorDialog(error?.message);
            }
          );
      }
    });
  }

  // Activate customer
  activateCustomer(customer: any) {
    const dialogRef = this.dialog.open(AlertComponent, {
      width: '400px',
      data: { errorMsg: 'Are you sure you want to activate this customer?' },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result.data) {
        let body = {
          CUSTOMER_ID: customer.CUSTOMER_ID,
          DISABLED: 'N',
        };

        await this.customerService
          .updateCustomer(body)
          .toPromise()
          .then(
            (response: any) => {
              if (response.status === 204) {
                let offset =
                  this.paginator._pageIndex * this.paginator._pageSize;
                if (this.sort.active) {
                  this.getCustomers(
                    this.paginatorLength,
                    offset,
                    this.searchTerm != null && this.searchTerm != ''
                      ? this.searchTerm
                      : '',
                    this.sort.active,
                    this.sort._direction
                  );
                } else {
                  this.getCustomers(
                    this.paginatorLength,
                    offset,
                    this.searchTerm != null && this.searchTerm != ''
                      ? this.searchTerm
                      : ''
                  );
                }
                this.paginator.pageIndex = this.paginator._pageIndex;
              } else {
                this.showErrorDialog(response?.error);
              }
            },
            (error) => {
              this.showErrorDialog(error?.message);
            }
          );
      }
    });
  }

  // Error dialog when any server erros
  showErrorDialog(msg: string) {
    const dialogRef = this.dialog.open(ErrorAlertComponent, {
      width: '400px',
      data: {
        errorMsg: msg,
      },
    });
  }

  // Cancel on going http calls when page leaving
  cancelOutstandingRequests() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  switchCompany() {
    let userTenants: any = localStorage.getItem('userTenants');
    if (userTenants && userTenants != null) {
      userTenants = JSON.parse(userTenants);
      const dialogRef = this.dialog.open(CustomerSelectComponent, {
        panelClass: 'add-customer-dialog',
        width: '550px',
        height: '380px',
        disableClose: false,
        data: { mode: 0, item: userTenants },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
        this.refreshCustomers();
        }
      });
    }
  }
}
