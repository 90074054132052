import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from '../app.config';
import { AppConstants } from '../shared/AppConstants';

@Injectable({
  providedIn: 'root',
})
export class ChoicesService {
  public constants: AppConstants;

  constructor(
    private appConfigService: AppConfigService,
    private http: HttpClient
  ) {
    this.constants = new AppConstants();
  }

  getCustomization() {
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(
      url + this.constants.GET_CUSTOMIZATION_URL,
      httpOptions
    );
  }

  addChoice(body: any): Observable<any> {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.post<any>(
      url + this.constants.CHOICE_ADD_URL,
      body,
      httpOptions
    );
  }

  updateChoice(body: any): Observable<any> {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.put<any>(
      url + this.constants.CHOICE_ADD_URL,
      body,
      httpOptions
    );
  }

  deleteChoice(body: any): Observable<any> {
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      body: body,
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.delete<any>(
      url + this.constants.CHOICE_ADD_URL,
      httpOptions
    );
  }
}
