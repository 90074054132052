<div>
    <div class="icon">
        <mat-icon>error_outline</mat-icon>
    </div>
    <div class="title">
        <!-- <h1 mat-dialog-title>Alert</h1> -->
        <p>{{data.errorMsg}}</p>
    </div>
    <div mat-dialog-actions align="center" style="padding-top:10px;">
        <button class="mat-raised-button" (click)="cancel()">NO</button>
        <button mat-raised-button class="confirm-button" (click)="save()">YES</button>
    </div>
</div>