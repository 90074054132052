import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'serviceLogIconFilter',
})
export class ServiceLogIconFilterPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) return null;

    let icon = '';
    switch (value) {
      case 'PAUSE':
        icon = 'pause';
        break;
      case 'RESUME':
        icon = 'play_arrow';
        break;
      case 'MOVEMENT':
        icon = 'local_shipping';
        break;
      case 'CREATE':
        icon = 'create';
        break;
      case 'UPDATE':
        icon = 'drive_file_rename_outline';
        break;
    }
    return icon;
  }
}

@Pipe({
  name: 'serviceLogIconColorFilter',
})
export class ServiceLogIconColorFilterPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) return null;

    let color = '';
    switch (value) {
      case 'PAUSE':
        color = '#ff9800';
        break;
      case 'RESUME':
        color = '#7eb93b';
        break;
      case 'MOVEMENT':
        color = '#00629b';
        break;
      case 'CREATE':
        color = '#00629b';
        break;
      case 'UPDATE':
        color = 'rgb(228, 0, 43)';
        break;
    }
    return color;
  }
}
