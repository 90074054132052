import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { BudgetService } from 'src/app/services/budget.service';
import { ConfigService } from 'src/app/services/config.service';
import { PartOrderService } from 'src/app/services/part-order.service';
import { PartService } from 'src/app/services/part.service';
import { ActionType } from 'src/app/shared/AppConstants';

export interface DialogData {
  mode: number;
  item: any;
}

@Component({
  selector: 'app-add-new-part-order',
  templateUrl: './add-new-part-order.component.html',
  styleUrls: ['./add-new-part-order.component.scss']
})
export class AddNewPartOrderComponent implements OnInit {
  public title: string;
  public formGroup: FormGroup;
  public errorMsg: string;
  public accessModules: any;
  public accessCustomers: any[] = [];
  public loggedInCustomerId: any;
  public isShowProgressBar: boolean = false;
  public file: File[] = [];
  public groupList: any[] = [];
  public manufacturerList: any[] = [];
  public budgetStartDate: string = '';
  public selectedPartsArray: any[] = [];
  public isShowPartsProgressBar: boolean = false;
  public partDataSource = new MatTableDataSource<any>([]);
  public partColumns: string[] = ['No', 'PART_ID', 'QUANTITY', 'UNIT_PRICE', 'EXTENDED_PRICE', 'Actions'];
  public partGroupsList: any[] = [];
  public totalCost: any = 0;
  public fYDateRangeList: any[] = [];
  public partGroupsFilteredList: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  constructor(
    public authService: AuthService,
    public router: Router,
    public partOrderDialogRef: MatDialogRef<AddNewPartOrderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public configService: ConfigService,
    public budgetService: BudgetService,
    public partService: PartService,
    public partOrderService: PartOrderService
  ) {
    this.partDataSource = new MatTableDataSource<any>(
      []
    );
    this.errorMsg = '';
    this.title =
      this.data.mode == ActionType.Add ? 'Create Part Order' : 'Update Part Order';
    if (this.data.mode == ActionType.Add) {
      this.formGroup = new FormGroup({
        ORDER_NUMBER: new FormControl('', [Validators.required]),
        ORDER_DATE: new FormControl('', [Validators.required]),
        SHIP_DATE: new FormControl('', [Validators.required]),
        SHIPPING_COST: new FormControl('', [Validators.required]),
        MISC_COST: new FormControl('', [Validators.required, Validators.min(0), Validators.max(100), Validators.pattern('[0-9.]*')]),
        MANUFACTURER: new FormControl('', [Validators.required]),
        BUDGET_GROUP: new FormControl('', [Validators.required]),
      });
      this.selectedPartsArray = [
        { PART_ID: '', UNIT_PRICE: null, QUANTITY: null, PART_lIST: [] },
        { PART_ID: '', UNIT_PRICE: null, QUANTITY: null, PART_lIST: [] },
        { PART_ID: '', UNIT_PRICE: null, QUANTITY: null, PART_lIST: [] },
        { PART_ID: '', UNIT_PRICE: null, QUANTITY: null, PART_lIST: [] },
        { PART_ID: '', UNIT_PRICE: null, QUANTITY: null, PART_lIST: [] },
        { PART_ID: '', UNIT_PRICE: null, QUANTITY: null, PART_lIST: [] },
        { PART_ID: '', UNIT_PRICE: null, QUANTITY: null, PART_lIST: [] },
        { PART_ID: '', UNIT_PRICE: null, QUANTITY: null, PART_lIST: [] },
        { PART_ID: '', UNIT_PRICE: null, QUANTITY: null, PART_lIST: [] },
        { PART_ID: '', UNIT_PRICE: null, QUANTITY: null, PART_lIST: [] },
      ]
      this.partDataSource = new MatTableDataSource<any>(
        this.selectedPartsArray
      );
    } else {
      this.formGroup = new FormGroup({
        ORDER_NUMBER: new FormControl({ value: this.data.item.ORDER_NUMBER, disabled: true }, [
          Validators.required
        ]),
        ORDER_DATE: new FormControl({ value: this.data.item.ORDER_DATE.replace('Z', ''), disabled: true }, [Validators.required]),
        SHIP_DATE: new FormControl({ value: this.data.item.ORDER_DATE.replace('Z', ''), disabled: true }, [Validators.required]),
        SHIPPING_COST: new FormControl({ value: this.data.item.SHIPPING_COST, disabled: false }, [Validators.required]),
        MISC_COST: new FormControl({ value: null, disabled: false }, [Validators.required, Validators.min(0), Validators.max(100), Validators.pattern('[0-9.]*')]),
        MANUFACTURER: new FormControl({ value: this.data.item.MANUFACTURER, disabled: true }, [Validators.required]),
        BUDGET_GROUP: new FormControl({ value: this.data.item.BUDGET_GROUP, disabled: true }, [Validators.required]),
      });

    }

    this.formGroup.valueChanges.subscribe((x) => {
      this.errorMsg = '';
    });

    this.formGroup.controls.SHIPPING_COST.valueChanges.subscribe((x) => {
      this.getTotalCost();
    });

    this.formGroup.controls.MISC_COST.valueChanges.subscribe((x) => {
      this.getTotalCost();
    });

  }

  async ngOnInit(): Promise<void> {
    this.partGroupsFilteredList.next(this.partGroupsList.slice());
    this.loggedInCustomerId = localStorage.getItem('customerId');
    await this.getFYDateRange();
    await this.getManufatures();
    if (this.data.mode != ActionType.Add) {
      if (this.data.item.ORDER_DOCUMENT && this.data.item.ORDER_DOCUMENT.length > 0) {
        var blob = this.base64toBlob(this.data.item.ORDER_DOCUMENT, 'application/pdf');
        this.file.push(new File([blob], `Part_Order_${this.data.item.ORDER_NUMBER}`, { type: 'application/pdf' }))
      }
      let res = this.isDateInRange(this.formGroup.controls.ORDER_DATE.value);
      if (res) {
        await this.getBudgetparts(res);
      } else {
        await this.getBudgetparts(new Date(this.formGroup.controls.ORDER_DATE.value).getFullYear());
      }
      await this.getOrderDetailsByOrderNumber();
      const baseCost = this.partDataSource.data.reduce((acc, element) => acc + element.QUANTITY * element.UNIT_PRICE, 0);
      const totalCost = this.formGroup.controls.SHIPPING_COST.value + baseCost;
      let miscCost = ((this.data.item.MISC_COST / totalCost) * 100).toFixed(2);
      if (miscCost.endsWith('.00')) {
        miscCost = miscCost.slice(0, -3);
      }
      this.formGroup.controls.MISC_COST.setValue(miscCost);
    }
  }

  closeDialog(): void {
    this.partOrderDialogRef.close(false);
  }

  private fileToBase64(file: any): Promise<any> {
    const reader = new FileReader();
    const future = new Promise((resolve, reject) => {
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result);
        },
        false
      );
      reader.addEventListener(
        'error',
        function (event) {
          reject(event);
        },
        false
      );

      reader.readAsDataURL(file);
    });
    return future;
  }

  base64toBlob(base64Data: string, contentType: string) {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  async getFYDateRange() {
    this.isShowProgressBar = true;
    await this.configService
      .getFYDateRange()
      .toPromise()
      .then(
        (response: any) => {
          this.isShowProgressBar = false;
          if (response.status === 200) {
            if (response?.body) {
              this.fYDateRangeList = response?.body;
            } else { this.fYDateRangeList = [] }
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug'); localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.errorMsg = response.error;
          } else {
            this.errorMsg = response.message;
          }
        },
        (error) => {
          this.isShowProgressBar = false;
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug'); localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.errorMsg = error.message;
          } else {
            this.errorMsg = error.message;
          }
        }
      );
  }

  async saveOrder() {
    let list: any = [];
    this.partDataSource.data.forEach(element => {
      if (element.PART_ID != '' && element.PART_ID != null && element.PART_ID != undefined) {
        list.push(element);
      }
    });
    if (list.length == 0) {
      this.errorMsg = 'select at least one part to place an order.'
    } else {
      this.isShowProgressBar = true;
      const baseCost = this.partDataSource.data.reduce((acc, element) => acc + element.QUANTITY * element.UNIT_PRICE, 0);
      const shippingCost = this.formGroup.controls.SHIPPING_COST.value;
      const totalCost = this.getTotalCost() - (baseCost + shippingCost);
      let body: any = {
        ORDER_NUMBER: this.formGroup.controls.ORDER_NUMBER.value,
        ORDER_DATE: this.removeTime(new Date(this.formGroup.controls.ORDER_DATE.value)),
        SHIP_DATE: this.removeTime(new Date(this.formGroup.controls.SHIP_DATE.value)),
        SHIPPING_COST: shippingCost,
        MISC_COST: totalCost.toFixed(2),
        BUDGET_GROUP: this.formGroup.controls.BUDGET_GROUP.value,
        MANUFACTURER: this.formGroup.controls.MANUFACTURER.value,
        PART_DETAILS: [],
        ORDER_DOCUMENT: ''
      };

      if (this.file.length > 0) {
        await this.fileToBase64(this.file[0])
          .then(async (base64) => {
            let bString = base64.split("data:application/pdf;base64,");
            body.ORDER_DOCUMENT = bString[1];
          })
          .catch((err) => {
            this.errorMsg = err.message;
            this.isShowProgressBar = false;
          });
      }

      list.forEach((element: { PART_ID: any; UNIT_PRICE: any; QUANTITY: any; }) => {
        let obj = {
          "PART_ID": element.PART_ID,
          "UNIT_PRICE": element.UNIT_PRICE,
          "QUANTITY": element.QUANTITY,
        }
        body.PART_DETAILS.push(obj)
      })
      body.PART_DETAILS = JSON.stringify(body.PART_DETAILS);
      if (this.data.mode === ActionType.Add) {
        await this.partOrderService
          .createOrder(body)
          .toPromise()
          .then(
            (response) => {
              this.isShowProgressBar = false;
              if (response.status === 204) {
                this.partOrderDialogRef.close(true);
              } else {
                this.errorMsg = response.message;
              }
            },
            (error) => {
              this.isShowProgressBar = false;
              this.errorMsg = error.error;
            }
          );
      } else {
        body.ORDER_ID = this.data.item.ORDER_ID;
        await this.partOrderService
          .updateOrder(body)
          .toPromise()
          .then(
            (response: any) => {
              this.isShowProgressBar = false;
              if (response.status === 204) {
                this.partOrderDialogRef.close(true);
              } else {
                this.errorMsg = response.message;
              }
            },
            (error) => {
              this.isShowProgressBar = false;
              this.errorMsg = error.error;
            }
          );
      }
    }
  }

  removeTime(date = new Date()) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const twoDigitMonth = month.toString().padStart(2, '0');
    const twoDigitDay = day.toString().padStart(2, '0')
    let finalDate = `${year}-${twoDigitMonth}-${twoDigitDay}T00:00:00Z`;
    return finalDate;
  }

  onSelect(event: any) {
    this.errorMsg = '';
    this.file = [];
    this.file.push(...event.addedFiles);
  }

  onRemove(event: any) {
    this.errorMsg = '';
    this.file.splice(this.file.indexOf(event), 1);
  }

  async onOrderDateChange(event: any) {
    let res = this.isDateInRange(event.value);
    if (res) {
      await this.getBudgetparts(res);
    } else {
      await this.getBudgetparts(new Date(event.value).getFullYear());
    }
  }

  isDateInRange(selectedDate: Date): boolean {
    for (const range of this.fYDateRangeList) {
      if (selectedDate >= new Date(range.FY_START) && selectedDate <= new Date(range.FY_END)) {
        return range.FY;
      }
    }
    return false;
  }

  onShipDateChange(ev: any) {

  }

  searchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.viewValue.toLocaleLowerCase().indexOf(term) > -1 || item.value.toLocaleLowerCase().indexOf(term) > -1;
  }

  async getManufatures() {
    this.isShowProgressBar = true;
    await this.partService.getManufatures().toPromise()
      .then(
        (response: any) => {
          // Success callback
          this.isShowProgressBar = false;
          if (response.status === 200) {
            if (response.body) {
              // Assign the data
              this.manufacturerList = response.body;
            }
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug'); localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else {
            console.log(response.error);
          }
        },
        (error) => {
          this.isShowProgressBar = false;
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug'); localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else {
            console.log(error.error);
          }
        }
      );
  }

  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  async getBudgetparts(year: any) {
    this.isShowProgressBar = true;
    this.groupList = [];
    this.partGroupsList = [];
    await this.budgetService
      .getBudgets(999, 0, '', '', '', year)
      .toPromise()
      .then(
        (response: any) => {
          // Success callback
          if (response.status === 200) {
            if (response.body) {
              if (response.body.BUDGETS && response.body.BUDGETS.length > 0) {
                this.groupList = response.body.BUDGETS;
                if (this.data.mode == ActionType.Edit) {
                  let res: any = this.groupList.filter(grp => grp.PART_GROUP_NAME === this.formGroup.controls.BUDGET_GROUP.value);
                  if (res.length > 0) {
                    this.partGroupsList = res[0]['PART_GROUP_DETAILS'];
                  }
                  this.partGroupsFilteredList.next(this.partGroupsList.slice());
                }
              } else {
                this.errorMsg = 'Budget is not available for the selected fiscal year.'
              }
            }
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug'); localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.errorMsg = response.error;
          } else {
            this.errorMsg = response.error;
          }
        }
      );
    this.isShowProgressBar = false;
  }

  groupChange(value: any) {
    let res: any = this.groupList.filter(grp => grp.PART_GROUP_NAME === value)
    this.partGroupsList = res[0]['PART_GROUP_DETAILS'];
    for (let i = 0; i < this.partDataSource.data.length; i++) {
      this.partDataSource.data[i].PART_lIST = this.partGroupsList;
    }
    this.partGroupsFilteredList.next(this.partGroupsList.slice());
  }

  getTotalCost() {
    this.errorMsg = '';
    const baseCost = this.partDataSource.data.reduce((acc, element) => acc + element.QUANTITY * element.UNIT_PRICE, 0);
    const shippingCost = this.formGroup.controls.SHIPPING_COST.value;
    const miscCostPercentage = this.formGroup.controls.MISC_COST.value / 100;
    this.totalCost = (shippingCost + baseCost) * (1 + miscCostPercentage);
    return this.totalCost;
  }

  addNewPart() {
    let obj = { PART_ID: '', UNIT_PRICE: null, QUANTITY: null, PART_lIST: this.partGroupsList };
    let list: any = this.partDataSource.data;
    list.push(obj)
    this.partDataSource = new MatTableDataSource<any>([]);
    this.partDataSource = new MatTableDataSource<any>(
      list
    );
  }

  deletePart(removeIndex: number) {
    this.partDataSource.data = this.partDataSource.data.filter((i, index) => index !==
      removeIndex);
    this.getTotalCost();
  }

  async getOrderDetailsByOrderNumber() {
    await this.partOrderService
      .getPartOrderById(this.data.item.ORDER_ID)
      .toPromise()
      .then(
        (response: any) => {
          // Success callback
          if (response.status === 200) {
            if (response.body) {
              response.body.PART_ORDER_DETAILS.forEach((ele: any) => {
                ele['PART_lIST'] = this.partGroupsList
              });
              response.body.PART_ORDER_DETAILS.forEach((pOrder: any) => {
                pOrder.QUANTITY = pOrder.QUANTITY.toFixed(2)
                pOrder.UNIT_PRICE = pOrder.UNIT_PRICE.toFixed(2)
              });
              this.partDataSource = new MatTableDataSource<any>(
                response.body.PART_ORDER_DETAILS
              );
              this.getTotalCost();
            }
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug'); localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.errorMsg = response.error;
          } else {
            this.errorMsg = 'Error while downloading part orders, do you want to retry again?';
          }
        },
        (error) => {
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug'); localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.errorMsg = error.error;
          } else {
            this.errorMsg = 'Error while downloading part orders, do you want to retry again?';
          }
        }
      )
  }

  disablePart(part: string) {
    return this.partDataSource.data.some(function (el) {
      return el.PART_ID === part;
    });
  }

  partfilter(search: string, parts: any, index: number) {
    if (!parts) {
      this.partDataSource.data[index]['PART_lIST'] = this.partGroupsList;
      return;
    }
    if (!search) {
      this.partDataSource.data[index]['PART_lIST'] = this.partGroupsList;
      return;
    }
    search = search.toLowerCase();
    parts = this.partGroupsList.filter((part: any) => part.NAME.toLowerCase().indexOf(search) > -1);
    this.partDataSource.data[index]['PART_lIST'] = parts;
  }

  getExtendTotal(total: number) {
    return total.toFixed(2)
  }
}
