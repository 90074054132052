import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActionType } from 'src/app/shared/AppConstants';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { CustomerService } from 'src/app/services/customer.service';

export interface DialogData {
  mode: number;
  item: any;
}

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
  public title: string;
  public formGroup: FormGroup;
  public errorMsg: string;
  public accessModules: any;
  public accessCustomers: any[] = [];
  public loggedInCustomerId: any;
  public isShowProgressBar: boolean = false;

  constructor(
    public customerService: CustomerService,
    public authService: AuthService,
    public router: Router,
    public userService: UserService,
    public userDialogRef: MatDialogRef<UserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.errorMsg = '';
    this.title =
      this.data.mode == ActionType.Add ? 'Create User' : 'Update User';

    if (this.data.mode == ActionType.Add) {
      this.formGroup = new FormGroup({
        userId: new FormControl(''),
        emailId: new FormControl('', [Validators.required, Validators.email]),
        firstName: new FormControl('', [Validators.required]),
        lastName: new FormControl('', [Validators.required]),
        phoneNumber: new FormControl(''),
        accessModules: new FormControl(''),
        accessCustomers: new FormControl(''),
      });
    } else {
      let modules = [];
      for (let i = 0; i < this.data?.item?.MODULE?.length; i++) {
        modules.push(this.data?.item?.MODULE[i].MODULE_NAME);
      }
      let tenants = [];
      for (let i = 0; i < this.data?.item?.TENANTS?.length; i++) {
        tenants.push(this.data?.item?.TENANTS[i]);
      }
      this.formGroup = new FormGroup({
        userId: new FormControl(
          { value: this.data.item.USER_ID, disabled: true },
          [Validators.required]
        ),
        emailId: new FormControl(this.data.item.EMAIL, [
          Validators.required,
          Validators.email,
        ]),
        firstName: new FormControl(this.data.item.FIRST_NAME, [
          Validators.required,
        ]),
        lastName: new FormControl(this.data.item.LAST_NAME, [
          Validators.required,
        ]),
        phoneNumber: new FormControl(this.data.item.PHONE),
        accessModules: new FormControl(modules),
        accessCustomers: new FormControl(tenants),
      });
    }

    this.formGroup.valueChanges.subscribe((x) => {
      this.errorMsg = '';
    });
  }

  ngOnInit(): void {
    this.getCustomers(999, 0);
    this.loggedInCustomerId = localStorage.getItem('customerId');
    this.getSModules();
  }

  closeDialog(): void {
    this.userDialogRef.close(false);
  }

  async saveUser() {
    this.isShowProgressBar = true;
    let body: any = {
      EMAIL: this.formGroup.controls.emailId.value,
      FIRST_NAME: this.formGroup.controls.firstName.value,
      LAST_NAME: this.formGroup.controls.lastName.value,
      MODULE: this.formGroup.controls.accessModules.value,
      PHONE: this.formGroup.controls.phoneNumber.value,
      TENANTS: this.formGroup.controls.accessCustomers.value,
    };

    if (this.data.mode === ActionType.Add) {
      body.CUSTOMER_ID = this.loggedInCustomerId;
      await this.userService
        .createUser(body)
        .toPromise()
        .then(
          (response) => {
            this.isShowProgressBar = false;
            if (response.status === 204) {
              this.userDialogRef.close(true);
            } else {
              this.errorMsg = response.message;
            }
          },
          (error) => {
            this.isShowProgressBar = false;
            this.errorMsg = error.error;
          }
        );
    } else {
      // Update existing User
      body.USER_ID = this.formGroup.controls.userId.value;
      body.CUSTOMER_ID = this.data.item.CUSTOMER_ID;
      await this.userService
        .updateUser(body)
        .toPromise()
        .then(
          (response: any) => {
            this.isShowProgressBar = false;
            if (response.status === 204) {
              this.userDialogRef.close(true);
            } else {
              this.errorMsg = response.message;
            }
          },
          (error) => {
            this.isShowProgressBar = false;
            this.errorMsg = error.error;
          }
        );
    }
  }

  getSModules() {
    this.userService.getModules().subscribe(
      (response: any) => {
        // Success callback
        if (response.status === 200) {
          if (response.body) {
            this.accessModules = response.body;
          }
        } else if (response.status === 401) {
          // Redirect to login for unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        } else if (response.status === 403) {
        } else {
        }
      },
      (error) => {
        if (error.status === 401) {
          // Redirect to login for unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        } else if (error.status === 403) {
        } else {
        }
      }
    );
  }

  // Download customers from server
  getCustomers(limit: number, offset: number) {
    this.customerService.getCustomers(limit, offset).subscribe(
      (response: any) => {
        // Success callback
        if (response.status === 200) {
          if (response.body) {
            this.accessCustomers = response.body.CUSTOMERS;
          }
        } else if (response.status === 401) {
          // Redirect to login for unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        } else if (response.status === 403) {
        } else {
        }
      },
      (error) => {
        if (error.status === 401) {
          // Redirect to login for unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        } else if (error.status === 403) {
        } else {
        }
      }
    );
  }
}
