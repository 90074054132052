<mat-progress-bar *ngIf="isShowProgressBar" class="primary-color" mode="indeterminate">
</mat-progress-bar>

<div class="centered">

  <div>
    <img class="logo" src="assets/logo/AFS_PrimaryLogo_FC.png">
  </div>

  <div *ngIf="!isLogin">
    <div class="welcome-back-txt"> Welcome back {{name}}! </div>
    <div class="logged-in-txt"> You are logged in as: </div>
    <div class="returning-user-login-screen"><u>{{email}}</u></div>
  </div>

  <div *ngIf="isLogin" class="login-txt-div">Login</div>

  <form
    (keyup.enter)="(!passwordFormControl.hasError('required') && !emailFormControl.hasError('required'))? login() : ''">
    <mat-form-field appearance="legacy" *ngIf="isLogin">
      <mat-label>Email</mat-label>
      <input matInput type="text" [formControl]="emailFormControl" required>
      <mat-error *ngIf="emailFormControl.hasError('required')">
        email or username is required
      </mat-error>
      <mat-error *ngIf="emailFormControl.hasError('email')">
        Please enter valid email id
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="legacy" style="margin-top:8px">
      <mat-label>Password</mat-label>
      <input matInput [type]="hide ? 'password' : 'text'" [formControl]="passwordFormControl" required>
      <mat-icon matSuffix style="font-size: 20px;" (click)="hide = !hide"
        [ngClass]="hide ? 'mdi mdi-eye-off-outline': 'mdi mdi-eye-outline'"></mat-icon>
      <mat-error *ngIf="passwordFormControl.hasError('required')">
        password is required
      </mat-error>
    </mat-form-field>
  </form>

  <button (click)="login()"
    [disabled]="passwordFormControl.hasError('required') || emailFormControl.hasError('required')" mat-raised-button
    class="login-button">Login</button>

  <div class="other-options">
    <div style="float:left" *ngIf="!isLogin">
      <button class="use-diff-account-button" (click)="useDifferentAccount()" mat-button>Use a
        different account</button>
    </div>

    <div style="float:right">
      <button (click)="forgotPassword()" mat-button class="forgot-pass-button">Forgot Password?</button>
    </div>
  </div>

  <div style="margin-top: 70px;">
    <p [ngClass]="isSuccess == true ? 'success-msg' : 'error-msg'">{{errorMsg}}</p>
  </div>
</div>