<mat-toolbar class="app-toolbar-fixed">
    <span> {{title}} </span>
    <span class="example-spacer"></span>
    <button mat-icon-button (click)="closeDialog()" onclick="this.blur()">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>

<mat-progress-bar *ngIf="isShowProgressBar" mode="indeterminate"></mat-progress-bar>

<form style="margin:0px 0px 10px 0px;padding: 20px;font-size: 15px !important;" [formGroup]="formGroup">

    <mat-form-field appearance="legacy" *ngIf="isAdd">
        <mat-label>Location</mat-label>
        <mat-select formControlName="locationId" required disableOptionCentering>
            <mat-option *ngFor="let loc of inventoryLocations" [value]="loc.LOCATION_ID">
                {{loc.NAME}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="legacy">
        <mat-label>Quantity</mat-label>
        <input autocomplete="off" matInput type="number" required formControlName="quantity">
        <mat-error *ngIf="formGroup.controls.quantity.hasError('min') && isAdd">
            The quantity must be greater than {{minValue}}
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="legacy" *ngIf="isAdd">
        <mat-label>Price (In $)</mat-label>
        <input autocomplete="off" matInput type="number" required formControlName="price">
    </mat-form-field>

    <mat-form-field appearance="legacy" *ngIf="isAdd">
        <mat-label>Comment</mat-label>
        <textarea matInput autocomplete="off" type="text" formControlName="comment"> </textarea>
    </mat-form-field>

    <mat-form-field appearance="legacy" *ngIf="!isAdd">
        <mat-label>Comment</mat-label>
        <textarea matInput autocomplete="off" type="text" required formControlName="comment"> </textarea>
    </mat-form-field>

    <div style="text-align:center;padding-top:10px">
        <button mat-raised-button (click)="save()" [ngClass]="formGroup.valid ? 'primary-button' : ''"
            [disabled]="!formGroup.valid">
            <span *ngIf="data.mode == 1"> Add </span>
            <span *ngIf="data.mode == 2"> Remove </span>
        </button>
    </div>

    <div *ngIf="errorMsg.length> 0" style="text-align: center;color: rgb(228, 0, 43)">
        <p>{{errorMsg}}</p>
    </div>
</form>