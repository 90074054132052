<!--#region Toolbar -->
<mat-toolbar class="app-toolbar-fixed">
    <span>Comment</span>
    <span class="example-spacer"></span>
    <button mat-icon-button (click)="closeDialog()" onclick="this.blur()">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>
<!--#endregion -->


<div style="padding: 20px;">
    <form [formGroup]="formGroup" (ngSubmit)="saveComment()">
        <mat-form-field appearance="legacy">
            <mat-label>Comment</mat-label>
            <textarea rows="3" matInput formControlName="comment"></textarea>
        </mat-form-field>

        <div style="text-align:center;padding-top:10px">
            <button mat-raised-button [ngClass]="formGroup.valid ? 'primary-button' : ''" [disabled]="!formGroup.valid">
                Save
            </button>
        </div>
    </form>
</div>