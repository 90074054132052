import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChoicesService } from 'src/app/services/choices.service';

export interface DialogData {
  mode: any;
  item: any;
  type: any;
  selectedObj: any;
}

@Component({
  selector: 'app-add-choice-value',
  templateUrl: './add-choice-value.component.html',
  styleUrls: ['./add-choice-value.component.scss'],
})
export class AddChoiceValueComponent implements OnInit {
  public title: string = '';
  public formGroup: FormGroup = new FormGroup({});
  public errorMsg: string = '';
  public isShowProgressBar: boolean = false;

  constructor(
    public choiceService: ChoicesService,
    public dialogRef: MatDialogRef<AddChoiceValueComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
    if (this.data?.mode == 'Value') {
      this.title = this.data?.type == 'add' ? 'Add New Value' : 'Update Value';
    } else if (this.data?.mode == 'SubValue') {
      this.title =
        this.data?.type == 'add' ? 'Add New Sub-Value' : 'Update Sub-Value';
    }

    if (this.data?.type == 'add') {
      this.formGroup = new FormGroup({
        value: new FormControl('', [Validators.required]),
        score: new FormControl('', [
          this.data?.item?.type == 'AUDIT_SCORES'
            ? Validators.required
            : Validators.minLength(0),
        ]),
        name: new FormControl('', [Validators.required]),
      });
    } else {
      this.formGroup = new FormGroup({
        value: new FormControl(
          { value: this.data?.selectedObj?.CHOICE_VALUE, disabled: true },
          [Validators.required]
        ),
        score: new FormControl(
          { value: this.data?.selectedObj?.CHOICE_SCORE, disabled: true },
          [
            this.data?.item?.type == 'AUDIT_SCORES'
              ? Validators.required
              : Validators.minLength(0),
          ]
        ),
        name: new FormControl(
          { value: this.data?.selectedObj?.CHOICE_NAME, disabled: false },
          [Validators.required]
        ),
      });
    }
  }

  closeDialog(): void {
    this.dialogRef.close(false);
  }

  async saveValue() {
    if (this.data?.type == 'add') {
      this.isShowProgressBar = true;
      let body: any = {
        CHOICE_NAME: this.formGroup.controls.name.value,
        CHOICE_VALUE: this.formGroup.controls.value.value,
        CHOICE_TYPE: this.data?.item?.type ? this.data?.item?.type : '',
      };

      if (this.data?.item?.type == 'AUDIT_SCORES') {
        body['CHOICE_SCORE'] = this.formGroup.controls.score.value;
      }

      if (this.data?.mode == 'SubValue') {
        body['PARENT_CHOICE_ID'] = this.data?.item?.choiceId;
      }

      await this.choiceService
        .addChoice(body)
        .toPromise()
        .then(
          (response) => {
            this.isShowProgressBar = false;
            if (response.status === 204) {
              this.dialogRef.close(true);
            } else {
              this.errorMsg = response.message;
            }
          },
          (error) => {
            this.isShowProgressBar = false;
            this.errorMsg = error.error;
          }
        );
    } else {
      this.isShowProgressBar = true;
      let body: any = {
        CHOICE_NAME: this.formGroup.controls.name.value,
        CHOICE_ID: this.data?.selectedObj?.CHOICE_ID,
      };

      await this.choiceService
        .updateChoice(body)
        .toPromise()
        .then(
          (response) => {
            this.isShowProgressBar = false;
            if (response.status === 204) {
              this.dialogRef.close(true);
            } else {
              this.errorMsg = response.message;
            }
          },
          (error) => {
            this.isShowProgressBar = false;
            this.errorMsg = error.error;
          }
        );
    }
  }

  async deleteChoice() {
    this.isShowProgressBar = true;
    let body: any = {
      CHOICE_ID: this.data?.selectedObj?.CHOICE_ID,
    };

    await this.choiceService
      .deleteChoice(body)
      .toPromise()
      .then(
        (response) => {
          this.isShowProgressBar = false;
          if (response.status === 204) {
            this.dialogRef.close(true);
          } else {
            this.errorMsg = response.message;
          }
        },
        (error) => {
          this.isShowProgressBar = false;
          this.errorMsg = error.error;
        }
      );
  }
}
