<mat-toolbar>
  <span>Upload Fleet Document</span>
  <span class="example-spacer"></span>
  <button mat-icon-button (click)="closeDialog()" onclick="this.blur()">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>
<mat-progress-bar *ngIf="isShowProgressBar" class="primary-color" mode="indeterminate"></mat-progress-bar>

<mat-dialog-content style="padding-bottom: 0px !important;">
  <ngx-dropzone (change)="onSelect($event)" [multiple]="false" [disabled]="isShowProgressBar" accept="application/pdf">
    <ngx-dropzone-label>
      <p>Drop a document file here</p>
      <p>Or </p>
      <p>click to browse and add file </p>
    </ngx-dropzone-label>
    <ngx-dropzone-preview *ngFor="let f of file" [removable]="true" (removed)="onRemove(f)">
      <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
    </ngx-dropzone-preview>
  </ngx-dropzone>

  <p style="color:rgb(228, 0, 43);text-align:center;margin-bottom: 0px;" *ngIf="errorMsg && errorMsg.length > 0">
    {{errorMsg}}</p>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button [ngClass]="(file.length == 0  || isShowProgressBar )? '': 'primary-button'" mat-raised-button
    (click)="uploadDocument()" [disabled]="file.length == 0 || isShowProgressBar">&nbsp;&nbsp;
    Upload &nbsp;&nbsp;</button>
</mat-dialog-actions>
