<mat-toolbar class="toolbar">
    <button mat-icon-button (click)="authService.isExpanded = !authService.isExpanded">
        <mat-icon>menu</mat-icon>
    </button>
    {{utilService.customerName}}
    <div class="company-logo">
        <button mat-button routerLink="/dashboard">
            <img class="company-image" src="assets/logo/AFS_PrimaryLogo_1C_White.png" />
        </button>
    </div>
    <span class="example-spacer"></span>
    <button matTooltip="Switch Company" mat-icon-button *ngIf="authService.isAfsUser()" (click)="switchCompany()" >
        <mat-icon style="font-size: 29px;" class="material-icons-outlined">swap_horiz</mat-icon>
      </button> &nbsp;
    <button matTooltip="Logout" mat-icon-button (click)="logout()">
        <mat-icon class="material-icons-outlined">logout</mat-icon>
    </button>
</mat-toolbar>

<mat-sidenav-container class="sidenav-container" autosize [hasBackdrop]="false">

    <mat-sidenav #drawer [fixedInViewport]="true" [disableClose]="true" [fixedTopGap]="55"
        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'side' : 'side'"
        [opened]="true">
        <div class="side-menu-height">
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('ADMIN')  && authService.isNotAfsCustomer()">
                <a mat-list-item [routerLink]="['/dashboard']" matTooltip="Dashboard" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">dashboard</mat-icon>
                    <span [translate]="'Dashboard'" *ngIf="authService.isExpanded"
                        class="menu-icon-spacing">Dashboard</span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('ADMIN') && authService.isNotAfsCustomer()">
                <a mat-list-item [routerLink]="['/reports']" matTooltip="Reports" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">auto_awesome_mosaic</mat-icon>
                    <span [translate]="'Reports'" *ngIf="authService.isExpanded"
                        class="menu-icon-spacing">Reports</span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('CUSTOMER')">
                <a mat-list-item [routerLink]="['/customers']" matTooltip="Customers" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon>corporate_fare</mat-icon>
                    <span [translate]="'Customers'" *ngIf="authService.isExpanded"
                        class="menu-icon-spacing">Customers</span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('USER')">
                <a mat-list-item [routerLink]="['/users']" matTooltip="Users" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">people</mat-icon>
                    <span [translate]="'Users'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Users</span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('SERVICE_REQUEST')">
                <a mat-list-item [routerLink]="['/service-requests']" matTooltipPosition="right"
                    matTooltip="Service Requests" routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">content_paste</mat-icon>
                    <span [translate]="'Service Requests'" *ngIf="authService.isExpanded"
                        class="menu-icon-spacing">Service
                        Requests</span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('SCHEDULED_SERVICE_REQUEST')">
                <a mat-list-item [routerLink]="['/timed-services']" matTooltipPosition="right"
                    matTooltip="Timed Services" routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">timer</mat-icon>
                    <span [translate]="'Timed Services'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Timed
                        Services</span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('SCHEDULED_SERVICE_REQUEST')">
                <a mat-list-item [routerLink]="['/timed-service-schedules']" matTooltipPosition="right"
                    matTooltip="Timed Service Schedules" routerLinkActive="active-list-item">
                    <mat-icon class="material-icons">pending_actions</mat-icon>
                    <span [translate]="'Timed Service Schedules'" *ngIf="authService.isExpanded"
                        class="menu-icon-spacing">Timed Service Schedules</span>
                </a>
            </mat-nav-list>
            <mat-nav-list
                *ngIf="(authService.checkRoleBasedModuleAccess('MESSAGE') || authService.checkRoleBasedModuleAccess('SERVICE_REQUEST'))">
                <a mat-list-item [routerLink]="['/notifications']" matTooltip="Notifications" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">feedback</mat-icon>
                    <span [translate]="'Notifications'" *ngIf="authService.isExpanded"
                        class="menu-icon-spacing">Notifications</span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('AUDIT')">
                <a mat-list-item [routerLink]="['/audits']" matTooltip="Services " matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon>checklist_rtl</mat-icon>
                    <span [translate]="'Services'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Services
                    </span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('AUDIT')">
                <a mat-list-item [routerLink]="['/audit-schedule']" matTooltip="Scheduled Services"
                    matTooltipPosition="right" routerLinkActive="active-list-item">
                    <mat-icon>schedule</mat-icon>
                    <span [translate]="'Scheduled Services'" *ngIf="authService.isExpanded"
                        class="menu-icon-spacing">Scheduled Services
                    </span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('LOPA')">
                <a mat-list-item [routerLink]="['/lopa']" matTooltip="LOPA Management" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">flight</mat-icon>
                    <span [translate]="'Lopa'" *ngIf="authService.isExpanded" class="menu-icon-spacing">LOPA Management
                    </span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('EQUIPMENT')">
                <a mat-list-item [routerLink]="['/locations']" matTooltip="Locations" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">my_location</mat-icon>
                    <span [translate]="'Locations'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Locations
                    </span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('EQUIPMENT')">
                <a mat-list-item [routerLink]="['/parts']" matTooltip="Parts" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">chair_alt</mat-icon>
                    <span [translate]="'Parts'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Parts
                    </span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('BUDGET')">
                <a mat-list-item [routerLink]="['/budget']" matTooltip="Budget" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">card_travel</mat-icon>
                    <span [translate]="'Budget'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Budget
                    </span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('INVOICE')">
                <a mat-list-item [routerLink]="['/invoice']" matTooltip="Invoice" matTooltipPosition="right"
                  routerLinkActive="active-list-item">
                  <mat-icon class="material-icons-outlined">description</mat-icon>
                  <span [translate]="'Invoice'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Invoice
                  </span>
                </a>
              </mat-nav-list>
              <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('PART_ORDER') || authService.checkRoleBasedModuleAccess('PART_ORDER_READ_ONLY')">
                <a mat-list-item [routerLink]="['/part-orders']" matTooltip="Part Orders" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">add_shopping_cart</mat-icon>
                    <span [translate]="'Part Orders'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Part Orders
                    </span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('BILLABLE_UNIT_REPORT') || authService.checkRoleBasedModuleAccess('BILLABLE_UNIT_REPORT_READ_ONLY')">
                <a mat-list-item [routerLink]="['/billable-reports']" matTooltip="Billable Reports" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-symbols-outlined material-icons-outlined">receipt_long</mat-icon>
                    <span [translate]="'Billable Reports'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Billable Reports
                    </span>
                </a>
                </mat-nav-list>
        </div>
        <div [ngClass]='appropriateClass'>
            <mat-nav-list>
                <a mat-list-item [routerLink]="['/settings']" matTooltip="Settings" matTooltipPosition="right"
                    routerLinkActive="active-list-item" *ngIf="authService.checkRoleBasedModuleAccess('SETTINGS')">
                    <mat-icon class="material-icons-outlined">settings</mat-icon>
                    <span [translate]="'Settings'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Settings
                    </span>
                </a>

                <a (click)="authService.isExpanded = !authService.isExpanded" mat-list-item>
                    <mat-icon *ngIf="authService.isExpanded">keyboard_double_arrow_left</mat-icon>
                    <mat-icon *ngIf="!authService.isExpanded">keyboard_double_arrow_right</mat-icon>
                    <span [translate]="'Collapse sidebar'" *ngIf="authService.isExpanded"
                        class="menu-icon-spacing">Collapse
                        sidebar</span>
                    <span slot="right" style="color: grey;font-size: small; margin: 0 0 0 auto;"
                        *ngIf="authService.isExpanded" class="menu-icon-spacing">
                        {{version }}</span>
                </a>
            </mat-nav-list>
        </div>
    </mat-sidenav>

    <!--#region SideNav Content-->
    <mat-sidenav-content>

        <mat-toolbar class="toolbar-height-50">
            <span>Other Service Requests <span *ngIf="isAuditIdPresent">- {{requestType |
                    UppercaseToLowerCaseFilter}}</span> </span>
            <span class="toolbar-spacer"></span>
            <div class="search-container" [ngClass]="{'isSearchHasValue':isSearchHasValue}">
                <mat-icon style="vertical-align: middle;padding-right: 8px;" *ngIf="isSearchHasValue">search</mat-icon>
                <mat-form-field floatLabel="never">
                    <input #searchText matInput type="search" [(ngModel)]="searchTerm" (keyup)="searchServiceRequestsFilter($event)"
                        placeholder="Search service requests" autocomplete="off"
                        (blur)="isSearchHasValue=searchTerm?true:false">
                </mat-form-field>
                <button [disabled]="isTableEditable" type="button" class="searchIcon" mat-icon-button
                    (click)="isSearchHasValue=!isSearchHasValue;searchText.focus()">
                    <mat-icon *ngIf="!isSearchHasValue" class="mat-18">search</mat-icon>
                    <mat-icon *ngIf="isSearchHasValue" (click)="clearSearch()" class="mat-18 
                         ">close</mat-icon>
                </button>

                <button [disabled]="isTableEditable" mat-icon-button
                    (click)="filterNotifications()" matTooltip="Filter">
                    <mat-icon class="material-icons-outlined">filter_alt</mat-icon>
                </button>

                <button mat-icon-button (click)="refreshServiceRequests()" matTooltip="Refresh">
                    <mat-icon class="material-icons-outlined">refresh</mat-icon>
                </button>
            </div>
        </mat-toolbar>

        <div class="container mat-elevation-z4">
            <mat-progress-bar *ngIf="isShowNotificationsProgressBar" class="primary-color" mode="indeterminate">
            </mat-progress-bar>
            <mat-toolbar class="container-toolbar">
                <mat-toolbar-row class="container-toolbar-row">

                    <span *ngIf="navigatedFromSubAudits" style="color:#00629b;font-size:18px">
                        <button mat-button (click)="goBackToSubAudits()">
                            <mat-icon style="color:#00629b;">arrow_back</mat-icon> &nbsp; <span>
                                Go back to sub services
                            </span>
                        </button>
                    </span>

                    <button onclick="this.blur()" mat-button class="primary-color"
                        *ngIf="isShowSelectButton() && !isTableEditable && requestType == 'NOTIFICATION'"
                        (click)="isTableEditable = !isTableEditable; displayedColumns();">
                        <mat-icon class="primary-color" style="padding-right: 3px">done</mat-icon>
                        Select
                    </button>

                    <button onclick="this.blur()" mat-button style="color:darkgreen" *ngIf="isTableEditable"
                        (click)="isTableEditable = !isTableEditable;notificationSelection.clear(); pickListDataSource.data = []; pickList=[]; displayedColumns();">
                        <mat-icon style="color:darkgreen" style="padding-right: 3px">done</mat-icon>
                        Done
                    </button>

                    <span *ngIf="isTableEditable">
                        <button mat-icon-button matTooltip="Cancel" (click)="bulkCancel()"
                            [disabled]="!notificationSelection.hasValue()">
                            <mat-icon
                                [ngClass]="!notificationSelection.hasValue() ? '' : 'danger-color'">clear</mat-icon>
                        </button>
                        <button mat-icon-button matTooltip="Convert to Service Request" (click)="bulkConvert()"
                            [disabled]="!notificationSelection.hasValue()">
                            <mat-icon
                                [ngClass]="!notificationSelection.hasValue() ? ' material-icons-outlined' : 'warning-color material-icons-outlined'">file_copy</mat-icon>
                        </button>

                        <button onclick="this.blur()" mat-button (click)="getPicklist()"
                            [ngClass]="!notificationSelection.hasValue() ? '' : 'primary-color'"
                            [disabled]="!notificationSelection.hasValue()">
                            <mat-icon>download</mat-icon> Generate Parts Picklist
                        </button>
                    </span>

                    <mat-form-field appearance="outline" style="font-size: 15px;margin: 12px 10px 0px 10px;"
                        *ngIf="!isAuditIdPresent">
                        <mat-label>Select Type</mat-label>
                        <mat-select disableOptionCentering [(ngModel)]="requestType"
                            (selectionChange)="requestTypeChange($event)">
                            <mat-option *ngFor="let option of typeDropdownOptions" [value]="option.key">
                                {{option.value}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <span *ngIf="isAuditIdPresent" style="color:#00629b;font-size:18px">
                        <button mat-button (click)="goBackToAudits()">
                            <mat-icon style="color:#00629b;">arrow_back</mat-icon> &nbsp; <span>
                                Go back to services
                            </span>
                        </button>
                    </span>



                    <span class="example-spacer"></span>
                    <mat-paginator #paginator [pageSize]="paginatorLength" [pageSizeOptions]="[5, 10, 25, 50, 100]"
                        (page)="handlePaginator($event);" [length]="notificationsLength">
                    </mat-paginator>
                </mat-toolbar-row>
            </mat-toolbar>

            <div class="table-container">
                <table mat-table (matSortChange)="sortfields($event)" [dataSource]="notificationsDataSource" matSort
                    class="table" multiTemplateDataRows>

                    <ng-container matColumnDef="SELECT">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="$event ? serviceRequestsMasterToggle() : null"
                                [checked]="notificationSelection.hasValue() && isServiceRequestsAllSelected()"
                                [indeterminate]="notificationSelection.hasValue() && !isServiceRequestsAllSelected()">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="toggeleServiceRequestSelection($event, row)"
                                [checked]="selectedServiceRequests(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <!-- ID Column -->
                    <ng-container matColumnDef="REQUEST_ID">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                        <td mat-cell *matCellDef="let row"> {{row.REQUEST_ID}} </td>
                    </ng-container>

                    <ng-container matColumnDef="TAG">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Equipment TAG </th>
                        <td mat-cell *matCellDef="let row">
                           {{row.TAG}}
                        </td>
                    </ng-container>

                    <!-- Equipment Name Column -->
                    <ng-container matColumnDef="EQUIPMENT_NAME">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Equipment Name </th>
                        <td mat-cell *matCellDef="let row">
                            <a class="primary-color" style="cursor: pointer;"
                                (click)="showEquipmentDetails(row)">{{row.EQUIPMENT_NAME}}</a>
                        </td>
                    </ng-container>

                    <!-- Task Type Column -->
                    <ng-container matColumnDef="TASK_TYPE">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Task Type </th>
                        <td mat-cell *matCellDef="let row"> {{row.TASK_TYPE_DESC}} </td>
                    </ng-container>

                    <!-- Location Column -->
                    <ng-container matColumnDef="LOCATION" *ngIf="requestType != 'MOVEMENT'">
                        <th mat-header-cell *matHeaderCellDef>Location / Movement</th>
                        <td mat-cell *matCellDef="let row">
                            <span *ngIf="!this.isAirlineCusromer">
                                <div *ngIf="row.NEW_LOCATION" style="display: flex">
                                    <mat-icon class="primary-color material-icons-outlined location-icon">
                                        local_shipping</mat-icon>
                                    <span style="padding-top: 4px">Moved to:
                                        <span *ngFor="let loc of row.NEW_LOCATION; let last = last;">
                                            {{loc.NAME}}<span *ngIf="!last">,</span>
                                        </span>
                                    </span>
                                </div>
                                <div *ngIf="!row.NEW_LOCATION && row.LOCATION" style="display: flex">
                                    <mat-icon class="success-color material-icons-outlined location-icon">done
                                    </mat-icon>
                                    <span style="padding-top: 4px">
                                        <span *ngFor="let loc of row.LOCATION; let last = last;">
                                            {{loc.NAME}}<span *ngIf="!last">,</span>
                                        </span>
                                    </span>
                                </div>
                            </span>

                            <span *ngIf="this.isAirlineCusromer">
                                <div *ngIf="row.NEW_LOCATION" style="display: flex">
                                    <mat-icon class="primary-color material-icons-outlined location-icon">
                                        local_shipping</mat-icon>
                                    <span style="padding-top: 4px">Moved to:
                                        <span *ngFor="let loc of row.LOCATION; let last = last;">
                                            {{loc.NAME}}<span *ngIf="!last">,</span>
                                        </span>
                                    </span>
                                </div>

                                <div *ngIf="!row.NEW_LOCATION && row.LOCATION" style="display: flex">
                                    <mat-icon class="success-color material-icons-outlined location-icon">done
                                    </mat-icon>
                                    <span style="padding-top: 4px">
                                        <span *ngFor="let loc of row.LOCATION; let last = last;">
                                            {{loc.NAME}}<span *ngIf="!last">,</span>
                                        </span>
                                    </span>
                                </div>
                            </span>
                        </td>
                    </ng-container>

                    <!-- Barcode Column -->
                    <ng-container matColumnDef="NEW_BARCODE">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Barcode </th>
                        <td mat-cell *matCellDef="let row"> {{row.NEW_BARCODE}} </td>
                    </ng-container>

                    <!-- Status Column -->
                    <ng-container matColumnDef="STATUS">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 5px;"> Status </th>
                        <td mat-cell *matCellDef="let row">
                            <div class="badge open"
                                style="padding-left:15.5px !important; padding-right: 15.5px !important;"
                                *ngIf="row.STATUS == 'OPEN'">
                                {{row.STATUS}}
                            </div>
                            <div class="badge pause" *ngIf="row.STATUS == 'PAUSE'">
                                {{row.STATUS}}
                            </div>
                            <div class="badge completed" *ngIf="row.STATUS == 'CLOSED'">
                                {{row.STATUS}}
                            </div>
                            <div class="badge danger" *ngIf="row.STATUS == 'CANCEL'">
                                {{row.STATUS}}
                            </div>
                        </td>
                    </ng-container>

                    <!-- Location Column -->
                    <ng-container matColumnDef="LOCATION" *ngIf="requestType == 'MOVEMENT'">
                        <th mat-header-cell *matHeaderCellDef> Movement From</th>
                        <td mat-cell *matCellDef="let row">
                            <div *ngIf="row.LOCATION">
                                <!-- <mat-icon class="success-color material-icons-outlined location-icon">done
                                </mat-icon> -->
                                <span *ngFor="let loc of row.LOCATION; let last = last;">
                                    {{loc.NAME}}<span *ngIf="!last">,</span>
                                </span>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Location Column -->
                    <ng-container matColumnDef="NEW_LOCATION">
                        <th mat-header-cell *matHeaderCellDef> Movement To</th>
                        <td mat-cell *matCellDef="let row">
                            <div *ngIf="row.NEW_LOCATION">
                                <!-- <mat-icon class="primary-color material-icons-outlined location-icon">
                                    local_shipping</mat-icon>
                                <span style="padding-top: 4px;">Moved to: 
                          
                                </span> -->
                                <span *ngFor="let loc of row.NEW_LOCATION; let last = last;">
                                    {{loc.NAME}}<span *ngIf="!last">,</span>
                                </span>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="EQUIPMENT_CATEGORY_NAME">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Category </th>
                        <td mat-cell *matCellDef="let row"> {{row.EQUIPMENT_CATEGORY_NAME}} </td>
                    </ng-container>

                    <ng-container matColumnDef="CREATED_BY">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Created By </th>
                        <td mat-cell *matCellDef="let row"> {{row.CREATED_BY_NAME }} </td>
                    </ng-container>

                    <ng-container matColumnDef="CREATED_AT">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Created At </th>
                        <td mat-cell *matCellDef="let row"> {{returnDate(row.CREATED_AT)}} </td>
                    </ng-container>

                    <!-- Actions Column -->
                    <ng-container matColumnDef="Actions">
                        <th mat-header-cell *matHeaderCellDef style="padding-left: 10px;"> Actions</th>
                        <td mat-cell *matCellDef="let row">

                            <div class="button-row">
                                <button mat-icon-button matTooltip="Go To Service Request - {{row.REF_REQUEST_ID}}"
                                    (click)="gotoServiceRequest(row.REF_REQUEST_ID)" *ngIf="row.REQUEST_TYPE == 'NOTIFICATION' && row.STATUS == 'CLOSED' &&
                                    row.REF_REQUEST_ID != null">
                                    <mat-icon class="primary-color material-icons-outlined">
                                        assistant_direction </mat-icon>
                                </button>

                                <button style="cursor: default" mat-icon-button *ngIf="row.REQUEST_TYPE == 'NOTIFICATION' && row.STATUS == 'CLOSED' &&
                                row.REF_REQUEST_ID == null">
                                </button>

                                <button mat-icon-button matTooltip="Service Log" (click)="showServiceLogs(row)">
                                    <mat-icon class="primary-color material-icons-outlined"> description</mat-icon>
                                </button>

                                <button (click)="modifyServiceRequest(row, 'CANCEL')" mat-icon-button
                                    matTooltip="Cancel" [disabled]="isTableEditable"
                                    *ngIf="row.REQUEST_TYPE == 'NOTIFICATION' && row.STATUS == 'OPEN'">
                                    <mat-icon [ngClass]="isTableEditable ? '':'danger-color'">clear</mat-icon>
                                </button>

                                <button mat-icon-button matTooltip="Convert to Service Request"
                                    (click)="modifyServiceRequest(row, 'CLOSED')" [disabled]="isTableEditable"
                                    *ngIf="row.REQUEST_TYPE == 'NOTIFICATION' && row.STATUS == 'OPEN'">
                                    <mat-icon style="font-size: 23px;"
                                        [ngClass]="isTableEditable ? 'material-icons-outlined':'warning-color material-icons-outlined'">
                                        file_copy</mat-icon>
                                </button>

                                <button mat-icon-button matTooltip="Show Details"
                                    (click)="expandedElement = expandedElement === row ? null : row; showServiceRequestDetails(row,'expand')"
                                    *ngIf="row != selectedItem">
                                    <mat-icon style="font-size: xx-large;"
                                        class="primary-color material-icons-outlined">
                                        keyboard_arrow_down
                                    </mat-icon>
                                </button>

                                <button mat-icon-button matTooltip="Hide Details"
                                    (click)="expandedElement = expandedElement === row ? null : row;showServiceRequestDetails(row,'collapse')"
                                    *ngIf="row === selectedItem">
                                    <mat-icon style="font-size: xx-large;"
                                        class="primary-color material-icons-outlined">
                                        keyboard_arrow_up
                                    </mat-icon>
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="expandedDetail">
                        <td mat-cell *matCellDef="let element" [attr.colspan]="tableColumns.length">

                            <div class="element-detail"
                                [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

                                <div style="text-align: -webkit-center !important;margin:10px !important">
                                    <mat-spinner diameter="30" *ngIf="!serviceRequestDetails"></mat-spinner>
                                </div>

                                <div fxLayout="row"
                                    *ngIf="serviceRequestDetails && serviceRequestDetails != null && serviceRequestDetails != undefined">
                                    <div fxFlex="50" style="
                                    border-radius: 5px;
                                    border: 1px solid #dbdbdb;
                                    padding: 10px;
                                    margin: 0px 0px 10px 10px;
                                  ">

                                        <p class="details-p-tag">Details</p>
                                        <div class="element-description">
                                            <P style="color:#00629b;font-weight: bold;"
                                                *ngIf="element?.DESCRIPTION && element?.DESCRIPTION?.length > 0">
                                                Description: &nbsp; <span style="color:black"
                                                    class="element-description-attribution">
                                                    {{element.DESCRIPTION}}
                                                </span></P>

                                            <div fxLayout="row"
                                                *ngIf="serviceRequestDetails.EXTN && serviceRequestDetails.EXTN != null">
                                                <div>
                                                    <P style="color:#00629b;font-weight: bold;" *ngFor="let item of serviceRequestDetails.EXTN |
                                                    extnJsonParserFilter"> {{item.key}}
                                                        <span class="element-description-attribution"
                                                            *ngIf="item.value && item.value.length > 0">
                                                            <span *ngFor="let tempItem of item.value">
                                                                <p *ngFor="let item of tempItem | keyvalue"
                                                                    style="color:black !important;opacity: 0.6;">
                                                                    <span *ngIf="item.key != 'FIELD_MANDATORY' && 
                                                                    item.key != 'FIELD_ORDER' &&
                                                                    item.key != 'FIELD_NAME' &&
                                                                    item.key != 'FIELD_TECH_NAME' &&
                                                                    item.key != 'FIELD_CONTROL' &&
                                                                    item.key != 'FIELD_CHOICE_TYPE' &&
                                                                    item.key != 'FIELD_COMMENT'">
                                                                        {{ item.key }} : {{ item.value }}
                                                                    </span>
                                                                </p>
                                                            </span>
                                                        </span>
                                                    </P>
                                                </div>
                                            </div>

                                            <div *ngIf="isArray(serviceRequestDetails?.DOCUMENT)">
                                                <div fxLayout="row" *ngIf="
                                                    serviceRequestDetails.DOCUMENT &&
                                                    serviceRequestDetails.DOCUMENT.length > 0
                                                  ">
                                                  <P style="
                                                      color: #00629b;
                                                      font-weight: bold;
                                                      align-items: center;
                                                      display: flex;
                                                    ">
                                                    Images: &nbsp;
                                                    <span style="color: black" class="element-description-attribution">
                                                    </span>
                                                  </P>
                                                  <div class="element-diagram" style="cursor: pointer" *ngFor="
                                                      let img of serviceRequestDetails.DOCUMENT
                                                    ">
                                                    <img class="document-image" (click)="
                                                        viewFullScreenImage(
                                                          img.DOCUMENT_BLOB,
                                                          img.DOCUMENT_TYPE
                                                        )
                                                      " [src]="
                                                        displayImage(
                                                          img.DOCUMENT_TYPE,
                                                          img.DOCUMENT_BLOB
                                                        )
                                                      " />
                                                  </div>
                                                </div>
                                              </div>

                                              <div *ngIf="!isArray(serviceRequestDetails?.DOCUMENT)">
                                                <P style="
                                                    color: #00629b;
                                                    font-weight: bold;
                                                    align-items: center;
                                                    display: flex;
                                                  " *ngIf="
                                                    checkKeyPresent(serviceRequestDetails?.DOCUMENT)
                                                  ">
                                                  Images: &nbsp;
                                                </P>
                    
                                                <div fxLayout="row" *ngFor="
                                                    let items of serviceRequestDetails?.DOCUMENT
                                                      | keyvalue
                                                  ">
                                                  <p class="label" style="
                                                      color: #00629b;
                                                      padding-top: 25px;
                                                      padding-right: 10px;
                                                    ">
                                                    Equipment:
                                                    <span> {{ items.key }}</span>
                                                  </p>
                                                  <div class="element-diagram" style="cursor: pointer"
                                                    *ngFor="let img of items.value | extnPipe">
                                                    <img class="document-image" (click)="
                                                        viewFullScreenImage(
                                                          img.DOCUMENT_BLOB,
                                                          img.DOCUMENT_TYPE
                                                        )
                                                      " [src]="
                                                        displayImage(
                                                          img.DOCUMENT_TYPE,
                                                          img.DOCUMENT_BLOB
                                                        )
                                                      " />
                                                  </div>
                                                </div>
                                              </div>
                                        </div>
                                    </div>
                                    <div fxFlex="50" *ngIf="requestType == 'NOTIFICATION'" style="
                                    border-radius: 5px;
                                    border: 1px solid #dbdbdb;
                                    padding: 10px;
                                    margin: 0px 0px 10px 10px;
                                  ">

                                        <p class="details-p-tag">Parts</p>
                                        <div *ngIf="isArray(serviceRequestDetails?.PARTS)" class="container"
                                            style="padding: 10px">
                                            <mat-toolbar class="container-toolbar">
                                                <mat-toolbar-row class="container-toolbar-row"
                                                    style="display: contents !important">
                                                    <mat-paginator #paginator1 [pageSizeOptions]="[5, 10, 25, 100]"
                                                        aria-label="Select page of users">
                                                    </mat-paginator>
                                                </mat-toolbar-row>
                                            </mat-toolbar>

                                            <div class="table-container">
                                                <table class="table" mat-table [dataSource]="partDataSource" #sort1
                                                    matSort>
                                                    <!-- ID Column -->
                                                    <ng-container matColumnDef="PART_ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            ID
                                                        </th>
                                                        <td mat-cell *matCellDef="let row">
                                                            {{ row.PART_ID }}
                                                        </td>
                                                    </ng-container>

                                                    <!-- Progress Column -->
                                                    <ng-container matColumnDef="NAME">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Name
                                                        </th>
                                                        <td mat-cell *matCellDef="let row">
                                                            {{ row.NAME }}
                                                        </td>
                                                    </ng-container>

                                                    <ng-container matColumnDef="MANUFACTURER">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Manufacturer
                                                        </th>
                                                        <td mat-cell *matCellDef="let row">
                                                            {{ row.MANUFACTURER }}
                                                        </td>
                                                    </ng-container>

                                                    <ng-container matColumnDef="MFG_PART_NUMBER">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Part No
                                                        </th>
                                                        <td mat-cell *matCellDef="let row">
                                                            {{ row.MFG_PART_NUMBER }}
                                                        </td>
                                                    </ng-container>

                                                    <!-- Name Column -->
                                                    <ng-container matColumnDef="PART_TYPE">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Type
                                                        </th>
                                                        <td mat-cell *matCellDef="let row">
                                                            {{ row.PART_TYPE }}
                                                        </td>
                                                    </ng-container>

                                                    <!-- Fruit Column -->
                                                    <ng-container matColumnDef="UOM">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            UOM
                                                        </th>
                                                        <td mat-cell *matCellDef="let row">
                                                            {{ row.UOM }}
                                                        </td>

                                                    </ng-container>

                                                    <ng-container matColumnDef="COUNT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                            style="direction: rtl;padding-right: 10px !important;">
                                                            Qty
                                                        </th>
                                                        <td mat-cell *matCellDef="let row" class="align-right">
                                                            {{ row.COUNT }}
                                                        </td>
                                                    </ng-container>

                                                    <ng-container matColumnDef="AVAILABILITY">
                                                        <th mat-header-cell *matHeaderCellDef
                                                            style="direction: rtl;padding-right: 10px !important;">
                                                            Availability </th>
                                                        <td mat-cell *matCellDef="let row" class="align-right">
                                                            {{ row.AVAILABILITY }}
                                                        </td>
                                                    </ng-container>

                                                    <ng-container matColumnDef="COST">
                                                        <th mat-header-cell *matHeaderCellDef class="right"> Cost </th>
                                                        <td mat-cell *matCellDef="let row" class="right">
                                                            <span style="font-weight: bold;">$</span>{{ row.COST |
                                                            number : '1.2' }}
                                                        </td>
                                                    </ng-container>

                                                    <tr mat-header-row *matHeaderRowDef="getPartColumn(element)"></tr>
                                                    <tr mat-row *matRowDef="let row;columns: getPartColumn(element)">
                                                    </tr>
                                                </table>

                                                <div style="text-align:right;margin-right:25px;padding-top: 10px;font-weight: bold;"
                                                    class="danger-color">
                                                    <span style="margin-right: 20px;font-weight: bold;">Total
                                                        Cost</span> ${{getTotalCost() | number : '1.2' }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="container" style="padding: 10px"
                                            *ngIf="!isArray(serviceRequestDetails?.PARTS)">
                                            <mat-card style="border-radius: 0px !important">
                                                <div fxLayout="row">
                                                    <div fxFlex="50" style="color: #0000008a">ID</div>
                                                    <div fxFlex="30" style="color: #0000008a">Qty</div>
                                                    <div fxFlex="30" style="color: #0000008a">Availability</div>
                                                    <div fxFlex="20" style="color: #0000008a;text-align: right"> Cost
                                                    </div>
                                                </div>
                                            </mat-card>

                                            <mat-card *ngFor="
                                                let items of serviceRequestDetails?.PARTS
                                                  | keyvalue
                                              " style="
                                                border-radius: 0px !important;
                                                border: 1px solid #dbdbdb;
                                              ">
                                                <div fxLayout="row" *ngFor="let part of items.value | extnPipe">
                                                    <div fxFlex="50">{{ part.PartId }}</div>
                                                    <div fxFlex="30" style="color: #0000008a">1</div>
                                                    <div fxFlex="30">{{ part.Availability }}</div>
                                                    <div fxFlex="20" style="text-align: right;">
                                                        <span style="font-weight: bold;">$</span>{{ part.Cost | number :
                                                        '1.2' }}
                                                    </div>
                                                </div>
                                            </mat-card>
                                            <div fxLayout="row">
                                                <div fxFlex="50" style="color: #0000008a"></div>
                                                <div fxFlex="10" style="color: #0000008a"></div>
                                                <div fxFlex="40"
                                                    style="font-weight: bold;padding-top: 10px;text-align: right;margin-right: 12px;"
                                                    class="danger-color">
                                                    <span style="margin-right: 20px;font-weight: bold;">Total
                                                        Cost</span> ${{getTotal() | number : '1.2' }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true"
                        style="min-height: 30px !important;"></tr>
                    <tr mat-row *matRowDef="let element; columns: tableColumns" class="element-row"
                        [class.expanded-row]="expandedElement === element">
                    </tr>
                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>

                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="9999" style="color:rgb(228, 0, 43)">
                            <span *ngIf="notificationsDataSource?.data?.length == 0 && !isShowNotificationsProgressBar">
                                No service requests found
                            </span>
                            <span *ngIf="notificationsDataSource?.data?.length == 0 && isShowNotificationsProgressBar">
                            </span>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <div class="container mat-elevation-z4" style="margin-top:20px" *ngIf="pickList.length > 0">
            <mat-toolbar class="toolbar-height-50-1">
                <span>Picklist </span>
                <span class="example-spacer"></span>
                <button onclick="this.blur()" mat-button (click)="downloadPicklist()">
                    <mat-icon>download</mat-icon> Download Picklist
                </button>
            </mat-toolbar>

            <div class="table-container">
                <table mat-table [dataSource]="pickListDataSource" matSort>

                    <ng-container matColumnDef="NAME">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                        <td mat-cell *matCellDef="let row"> {{row.NAME}} </td>
                    </ng-container>

                    <ng-container matColumnDef="PART_ID">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                        <td mat-cell *matCellDef="let row"> {{row.PART_ID}} </td>
                    </ng-container>

                    <ng-container matColumnDef="TOTAL_COUNT">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="direction: rtl"> Required </th>
                        <td mat-cell *matCellDef="let row"> {{row.TOTAL_COUNT}} </td>
                    </ng-container>

                    <ng-container matColumnDef="BALANCE">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="direction: rtl"> Available </th>
                        <td mat-cell *matCellDef="let row"> {{row.BALANCE}} </td>
                    </ng-container>

                    <ng-container matColumnDef="DIFFERENCE">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="direction: rtl"> Difference </th>
                        <td mat-cell *matCellDef="let row"> {{row.DIFFERENCE}} </td>
                    </ng-container>

                    <ng-container matColumnDef="UNIT_PRICE">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="direction: rtl"> Unit Price </th>
                        <td mat-cell *matCellDef="let row"> {{row.UNIT_PRICE | currency:'USD'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="PRICE">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="direction: rtl"> Price </th>
                        <td mat-cell *matCellDef="let row"> {{row.PRICE | currency:'USD'}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="pickListColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: pickListColumns;"></tr>

                </table>
                <div style="text-align:right;margin-right:10px;padding: 10px;font-weight: bold;" class="danger-color">
                    <span style="margin-right: 20px;font-weight: bold;">Total
                        Cost</span> ${{getPicklistTotalCost() | number : '1.2' }}
                </div>
            </div>
        </div>

    </mat-sidenav-content>
    <!--#endregion -->
</mat-sidenav-container>