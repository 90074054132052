import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuditService } from 'src/app/services/audit.service';
import { AuthService } from 'src/app/services/auth.service';
import { LocationService } from 'src/app/services/location.service';
import { AuditFilterComponent } from '../audit-filter/audit-filter.component';

export interface DialogData {
  item: any;
}

@Component({
  selector: 'app-scheduled-service-request-filter',
  templateUrl: './scheduled-service-request-filter.component.html',
  styleUrls: ['./scheduled-service-request-filter.component.scss']
})
export class ScheduledServiceRequestFilterComponent implements OnInit, AfterViewInit {
  public isShowProgressBar: boolean = false;
  public filterData: any;
  public formGroup: FormGroup = new FormGroup({});
  public gateList: any = [];
  public statusList: any = [];
  public locations: any[] = [];
  public filterdLocations: any = [];
  public subLocationsFormGroup: FormGroup = new FormGroup({});

  constructor(
    public authService: AuthService,
    public auditService: AuditService,
    public router: Router,
    public dialogRef: MatDialogRef<ScheduledServiceRequestFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public locationService: LocationService,
  ) {
    // this.statusList = [
    //   { name: 'Open', value: 'OPEN' },
    //   { name: 'Closed', value: 'CLOSED' },
    //   { name: 'Incomplete', value: 'INCOMPLETE' }
    // ];
  }

  ngOnInit(): void {
    const rightMostPos = window.innerWidth - Number(0);
    this.dialogRef.updatePosition({
      top: `110px`,
      right: `45px`,
    });

    this.initializeFormControls();
    this.getLocationHeirarchy();
  }

  ngAfterViewInit() {
    this.setFormControlValues();
  }

  initializeFormControls() {
    this.formGroup = new FormGroup({
      location: new FormControl(''),
      // status: new FormControl(''),
    });
  }

  setFormControlValues() {
    if (this.data?.item && this.data?.item != null && this.data?.item != undefined) {
      this.formGroup = new FormGroup({
        location: new FormControl(this.data.item?.location),
        // status: new FormControl(this.data.item?.status)
      });
      this.filterdLocations = this.data?.item?.locationsList;
      this.formGroup.controls['location'].setValue(this.filterdLocations[0]?.id);
      for (let index = 0; index < this.filterdLocations.length; index++) {
        this.subLocationsFormGroup.addControl(this.filterdLocations[index].formControlName, new FormControl(this.filterdLocations[index + 1]?.id))
      }
    }
  }

  getLocationHeirarchy() {
    this.isShowProgressBar = true;
    this.locationService.getLocations().subscribe(
      (response: any) => {
        // Success callback
        this.isShowProgressBar = false;
        if (response.status === 200) {
          if (response?.body && response?.body?.length > 0) {
            // Assign the data
            this.locations = response.body;
            if (this.locations.length > 0 && this.data?.item == undefined) {
              this.formGroup.controls['location'].setValue(this.locations[0].id);
              if (this.locations[0]?.childrens && this.locations[0]?.childrens?.length > 0) {
                this.subLocationsFormGroup = new FormGroup({ 'a': new FormControl('') });
                this.filterdLocations.push(this.locations[0]);
                this.filterdLocations[0].formControlName = 'a';
              }
            }
          }
        } else if (response.status === 401) {
          // Redirect to login for unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        }
      },
      (error) => {
        this.isShowProgressBar = false;
        if (error.status === 401) {
          // Redirect to login for unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        } else if (error.status === 403) {
          //this.showErrorAlertDialog(error.error);
        } else {
          //this.showErrorAlertDialog(error.message);
        }
      }
    );
  }

  clearFilter() {
    this.formGroup.reset();
    this.subLocationsFormGroup.reset();
    this.filterdLocations = [];
    setTimeout(() => {
      if (this.locations.length > 0) {
        this.formGroup.controls['location'].setValue(this.locations[0].id);
        if (this.locations[0]?.childrens && this.locations[0]?.childrens?.length > 0) {
          this.subLocationsFormGroup = new FormGroup({
            a: new FormControl('')
          });
          this.filterdLocations.push(this.locations[0]);
          this.filterdLocations[0].formControlName = 'a';
        }
      }
    }, 300);
  }

  applyFilter() {
    let filterDetails = {
      location: this.filterdLocations[this.filterdLocations?.length - 1]?.id,
      // status: this.formGroup.controls['status'].value,
      locationsList: this.filterdLocations
    };
    this.dialogRef.close({ data: filterDetails });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  parentLocationsChange(item: any, index: number) {
    this.filterdLocations = [];
    this.subLocationsFormGroup = new FormGroup({});
    setTimeout(() => {
      this.filterdLocations.push(item);
      this.filterdLocations[0].formControlName = 'a';
      if (item?.childrens) { this.subLocationsFormGroup.addControl('a', new FormControl('')); }
    }, 100);
  }

  childLocationsChange(item: any, index: number) {
    this.filterdLocations = this.filterdLocations.slice(0, index + 1);
    const findedIndex = this.filterdLocations.findIndex((x: { id: any; }) => x.id == item.id);
    if (findedIndex == -1) {
      let name = Math.floor(1000 + Math.random() * 9000).toString();
      item.formControlName = name;
      this.filterdLocations.push(item);
      if (item?.childrens) { this.subLocationsFormGroup.addControl(name, new FormControl('')); }
    } else {
      this.filterdLocations = this.filterdLocations.slice(0, findedIndex);
    }
  }

  resetLocations() {
    this.filterdLocations = [];
    setTimeout(() => {
      if (this.locations.length > 0) {
        this.formGroup.controls['location'].setValue(this.locations[0].id);
        if (this.locations[0]?.childrens && this.locations[0]?.childrens?.length > 0) {
          this.subLocationsFormGroup = new FormGroup({
            a: new FormControl('')
          });
          this.filterdLocations.push(this.locations[0]);
          this.filterdLocations[0].formControlName = 'a';
        }
      }
    }, 300);
  }
}
