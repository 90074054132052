import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from '../app.config';
import { AppConstants } from '../shared/AppConstants';

@Injectable({
  providedIn: 'root',
})
export class AuditService {
  public constants: AppConstants;
  public filterDetails: any;
  public auditPageStateDetails: any;
  public auditToSubAuditStateDetails: any;
  public subAuditsFilterDetails: any;
  public subAuditsToNotificationStateDetails: any;
  constructor(
    private appConfigService: AppConfigService,
    private http: HttpClient
  ) {
    this.constants = new AppConstants();
  }

  // Download audits from servevr
  getAudits(
    limit: number,
    offset: number,
    searchText?: any,
    sortField?: any,
    sortDirection?: any,
    status?: any,
    location?: any,
    scheduleId?: any,
    serviceType?:any
  ) {
    let tempParams: any = {
      limit: limit,
      offset: offset,
    };
    if (scheduleId) {
      tempParams.scheduleId = scheduleId;
    }
    if (searchText) {
      tempParams.search = searchText;
    }
    if (sortField) {
      tempParams.sortingField = sortField;
    }
    if (sortDirection) {
      tempParams.sortingOrder = sortDirection;
    }
    if (status) {
      tempParams.status = status;
    }
    if (location) {
      tempParams.locationId = location;
    }
    if (serviceType) {
      tempParams.serviceType = serviceType;
    } else { tempParams.serviceType = 'ALL'};
    
    tempParams.app = 'admin';
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      params: tempParams,
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(url + this.constants.GET_AUDITS_URL, httpOptions);
  }

  // Download sub audits from servevr
  geSubtAudits(
    refAuditId: any,
    limit: number,
    offset: number,
    searchText?: any,
    sortField?: any,
    sortDirection?: any,
    status?: any,
    location?: any,
    scheduleId?: any
  ) {
    let tempParams: any = {
      refAuditId: refAuditId,
      limit: limit,
      offset: offset,
    };
    if (scheduleId) {
      tempParams.scheduleId = scheduleId;
    }
    if (searchText) {
      tempParams.search = searchText;
    }
    if (sortField) {
      tempParams.sortingField = sortField;
    }
    if (sortDirection) {
      tempParams.sortingOrder = sortDirection;
    }
    if (status) {
      tempParams.status = status;
    }
    if (location) {
      tempParams.locationId = location;
    }
    tempParams.serviceType = 'ALL';
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      params: tempParams,
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(url + this.constants.GET_AUDITS_URL, httpOptions);
  }

  // Download Locations
  getLocations() {
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(
      url + this.constants.GET_LOACTIONS_URL,
      httpOptions
    );
  }

  deleteAudits(body: any): Observable<any> {
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      body: body,
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.delete<any>(
      url + this.constants.DELETE_AUDIT,
      httpOptions
    );
  }
}
