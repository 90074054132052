import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { BudgetDetailsComponent } from 'src/app/components/budget-details/budget-details.component';
import { CustomerSelectComponent } from 'src/app/components/customer-select/customer-select.component';
import { ErrorAlertComponent } from 'src/app/components/error alert/error-alert.component';
import { AuthService } from 'src/app/services/auth.service';
import { BudgetService } from 'src/app/services/budget.service';
import { ConfigService } from 'src/app/services/config.service';
import { UtilService } from 'src/app/services/util.service';
import { ActionType, AppConstants } from 'src/app/shared/AppConstants';

@Component({
  selector: 'app-budget',
  templateUrl: './budget.component.html',
  styleUrls: ['./budget.component.scss'],
})
export class BudgetComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: any;
  @ViewChild(MatSort, { static: true }) sort!: any;
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  public version: string;
  public release: string;
  public constants: AppConstants;
  public selectedCustomer: any;
  public isShowBudjetProgressBar: boolean = false;
  public paginatorLength: number;
  public budgetsLength: number;
  public budgetsDataSource: MatTableDataSource<any>;
  public tableColumns: string[] = [
    'BUDGET_ID',
    'PART_GROUP_NAME',
    'PART_GROUPS',
    'BUDGET',
    'BUDGET_YEAR',
    'Actions',
  ];
  public budgetYears: any[] = [];
  public searchTerm: string = '';
  public selectedBudgetYear = new Date().getFullYear();
  public isDisabledYear: boolean = false;
  public months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  public appropriateClass:string = '';
  public events: string[] = [];
  
  @HostListener('window:resize', ['$event'])
  getScreenHeight(event?: any){
    if(window.innerHeight<=700){
      this.appropriateClass = 'bottomRelative';
    }else{
      this.appropriateClass = 'bottomStick';
    }
  }
  constructor(
    public authService: AuthService,
    public utilService: UtilService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    public dialog: MatDialog,
    public budgetService: BudgetService,
    public configService: ConfigService
  ) {
    this.getScreenHeight();
    this.constants = new AppConstants();
    this.version = this.constants.APP_RELEASE_NUMBER;
    this.release = this.constants.APP_RELEASE_DATE;
    this.paginatorLength = this.constants.INITIAL_PAGINATOR_LIMIT;
    this.budgetsLength = 0;
    this.budgetsDataSource = new MatTableDataSource();
    this.searchTerm = '';
  }

  async ngOnInit(): Promise<void> {
    this.selectedCustomer = localStorage.getItem('customerId');
    if (this.selectedCustomer && this.selectedCustomer != null) {
      this.authService.isAFSUser = this.selectedCustomer == 1 ? true : false;
    }
    await this.getFYDateRange();
    this.getBudgets(
      this.paginatorLength,
      0,
      '',
      '',
      '',
      this.selectedBudgetYear
    );
  }

  logout() {
    this.authService.logout();
  }

  getBudgets(
    limit: number,
    offset: number,
    searchText?: any,
    sortField?: any,
    sortDirection?: any,
    year?: any
  ) {
    this.isShowBudjetProgressBar = true;
    this.budgetService
      .getBudgets(limit, offset, searchText, sortField, sortDirection, year)
      .subscribe(
        (response: any) => {
          // Success callback
          this.isShowBudjetProgressBar = false;
          if (response.status === 200) {
            if (response.body) {
              // Assign the data to the data source for the table to render
              this.budgetsDataSource = new MatTableDataSource(
                response.body.BUDGETS
              );
              this.budgetsLength = response.body.BUDGETS_COUNT;
            }
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.router.navigate(['dashboard']);
          } else {
            this.showErrorDialog(response.error);
          }
        },
        (error) => {
          this.isShowBudjetProgressBar = false;
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.router.navigate(['dashboard']);
          } else {
            if (error?.error && error?.error?.length > 0) {
              this.showErrorDialog(error.error);
            } else {
              this.showErrorDialog(error.message);
            }
          }
        }
      );
  }

  async refreshBudgets() {
    this.paginator.pageIndex = 0;
    this.isDisabledYear = false;
    this.selectedBudgetYear = new Date().getFullYear();
    await this.getFYDateRange();
    this.getBudgets(
      this.paginatorLength,
      0,
      '',
      '',
      '',
      this.selectedBudgetYear
    );
  }

  showErrorDialog(msg: string) {
    const dialogRef = this.dialog.open(ErrorAlertComponent, {
      width: '400px',
      data: {
        errorMsg: msg,
      },
    });
  }

  handlePaginator(e: any) {
    this.paginatorLength = e.pageSize;
    let limit = e.pageSize;
    let offset = e.pageIndex * limit;
    this.paginator.pageIndex = e.pageIndex;
    if (this.sort.active) {
      this.getBudgets(
        limit,
        offset,
        this.searchTerm != null && this.searchTerm != '' ? this.searchTerm : '',
        this.sort.active,
        this.sort._direction,
        this.selectedBudgetYear
      );
    } else {
      this.getBudgets(
        limit,
        offset,
        this.searchTerm != null && this.searchTerm != '' ? this.searchTerm : '',
        '',
        '',
        this.selectedBudgetYear
      );
    }
  }

  sortfields(e: any) {
    let offset = this.paginator._pageIndex * this.paginator._pageSize;
    this.getBudgets(
      this.paginatorLength,
      offset,
      this.searchTerm != null && this.searchTerm != '' ? this.searchTerm : '',
      e.active,
      e.direction,
      this.selectedBudgetYear
    );
    this.paginator.pageIndex = this.paginator._pageIndex;
  }

  addBudget() {
    const dialogRef = this.dialog.open(BudgetDetailsComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: {
        mode: ActionType.Add,
        item: {},
        selectedYear: this.selectedBudgetYear,
      },
      panelClass: 'add-customer-dialog',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.paginator.pageIndex = 0;
        this.getBudgets(
          this.paginatorLength,
          0,
          '',
          '',
          '',
          this.selectedBudgetYear
        );
      }
    });
  }

  editBudget(item: any) {
    const dialogRef = this.dialog.open(BudgetDetailsComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: {
        mode: ActionType.Edit,
        item: item,
        selectedYear: this.selectedBudgetYear,
      },
      panelClass: 'add-customer-dialog',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let offset = this.paginator._pageIndex * this.paginator._pageSize;
        this.paginator.pageIndex = this.paginator._pageIndex;
        if (this.sort.active) {
          this.getBudgets(
            this.paginatorLength,
            offset,
            '',
            this.sort.active,
            this.sort._direction,
            this.selectedBudgetYear
          );
        } else {
          this.getBudgets(
            this.paginatorLength,
            offset,
            '',
            '',
            '',
            this.selectedBudgetYear
          );
        }
      }
    });
  }

  budgetYearChange(ev: any) {
    this.paginator.pageIndex = 0;
    this.isDisabledYear = false;
    this.getBudgets(
      this.paginatorLength,
      0,
      '',
      '',
      '',
      this.selectedBudgetYear
    );
    if (this.selectedBudgetYear < new Date().getFullYear()) {
      this.isDisabledYear = true;
    }
  }

  deleteBudget(item: any) {
    const dialogRef = this.dialog.open(AlertComponent, {
      width: '400px',
      data: { errorMsg: 'Are you sure you want to delete this budget?' },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result.data) {
        let body = {
          BUDGET_ID: item.BUDGET_ID,
        };

        await this.budgetService
          .deleteBudget(body)
          .toPromise()
          .then(
            (response: any) => {
              if (response.status === 204) {
                let offset =
                  this.paginator._pageIndex * this.paginator._pageSize;
                this.paginator.pageIndex = this.paginator._pageIndex;
                if (this.sort.active) {
                  this.getBudgets(
                    this.paginatorLength,
                    offset,
                    '',
                    this.sort.active,
                    this.sort._direction,
                    this.selectedBudgetYear
                  );
                } else {
                  this.getBudgets(
                    this.paginatorLength,
                    offset,
                    '',
                    '',
                    '',
                    this.selectedBudgetYear
                  );
                }
              } else {
                if (response?.error && response?.error?.length > 0) {
                  this.showErrorDialog(response.error);
                } else {
                  this.showErrorDialog(response.message);
                }
              }
            },
            (error) => {
              if (error?.error && error?.error?.length > 0) {
                this.showErrorDialog(error.error);
              } else {
                this.showErrorDialog(error.message);
              }
            }
          );
      }
    });
  }

  getIsoDate(date: any) {
    var iso = date.toISOString();
    iso = iso.substring(0, iso.indexOf('T'));
    return iso;
  }

  async getFYDateRange() {
    await this.configService
      .getFYDateRange()
      .toPromise()
      .then(
        (response: any) => {
          this.isShowBudjetProgressBar = false;
          if (response.status === 200) {
            if (response?.body) {
              let dates = response?.body;
              dates.forEach((element: any) => {
                let startIsoDate = this.getIsoDate(new Date(element.FY_START));
                let endIsoDate = this.getIsoDate(new Date(element.FY_END));
                element.FY_LABEL = `FY ${element.FY} (${new Date(startIsoDate).getUTCDate().toString().padStart(2, "0")} ${this.months[Number((new Date(startIsoDate).getUTCMonth()).toString().padStart(2, "0"))]} ${new Date(startIsoDate).getUTCFullYear()} - ${new Date(endIsoDate).getUTCDate().toString().padStart(2, "0")} ${this.months[Number((new Date(endIsoDate).getUTCMonth()).toString().padStart(2, "0"))]} ${new Date(endIsoDate).getUTCFullYear()})`
              });
              this.budgetYears = dates;
            }
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.showErrorDialog(response.error);
          } else {
            this.showErrorDialog(response.message);
          }
        },
        (error) => {
          this.isShowBudjetProgressBar = false;
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.showErrorDialog(error.message);
          } else {
            this.showErrorDialog(error.message);
          }
        }
      );
  }

  switchCompany() {
    let userTenants: any = localStorage.getItem('userTenants');
    if (userTenants && userTenants != null) {
      userTenants = JSON.parse(userTenants);
      const dialogRef = this.dialog.open(CustomerSelectComponent, {
        panelClass: 'add-customer-dialog',
        width: '550px',
        height: '380px',
        disableClose: false,
        data: { mode: 0, item: userTenants },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
         this.refreshBudgets();
        }
      });
    }
  }
}
