import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CustomerSelectComponent } from 'src/app/components/customer-select/customer-select.component';
import { ErrorAlertComponent } from 'src/app/components/error alert/error-alert.component';
import { AuthService } from 'src/app/services/auth.service';
import { ChoicesService } from 'src/app/services/choices.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public isLogin: boolean;
  public name: any;
  public email: any;
  public hide: boolean;
  public errorMsg: string;
  public isSuccess: boolean;
  public isShowProgressBar: boolean = false;

  public emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  public passwordFormControl = new FormControl('', [Validators.required]);

  constructor(
    public authService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    public choiceService: ChoicesService
  ) {
    this.isLogin = true;
    this.hide = true;
    this.name = '';
    this.email = '';
    this.errorMsg = '';
    this.isSuccess = false;
  }

  ngOnInit(): void {
    this.emailFormControl.valueChanges.subscribe((value) => {
      this.errorMsg = '';
    });
    this.passwordFormControl.valueChanges.subscribe((value) => {
      this.errorMsg = '';
    });

    this.loginCheck();
  }

  // Login process
  public async login() {
    this.isShowProgressBar = true;
    this.errorMsg = '';
    this.isSuccess = false;
    let body = {
      email: this.emailFormControl.value,
      password: this.passwordFormControl.value,
    };

    // sha256 converted password
    body.password = await this.sha256(body.password);

    this.authService.authenticateUser(body).subscribe(
      async (response: any) => {
        // Success callback
        this.isShowProgressBar = false;
        if (response.status === 200) {
          if (response.body) {
            if (response?.body?.tenants?.length > 0) {
              localStorage.setItem('userTenants', JSON.stringify(response?.body?.tenants));
              // Showing customer select dialog
              const dialogRef = this.dialog.open(CustomerSelectComponent, {
                panelClass: 'add-customer-dialog',
                width: '550px',
                height: '380px',
                disableClose: true,
                data: { mode: 1, item: response?.body?.tenants },
              });
              dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                  this.authService
                    .authenticateUserWithTanentId(body, result.CUSTOMER_ID)
                    .subscribe(async (response: any) => {
                      // Success callback
                      this.isShowProgressBar = false;
                      if (response.status === 200) {
                        if (response.body) {
                          if (
                            response.body.USER &&
                            response.body.USER.MODULE &&
                            response.body.USER.MODULE.length > 0
                          ) {
                            if (response.body.USER.MODULE.includes('ADMIN')) {
                              // Setting user details to local storage.
                              localStorage.setItem(
                                'token',
                                response.body.token
                              );
                              localStorage.setItem(
                                'email',
                                this.emailFormControl.value
                              );
                              localStorage.setItem(
                                'name',
                                response.body.USER.FIRST_NAME
                              );
                              localStorage.setItem(
                                'customerName',
                                response.body.CUSTOMER_NAME
                              );
                              localStorage.setItem(
                                'customerId',
                                response.body.USER.CUSTOMER_ID
                              );
                              localStorage.setItem(
                                'user-details',
                                JSON.stringify(response.body.USER)
                              );
                              await this.getCustomization();
                              this.authService.loggedIn.next(true);

                              if (response?.body?.USER?.CUSTOMER_TYPE == null) {
                                this.router.navigate(['/users']);
                              } else {
                                this.router.navigate(['/dashboard']);
                              }
                            } else {
                              this.errorMsg =
                                'You do not have access to the Admin App, check with your Administrator to get access';
                            }
                          } else {
                            this.errorMsg =
                              'You do not have access to the Admin App, check with your Administrator to get access';
                          }
                        } else {
                          this.errorMsg = 'No response from server';
                        }
                      }
                    });
                }
              });
            } else {
              if (
                response.body.USER &&
                response.body.USER.MODULE &&
                response.body.USER.MODULE.length > 0
              ) {
                if (response.body.USER.MODULE.includes('ADMIN')) {
                  // Setting user details to local storage.
                  localStorage.setItem('token', response.body.token);
                  localStorage.setItem('email', this.emailFormControl.value);
                  localStorage.setItem('name', response.body.USER.FIRST_NAME);
                  localStorage.setItem(
                    'customerName',
                    response.body.CUSTOMER_NAME
                  );
                  localStorage.setItem(
                    'customerId',
                    response.body.USER.CUSTOMER_ID
                  );
                  localStorage.setItem(
                    'user-details',
                    JSON.stringify(response.body.USER)
                  );
                  await this.getCustomization();
                  this.authService.loggedIn.next(true);
                  if (response?.body?.USER?.CUSTOMER_TYPE == null) {
                    this.router.navigate(['/users']);
                  } else {
                    this.router.navigate(['/dashboard']);
                  }
                } else {
                  this.errorMsg =
                    'You do not have access to the Admin App, check with your Administrator to get access';
                }
              } else {
                this.errorMsg =
                  'You do not have access to the Admin App, check with your Administrator to get access';
              }
            }
          } else {
            this.errorMsg = 'No response from server';
          }
        }
      },
      (error) => {
        this.isShowProgressBar = false;
        // Error callback
        if (error.status === 401) {
          this.errorMsg = error.error;
        } else {
          this.errorMsg = error.message;
        }
      },
      () => {
        // Call completed callback
      }
    );
  }

  // Use differnt account for login
  public useDifferentAccount() {
    this.isSuccess = false;
    this.email = '';
    this.name = '';
    this.errorMsg = '';
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('name');
    localStorage.removeItem('user-details');
    localStorage.removeItem('customerName');
    localStorage.removeItem('customerId');
    localStorage.removeItem('dashboardSlug');
    localStorage.removeItem('userTenants');
    this.isLogin = true;
    this.emailFormControl.setValue('');
    this.emailFormControl.markAsUntouched({ onlySelf: true });
    this.passwordFormControl.setValue('');
    this.passwordFormControl.markAsUntouched({ onlySelf: true });
  }

  // Login check 2nd time
  public loginCheck() {
    this.errorMsg = '';
    this.isSuccess = false;
    this.email = localStorage.getItem('email');
    this.name = localStorage.getItem('name');

    if (this.email != null && this.name != null) {
      this.isLogin = false;
      this.emailFormControl.setValue(this.email);
      this.emailFormControl.markAsUntouched({ onlySelf: true });
      this.passwordFormControl.setValue('');
      this.passwordFormControl.markAsUntouched({ onlySelf: true });
    }
  }

  // sha256 converted password
  async sha256(password: string) {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(password);

    // hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // convert bytes to hex string
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('');
    return hashHex.toUpperCase();
  }

  // Forgot password
  forgotPassword() {
    this.errorMsg = '';
    if (this.emailFormControl.valid) {
      this.isShowProgressBar = true;
      let body = {
        client: 'admin',
        email: this.emailFormControl.value,
      };
      this.authService.forgotPassword(body).subscribe(
        (response: any) => {
          this.isShowProgressBar = false;
          // Success callback
          this.isSuccess = true;
          if (response.status === 204) {
            this.errorMsg =
              'An email containing instructions to reset password is sent. Please check your inbox.';
          } else {
            this.isSuccess = false;
            this.errorMsg = response.statusText;
          }
        },
        (error) => {
          this.isShowProgressBar = false;
          // Error callback
          this.isSuccess = false;
          this.errorMsg = error.error;
        }
      );
    } else {
      this.isSuccess = false;
      // Show error email not valid
      if (
        this.emailFormControl.value &&
        this.emailFormControl.value != null &&
        this.emailFormControl.value != ''
      ) {
        this.errorMsg = 'Enter valid email to reset password';
      } else {
        this.errorMsg = 'Email is required to reset password';
      }
    }
  }

  async getCustomization() {
    this.isShowProgressBar = true;
    await this.choiceService
      .getCustomization()
      .toPromise()
      .then(
        (response: any) => {
          // Success callback
          this.isShowProgressBar = false;
          if (response.status === 200) {
            if (response?.body?.DashboardUrl) {
              localStorage.setItem(
                'dashboardSlug',
                response?.body?.DashboardUrl
              );
            }
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.showErrorDialog(response.error);
          } else {
            this.showErrorDialog(response.error);
          }
        },
        (error) => {
          this.isShowProgressBar = false;
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.showErrorDialog(error.error);
          } else {
            this.showErrorDialog(error.message);
          }
        }
      );
  }

  showErrorDialog(msg: string) {
    this.dialog.open(ErrorAlertComponent, {
      width: '400px',
      data: {
        errorMsg: msg,
      },
    });
  }
}
