import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ExtensionsService } from 'src/app/services/extensions.service';
import { PartService } from 'src/app/services/part.service';
import { ActionType, AppConstants } from 'src/app/shared/AppConstants';
import { PartsListComponent } from '../parts-list/parts-list.component';

export interface DialogData {
  mode: number;
  item: any;
}

@Component({
  selector: 'app-add-new-part',
  templateUrl: './add-new-part.component.html',
  styleUrls: ['./add-new-part.component.scss'],
})
export class AddNewPartComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: any;
  @ViewChild(MatSort, { static: false }) sort: any;
  public timeout: any = null;
  public title: string;
  public errorMsg: string;
  public isShowProgressBar: boolean = false;
  public constants: AppConstants;
  public formGroup: FormGroup = new FormGroup({});
  public customChoices: any = [];
  public customUomChoices: any = [];
  public serviceRequestExtensions: any = {};
  public isShowPartsProgressBar: boolean = false;
  public paginatorLength: number = 0;
  public partsLength = 0;
  public partDataSource = new MatTableDataSource<any>([]);
  public partSelection = new SelectionModel<any>(true, []);
  public partColumns: string[] = ['BOM_PART_ID', 'NAME', 'QUANTITY', 'UOM'];

  constructor(
    public extensionsService: ExtensionsService,
    public authService: AuthService,
    public router: Router,
    public partService: PartService,
    public dialog: MatDialog,
    public partDialogRef: MatDialogRef<AddNewPartComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.constants = new AppConstants();
    this.errorMsg = '';
    this.title =
      this.data.mode == ActionType.Add ? 'Create Part' : 'Update Part';
    this.paginatorLength = 5;
  }

  ngOnInit(): void {
    this.getExtensions();
    if (this.data.mode == ActionType.Add) {
      this.formGroup = new FormGroup({
        partId: new FormControl('', [Validators.required]),
        name: new FormControl('', [Validators.required]),
        description: new FormControl(''),
        manufacturer: new FormControl('', [Validators.required]),
        manufacturerPartNo: new FormControl('', [Validators.required]),
        partType: new FormControl('', [Validators.required]),
        uom: new FormControl('', [Validators.required]),
        manufacturerPartNo2: new FormControl(''),
        manufacturerPartNo3: new FormControl(''),
        minInventory: new FormControl(''),
        maxInventory: new FormControl(''),
      });
    } else {
      this.formGroup = new FormGroup({
        partId: new FormControl(
          { value: this.data.item.PART_ID, disabled: true },
          [Validators.required]
        ),
        name: new FormControl(this.data.item.NAME, [Validators.required]),
        description: new FormControl(this.data.item.DESCRIPTION),
        manufacturer: new FormControl(this.data.item.MANUFACTURER, [
          Validators.required,
        ]),
        manufacturerPartNo: new FormControl(this.data.item.MFG_PART_NUMBER, [
          Validators.required,
        ]),
        partType: new FormControl(this.data.item.PART_TYPE, [
          Validators.required,
        ]),
        uom: new FormControl(this.data.item.UOM, [Validators.required]),
        manufacturerPartNo2: new FormControl(this.data.item.MFG_PART_NUMBER_2),
        manufacturerPartNo3: new FormControl(this.data.item.MFG_PART_NUMBER_3),
        minInventory: new FormControl(this.data.item.MIN_INVENTORY),
        maxInventory: new FormControl(this.data.item.MAX_INVENTORY),
      });

      if (this.data.item?.PART_TYPE == 'BOM') {
        this.partDataSource = new MatTableDataSource<any>(
          this.data.item?.PART_BOM
        );
      }
    }
    this.formGroup.valueChanges.subscribe((x) => {
      this.errorMsg = '';
    });
  }

  closeDialog(): void {
    this.partDialogRef.close(false);
  }

  getExtensions() {
    this.extensionsService.getExtensions().subscribe(
      (response: any) => {
        // Success callback
        if (response.status === 200) {
          if (response.body) {
            this.customChoices = response.body.CHOICE.CUSTOM;
            this.customUomChoices = response.body.CHOICE.UOM;
            this.serviceRequestExtensions =
              response.body.EXTENSIONS.SERVICE_REQUEST;
            if (this.data.mode == ActionType.Add) {
              if (this.serviceRequestExtensions) {
                for (var key in this.serviceRequestExtensions) {
                  if (this.serviceRequestExtensions[key].length > 0) {
                    for (
                      let i = 0;
                      i < this.serviceRequestExtensions[key].length;
                      i++
                    ) {
                      switch (
                      this.serviceRequestExtensions[key][i].FIELD_TECH_NAME
                      ) {
                        case 'txtFld1':
                          this.serviceRequestExtensions[key][i]['txtFld1'] = '';
                          break;
                        case 'txtAreaFld1':
                          this.serviceRequestExtensions[key][i]['txtAreaFld1'] =
                            '';
                          break;
                        case 'numFld1':
                          this.serviceRequestExtensions[key][i]['numFld1'] = '';
                          break;
                        case 'chFld1':
                          this.serviceRequestExtensions[key][i]['chFld1'] = '';
                          break;
                        case 'tofFld1':
                          this.serviceRequestExtensions[key][i]['tofFld1'] =
                            'Y';
                          break;
                        default:
                          break;
                      }
                    }
                  }
                }
              }
            } else {
              if (this.data.item.EXTN) {
                this.serviceRequestExtensions = this.data.item.EXTN;
              } else {
                if (this.serviceRequestExtensions) {
                  for (var key in this.serviceRequestExtensions) {
                    if (this.serviceRequestExtensions[key].length > 0) {
                      for (
                        let i = 0;
                        i < this.serviceRequestExtensions[key].length;
                        i++
                      ) {
                        switch (
                        this.serviceRequestExtensions[key][i].FIELD_TECH_NAME
                        ) {
                          case 'txtFld1':
                            this.serviceRequestExtensions[key][i]['txtFld1'] =
                              '';
                            break;
                          case 'txtAreaFld1':
                            this.serviceRequestExtensions[key][i][
                              'txtAreaFld1'
                            ] = '';
                            break;
                          case 'numFld1':
                            this.serviceRequestExtensions[key][i]['numFld1'] =
                              '';
                            break;
                          case 'chFld1':
                            this.serviceRequestExtensions[key][i]['chFld1'] =
                              '';
                            break;
                          case 'tofFld1':
                            this.serviceRequestExtensions[key][i]['tofFld1'] =
                              'Y';
                            break;
                          default:
                            break;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        } else if (response.status === 401) {
          // Redirect to login for unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId');
          localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        } else if (response.status === 403) {
          console.log(response.error);
        } else {
        }
      },
      (error) => {
        if (error.status === 401) {
          // Redirect to login for unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId');
          localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        } else if (error.status === 403) {
          console.log(error.error);
        } else {
          console.log('Error while downloading choices');
        }
      }
    );
  }

  async savePart() {
    this.isShowProgressBar = true;
    let body: any = {
      PART_ID: this.formGroup.controls.partId.value,
      NAME: this.formGroup.controls.name.value,
      DESCRIPTION: this.formGroup.controls.description.value,
      MANUFACTURER: this.formGroup.controls.manufacturer.value,
      MFG_PART_NUMBER: this.formGroup.controls.manufacturerPartNo.value,
      PART_TYPE: this.formGroup.controls.partType.value,
      UOM: this.formGroup.controls.uom.value,
      MFG_PART_NUMBER_2: this.formGroup.controls.manufacturerPartNo2.value,
      MFG_PART_NUMBER_3: this.formGroup.controls.manufacturerPartNo3.value,
      MIN_INVENTORY: this.formGroup.controls.minInventory.value
        ? Number(this.formGroup.controls.minInventory.value)
        : null,
      MAX_INVENTORY: this.formGroup.controls.maxInventory.value
        ? Number(this.formGroup.controls.maxInventory.value)
        : null,
    };

    if (body.PART_TYPE == 'BOM') {
      if (this.partDataSource.data && this.partDataSource.data.length > 0) {
        let tempArr: any = [];
        for (let i = 0; i < this.partDataSource?.data.length; i++) {
          let obj = {
            PART_ID: body.PART_ID,
            BOM_PART_ID: this.partDataSource.data[i].BOM_PART_ID,
            QTY: this.partDataSource.data[i].QTY
              ? Number(this.partDataSource.data[i].QTY)
              : null,
            UOM: this.partDataSource.data[i].UOM,
          };
          tempArr.push(obj);
        }
        body.PART_BOM = tempArr;
      } else {
        body.PART_BOM = [];
      }
    } else {
      body.EXTN = this.serviceRequestExtensions;
    }

    if (this.data.mode === ActionType.Add) {
      await this.partService
        .createPart(body)
        .toPromise()
        .then(
          (response: any) => {
            this.isShowProgressBar = false;
            if (response.status === 204) {
              this.partDialogRef.close(true);
            } else {
              this.errorMsg = response.message;
            }
          },
          (error) => {
            this.isShowProgressBar = false;
            this.errorMsg = error.error;
          }
        );
    } else {
      await this.partService
        .updatePart(body)
        .toPromise()
        .then(
          (response: any) => {
            this.isShowProgressBar = false;
            if (response.status === 204) {
              this.partDialogRef.close(true);
            } else {
              this.errorMsg = response.message;
            }
          },
          (error) => {
            this.isShowProgressBar = false;
            this.errorMsg = error.error;
          }
        );
    }
  }

  editParts() {
    const dialogRef = this.dialog.open(PartsListComponent, {
      width: '650px',
      height: '450px',
      data: { mode: ActionType.Add, item: this.partDataSource?.filteredData },
      panelClass: 'add-customer-dialog',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.length > 0) {
          this.partDataSource = new MatTableDataSource<any>([]);
          this.partDataSource = new MatTableDataSource(result);
          this.partsLength = result.length;
          this.partDataSource.paginator = this.paginator;
          this.partDataSource.sort = this.sort;
          if (this.partDataSource.paginator) {
            this.partDataSource.paginator.firstPage();
          }
        } else {
          this.partDataSource = new MatTableDataSource<any>([]);
        }
      }
    });
  }

  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
