import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Airline';
  token: any;

  constructor(public router: Router, public authService: AuthService) { }

  ngOnInit(): void {
    let url: string = window.location.href;
    if(url.includes("resetpassword")) {} else {
      this.token = localStorage.getItem('token');
      if (this.token && this.token != undefined) {
        if (this.tokenExpired(this.token)) {
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId');
          localStorage.removeItem('dashboardSlug');
          localStorage.removeItem('userTenants');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        }
      } else { this.router.navigate(['login']); }
    }
  }

  tokenExpired(token: string) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }
}
