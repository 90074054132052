<!--#region Toolbar -->
<mat-toolbar class="app-toolbar-fixed">
    <button mat-icon-button (click)="closeDialog()" onclick="this.blur()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <span>{{title}}</span>
    <span class="example-spacer"></span>
</mat-toolbar>

<mat-progress-bar *ngIf="isShowProgressBar" class="primary-color" mode="indeterminate">
</mat-progress-bar>

<div class="container">
    <div fxLayout="row" class="div-row">
        <div [fxFlex]="formGroup.controls.partType.valid? 100: 35" class="part-div" style="display:flex">
            <div fxFlex="auto">
                <p class="title-p-tag">Part Details</p>
                <form [formGroup]="formGroup">
                    <mat-form-field appearance="outline">
                        <mat-label>Part Id</mat-label>
                        <input autocomplete="off" matInput type="text" required formControlName="partId">
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Name</mat-label>
                        <input autocomplete="off" matInput type="text" required formControlName="name">
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Description</mat-label>
                        <textarea autocomplete="off" matInput type="text" formControlName="description"> </textarea>
                    </mat-form-field>

                    <div fxLayout="row">
                        <div fxFlex="50" style="padding-right: 6px;">
                            <mat-form-field appearance="outline">
                                <mat-label>Manufacturer Name</mat-label>
                                <input autocomplete="off" matInput type="text" required formControlName="manufacturer">
                            </mat-form-field>
                        </div>

                        <div fxFlex="50" style="padding-left: 6px;">
                            <mat-form-field appearance="outline">
                                <mat-label>Manufacturer Part Number 1</mat-label>
                                <input autocomplete="off" matInput type="text" required
                                    formControlName="manufacturerPartNo">
                            </mat-form-field>
                        </div>
                    </div>

                    <div fxLayout="row">
                        <div fxFlex="50" style="padding-right: 6px;">
                            <mat-form-field appearance="outline">
                                <mat-label>Manufacturer Part Number 2</mat-label>
                                <input autocomplete="off" matInput type="text" formControlName="manufacturerPartNo2">
                            </mat-form-field>
                        </div>

                        <div fxFlex="50" style="padding-left: 6px;">
                            <mat-form-field appearance="outline">
                                <mat-label>Manufacturer Part Number 3</mat-label>
                                <input autocomplete="off" matInput type="text" formControlName="manufacturerPartNo3">
                            </mat-form-field>
                        </div>
                    </div>

                    <div fxLayout="row">
                        <div fxFlex="50" style="padding-right: 6px;">
                            <mat-form-field appearance="outline">
                                <mat-label>Minimum Inventory</mat-label>
                                <input autocomplete="off" (keypress)="numberOnly($event)" matInput type="text"
                                    formControlName="minInventory">
                            </mat-form-field>
                        </div>

                        <div fxFlex="50" style="padding-left: 6px;">
                            <mat-form-field appearance="outline">
                                <mat-label>Maximum Inventory</mat-label>
                                <input autocomplete="off" matInput type="text" (keypress)="numberOnly($event)"
                                    formControlName="maxInventory">
                            </mat-form-field>
                        </div>
                    </div>

                    <div fxLayout="row">
                        <div fxFlex="50" style="padding-right: 6px;">
                            <mat-form-field appearance="outline">
                                <mat-label>UOM</mat-label>
                                <mat-select disableOptionCentering formControlName="uom" required>
                                    <mat-option [value]="item.CHOICE_VALUE" *ngFor="let item of customUomChoices">
                                        {{item.CHOICE_NAME}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div fxFlex="50" style="padding-left: 6px;">
                            <mat-form-field appearance="outline">
                                <mat-label>Part Type</mat-label>
                                <mat-select formControlName="partType" required disableOptionCentering>
                                    <mat-option value="PART">
                                        PART
                                    </mat-option>
                                    <mat-option value="BOM">
                                        BOM
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </form>
            </div>
            <div fxFlex="55" *ngIf="formGroup.controls.partType.valid" class="table-div">
                <div id="menu"></div>

                <div *ngIf="formGroup.controls.partType.value == 'PART' && serviceRequestExtensions"
                    style="padding-left: 10px;">
                    <p class="title-p-tag">Extensions</p>

                    <div *ngIf="serviceRequestExtensions" style="display: flex;">
                        <div class="section" *ngFor="let items of serviceRequestExtensions | keyvalue"
                            style="margin-left: 20px;">
                            <span class="label">{{items.key}}</span>
                            <hr />
                            <span *ngFor="let item of items.value| extnPipe">
                                <p class="field-name"> {{item.FIELD_NAME}} <span *ngIf="item.FIELD_MANDATORY == 'Y'"
                                        style="color:rgb(228, 0, 43)">*</span></p>

                                <div class="text" *ngIf="item.FIELD_CONTROL == 'TEXT'">
                                    <input [(ngModel)]="item.txtFld1" [ngModelOptions]="{standalone: true}" matInput>
                                </div>

                                <div class="text" *ngIf="item.FIELD_CONTROL == 'TEXTAREA'">
                                    <textarea matInput [(ngModel)]="item.txtAreaFld1"
                                        [ngModelOptions]="{standalone: true}"> </textarea>
                                </div>

                                <div class="text" *ngIf="item.FIELD_CONTROL == 'NUMBER'">
                                    <input matInput [(ngModel)]="item.numFld1" [ngModelOptions]="{standalone: true}">
                                </div>

                                <div class="text" *ngIf="item.FIELD_CONTROL == 'CHOICE'">
                                    <mat-select disableOptionCentering [(ngModel)]="item.chFld1"
                                        [ngModelOptions]="{standalone: true}">
                                        <mat-option [value]="item.CHOICE_VALUE" *ngFor="let item of customChoices">
                                            {{item.CHOICE_NAME}}</mat-option>
                                    </mat-select>
                                </div>

                                <div class="text" *ngIf="item.FIELD_CONTROL == 'TOGGLE'">
                                    <mat-button-toggle-group [(ngModel)]="item.tofFld1"
                                        [ngModelOptions]="{standalone: true}">
                                        <mat-button-toggle value="Y">True</mat-button-toggle>
                                        <mat-button-toggle value="N">False</mat-button-toggle>
                                    </mat-button-toggle-group>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>

                <div *ngIf="formGroup.controls.partType.value == 'BOM'" style="padding: 0px 0px 0px 30px;width: 90%;">
                    <p class="title-p-tag">BOM Parts</p>

                    <div class="mat-elevation-z4" style="max-height:80%">
                        <mat-progress-bar *ngIf="isShowPartsProgressBar" class="primary-color" mode="indeterminate">
                        </mat-progress-bar>
                        <mat-toolbar class="container-toolbar">
                            <mat-toolbar-row style="padding-left: 0px;">
                                <button mat-button color="primary" class="primary-color" onclick="this.blur()"
                                    (click)="editParts()">
                                    <mat-icon class="primary-color material-icons-outlined" style="font-size: 20px;">
                                        edit</mat-icon>
                                    Edit Parts
                                </button>
                                <span class="example-spacer"></span>
                                <mat-paginator pageSize="6">
                                </mat-paginator>
                            </mat-toolbar-row>
                        </mat-toolbar>

                        <table mat-table [dataSource]="partDataSource" matSort>
                            <!-- ID Column -->
                            <ng-container matColumnDef="BOM_PART_ID">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> BOM Part ID </th>
                                <td mat-cell *matCellDef="let element"> {{element.BOM_PART_ID}} </td>
                            </ng-container>

                            <!-- Name Column -->
                            <ng-container matColumnDef="NAME">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                                <td mat-cell *matCellDef="let element"> {{element.NAME}} </td>
                            </ng-container>

                            <ng-container matColumnDef="QUANTITY">
                                <th mat-header-cell *matHeaderCellDef> Quantity
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <div class="text">
                                        <input matInput (keypress)="numberOnly($event)" [(ngModel)]="element.QTY"
                                            [ngModelOptions]="{standalone: true}">
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="UOM">
                                <th mat-header-cell *matHeaderCellDef> UOM </th>
                                <td mat-cell *matCellDef="let element">
                                    <div class="text">
                                        <mat-select disableOptionCentering [(ngModel)]="element.UOM"
                                            [ngModelOptions]="{standalone: true}">
                                            <mat-option [value]="item.CHOICE_VALUE"
                                                *ngFor="let item of customUomChoices">
                                                {{item.CHOICE_NAME}}</mat-option>
                                        </mat-select>
                                    </div>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="partColumns;"></tr>
                            <tr mat-row *matRowDef="let row; columns: partColumns;" (click)="partSelection.toggle(row)">
                            </tr>
                        </table>
                    </div>
                </div>
            </div>

            <div [ngClass]="formGroup.controls.partType.valid? 'button-large-div' : 'button-small-div'">
                <button mat-raised-button [ngClass]="formGroup.valid ? 'primary-button' : ''" (click)="savePart()"
                    [disabled]="!formGroup.valid">
                    <span *ngIf="data.mode == 1"> Create </span>
                    <span *ngIf="data.mode == 2"> Update </span>
                </button>
            </div>

            <div *ngIf="errorMsg.length> 0"
                style="text-align: center;color: rgb(228, 0, 43);position: absolute;bottom: 20px;width: 100%;">
                <p>{{errorMsg}}</p>
            </div>
        </div>
    </div>
</div>