import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

export interface DialogData {
  item: any;
}

@Component({
  selector: 'app-full-screen-image',
  templateUrl: './full-screen-image.component.html',
  styleUrls: ['./full-screen-image.component.scss'],
})
export class FullScreenImageComponent implements OnInit {
  public path: string;

  constructor(
    public sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<FullScreenImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.path =
      `data:${this.data.item.mimetype};base64,` +
      (
        this.sanitizer.bypassSecurityTrustResourceUrl(
          this.data.item.imageBase64
        ) as any
      ).changingThisBreaksApplicationSecurity;
  }

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }
}
