import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ExtensionsService } from 'src/app/services/extensions.service';
import { LocationService } from 'src/app/services/location.service';

export interface DialogData {
  item: any;
}

@Component({
  selector: 'extension-fields',
  templateUrl: './extension-fields.component.html',
  styleUrls: ['./extension-fields.component.scss'],
})
export class ExtensionFieldsComponent implements OnInit {
  public customChoices: any = [];
  public serviceRequestExtensions: any = [];
  constructor(
    public authService: AuthService,
    public router: Router,
    public extensionsService: ExtensionsService,
    public locationService: LocationService,
    public dialogRef: MatDialogRef<ExtensionFieldsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
    this.getExtensions();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getExtensions() {
    this.extensionsService.getExtensions().subscribe(
      (response: any) => {
        // Success callback
        if (response.status === 200) {
          if (response.body) {
            this.customChoices = response.body.CHOICE.CUSTOM;
            this.serviceRequestExtensions =
              response.body.EXTENSIONS.SERVICE_REQUEST;
            console.log(response.body);
          }
        } else if (response.status === 401) {
          // Redirect to login for unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId');
          localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        } else if (response.status === 403) {
          console.log(response.error);
        } else {
        }
      },
        (error) => {
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            console.log(error.error);
          } else {
            console.log('Error while downloading choices');
          }
        }
    );
  }
}
