import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AuthService } from 'src/app/services/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { ActionType, AppConstants } from 'src/app/shared/AppConstants';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { UserService } from 'src/app/services/user.service';
import { UserComponent } from 'src/app/components/user/user.component';
import { Router } from '@angular/router';
import { ErrorAlertComponent } from 'src/app/components/error alert/error-alert.component';
import { SuccessDialogComponent } from 'src/app/components/success-dialog/success-dialog.component';
import { CustomerSelectComponent } from 'src/app/components/customer-select/customer-select.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator!: any;
  @ViewChild(MatSort, { static: true }) sort!: any;

  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  public tableColumns: string[] = [
    'USER_ID',
    'NAME',
    'EMAIL',
    'PHONE',
    'ACCESS_MODULES',
    'Actions',
  ];

  public usersDataSource: MatTableDataSource<any>;
  public searchTerm: string;
  public isSearchHasValue: boolean;
  public isShowUsersProgressBar: boolean;
  public usersLength: number;
  public paginatorLength: number;
  public constants: AppConstants;
  public loggedInUserId: any;
  public subscriptions: Subscription[] = [];
  public timeout: any = null;
  public version: string;
  public release: string;
  public appropriateClass:string = '';
  public events: string[] = [];
  
  @HostListener('window:resize', ['$event'])
  getScreenHeight(event?: any){
    if(window.innerHeight<=700){
      this.appropriateClass = 'bottomRelative';
    }else{
      this.appropriateClass = 'bottomStick';
    }
  }
  constructor(
    public authService: AuthService,
    private breakpointObserver: BreakpointObserver,
    public utilService: UtilService,
    public dialog: MatDialog,
    public userService: UserService,
    public router: Router
  ) {
    this.getScreenHeight();
    this.constants = new AppConstants();
    this.version = this.constants.APP_RELEASE_NUMBER;
    this.release = this.constants.APP_RELEASE_DATE;
    this.searchTerm = '';
    this.usersLength = 0;
    this.paginatorLength = this.constants.INITIAL_PAGINATOR_LIMIT;
    this.isSearchHasValue = false;
    this.isShowUsersProgressBar = false;
    this.usersDataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    let customerId: any = localStorage.getItem('customerId');
    if (customerId && customerId != null) {
      if (customerId == 1) {
        this.authService.isAFSUser = true;
      } else {
        this.authService.isAFSUser = false;
      }
    }
    this.getUsers(this.paginatorLength, 0);
    let user: any = localStorage.getItem('user-details');
    if (user && user != null) {
      user = JSON.parse(user);
      this.loggedInUserId = user.USER_ID;
    }
  }

  // Logout from app
  logout() {
    this.authService.logout();
  }

  // Download users from server
  getUsers(
    limit: number,
    offset: number,
    searchText?: any,
    sortField?: any,
    sortDirection?: any
  ) {
    this.isShowUsersProgressBar = true;
    let usersSubscription = this.userService
      .getUsers(limit, offset, searchText, sortField, sortDirection)
      .subscribe(
        (response: any) => {
          // Success callback
          if (response.status === 200) {
            if (response.body) {
              // Assign the data to the data source for the table to render
              this.usersDataSource = new MatTableDataSource(
                response.body.USERS
              );
              this.usersLength = response.body.USERS_COUNT;
            }
            this.isShowUsersProgressBar = false;
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.isShowUsersProgressBar = false;
            this.router.navigate(['dashboard']);
          } else {
            this.isShowUsersProgressBar = false;
            this.errorDialog(response.error);
          }
        },
        (error) => {
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.isShowUsersProgressBar = false;
            this.router.navigate(['dashboard']);
          } else {
            this.isShowUsersProgressBar = false;
            this.errorDialog(error.message);
          }
        }
      );
    this.subscriptions.push(usersSubscription);
  }

  errorDialog(msg: string) {
    const dialogRef = this.dialog.open(ErrorAlertComponent, {
      width: '400px',
      data: {
        errorMsg: msg,
      },
    });
  }

  refreshUsers() {
    this.searchTerm = '';
    this.cancelOutstandingRequests();
    this.paginator.pageIndex = 0;
    this.paginatorLength = this.constants.INITIAL_PAGINATOR_LIMIT;
    this.getUsers(this.paginatorLength, 0);
  }

  // Setting paginator and sort for table
  ngAfterViewInit() {}

  ngOnDestroy() {
    this.cancelOutstandingRequests();
  }

  sortfields(e: any) {
    let offset = this.paginator._pageIndex * this.paginator._pageSize;
    this.getUsers(
      this.paginatorLength,
      offset,
      this.searchTerm != null && this.searchTerm != '' ? this.searchTerm : '',
      e.active,
      e.direction
    );
    this.paginator.pageIndex = this.paginator._pageIndex;
  }

  //Paginator page items handle
  handlePaginator(e: any) {
    this.paginatorLength = e.pageSize;
    let limit = e.pageSize;
    let offset = e.pageIndex * limit;
    this.paginator.pageIndex = e.pageIndex;
    if (this.sort.active) {
      this.getUsers(
        limit,
        offset,
        this.searchTerm != null && this.searchTerm != '' ? this.searchTerm : '',
        this.sort.active,
        this.sort._direction
      );
    } else {
      this.getUsers(
        limit,
        offset,
        this.searchTerm != null && this.searchTerm != '' ? this.searchTerm : ''
      );
    }
  }

  searchUserFilter(event: any) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if ($this.sort.active) {
        $this.getUsers(
          $this.paginatorLength,
          0,
          event.target.value,
          $this.sort.active,
          $this.sort._direction
        );
      } else {
        $this.getUsers($this.paginatorLength, 0, event.target.value);
      }
      $this.paginator.pageIndex = 0;
    }, 1000);
  }

  clearSearch() {
    this.cancelOutstandingRequests();
    this.searchTerm = '';
    this.paginator.pageIndex = 0;
    this.getUsers(this.paginatorLength, 0);
  }

  // Create new user
  addNewUser() {
    const dialogRef = this.dialog.open(UserComponent, {
      width: '600px',
      data: { mode: ActionType.Add, item: {} },
      panelClass: 'add-user-dialog',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.paginator.pageIndex = 0;
        this.getUsers(this.paginatorLength, 0);
      }
    });
  }

  // Update user
  updateUser(user: any) {
    const dialogRef = this.dialog.open(UserComponent, {
      width: '600px',
      data: { mode: ActionType.Edit, item: user },
      panelClass: 'modify-user-dialog',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let offset = this.paginator._pageIndex * this.paginator._pageSize;
        this.paginator.pageIndex = this.paginator._pageIndex;

        if (this.sort.active) {
          this.getUsers(
            this.paginatorLength,
            offset,
            this.searchTerm != null && this.searchTerm != ''
              ? this.searchTerm
              : '',
            this.sort.active,
            this.sort._direction
          );
        } else {
          this.getUsers(
            this.paginatorLength,
            offset,
            this.searchTerm != null && this.searchTerm != ''
              ? this.searchTerm
              : ''
          );
        }
      }
    });
  }

  // Soft deactivate existing user
  deactivateUser(user: any) {
    const dialogRef = this.dialog.open(AlertComponent, {
      width: '400px',
      data: { errorMsg: 'Are you sure you want to deactivate this user?' },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result.data) {
        let body = {
          USER_ID: user.USER_ID,
          DISABLED: 'Y',
        };

        await this.userService
          .updateUser(body)
          .toPromise()
          .then(
            (response: any) => {
              if (response.status === 204) {
                let offset =
                  this.paginator._pageIndex * this.paginator._pageSize;
                this.paginator.pageIndex = this.paginator._pageIndex;
                if (this.sort.active) {
                  this.getUsers(
                    this.paginatorLength,
                    offset,
                    this.searchTerm != null && this.searchTerm != ''
                      ? this.searchTerm
                      : '',
                    this.sort.active,
                    this.sort._direction
                  );
                } else {
                  this.getUsers(
                    this.paginatorLength,
                    offset,
                    this.searchTerm != null && this.searchTerm != ''
                      ? this.searchTerm
                      : ''
                  );
                }
              } else {
                this.showErrorDialog(response?.message);
              }
            },
            (error) => {
              this.showErrorDialog(error?.message);
            }
          );
      }
    });
  }

  // Activate user
  activateUser(user: any) {
    const dialogRef = this.dialog.open(AlertComponent, {
      width: '400px',
      data: { errorMsg: 'Are you sure you want to activate this user?' },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result.data) {
        let body = {
          USER_ID: user.USER_ID,
          DISABLED: 'N',
        };

        await this.userService
          .updateUser(body)
          .toPromise()
          .then(
            (response: any) => {
              if (response.status === 204) {
                let offset =
                  this.paginator._pageIndex * this.paginator._pageSize;
                if (this.sort.active) {
                  this.getUsers(
                    this.paginatorLength,
                    offset,
                    this.searchTerm != null && this.searchTerm != ''
                      ? this.searchTerm
                      : '',
                    this.sort.active,
                    this.sort._direction
                  );
                } else {
                  this.getUsers(
                    this.paginatorLength,
                    offset,
                    this.searchTerm != null && this.searchTerm != ''
                      ? this.searchTerm
                      : ''
                  );
                }
                this.paginator.pageIndex = this.paginator._pageIndex;
              } else {
                this.showErrorDialog(response?.error);
              }
            },
            (error) => {
              this.showErrorDialog(error?.message);
            }
          );
      }
    });
  }

  showErrorDialog(msg: string) {
    const dialogRef = this.dialog.open(ErrorAlertComponent, {
      width: '400px',
      data: {
        errorMsg: msg,
      },
    });
  }

  // Cancel on going http calls when page leaving
  cancelOutstandingRequests() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  resetPassword(user: any) {
    this.isShowUsersProgressBar = true;
    let body = {
      client: 'admin',
      email: user?.EMAIL,
    };
    this.authService.forgotPassword(body).subscribe(
      (response: any) => {
        // Success callback
        this.isShowUsersProgressBar = false;
        if (response.status === 204) {
          this.dialog.open(SuccessDialogComponent, {
            width: '400px',
            data: { msg: `An email has been sent to ${ user?.EMAIL } containing instructions to reset the password` },
          });
        } else {
          this.showErrorDialog(response?.statusText);
        }
      },
      (error) => {
        // Error callback
        this.isShowUsersProgressBar = false;
        this.showErrorDialog(error?.error);
      }
    );
  }
  switchCompany() {
    let userTenants: any = localStorage.getItem('userTenants');
    if (userTenants && userTenants != null) {
      userTenants = JSON.parse(userTenants);
      const dialogRef = this.dialog.open(CustomerSelectComponent, {
        panelClass: 'add-customer-dialog',
        width: '550px',
        height: '380px',
        disableClose: false,
        data: { mode: 0, item: userTenants },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
        this.refreshUsers();
        }
      });
    }
  }
}
