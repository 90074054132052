<mat-toolbar class="app-toolbar">
    <span>{{title}}</span>
    <span class="example-spacer"></span>
    <button mat-icon-button (click)="closeDialog()" onclick="this.blur()">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>

<mat-progress-bar *ngIf="isShowProgressBar" mode="indeterminate" class="primary-color"></mat-progress-bar>

<div style="padding: 20px;font-size: 14.5px !important;">

    <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput type="text" required [(ngModel)]="reportType">
    </mat-form-field>

    <div class="mat-elevation-z4" style="height: 35vh;
    margin-top: 20px;
    max-height: 35vh;
    overflow-y: auto;">
        <mat-toolbar class="container-toolbar2">
            <mat-toolbar-row style="padding-left: 0px;">
                <button mat-button color="primary" onclick="this.blur()" class="primary-color" (click)="addNewRow()">
                    <mat-icon class="material-icons-outlined" style="font-size: 20px;">
                        add</mat-icon>
                    Add More
                </button>
                <span class="example-spacer"></span>
            </mat-toolbar-row>
        </mat-toolbar>

        <table mat-table [dataSource]="partDataSource" matSort>

            <ng-container matColumnDef="No">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> No </th>
                <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}}
                </td>
            </ng-container>

            <ng-container matColumnDef="Attribute">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Attribute</th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    <mat-form-field appearance="outline">
                        <mat-select placeholder="Select Attribute" #singleSelect disableOptionCentering
                            [multiple]="true" [(ngModel)]="element.ATTRIBUTE_NAME"
                            (selectionChange)="attributeChange($event.value, i)">
                            <mat-option style="position: relative !important;">
                                <ngx-mat-select-search placeholderLabel="Search"
                                    (ngModelChange)="attributefilter($event, element.AttributeList,i )"
                                    [ngModelOptions]="{standalone: true}" ngModel
                                    noEntriesFoundLabel="No matches found"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let attr of element.AttributeList" [value]="attr.ATTRIBUTE_NAME">
                                {{attr.ATTRIBUTE_NAME}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
            </ng-container>

            <ng-container matColumnDef="Category">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Equipment Category</th>
                <td mat-cell *matCellDef="let element; let i = index;">
                    <mat-form-field appearance="outline">
                        <mat-select placeholder="Select Category" #singleSelect1 disableOptionCentering
                            [disabled]="element.ATTRIBUTE_NAME == ''" [multiple]="true"
                            [(ngModel)]="element.CATEGORY_ID">
                            <mat-option style="position: relative !important;">
                                <ngx-mat-select-search placeholderLabel="Search"
                                    (ngModelChange)="categoryFilter($event, element.CategoryList,i )"
                                    [ngModelOptions]="{standalone: true}" ngModel
                                    noEntriesFoundLabel="No matches found"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let attr of element.CategoryList" [value]="attr.CATEGORY_ID">
                                {{attr.CATEGORY_NAME}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
            </ng-container>

            <ng-container matColumnDef="Actions">
                <th mat-header-cell *matHeaderCellDef style="padding-left: 10px;"> Action </th>
                <td mat-cell *matCellDef="let row; let i = index;" style="text-align: center;">
                    <div class="button-row">
                        <button mat-icon-button matTooltip="Delete" (click)="deleteRow(i)">
                            <mat-icon class="warning-color">
                                delete_outline</mat-icon>
                        </button>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="partColumns;sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: partColumns;">
            </tr>
        </table>
    </div>

    <div *ngIf="errorMsg.length > 0" style="text-align: center;color: rgb(228, 0, 43);padding-top: 10px;">
        <p>{{errorMsg}}</p>
    </div>
</div>

<mat-dialog-actions align="center">
    <button mat-raised-button (click)="deleteReport()" *ngIf="data.mode == 2" color="warn">Delete
    </button>
    <button mat-raised-button [disabled]="reportType == ''" (click)="saveReport()"
        [ngClass]="(reportType != '')  ? 'primary-button' : ''">
        <span *ngIf="data.mode == 1"> Create </span>
        <span *ngIf="data.mode == 2"> Update </span>
    </button>
</mat-dialog-actions>