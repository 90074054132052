<mat-toolbar class="app-toolbar-fixed">
  <button mat-icon-button (click)="closeDialog()" onclick="this.blur()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <span>LOPA Details</span>
  <span class="example-spacer"></span>
</mat-toolbar>
<mat-progress-bar *ngIf="isShowProgressBar" mode="indeterminate">
</mat-progress-bar>

<div style="padding: 13px;font-size: 14.5px;">
  <div fxLayout="row" style="margin-bottom: -15px;" [formGroup]="firstSectionformGroup">
    <div fxFlex="auto">
      <mat-form-field appearance="outline" style="padding-right: 20px;width:13.5%">
        <mat-label>Select Tail Number</mat-label>
        <mat-select formControlName="tailNo" disableOptionCentering required
          (selectionChange)="tailNumberChange($event)">
          <mat-option *ngFor="let tailno of tailNumbers | keyvalue" [value]="tailno.key"> {{tailno.key}} </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="vertical-line-div1"></div>

      <mat-form-field appearance="outline" style="padding-left: 20px;padding-right: 20px; width:13.5%">
        <mat-label>Select Parts Location</mat-label>
        <mat-select formControlName="location" disableOptionCentering>
          <mat-option *ngFor="let location of locations" [value]="location.LOCATION_ID"> {{location.NAME}} </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="vertical-line-div"></div>

      <section class="section" style="display: inline-block;padding-right: 20px;">
        <div style="display: contents;">
          <div fxLayout="row" style="margin-top: -10px;">
            <span style="padding-bottom: 5px;color: rgba(12, 12, 12, 0.644);">Select Type</span>
          </div>
          <div fxLayout="row" style="margin-bottom: -8px;">
            <mat-radio-group aria-label="Select an option" [ngModelOptions]="{standalone: true}"
              (change)="typeChange($event)" [(ngModel)]="isServiceRequest">
              <mat-radio-button [value]="true">Service Request</mat-radio-button> &nbsp;&nbsp;
              <mat-radio-button [value]="false">Notification</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </section>

      <span *ngIf="isServiceRequest">
        <div class="vertical-line-div"></div>
        <mat-form-field appearance="outline" style="padding-right: 10px;width:13.5%">
          <mat-label>Select Service Request</mat-label>
          <mat-select formControlName="serviceRequest" disableOptionCentering>
            <mat-option *ngFor="let sr of serviceRequests" [value]="sr"> {{sr}} </mat-option>
          </mat-select>
        </mat-form-field>

        <button mat-icon-button matTooltip="Chat" style="margin-right: 15px;" (click)="openMessageDialog()">
          <mat-icon style="font-size:28px;vertical-align: text-bottom;" class="primary-color material-icons-outlined">
            textsms
          </mat-icon>
        </button>
      </span>

      <span *ngIf="!isServiceRequest">
        <div class="vertical-line-div1"></div>
        <mat-form-field appearance="outline" class="notifications">
          <mat-label>Select Notification</mat-label>
          <mat-select formControlName="notification" disableOptionCentering>
            <mat-option *ngFor="let notification of notifications" [value]="notification"> {{notification}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <button mat-icon-button [matMenuTriggerFor]="menu1" matTooltip="Convert to Service Request"
          style="margin-right: 10px;">
          <mat-icon style="font-size:30px;vertical-align: top; transform: rotate(90deg);"
            class="primary-color material-icons-outlined">
            navigation
          </mat-icon>
        </button>
        <mat-menu #menu1="matMenu" style="margin-top: 0px !important;">
          <p style="text-align: center;">Are you sure you want to convert this notification to a service request?</p>
          <div style="text-align: center;padding-bottom: 10px;">
            <button mat-flat-button class="success-button" (click)="convertToServiceRequest()"
              style="border-radius: 20px !important;">&nbsp;&nbsp;&nbsp;Convert
              to Service Request&nbsp;&nbsp;&nbsp;</button>
          </div>

        </mat-menu>
      </span>

      <div class="vertical-line-div"></div>

      <section class="section" style="display: inline-block;">
        <div style="display: contents;">
          <div fxLayout="row" style="margin-top: -10px;">
            <span style="padding-bottom: 5px;color: rgba(12, 12, 12, 0.644);">Part Numbers</span>
          </div>
          <div fxLayout="row" style="margin-bottom: -10px;">
            <mat-checkbox class="margin" *ngFor="let lable of partLables" (change)="partNumberChange(lable.value)"
              [(ngModel)]="lable.isCheck" [ngModelOptions]="{standalone: true}">{{lable.value}}
            </mat-checkbox>
          </div>
        </div>
      </section>

      <button mat-flat-button style="float: right;
      margin-top: 10px;
      margin-right: 10px;" [disabled]="!firstSectionformGroup.valid" (click)="search()"
        [ngClass]="!firstSectionformGroup.valid ? '' : 'success-button'">&nbsp;
        &nbsp; Search &nbsp;
        &nbsp;</button>
    </div>
  </div>
  <hr>
  <div class="container" *ngIf="isShowDetails">
    <div fxLayout="row" class="div-row">
      <div fxFlex="25" class="location-div">
        <mat-toolbar style="height: 45px;background:#00629b;color:white">
          <span style="font-size:medium">Parts to be replaced</span>
        </mat-toolbar>

        <span *ngIf="partsToBeReplaced && partsToBeReplaced.length > 0">
          <mat-card style="margin:10px;border:1px solid #bbb;padding: 10px;" *ngFor="let part of partsToBeReplaced">
            <div style="display: inline-grid;margin-bottom: 5px;">
              <div class="parts-count-block">
                <span style="vertical-align: sub;">{{part.Qty}}</span>
              </div>
            </div>
            <span style="vertical-align: sub;
            padding-left: 15px;">{{part.Description}}</span>
            <mat-divider style="margin: 5px 20px 0px 16px;width:90%"></mat-divider>
            <p [ngClass]="part.Availability < part.Qty? 'warning-color': 'success-color'">
              {{part.Availability}}
              Available</p>
            <p style="margin-top:-8px !important;color:grey">Part Number</p>
            <p style="margin-top:-8px;color: dimgray;font-size: smaller;" *ngIf="isShowPartNumber('MPN')">
              MPN: {{
              part.Part1 }}</p>
            <p style="margin-top:-8px;color: dimgray;font-size: smaller;" *ngIf="isShowPartNumber('CPN')">
              CPN: {{
              part.Part2 }}</p>
            <p style="margin-top:-8px;color: dimgray;font-size: smaller;" *ngIf="isShowPartNumber('AACO')">AACO: {{
              part.Part3 }}</p>
          </mat-card>
        </span>
        <div *ngIf="partsToBeReplaced && partsToBeReplaced.length == 0" style="text-align: center;padding-top: 30vh;">
          No Parts to Replace
        </div>
      </div>

      <div fxFlex="auto" class="category-div">
        <div *ngIf="details && details.length > 0">
          <span *ngFor="let item of details">
            <mat-toolbar style="height: 45px;background:#00629b;color:white">
              <span style="font-size:medium">{{item.class}} ({{seatsCountByClass(item)}})</span>
            </mat-toolbar>

            <div fxLayout="row" style="text-align: center;padding-top: 10px;"
              *ngFor="let seat of item?.seats; let i= index">
              <div fxFlex="5" style="margin: auto;text-align: center;"></div>
              <div fxFlex="22" *ngFor="let item of seat?.value | keyvalue">

                <mat-icon class="badge" [matMenuTriggerFor]="menu" matBadge="{{seat?.key}}{{item.key}}"
                  style="font-size:60px;cursor: pointer;" [class]="seatColorCssClass(item)">
                  chair</mat-icon>

                <span class="availability-badge" matBadge="{{getAvailabilityBadge(item)}}"></span>

                <mat-menu #menu="matMenu" class="mat-ment-parts">
                  <mat-list-item>
                    <div style="margin: 3px 0px 7px 0px;" mat-line>Seat Number: <span
                        style="font-weight: bold;">{{seat?.key}}{{item.key}}</span>
                    </div>
                  </mat-list-item>
                  <mat-divider></mat-divider>

                  <p class="parts-p-tag" *ngIf="checkReplacePartsCount(item.value) > 0"> PARTS TO BE REPLACED
                    ({{checkReplacePartsCount(item.value)}})</p>
                  <span *ngFor="let subItem of item.value | extnPipe">
                    <mat-list-item *ngIf="subItem['Service Request']">
                      <div fxLayout="row" style="padding: 10px 0px 10px 0px;">
                        <div fxFlex="10">
                          <div class="parts-count-block-1">
                            <span>{{ subItem.Qty }}</span>
                          </div>
                        </div>
                        <div fxFlex="auto" style="margin: auto !important;padding-left: 10px;">{{subItem.Description}}
                        </div>
                        <div fxFlex="25" style="text-align: right;margin: auto !important;" class="primary-color"><span
                            *ngIf="isServiceRequest">SR</span><span *ngIf="!isServiceRequest">N</span>:
                          {{subItem['Service Request']}}
                        </div>
                      </div>
                      <div fxLayout="row" style="margin-bottom: -8px;">
                        <div fxFlex="60">
                          <p style="margin-top: 0px;color: dimgray;font-size: smaller;" *ngIf="isShowPartNumber('MPN')">
                            MPN: {{
                            subItem.Part1 }}</p>
                          <p style="margin-top:-8px;color: dimgray;font-size: smaller;" *ngIf="isShowPartNumber('CPN')">
                            CPN: {{
                            subItem.Part2 }}</p>
                          <p style="margin-top:-8px;color: dimgray;font-size: smaller;"
                            *ngIf="isShowPartNumber('AACO')">AACO: {{
                            subItem.Part3 }}</p>
                        </div>
                        <div fxFlex="auto" style="padding-left: 10px;text-align:right;">
                          <span [ngClass]="subItem.Availability < subItem.Qty? 'warning-color': 'success-color'">
                            {{subItem.Availability}}
                            Available</span>
                        </div>
                      </div>
                      <mat-divider style="margin-top: 5px;"></mat-divider>
                    </mat-list-item>
                  </span>

                  <p class="parts-p-tag" *ngIf="checkOtherPartsCount(item.value) > 0"> OTHER PARTS
                    ({{checkOtherPartsCount(item.value)}}) </p>
                  <span *ngFor="let subItem of item.value| extnPipe">
                    <mat-list-item *ngIf="!subItem['Service Request']">
                      <div fxLayout="row" style="padding: 10px 0px 10px 0px;">
                        <div fxFlex="10">
                          <div class="parts-count-block-1">
                            <span>{{ subItem.Qty }}</span>
                          </div>
                        </div>
                        <div fxFlex="auto" style="margin: auto !important;padding-left: 10px;">{{subItem.Description}}
                        </div>
                      </div>
                      <div fxLayout="row" style="margin-bottom: -8px;">
                        <div fxFlex="60">
                          <p style="margin-top: 0px;color: dimgray;font-size: smaller;" *ngIf="isShowPartNumber('MPN')">
                            MPN: {{
                            subItem.Part1 }}</p>
                          <p style="margin-top:-8px;color: dimgray;font-size: smaller;" *ngIf="isShowPartNumber('CPN')">
                            CPN: {{
                            subItem.Part2 }}</p>
                          <p style="margin-top:-8px;color: dimgray;font-size: smaller;"
                            *ngIf="isShowPartNumber('AACO')">AACO: {{
                            subItem.Part3 }}</p>
                        </div>
                        <div fxFlex="auto" style="padding-left: 10px;text-align:right;">
                          <span [ngClass]="subItem.Availability < subItem.Qty? 'warning-color': 'success-color'">
                            {{subItem.Availability}}
                            Available</span>
                        </div>
                      </div>
                      <mat-divider style="margin-top: 5px;"></mat-divider>
                    </mat-list-item>
                  </span>

                </mat-menu>

              </div>
              <div fxFlex="5" style="margin: auto;text-align: center;"></div>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>