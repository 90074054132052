import { Component, ElementRef, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgDialogAnimationService } from 'ng-dialog-animation';
import { AuthService } from 'src/app/services/auth.service';
import { ExtensionsService } from 'src/app/services/extensions.service';
import { LocationService } from 'src/app/services/location.service';
import { PartService } from 'src/app/services/part.service';
import { UtilService } from 'src/app/services/util.service';
import { ActionType, AppConstants } from 'src/app/shared/AppConstants';
import { AddEquipmentCategoryComponent } from '../add-equipment-category/add-equipment-category.component';

export interface DialogData {
  mode: number;
  item: any;
}

@Component({
  selector: 'add-equipment',
  templateUrl: './add-equipments.component.html',
  styleUrls: ['./add-equipments.component.scss'],
})
export class AddEquipmentsComponent implements OnInit {
  public constants: AppConstants;
  public title: string;
  public formGroup: FormGroup;
  public errorMsg: string;
  public locationCategories: any[] = [];
  public isShowProgressBar: boolean = false;
  public customChoices: any = [];
  public bomIdList: any = [];
  public serviceRequestExtensions: any = {};

  constructor(
    public extensionsService: ExtensionsService,
    public locationService: LocationService,
    public authService: AuthService,
    public utilService: UtilService,
    public router: Router,
    public dialog: NgDialogAnimationService,
    public dialogRef: MatDialogRef<AddEquipmentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public partService: PartService,
  ) {
    this.getExtensions();
    this.constants = new AppConstants();
    this.errorMsg = '';
    this.title =
      this.data.mode == ActionType.Add ? 'Add Equipment' : 'Update Equipment';

    if (this.data.mode == ActionType.Add) {
      this.formGroup = new FormGroup({
        name: new FormControl('', [Validators.required]),
        description: new FormControl(''),
        category: new FormControl('', [Validators.required]),
        bomId: new FormControl('', [Validators.required]),
        tag: new FormControl('', [Validators.required]),
      });
    } else {
      this.formGroup = new FormGroup({
        equipmentId: new FormControl(
          { value: this.data.item.EQUIPMENT_ID, disabled: true },
          [Validators.required]
        ),
        name: new FormControl(this.data.item.NAME, [Validators.required]),
        description: new FormControl(this.data.item.DESCRIPTION),
        category: new FormControl(this.data.item.CATEGORY_ID, [
          Validators.required,
        ]),
        bomId: new FormControl(this.data.item.BOM_ID, [Validators.required]),
        tag: new FormControl(this.data.item.TAG, [Validators.required]),
      });
    }
  }

  ngOnInit(): void {
    this.getEquipmentCategories();
    this.getParts('BOM');
  }

  closeDialog(): void {
    this.dialogRef.close(false);
  }

  getEquipmentCategories() {
    this.isShowProgressBar = true;
    this.locationCategories = [];
    this.locationService.getEquipmentCategories().subscribe(
      (response: any) => {
        // Success callback
        this.isShowProgressBar = false;
        if (response.status === 200) {
          if (response.body) {
            // Assign the data
            this.locationCategories = response.body.EQUIPMENT_CATEGORIES;
          }
        } else if (response.status === 401) {
          // Redirect to login for unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        } else if (response.status === 403) {
          this.errorMsg = response.message;
        } else {
          this.errorMsg = response.message;
        }
      },
      (error) => {
        this.isShowProgressBar = false;
        if (error.status === 401) {
          // Redirect to login for unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        } else if (error.status === 403) {
          this.errorMsg = error.error;
        } else {
          this.errorMsg = error.error;
        }
      }
    );
  }

  async saveEquipment() {
    this.errorMsg = '';
    this.isShowProgressBar = true;
    let body: any = {
      NAME: this.formGroup.controls.name.value,
      CATEGORY_ID: this.formGroup.controls.category.value,
      DESCRIPTION: this.formGroup.controls.description.value,
      BOM_ID: this.formGroup.controls.bomId.value,
      TAG: this.formGroup.controls.tag.value,
      EXTN: this.serviceRequestExtensions,
      LOCATION_ID: this.data?.item?.locationId,
    };
    await this.locationService
      .addEquipmentByLocation(body)
      .toPromise()
      .then(
        (response: any) => {
          this.isShowProgressBar = false;
          if (response.status === 204) {
            this.dialogRef.close(true);
          } else {
            this.errorMsg = response.message;
          }
        },
        (error) => {
          this.isShowProgressBar = false;
          this.errorMsg = error.error;
        }
      );
  }

  async updateEquipment() {
    this.errorMsg = '';
    this.isShowProgressBar = true;
    let body: any = {
      EQUIPMENT_ID: Number(this.formGroup.controls.equipmentId.value),
      NAME: this.formGroup.controls.name.value,
      CATEGORY_ID: this.formGroup.controls.category.value,
      DESCRIPTION: this.formGroup.controls.description.value,
      BOM_ID: this.formGroup.controls.bomId.value,
      TAG: this.formGroup.controls.tag.value,
      EXTN: this.serviceRequestExtensions,
      LOCATION_ID: this.data?.item?.LOCATION_ID,
      INACTIVE: this.data?.item?.INACTIVE,
    };
    await this.locationService
      .updateEquipmentByLocation(body)
      .toPromise()
      .then(
        (response: any) => {
          this.isShowProgressBar = false;
          if (response.status === 204) {
            this.dialogRef.close(true);
          } else {
            this.errorMsg = response.message;
          }
        },
        (error) => {
          this.isShowProgressBar = false;
          this.errorMsg = error.error;
        }
      );
  }

  getExtensions() {
    this.extensionsService.getExtensions().subscribe(
      (response: any) => {
        // Success callback
        if (response.status === 200) {
          if (response.body) {
            this.customChoices = response.body.CHOICE.CUSTOM;
            this.serviceRequestExtensions =
              response.body.EXTENSIONS.SERVICE_REQUEST;
            if (this.data.mode == ActionType.Add) {
              if (this.serviceRequestExtensions) {
                for (var key in this.serviceRequestExtensions) {
                  if (this.serviceRequestExtensions[key].length > 0) {
                    for (
                      let i = 0;
                      i < this.serviceRequestExtensions[key].length;
                      i++
                    ) {
                      switch (
                        this.serviceRequestExtensions[key][i].FIELD_TECH_NAME
                      ) {
                        case 'txtFld1':
                          this.serviceRequestExtensions[key][i]['txtFld1'] = '';
                          break;
                        case 'txtAreaFld1':
                          this.serviceRequestExtensions[key][i]['txtAreaFld1'] =
                            '';
                          break;
                        case 'numFld1':
                          this.serviceRequestExtensions[key][i]['numFld1'] = '';
                          break;
                        case 'chFld1':
                          this.serviceRequestExtensions[key][i]['chFld1'] = '';
                          break;
                        case 'tofFld1':
                          this.serviceRequestExtensions[key][i]['tofFld1'] =
                            'Y';
                          break;
                        default:
                          break;
                      }
                    }
                  }
                }
              }
            } else {
              if (this.data.item.EXTN) {
                this.serviceRequestExtensions = this.data.item.EXTN;
              } else {
                if (this.serviceRequestExtensions) {
                  for (var key in this.serviceRequestExtensions) {
                    if (this.serviceRequestExtensions[key].length > 0) {
                      for (
                        let i = 0;
                        i < this.serviceRequestExtensions[key].length;
                        i++
                      ) {
                        switch (
                          this.serviceRequestExtensions[key][i].FIELD_TECH_NAME
                        ) {
                          case 'txtFld1':
                            this.serviceRequestExtensions[key][i]['txtFld1'] =
                              '';
                            break;
                          case 'txtAreaFld1':
                            this.serviceRequestExtensions[key][i][
                              'txtAreaFld1'
                            ] = '';
                            break;
                          case 'numFld1':
                            this.serviceRequestExtensions[key][i]['numFld1'] =
                              '';
                            break;
                          case 'chFld1':
                            this.serviceRequestExtensions[key][i]['chFld1'] =
                              '';
                            break;
                          case 'tofFld1':
                            this.serviceRequestExtensions[key][i]['tofFld1'] =
                              'Y';
                            break;
                          default:
                            break;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        } else if (response.status === 401) {
          // Redirect to login for unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        } else if (response.status === 403) {
          console.log(response.error);
        } else {
        }
      },
      (error) => {
        if (error.status === 401) {
          // Redirect to login for unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        } else if (error.status === 403) {
          console.log(error.error);
        } else {
          console.log('Error while downloading choices');
        }
      }
    );
  }

  addCategory() {
    const dialogRef1 = this.dialog.open(AddEquipmentCategoryComponent, {
      width: '350px',
      id: 'test',
      panelClass: 'equipment-dialog',
      position: { right: '0' },
      data: { mode: ActionType.Add, item: {} },
    });
    dialogRef1.afterClosed().subscribe(async (result) => {
      if (result) {
        this.getEquipmentCategories();
        this.formGroup.controls.category.setValue(result);
      }
    });
  }

  editCategory() {
    let category: any = [];
    if (this.locationCategories && this.locationCategories.length > 0) {
      let that = this;
      category = this.locationCategories.filter(function (e) {
        return e.CATEGORY_ID == that.formGroup.controls.category.value;
      });
      category = category?.length > 0 ? category[0] : {};
    }
    const dialogRef = this.dialog.open(AddEquipmentCategoryComponent, {
      width: '350px',
      panelClass: 'modify-customer-dialog',
      position: { right: '0' },
      data: {
        mode: ActionType.Edit,
        item: category,
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.getEquipmentCategories();
      }
    });
  }

  getParts(
    manufacturer?: any
  ) {
    this.isShowProgressBar = true;
    this.partService
      .getBomParts(
        manufacturer
      )
      .subscribe(
        (response: any) => {
          // Success callback
          if (response.status === 200) {
            if (response.body) {
              console.log(response.body);
              this.bomIdList = response.body?.PARTS;
            }
           this.isShowProgressBar = false;
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.isShowProgressBar = false;
            // this.showErrorDialog(response.error);
          } else {
            this.isShowProgressBar = false;
            // this.showErrorDialog(response.error);
          }
        },
        (error) => {
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.isShowProgressBar = false;
            // this.showErrorDialog(error.error);
          } else {
            this.isShowProgressBar = false;
            // this.showErrorDialog(error.message);
          }
        }
      );
    // this.subscriptions.push(PartsSubscription);
  }
}
