<mat-toolbar class="app-toolbar-fixed">
    <button mat-icon-button (click)="closeDialog()" onclick="this.blur()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <span>{{title}}</span>
    <span class="example-spacer"></span>
</mat-toolbar>

<div style="padding: 30px;">

    <form [formGroup]="FormGroup" style="padding-top:10px">

        <div fxLayout="row" style="text-align: center;width:80%;margin: auto;">
            <div fxFlex="33.33" style="padding-right:10px">
                <mat-form-field appearance="legacy" style="width:100%;font-size: 15px;margin-top: 13px;">
                    <mat-label>Enter Group Name</mat-label>
                    <input required matInput placeholder="Enter group name" formControlName="groupNameCtrl">
                </mat-form-field>
            </div>
            <div fxFlex="33.33" style="padding-left:10px;padding-right:10px">
                <mat-form-field appearance="legacy" style="width:100%;font-size: 15px;margin-top: 13px;">
                    <mat-label>Enter Budget Amount</mat-label>
                    <input required type="number" matInput placeholder="Enter budget" formControlName="budgetCtrl">
                </mat-form-field>
            </div>

            <div fxFlex="33.33" style="padding-left:10px">
                <mat-form-field appearance="legacy" style="width:100%;font-size: 15px;margin-top: 13px;">
                    <mat-label>Selected Fiscal Year</mat-label>
                    <input required readonly type="number" matInput placeholder="Budget Year"
                        formControlName="budgetYearCtrl">
                </mat-form-field>
            </div>
        </div>

        <div style="margin-top: 10px;">
            <div class="mat-elevation-z8" style="width:80%;margin: auto;height:52vh; overflow: auto;">
                <mat-progress-bar *ngIf="isShowProgressBar" class="primary-color" mode="indeterminate">
                </mat-progress-bar>
                <mat-toolbar class="container-toolbar ">
                    <mat-toolbar-row class="container-toolbar-row">
                        <mat-form-field appearance="outline" style="font-size: 15px;margin-top: 12px;width:35%">
                            <mat-label>Search part</mat-label>
                            <mat-icon matPrefix>search</mat-icon>
                            <input autocomplete="off" matInput type="text" (keyup)="searchPart($event)"
                                formControlName="partSearchCtrl">
                            <button *ngIf="FormGroup.controls.partSearchCtrl.value" matSuffix mat-icon-button
                                aria-label="Clear" (click)="clearPartSerach()">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                        <span class="example-spacer"></span>
                        <mat-paginator #paginator [pageSize]="paginatorLength" [pageSizeOptions]="[5, 10, 25, 50, 100]"
                            (page)="handlePartsPaginator($event);" [length]="partsLength">
                        </mat-paginator>
                    </mat-toolbar-row>
                </mat-toolbar>

                <table mat-table [dataSource]="partDataSource" matSort (matSortChange)="sortPartsfields($event)">
                    <!-- Checkbox Column -->
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="$event ? partsMasterToggle() : null"
                                [checked]="partsSelection.hasValue() && isPartsAllSelected()"
                                [indeterminate]="partsSelection.hasValue() && !isPartsAllSelected()">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="toggelePartsSelection($event, row)" [checked]="selectedPart(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <!-- ID Column -->
                    <ng-container matColumnDef="PART_ID">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                        <td mat-cell *matCellDef="let row"> {{row.PART_ID}} </td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="NAME">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                        <td mat-cell *matCellDef="let row"> {{row.NAME}} </td>
                    </ng-container>

                    <!-- Address Column -->
                    <ng-container matColumnDef="DESCRIPTION">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
                        <td mat-cell *matCellDef="let row"> {{row.DESCRIPTION}}</td>
                    </ng-container>

                    <!-- City Column -->
                    <ng-container matColumnDef="MANUFACTURER">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Manufacturer </th>
                        <td mat-cell *matCellDef="let row"> {{row.MANUFACTURER}} </td>
                    </ng-container>

                    <!-- State Column -->
                    <ng-container matColumnDef="MFG_PART_NUMBER">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Part No 1</th>
                        <td mat-cell *matCellDef="let row"> {{row.MFG_PART_NUMBER}} </td>
                    </ng-container>

                    <ng-container matColumnDef="MFG_PART_NUMBER_2">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Part No 2</th>
                        <td mat-cell *matCellDef="let row"> {{row.MFG_PART_NUMBER_2}} </td>
                    </ng-container>

                    <ng-container matColumnDef="MFG_PART_NUMBER_3">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Part No 3</th>
                        <td mat-cell *matCellDef="let row"> {{row.MFG_PART_NUMBER_3}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="partColumns;"></tr>
                    <tr mat-row *matRowDef="let row; columns: partColumns;" (click)="partsSelection.toggle(row)">
                    </tr>
                </table>

            </div>
        </div>

        <mat-toolbar style="position:absolute;bottom:20px;height:40px;background:none;width: 97%;left:30px">
            <span class="example-spacer"></span>
            <button (click)="saveBudget()" *ngIf="this.data?.mode == 1"
                [ngClass]="(!FormGroup.valid) ? '' : 'success-button'"
                [disabled]="!FormGroup.valid" mat-button>Create</button>

            <button (click)="saveBudget()" *ngIf="this.data?.mode == 2"
                [ngClass]="(!FormGroup.valid) ? '' : 'success-button'"
                [disabled]="!FormGroup.valid" mat-button>Update</button>
        </mat-toolbar>
    </form>

</div>