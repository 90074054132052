<div *ngIf="serviceRequestExtensions">
   <div class="section" *ngFor="let items of serviceRequestExtensions | keyvalue">
      <span class="label">{{items.key}}</span>
      <hr />
      <span *ngFor="let item of items.value| extnPipe">
         <p> {{item.FIELD_NAME}} <span *ngIf="item.FIELD_MANDATORY == 'Y'" style="color:rgb(228, 0, 43)">*</span></p>

         <div class="text" *ngIf="item.FIELD_CONTROL == 'TEXT'">
            <input matInput>
         </div>

         <div class="text" *ngIf="item.FIELD_CONTROL == 'TEXTAREA'">
            <textarea matInput> </textarea>
         </div>

         <div class="text" *ngIf="item.FIELD_CONTROL == 'NUMBER'">
            <input matInput>
         </div>

         <div class="text" *ngIf="item.FIELD_CONTROL == 'CHOICE'">
            <mat-select disableOptionCentering [value]="item.CHOICE_VALUE">
               <mat-option *ngFor="let item of customChoices">{{item.CHOICE_NAME}}</mat-option>
            </mat-select>
         </div>

         <div class="text" *ngIf="item.FIELD_CONTROL == 'TOGGLE'">
            <mat-button-toggle-group>
               <mat-button-toggle value="Y">True</mat-button-toggle>
               <mat-button-toggle value="N">False</mat-button-toggle>
            </mat-button-toggle-group>
         </div>
      </span>
   </div>
</div>