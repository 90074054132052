<h3 mat-dialog-title style="color:#00629b;">Filter Timed Schedules
    <span style="float:right;">
        <button mat-icon-button (click)="closeDialog()" onclick="this.blur()"
            style="line-height: 0px !important;text-align: end;">
            <mat-icon style="margin-top:-10px !important">close</mat-icon>
        </button>
    </span>
</h3>

<mat-progress-bar *ngIf="isShowProgressBar" mode="indeterminate"></mat-progress-bar>

<mat-dialog-content class="mat-typography">

    <div [formGroup]="formGroup">
        <div [ngClass]="filterdLocations?.length > 0 ? 'location-div': ''">
            <p class="select-location-p-tag" *ngIf="filterdLocations?.length > 0">
                Select Location
                <span style="float:right;">
                    <button mat-button color="warn" style="line-height: 0px !important;text-align: end;"
                        (click)="resetLocations()">Reset</button>
                </span>
            </p>

            <mat-form-field appearance="legacy" style="margin-top:5px">
                <mat-select formControlName="location" disableOptionCentering placeholder="Select Location">
                    <mat-option>Select</mat-option>
                    <mat-option *ngFor="let loc of locations;let index = index" [value]="loc.id"
                        (click)="parentLocationsChange(loc, index)">
                        {{loc.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <span *ngFor="let location of filterdLocations;let index = index" [formGroup]="subLocationsFormGroup">
                <mat-form-field appearance="legacy" *ngIf="location?.childrens && location?.childrens?.length > 0"
                    style="margin-bottom: -5px !important;margin-top:5px">
                    <mat-select disableOptionCentering placeholder="Select Sub Location"
                        [formControlName]="location?.formControlName">
                        <mat-option>Select</mat-option>
                        <mat-option *ngFor="let child of location?.childrens" [value]="child.id"
                            (click)="childLocationsChange(child, index)">
                            {{child.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </span>
        </div>

        <!-- <mat-form-field appearance="legacy" style="margin-top:13px">
            <mat-label>Status</mat-label>
            <mat-select formControlName="status" disableOptionCentering>
                <mat-option>Select</mat-option>
                <mat-option *ngFor="let status of statusList" [value]="status.value"> {{status.name}} </mat-option>
            </mat-select>
        </mat-form-field> -->
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button class="danger-button" (click)="clearFilter()">Clear</button>
    <button mat-button class="success-button" (click)="applyFilter()">Apply</button>
</mat-dialog-actions>