<mat-toolbar class="app-toolbar">
    <span>Manage SLAs</span>
    <span class="example-spacer"></span>
    <button mat-icon-button (click)="closeDialog()" onclick="this.blur()">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>
<mat-progress-bar *ngIf="isShowCategoryProgressBar" mode="indeterminate"></mat-progress-bar>

<form style="padding: 20px;font-size: 14.5px !important;" [formGroup]="formGroup" (ngSubmit)="saveUser()">
    <mat-form-field appearance="legacy" *ngFor="let item of slaList">
        <mat-label>{{item.SLA_NAME | UppercaseToLowerCaseFilter}}</mat-label>
        <input matInput type="number" required [formControlName]="item.SLA_NAME">
    </mat-form-field>


<div style="text-align:center;padding:10px 0px 20px 0px">
    <button mat-raised-button [mat-dialog-close]="true" cdkFocusInitial style="margin-right: 10px;">Cancel</button>
    <button mat-raised-button [ngClass]="formGroup.valid ? 'success-button' : ''" [disabled]="!formGroup.valid">Save</button>
</div>

<div *ngIf="errorMsg.length> 0" style="text-align: center;color: rgb(228, 0, 43)">
    <p>{{errorMsg}}</p>
</div>
</form>