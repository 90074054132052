import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LopaService } from 'src/app/services/lopa.service';

export interface DialogData {
  item: any;
}

@Component({
  selector: 'app-upload-pdf',
  templateUrl: './upload-pdf.component.html',
  styleUrls: ['./upload-pdf.component.scss'],
})
export class UploadPdfComponent implements OnInit {
  public errorMsg: string = '';
  public file: File[] = [];
  public isShowProgressBar: boolean = false;

  constructor(
    public fleetDialogRef: MatDialogRef<UploadPdfComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public lopaService: LopaService
  ) {}

  ngOnInit(): void {}

  closeDialog() {
    this.fleetDialogRef.close(false);
  }

  onSelect(event: any) {
    this.errorMsg = '';
    this.file = [];
    this.file.push(...event.addedFiles);
  }

  onRemove(event: any) {
    this.errorMsg = '';
    this.file.splice(this.file.indexOf(event), 1);
  }

  async uploadDocument() {
    this.isShowProgressBar = true;
    this.fileToBase64(this.file[0])
      .then(async (base64) => {
        let body = {
          LOPA_ID: this.data?.item?.LOPA_ID,
          DOCUMENT: [
            { DOCUMENT_BLOB: base64, DOCUMENT_TYPE: this.file[0].type },
          ],
        };
        await this.lopaService
          .uploadFleetDocument(body)
          .toPromise()
          .then(
            (response: any) => {
              this.isShowProgressBar = false;
              if (response.status === 200) {
                this.fleetDialogRef.close(true);
              } else if (response.status === 204) {
                this.fleetDialogRef.close(true);
              } else {
                this.errorMsg = response.message;
              }
            },
            (error) => {
              this.isShowProgressBar = false;
              this.errorMsg = error?.message;
            }
          );
      })
      .catch((err) => {
        this.errorMsg = err.message;
        this.isShowProgressBar = false;
      });
  }

  private fileToBase64(file: any): Promise<any> {
    const reader = new FileReader();
    const future = new Promise((resolve, reject) => {
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result);
        },
        false
      );
      reader.addEventListener(
        'error',
        function (event) {
          reject(event);
        },
        false
      );

      reader.readAsDataURL(file);
    });
    return future;
  }
}
