import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MustMatch } from 'src/app/shared/mustmatchvalidator.directive';
import { whiteSpaceValidator } from 'src/app/shared/whitespacevalidator.directive';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  public resetpasswordform: FormGroup;
  public resetPasswordTitle: string;
  public isPasswordHide: boolean;
  public isConfirmPasswordHide: boolean;
  public resetType: string;
  public token: string;
  public successMessage: string;
  public errorMessage: string;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public authservice: AuthService,
    private router: Router
  ) {
    this.resetPasswordTitle = '';
    this.isPasswordHide = true;
    this.isConfirmPasswordHide = true;
    this.resetType = '';
    this.token = '';
    this.successMessage = '';
    this.errorMessage = '';
    this.resetpasswordform = this.fb.group({});
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.resetType = params['client'];
      this.token = params['token'];
    });

    if (this.resetType && this.resetType === 'admin') {
      this.resetPasswordTitle = 'Change ALink Password';
    } else if (this.resetType && this.resetType === 'app') {
      this.resetPasswordTitle = 'Change ALink App Password';
    } else {
      this.resetPasswordTitle = 'Change Password';
    }

    this.resetpasswordform = this.fb?.group(
      {
        password: ['', [Validators.required, whiteSpaceValidator()]],
        confirmpassword: ['', Validators.required],
      },
      {
        validator: MustMatch('password', 'confirmpassword'),
      }
    );
  }

  // Reset Password
  async resetPassword() {
    this.resetpasswordform.disable();
    let body = {
      client: this.resetType,
      token: this.token,
      password: await this.sha256(
        this.resetpasswordform.get('confirmpassword')?.value
      ),
    };
    this.authservice.resetPassword(body).subscribe(
      (res: any) => {
        if (res.status === 204) {
          if (this.resetType === 'app') {
            this.errorMessage = '';
            this.successMessage =
              'Password has been reset successfully, please login to the application now';
          } else {
            this.errorMessage = '';
            this.successMessage = 'Password has been reset successfully';
            setTimeout(() => {
              this.router.navigate(['login']);
            }, 2000);
          }
        }
      },
      (error) => {
        this.successMessage = '';
        this.errorMessage = error.error;
        this.resetpasswordform.enable();
      }
    );
  }

  // sha256 converted password
  async sha256(password: string) {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(password);

    // hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // convert bytes to hex string
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('');
    return hashHex.toUpperCase();
  }
}
