<div class="icon">
  <mat-icon class="error-icon">error_outline</mat-icon>
</div>
<div class="title">
  <!-- <h1 mat-dialog-title>Error</h1> -->
  <p>{{data.msg}}</p>
</div>
<div mat-dialog-actions align="center">
  <button mat-raised-button (click)="cancel()">Cancel</button> &nbsp;
  <button mat-button class="primary-button" (click)="retry()">&nbsp;Retry&nbsp;</button>
</div>