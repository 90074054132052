<!--#region Toolbar -->
<mat-toolbar class="app-toolbar">
  <span>Filter Parts</span>
  <span class="example-spacer"></span>
  <button mat-icon-button (click)="closeDialog()" onclick="this.blur()">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>
<!--#endregion -->

<mat-progress-bar *ngIf="isShowProgressBar" mode="indeterminate"></mat-progress-bar>

<div class="mat-elevation-z8 container" [formGroup]="formGroup">

  <!-- <mat-form-field appearance="legacy">
    <mat-label>Barcode No</mat-label>
    <input matInput formControlName="barcode" autocomplete="off">
  </mat-form-field>

  <mat-form-field appearance="legacy">
    <mat-label>Part No</mat-label>
    <input matInput formControlName="part" autocomplete="off">
  </mat-form-field> -->

  <mat-form-field appearance="legacy">
    <mat-label>Part Type</mat-label>
    <mat-select disableOptionCentering formControlName="type">
      <mat-option>Select</mat-option>
      <mat-option value="PART">PART</mat-option>
      <mat-option value="BOM">BOM</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="legacy">
    <mat-label>Manufacturer</mat-label>
    <mat-select disableOptionCentering formControlName="manufacturer">
      <mat-option>Select</mat-option>
      <mat-option [value]="item" *ngFor="let item of manufacturers">{{item}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="legacy">
    <mat-label>Inventory Count</mat-label>
    <mat-select disableOptionCentering formControlName="inventoryCount">
      <mat-option>Select</mat-option>
      <mat-option value="min">Less than min</mat-option>
      <mat-option value="max">More than max</mat-option>
    </mat-select>
  </mat-form-field>

  <div fxLayout="row">
    <div fxFlex="50" style="padding-right: 5px;">
      <button mat-raised-button class="danger-button" (click)="clearFilter()">Clear</button>
    </div>
    <div fxFlex="auto" style="padding-left: 5px;">
      <button [disabled]="
            (formGroup.controls.terminal?.value?.length> 1 && !formGroup.controls.gate.value)" mat-raised-button
        class="success-button" (click)="applyFilter()">Apply</button>
    </div>
  </div>
</div>
