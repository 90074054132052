import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConstants } from '../shared/AppConstants';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AlertComponent } from '../components/alert/alert.component';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConfigService } from '../app.config';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public constants: AppConstants;
  public isExpanded: boolean;
  public loggedIn = new BehaviorSubject<boolean>(false);
  public roles: any[];
  public isAFSUser: boolean = false;

  get isLoggedIn() {
    const idToken = localStorage.getItem('token');
    if (idToken) {
      this.loggedIn.next(true);
    }
    return this.loggedIn.asObservable();
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private dialog: MatDialog,
    private appConfigService: AppConfigService
  ) {
    this.constants = new AppConstants();
    this.isExpanded = false;
    this.roles = [];
  }

  // Authenticate User
  authenticateUser(userDetails: any) {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization:
          'Basic ' + btoa(userDetails.email + ':' + userDetails.password),
      }),
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(url + this.constants.LOGIN_URL, httpOptions);
  }

  // Authenticate User with tanent id
  authenticateUserWithTanentId(userDetails: any, tenantId?: any) {
    let tempParams: any = {};
    if (tenantId) {
      tempParams.tenantId = tenantId;
    }
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization:
          'Basic ' + btoa(userDetails.email + ':' + userDetails.password),
      }),
      params: tempParams,
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(url + this.constants.LOGIN_URL, httpOptions);
  }

  // Logout from the app
  logout() {
    const dialogRef = this.dialog.open(AlertComponent, {
      width: '400px',
      data: { errorMsg: 'Are you sure you want to logout?' },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        localStorage.removeItem('token');
        localStorage.removeItem('user-details');
        localStorage.removeItem('customerName');
        localStorage.removeItem('customerId');
        localStorage.removeItem('dashboardSlug');
        localStorage.removeItem('customerId');
        localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
        this.loggedIn.next(false);
        this.router.navigate(['login']);
      }
    });
  }

  // Check module acces based on user modules
  checkRoleBasedModuleAccess(module: string) {
    let access = false;
    let userDetails = localStorage.getItem('user-details');
    if (userDetails && userDetails != null) {
      let obj = JSON.parse(userDetails);
      if (obj.MODULE && obj.MODULE.length > 0) {
        this.roles = obj.MODULE;
        switch (module) {
          case 'ADMIN':
            if (this.roles.includes(module)) {
              access = true;
            }
            break;

          case 'CUSTOMER':
            if (this.roles.includes(module)) {
              access = true;
            }
            break;

          case 'USER':
            if (this.roles.includes(module)) {
              access = true;
            }
            break;

          case 'SERVICE_REQUEST':
            if (this.roles.includes(module)) {
              access = true;
            }
            break;

          case 'AUDIT':
            if (this.roles.includes(module)) {
              access = true;
            }
            break;

          case 'MESSAGE':
            if (
              this.roles.includes(module) ||
              this.roles.includes('SERVICE_REQUEST')
            ) {
              access = true;
            }
            break;
          case 'EQUIPMENT':
            if (this.roles.includes(module)) {
              access = true;
            }
            break;
          case 'SETTINGS':
            if (this.roles.includes(module)) {
              access = true;
            }
            break;
          case 'LOPA':
            if (this.roles.includes(module)) {
              access = true;
            }
            break;
          case 'SCHEDULED_SERVICE_REQUEST':
            if (this.roles.includes(module)) {
              access = true;
            }
            break;
          case 'BUDGET':
            if (this.roles.includes(module)) {
              access = true;
            }
            break;
          case 'INVOICE':
            if (this.roles.includes(module)) {
              access = true;
            }
            break;
          case 'PART_ORDER':
            if (
              this.roles.includes(module) ||
              this.roles.includes('PART_ORDER_READ_ONLY')
            ) {
              access = true;
            }
            break;
          case 'PART_ORDER_READ_ONLY':
            if (
              this.roles.includes(module) ||
              this.roles.includes('PART_ORDER')
            ) {
              access = true;
            }
            break;
            case 'BILLABLE_UNIT_REPORT':
            if (
              this.roles.includes(module) ||
              this.roles.includes('BILLABLE_UNIT_REPORT_READ_ONLY')
            ) {
              access = true;
            }
            break;
          case 'BILLABLE_UNIT_REPORT_READ_ONLY':
            if (
              this.roles.includes(module) ||
              this.roles.includes('BILLABLE_UNIT_REPORT')
            ) {
              access = true;
            }
            break;
          default:
            access = false;
            break;
        }
      } else {
        access = false;
      }
    }
    return access;
  }

  checkRoleBasedModuleReadOnlyAccessBillable() {
    let access = false;
    let userDetails = localStorage.getItem('user-details');
    if (userDetails && userDetails != null) {
      let obj = JSON.parse(userDetails);
      if (obj.MODULE && obj.MODULE.length > 0) {
        this.roles = obj.MODULE;
        if (this.roles.includes('BILLABLE_UNIT_REPORT') && this.roles.includes('BILLABLE_UNIT_REPORT_READ_ONLY')) {
          access = true;
        } else if (this.roles.includes('BILLABLE_UNIT_REPORT') && !this.roles.includes('BILLABLE_UNIT_REPORT_READ_ONLY')) {
          access = true;
        } else if (!this.roles.includes('BILLABLE_UNIT_REPORT') && this.roles.includes('BILLABLE_UNIT_REPORT_READ_ONLY')) {
          access = false;
        } else {
          access = true;
        }
      }
    }
    return access;
  }

  checkRoleBasedModuleReadOnlyAccessPartOrder() {
    let access = false;
    let userDetails = localStorage.getItem('user-details');
    if (userDetails && userDetails != null) {
      let obj = JSON.parse(userDetails);
      if (obj.MODULE && obj.MODULE.length > 0) {
        this.roles = obj.MODULE;
        if (this.roles.includes('PART_ORDER') && this.roles.includes('PART_ORDER_READ_ONLY')) {
          access = false;
        } else if (this.roles.includes('PART_ORDER') && !this.roles.includes('PART_ORDER_READ_ONLY')) {
          access = false;
        } else if (!this.roles.includes('PART_ORDER') && this.roles.includes('PART_ORDER_READ_ONLY')) {
          access = true;
        } else {
          access = false;
        }
      }
    }
    return access;
  }

  isNotAfsCustomer(): boolean {
    let user: any = localStorage.getItem('user-details');
    if (user && user != null) {
      user = JSON.parse(user);
      if(user?.CUSTOMER_TYPE == null) {
        return false; 
      } else return true
    } else return true;
  }

  isAfsUser(): boolean {
    let user: any = localStorage.getItem('user-details');
    if (user && user != null) {
      user = JSON.parse(user);
      if(user?.AFS_USER && user?.TENANTS?.length > 1) {
        return true; 
      } else return false
    } else return false;
  }

  

  // Reset password
  resetPassword(body: any) {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.post<any>(
      url + this.constants.RESET_PASSWORD,
      body,
      httpOptions
    );
  }

  // Forgot password
  forgotPassword(body: any) {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.post<any>(
      url + this.constants.FORGOT_PASSWORD,
      body,
      httpOptions
    );
  }

  getSwitchtenant(customerId: any) {
    let tempParams: any = {
      app:'admin',
      tenantId: customerId
    };
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      params: tempParams,
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(
      url + this.constants.GET_SWITCH_TENANTS_URL,
      httpOptions
    );
  }
}
