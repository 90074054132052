<mat-toolbar class="app-toolbar-fixed">
    <button mat-button onclick="this.blur()" (click)="closeDialog()">
        Cancel
    </button>
    <span style="margin: 0 auto;">{{title}}</span>
    <button mat-button onclick="this.blur()" (click)="saveParts()">
        Save
    </button>
</mat-toolbar>
<mat-progress-bar *ngIf="isShowProgressBar" class="primary-color" mode="indeterminate">
</mat-progress-bar>

<div>
    <mat-toolbar class="container-toolbar" style="height:56px !important;min-height:56px !important">
        <mat-toolbar-row style="padding-left: 0px;">
            <span class="example-spacer"></span>
            <mat-paginator #paginator [pageSize]="paginatorLength" [pageSizeOptions]="[5, 10, 25, 50, 100]"
                (page)="handlePartPaginator($event);" [length]="partsLength">
            </mat-paginator>
        </mat-toolbar-row>
    </mat-toolbar>

    <table mat-table [dataSource]="partDataSource" matSort (matSortChange)="sortPartfields($event)">
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? partMasterToggle() : null"
                    [checked]="partSelection.hasValue() && isPartAllSelected()"
                    [indeterminate]="partSelection.hasValue() && !isPartAllSelected()">
                </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="toggelePartSelection($event, row)"
                    [checked]="selectedPart(row)">
                </mat-checkbox>
            </td>
        </ng-container>

        <!-- ID Column -->
        <ng-container matColumnDef="PART_ID">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
            <td mat-cell *matCellDef="let element"> {{element.PART_ID}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="NAME">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.NAME}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="partColumns;"></tr>
        <tr mat-row *matRowDef="let row; columns: partColumns;" (click)="partSelection.toggle(row)">
        </tr>
    </table>
</div>