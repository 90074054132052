<!--#region Toolbar -->
<mat-toolbar class="app-toolbar-fixed">
    <span>Equipment Details</span>
    <span class="example-spacer"></span>
    <button mat-icon-button (click)="closeDialog()" onclick="this.blur()">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>
<mat-progress-bar *ngIf="isShowProgressBar" mode="indeterminate"></mat-progress-bar>
<!--#endregion -->

<mat-tab-group mat-stretch-tabs mat-align-tabs="start">

    <mat-tab label="Details">
        <div style="padding: 20px;">
            <div *ngIf="equipmentDetails && equipmentDetails != null && !isShowProgressBar">
                <div fxLayout="row">
                    <div fxFlex="auto">
                        <p>Equipment ID</p>
                    </div>

                    <div fxFlex="68">
                        <p class="details">{{equipmentDetails.EQUIPMENT_ID}}</p>
                    </div>
                </div>

                <div fxLayout="row">
                    <div fxFlex="auto">
                        <p>Equipment Name</p>
                    </div>

                    <div fxFlex="68">
                        <p class="details">{{equipmentDetails.NAME}}</p>
                    </div>
                </div>


                <div fxLayout="row">
                    <div fxFlex="auto">
                        <p>Description</p>
                    </div>

                    <div fxFlex="68">
                        <p class="details">{{equipmentDetails.DESCRIPTION}}</p>
                    </div>
                </div>

                <div fxLayout="row">
                    <div fxFlex="auto">
                        <p>Category ID</p>
                    </div>

                    <div fxFlex="68">
                        <p class="details">{{equipmentDetails.CATEGORY_ID}}</p>
                    </div>
                </div>

                <div fxLayout="row">
                    <div fxFlex="auto">
                        <p>Category Name</p>
                    </div>

                    <div fxFlex="68">
                        <p class="details">{{equipmentDetails.CATEGORY_NAME}}</p>
                    </div>
                </div>

                <div fxLayout="row">
                    <div fxFlex="auto">
                        <p>BOM ID</p>
                    </div>

                    <div fxFlex="68">
                        <p class="details">{{equipmentDetails.BOM_ID}}</p>
                    </div>
                </div>

                <div fxLayout="row">
                    <div fxFlex="auto">
                        <p>Tag</p>
                    </div>

                    <div fxFlex="68">
                        <p class="details">{{equipmentDetails.TAG}}</p>
                    </div>
                </div>

                <div fxLayout="row">
                    <div fxFlex="auto">
                        <p>Location</p>
                    </div>

                    <div fxFlex="68">
                        <p class="details">
                            <span *ngFor="let loc of equipmentDetails?.LOCATION; let last = last;">
                                {{loc.NAME}}<span *ngIf="!last">,</span>
                            </span>
                    </div>
                </div>

            </div>

            <div class="centered" *ngIf="(!equipmentDetails || equipmentDetails == null) && !isShowProgressBar">
                <h3 style="color:#00629b;font-weight: bold;">No equipment details found</h3>
            </div>
        </div>
    </mat-tab>

    <mat-tab label="Logs">
        <div>
            <mat-accordion style="border-radius: 0px !important;" *ngIf="equipmentLogs && equipmentLogs?.length > 0">
                <mat-expansion-panel style="border-radius: 0px !important;" *ngFor="let log of equipmentLogs"
                    (afterExpand)="expand(log)" (afterCollapse)="collpase()">
                    <mat-expansion-panel-header style="height:auto">
                        <mat-panel-title
                            style="flex-grow: initial !important;margin:auto;color: black; opacity: 0.6;min-width: 33px !important;">
                            # {{log.LOG_ID}}
                        </mat-panel-title>
                        <mat-panel-description>
                            <mat-list style="margin: 0px;padding:4px">
                                <mat-list-item style="margin: 0px;padding:0px;height: auto;">
                                    <mat-icon mat-list-icon class="material-icons-outlined" style="font-size: 26px;"
                                        [ngStyle]="{'color': log.ACTION | serviceLogIconColorFilter}">{{log.ACTION |
                                        serviceLogIconFilter}}
                                    </mat-icon>
                                    <h3 mat-line style="color:#00629b;margin-top: 10px;"> {{log.LOG_MESSAGE}} </h3>
                                    <p mat-line
                                        style="display: flex;margin: 8px 0px 8px 0px !important;color: black !important;">
                                        <span> Created By: &nbsp;</span>
                                        <span style="color: black; opacity: 0.6;"> {{log.CREATED_BY_NAME}} </span>
                                        <span style="margin-left: 15px;"> Created At: &nbsp;</span>
                                        <span style="color: black; opacity: 0.6;"> {{log.CREATED_AT | date: constants.DATE_FORMAT}}
                                        </span>
                                    </p>
                                </mat-list-item>
                            </mat-list>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div style="text-align: -webkit-center !important;margin:10px !important">
                        <mat-spinner diameter="30" *ngIf="isShowSpinner"></mat-spinner>
                    </div>

                    <mat-card
                        *ngIf="serviceRequestDetails && serviceRequestDetails != null && !isShowSpinner && !noDetailsFlag"
                        style="background: #fafafa;box-shadow: none !important;
                    border: 1px solid #dbdbdb;">
                        <div fxLayout=" row">
                            <div fxFlex="27">
                                <p style="color:#00629b"> Request ID:
                                    <span style="color:black;opacity: 0.6;">
                                        {{serviceRequestDetails.REQUEST_ID}}
                                    </span>
                                </p>
                            </div>
                            <div fxFlex="46">
                                <p style="color:#00629b"> Equipment Name:
                                    <span style="color:black;opacity: 0.6;">
                                        {{serviceRequestDetails.EQUIPMENT_NAME}}
                                    </span>
                                </p>
                            </div>
                            <div fxFlex="27">
                                <p style="color:#00629b"> Task Type:
                                    <span style="color:black;opacity: 0.6;">
                                        {{serviceRequestDetails.TASK_TYPE}}
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div fxLayout="row">
                            <div fxFlex="27">
                                <p style="color:#00629b"> Status:
                                    <span style="color:black;opacity: 0.6;">
                                        {{serviceRequestDetails.STATUS}}
                                    </span>
                                </p>
                            </div>
                            <div fxFlex="46">
                                <p style="color:#00629b"> Movement:
                                    <span *ngIf="serviceRequestDetails.NEW_LOCATION">
                                        <span style="padding-top: 4px;color:black;opacity: 0.6;">Moved to:
                                            {{serviceRequestDetails.NEW_LOCATION.Terminal.NAME}},
                                            {{serviceRequestDetails.NEW_LOCATION.Gate.NAME}}</span>
                                    </span>
                                    <span *ngIf="!serviceRequestDetails.NEW_LOCATION && serviceRequestDetails.LOCATION">
                                        <span style="padding-top: 4px;color:black;opacity: 0.6;">
                                            {{serviceRequestDetails.LOCATION.Terminal.NAME}},
                                            {{serviceRequestDetails.LOCATION.Gate.NAME}} </span>
                                    </span>
                                </p>
                            </div>
                            <div fxFlex="27">
                                <p style="color:#00629b"> Category:
                                    <span style="color:black;opacity: 0.6;">
                                        {{serviceRequestDetails.EQUIPMENT_CATEGORY_NAME}}
                                    </span>
                                </p>
                            </div>
                        </div>

                        <div fxLayout="row">
                            <div fxFlex="auto">
                                <p style="color:#00629b"> Description:
                                    <span style="color:black;opacity: 0.6;">
                                        {{serviceRequestDetails.DESCRIPTION}}
                                    </span>
                                </p>
                            </div>
                        </div>

                    </mat-card>

                    <mat-card *ngIf="noDetailsFlag && !isShowSpinner" style="background: #fafafa;box-shadow: none !important; text-align: -webkit-center !important;margin:auto !important;
                    border: 1px solid #dbdbdb;">
                        <p style="color: rgb(228, 0, 43) !important">No
                            details found</p>
                    </mat-card>

                </mat-expansion-panel>
            </mat-accordion>

            <div class="centered" *ngIf="(!equipmentLogs || equipmentLogs.length == 0) && !isShowProgressBar">
                <h3 style="color:#00629b;">No service logs found / recorded</h3>
            </div>
        </div>
    </mat-tab>

</mat-tab-group>