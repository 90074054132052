<mat-toolbar class="app-toolbar">
    <span>Receive Parts</span>
    <span class="example-spacer"></span>
    <button mat-icon-button (click)="closeDialog()" onclick="this.blur()">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>

<mat-progress-bar *ngIf="isShowProgressBar" mode="indeterminate"></mat-progress-bar>
<div class="container" style="padding: 20px 20px 20px 20px;">
    <mat-form-field appearance="outline">
        <mat-label>Location</mat-label>
        <mat-select required disableOptionCentering [(ngModel)]="location">
            <mat-option *ngFor="let loc of inventoryLocations" [value]="loc.LOCATION_ID">
                {{loc.NAME}}</mat-option>
        </mat-select>
    </mat-form-field>

    <div *ngIf="location">
        <mat-form-field appearance="outline">
            <mat-label>Received Date</mat-label>
            <input (click)="dp3.open()" matInput [matDatepicker]="dp3" required [(ngModel)]="reciveDate"
                [readonly]="true">
            <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
            <mat-datepicker disabled="false" #dp3></mat-datepicker>
        </mat-form-field>
    </div>

    <div class="mat-elevation-z4" style="max-height: 47vh;overflow: auto;">
        <table mat-table [dataSource]="partDataSource" matSort>
            <ng-container matColumnDef="No">
                <th mat-header-cell *matHeaderCellDef> No </th>
                <td mat-cell *matCellDef="let element; let i = index;"> <span
                        [ngClass]="(element.QUANTITY - element.RECEIVED_QUANTITY) == 0 ?'no-drop': ''">{{i+1}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="PART_ID">
                <th mat-header-cell *matHeaderCellDef> Part </th>
                <td mat-cell *matCellDef="let element">
                    <div class="text">
                        <input matInput type="text" [(ngModel)]="element.PART_ID" disabled="true"
                            [ngClass]="(element.QUANTITY - element.RECEIVED_QUANTITY) == 0 ?'no-drop': ''">
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="RECEIVED_QUANTITY">
                <th mat-header-cell *matHeaderCellDef> Quantity to be received
                </th>
                <td mat-cell *matCellDef="let element" style="text-align: right;">
                    <div class="text">
                        <input matInput type="number" disabled="true"
                            [ngClass]="(element.QUANTITY - element.RECEIVED_QUANTITY) == 0 ?'no-drop': ''"
                            [value]="element.QUANTITY - element.RECEIVED_QUANTITY">
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="QUANTITY">
                <th mat-header-cell *matHeaderCellDef> Received Quantity
                </th>
                <td mat-cell *matCellDef="let element">
                    <div [ngClass]="element.R_Quantity > (element.QUANTITY - element.RECEIVED_QUANTITY) ? 'text error-border':'text border'"
                        style="text-align: right;">
                        <input (input)="errorMsg=''" matInput type="number" [(ngModel)]="element.R_Quantity" min="0"
                            [max]="element.QUANTITY - element.RECEIVED_QUANTITY"
                            [disabled]="(element.QUANTITY - element.RECEIVED_QUANTITY) == 0"
                            [ngClass]="(element.QUANTITY - element.RECEIVED_QUANTITY) == 0 ?'no-drop': ''">
                    </div>

                </td>
            </ng-container>

            <ng-container matColumnDef="ERROR">
                <th mat-header-cell *matHeaderCellDef>
                </th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.R_Quantity > (element.QUANTITY - element.RECEIVED_QUANTITY)"
                        style="text-align: center;">
                        <mat-hint style="color: indianred;font-size: smaller;">Max allowed quantity is {{
                            element.QUANTITY -
                            element.RECEIVED_QUANTITY
                            }}</mat-hint>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="partColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: partColumns;">
            </tr>
        </table>
    </div>
</div>

<div *ngIf="errorMsg.length> 0" style="color: rgb(228, 0, 43);width: 100%;text-align: center;">
    <p style="margin-top: 0px;text-wrap: balance;">{{errorMsg}}</p>
</div>

<div class="button-small-div">
    <button mat-raised-button [ngClass]="errorMsg.length> 0 ? '': 'primary-button'" (click)="save()"
        [disabled]="errorMsg.length> 0">
        <span> Save </span>
    </button>
</div>