<div>
    <div class="icon">
        <mat-icon>error_outline</mat-icon>
    </div>
    <div class="title">
        <p style="color: #545454;">Are you sure you want to save your changes?</p>
    </div>
    <mat-progress-bar mode="indeterminate" *ngIf="isShowProgressBar">
    </mat-progress-bar>

    <div *ngIf="errorMsg.length> 0" style="text-align: center;color: rgb(228, 0, 43);margin-bottom: -5px;">
        <p>{{errorMsg}}</p>
    </div>

    <div mat-dialog-actions align="center" style="padding-top:10px;">
        <button mat-raised-button class="primary-button" (click)="cancel()">Cancel</button>&nbsp;&nbsp;
        <button mat-raised-button class="success-button" (click)="save()">&nbsp;&nbsp;Save &nbsp;&nbsp;</button>
    </div>
</div>