<mat-toolbar class="app-toolbar-fixed">
    <span> {{title}} </span>
    <span class="example-spacer"></span>
    <button mat-icon-button (click)="closeDialog()" onclick="this.blur()">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>
<mat-progress-bar *ngIf="isShowProgressBar" mode="indeterminate"></mat-progress-bar>

<form style="margin:0px 0px 10px 0px;padding: 10px;font-size: 14px !important;" [formGroup]="formGroup">
    <mat-form-field appearance="legacy">
        <mat-label>Category Id</mat-label>
        <input autocomplete="off" matInput type="text" required formControlName="categoryId">
    </mat-form-field>

    <mat-form-field appearance="legacy">
        <mat-label>Name</mat-label>
        <input autocomplete="off" matInput type="text" required formControlName="name">
    </mat-form-field>

    <mat-form-field appearance="legacy">
        <mat-label>Description</mat-label>
        <input autocomplete="off" matInput type="text" formControlName="description">
    </mat-form-field>

    <div style="text-align:center;padding-top:10px">
        <button mat-raised-button (click)="save()" [ngClass]="formGroup.valid ? 'primary-button' : ''" [disabled]="!formGroup.valid">
            <span *ngIf="data.mode == 1"> Save </span>
            <span *ngIf="data.mode == 2"> Update </span>
        </button>
    </div>

    <div *ngIf="errorMsg.length> 0" style="text-align: center;color: rgb(228, 0, 43)">
        <p>{{errorMsg}}</p>
    </div>
</form>