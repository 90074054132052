import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TestEmailComponent } from 'src/app/components/test-email/test-email.component';
import { AuthService } from 'src/app/services/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { AppConstants } from 'src/app/shared/AppConstants';
import { ConfigManagementComponent } from '../config-management/config-management.component';
import { CustomerSelectComponent } from 'src/app/components/customer-select/customer-select.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  public version: string;
  public release: string;
  public constants: AppConstants;
  public userCompanyList: any[] = [];
  public selectedCustomer: any;
  public appropriateClass:string = '';
  public events: string[] = [];
  
  @HostListener('window:resize', ['$event'])
  getScreenHeight(event?: any){
    if(window.innerHeight<=700){
      this.appropriateClass = 'bottomRelative';
    }else{
      this.appropriateClass = 'bottomStick';
    }
  }
  constructor(
    public authService: AuthService,
    public utilService: UtilService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.getScreenHeight();
    this.constants = new AppConstants();
    this.version = this.constants.APP_RELEASE_NUMBER;
    this.release = this.constants.APP_RELEASE_DATE;
  }

  ngOnInit(): void {
    this.selectedCustomer = localStorage.getItem('customerId');
    if (this.selectedCustomer && this.selectedCustomer != null) {
      if (this.selectedCustomer == 1) {
        this.authService.isAFSUser = true;
      } else {
        this.authService.isAFSUser = false;
      }
    }
  }

  logout() {
    this.authService.logout();
  }

  gotoChoices() {
    this.router.navigate(['choices']);
  }

  gotoExtensions() {
    this.router.navigate(['extensions']);
  }

  gotoConfiguration() {
    this.router.navigate(['configurations']);
  }

  testEmail() {
    this.dialog.open(TestEmailComponent, {
      width: '500px',
      disableClose: true
    });
  }

  gotoEquipmentCategory() {
    this.router.navigate(['equipment-category']);
  }

  switchCompany() {
    let userTenants: any = localStorage.getItem('userTenants');
    if (userTenants && userTenants != null) {
      userTenants = JSON.parse(userTenants);
      const dialogRef = this.dialog.open(CustomerSelectComponent, {
        panelClass: 'add-customer-dialog',
        width: '550px',
        height: '380px',
        disableClose: false,
        data: { mode: 0, item: userTenants },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.selectedCustomer = localStorage.getItem('customerId');
          if (this.selectedCustomer && this.selectedCustomer != null) {
            if (this.selectedCustomer == 1) {
              this.authService.isAFSUser = true;
            } else {
              this.authService.isAFSUser = false;
            }
          }
        }
      });
    }
  }
}
