import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddChoiceValueComponent } from 'src/app/components/add-choice-value/add-choice-value.component';
import { ErrorAlertComponent } from 'src/app/components/error alert/error-alert.component';
import { AuthService } from 'src/app/services/auth.service';
import { ChoicesService } from 'src/app/services/choices.service';
import { UtilService } from 'src/app/services/util.service';
import { AppConstants } from 'src/app/shared/AppConstants';
import { Location } from '@angular/common';
import { AddNewChoiceCategoryComponent } from 'src/app/components/add-new-choice-category/add-new-choice-category.component';
import { CustomerSelectComponent } from 'src/app/components/customer-select/customer-select.component';

@Component({
  selector: 'app-choices',
  templateUrl: './choices.component.html',
  styleUrls: ['./choices.component.scss'],
})
export class ChoicesComponent implements OnInit {
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  public version: string;
  public release: string;
  public constants: AppConstants;
  public userCompanyList: any[] = [];
  public selectedCustomer: any;
  public isShowCategoryProgressBar: boolean = false;
  public isShowValuesProgressBar: boolean = false;
  public isShowSubValuesProgressBar: boolean = false;
  public categoryList: any = {};
  public selectedCategoryIndex: any = null;
  public selectedValueIndex: any = null;
  public selectedCategoryValues: any[] = [];
  public selectedCategorySubValues: any[] = [];
  public selectedCategoryItem: any = null;
  public selectedCategoryValueItem: any = null;
  public appropriateClass:string = '';
  public events: string[] = [];
  
  @HostListener('window:resize', ['$event'])
  getScreenHeight(event?: any){
    if(window.innerHeight<=700){
      this.appropriateClass = 'bottomRelative';
    }else{
      this.appropriateClass = 'bottomStick';
    }
  }
  constructor(
    private _location: Location,
    public authService: AuthService,
    public utilService: UtilService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private choiceService: ChoicesService,
    public dialog: MatDialog
  ) {
    this.getScreenHeight();
    this.constants = new AppConstants();
    this.version = this.constants.APP_RELEASE_NUMBER;
    this.release = this.constants.APP_RELEASE_DATE;
  }

  ngOnInit(): void {
    this.selectedCustomer = localStorage.getItem('customerId');
    if (this.selectedCustomer && this.selectedCustomer != null) {
      if (this.selectedCustomer == 1) {
        this.authService.isAFSUser = true;
      } else {
        this.authService.isAFSUser = false;
      }
    }

    this.getCustomization();
  }

  logout() {
    this.authService.logout();
  }

  selectedCategory(index: any, item: any, type: any) {
    this.selectedCategoryItem = { type: type };
    this.selectedCategoryValues = [];
    this.selectedCategorySubValues = [];
    this.selectedCategoryIndex = index;
    this.selectedValueIndex = null;
    this.selectedCategoryValues = item;
  }

  selectedCategoryValue(index: any, item: any) {
    this.selectedCategoryValueItem = item;
    this.selectedValueIndex = index;
    if (item['SUB_CHOICE']) {
      this.selectedCategorySubValues = item['SUB_CHOICE'];
    } else {
      this.selectedCategorySubValues = [];
    }
  }

  refreshChoices() {
    this.selectedCategoryValueItem = null;
    this.selectedCategoryItem = null;
    this.selectedCategoryIndex = null;
    this.selectedValueIndex = null;
    this.selectedCategoryValues = [];
    this.selectedCategorySubValues = [];
    this.getCustomization();
  }

  async getCustomization() {
    // this.categoryList = {};
    this.isShowCategoryProgressBar = true;
    await this.choiceService
      .getCustomization()
      .toPromise()
      .then(
        (response: any) => {
          this.isShowCategoryProgressBar = false;
          if (response.status === 200) {
            if (response?.body) {
              this.categoryList = response?.body?.CHOICE;
            } else {
              this.categoryList = {};
            }
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.isShowCategoryProgressBar = false;
            this.router.navigate(['dashboard']);
          } else {
            this.isShowCategoryProgressBar = false;
            this.showErrorAlertDialog(response.message);
          }
        },
          (error) => {
            if (error.status === 401) {
              // Redirect to login for unauthorized
              localStorage.removeItem('token');
              localStorage.removeItem('user-details');
              localStorage.removeItem('customerName');
              localStorage.removeItem('customerId');
              localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
              this.authService.loggedIn.next(false);
              this.router.navigate(['login']);
            } else if (error.status === 403) {
              this.isShowCategoryProgressBar = false;
              this.router.navigate(['dashboard']);
            } else {
              this.isShowCategoryProgressBar = false;
              this.showErrorAlertDialog(error.message);
            }
          }
      );
  }

  showErrorAlertDialog(msg: string) {
    const dialogRef = this.dialog.open(ErrorAlertComponent, {
      width: '400px',
      data: {
        errorMsg: msg,
      },
    });
  }

  addValue(type: string, selectedObj: any) {
    const dialogRef = this.dialog.open(AddChoiceValueComponent, {
      width: '450px',
      data: {
        mode: 'Value',
        item: this.selectedCategoryItem,
        type: type,
        selectedObj: selectedObj,
      },
      panelClass: 'add-customer-dialog',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        await this.getCustomization();
        if (this.categoryList.hasOwnProperty(this.selectedCategoryItem?.type)) {
          this.selectedCategoryValues =
            this.categoryList[this.selectedCategoryItem?.type];
        } else {
          this.selectedCategoryIndex = null;
          this.selectedCategoryValues = [];
          this.selectedValueIndex = null;
        }
      }
    });
  }

  addSubValue(type: string, selectedObj: any) {
    let item = {
      type: this.selectedCategoryItem?.type
        ? this.selectedCategoryItem?.type
        : '',
      choiceId: this.selectedCategoryValueItem?.CHOICE_ID
        ? this.selectedCategoryValueItem?.CHOICE_ID
        : '',
    };
    const dialogRef = this.dialog.open(AddChoiceValueComponent, {
      width: '400px',
      data: {
        mode: 'SubValue',
        item: item,
        type: type,
        selectedObj: selectedObj,
      },
      panelClass: 'add-customer-dialog',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        await this.getCustomization();
        this.selectedCategoryValues =
          this.categoryList[this.selectedCategoryItem?.type];

        let selectedItem = this.selectedCategoryValues.filter(
          (c) => c.CHOICE_ID == this.selectedCategoryValueItem?.CHOICE_ID
        );

        if (selectedItem && selectedItem.length > 0) {
          if (selectedItem[0]['SUB_CHOICE']) {
            this.selectedCategorySubValues = selectedItem[0]['SUB_CHOICE'];
          } else {
            this.selectedCategorySubValues = [];
          }
        }
      }
    });
  }

  goBack() {
    this._location.back();
  }

  addChoices() {
    const dialogRef = this.dialog.open(AddNewChoiceCategoryComponent, {
      width: '450px',
      data: { item: this.selectedCategoryItem },
      panelClass: 'add-customer-dialog',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.categoryList[result] = [];
        // let index = Object.keys(this.categoryList).indexOf(result);
        // this.selectedCategory(index -1, [], result);
      }
    });
  }

  switchCompany() {
    let userTenants: any = localStorage.getItem('userTenants');
    if (userTenants && userTenants != null) {
      userTenants = JSON.parse(userTenants);
      const dialogRef = this.dialog.open(CustomerSelectComponent, {
        panelClass: 'add-customer-dialog',
        width: '550px',
        height: '380px',
        disableClose: false,
        data: { mode: 0, item: userTenants },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.refreshChoices();
        }
      });
    }
  }
}
