import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { PartService } from 'src/app/services/part.service';

export interface DialogData {
  item: any;
}

@Component({
  selector: 'app-part-filter',
  templateUrl: './part-filter.component.html',
  styleUrls: ['./part-filter.component.scss'],
})
export class PartFilterComponent implements OnInit {
  public isShowProgressBar: boolean = false;
  public filterData: any;
  public formGroup: FormGroup;
  public customUomChoices: any = [];
  public manufacturers: any = [];

  constructor(
    public authService: AuthService,
    public router: Router,
    public partService: PartService,
    public dialogRef: MatDialogRef<PartFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.formGroup = new FormGroup({
      type: new FormControl(''),
      inventoryCount: new FormControl(''),
      manufacturer: new FormControl(''),
    });
  }

  ngOnInit(): void {
    window.innerWidth - Number(0);
    this.dialogRef.updatePosition({
      top: `110px`,
      right: `45px`,
    });

    this.getManufatures();

    if (
      this.data?.item &&
      this.data?.item != null &&
      this.data?.item != undefined
    ) {
      this.formGroup = new FormGroup({
        type: new FormControl(this.data.item?.type),
        inventoryCount: new FormControl(this.data.item?.count),
        manufacturer: new FormControl(this.data.item?.manufacturer),
      });
    }
  }

  getManufatures() {
    this.isShowProgressBar = true;
    this.partService.getManufatures().subscribe(
      (response: any) => {
        // Success callback
        this.isShowProgressBar = false;
        if (response.status === 200) {
          if (response.body) {
            // Assign the data
            this.manufacturers = response.body;
          }
        } else if (response.status === 401) {
          // Redirect to login for unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId');
          localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        } else {
          console.log(response.error);
        }
      },
        (error) => {
          this.isShowProgressBar = false;
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else {
            console.log(error.error);
          }
        }
    );
  }

  closeDialog() {
    this.dialogRef.close();
  }

  clearFilter() {
    this.formGroup.reset();
  }

  applyFilter() {
    let filterDetails = {
      type: this.formGroup.controls['type'].value,
      count: this.formGroup.controls['inventoryCount'].value,
      manufacturer: this.formGroup.controls['manufacturer'].value,
    };
    this.dialogRef.close({ data: filterDetails });
  }
}
