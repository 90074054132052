<mat-toolbar class="app-toolbar-fixed">
  <button mat-icon-button (click)="closeDialog()" onclick="this.blur()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <span>Upload LOPA</span>
  <span class="example-spacer"></span>
</mat-toolbar>

<div style="padding: 30px;">
  <mat-stepper linear #stepper>

    <mat-step>
      <ng-template matStepLabel>Choose the LOPA excel sheet to upload</ng-template>
      <div class="centered" *ngIf="!isFleetsShow">
        <ngx-dropzone (change)="onSelect($event)" [multiple]="false"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
          <ngx-dropzone-label>
            <p>Drop a document file here</p>
            <p>Or </p>
            <p>click to browse and add file </p>
          </ngx-dropzone-label>
          <ngx-dropzone-preview *ngFor="let f of file" [removable]="true" (removed)="onRemove(f)">
            <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
          </ngx-dropzone-preview>
        </ngx-dropzone>

        <p style="color:rgb(228, 0, 43);text-align:center;margin-top: 3%;" *ngIf="errorMsg && errorMsg.length > 0">
          {{errorMsg}}</p>
        <mat-progress-bar *ngIf="isShowProgressBar" style="margin-top: 3%;" mode="indeterminate"></mat-progress-bar>
        <div style="text-align:center;margin-top: 3%;" *ngIf="file.length > 0">
          <button [ngClass]="file.length == 0 ? '': 'primary-button'" (click)="uploadFile()"
            mat-raised-button>&nbsp;&nbsp;
            Upload &nbsp;&nbsp;</button>
        </div>
        <p style="text-align:center;margin-top: 5%;"><span style="font-weight: bold;">Note:</span> Import a Microsoft
          Excel file (.xlsx) file in the <a (click)="downloadSampleDoc()" style="padding: 0px;" class="primary-color"
            mat-button>prescribed format</a> to upload the
          LOPA data.</p>
      </div>
    </mat-step>

    <mat-step state="check">
      <ng-template matStepLabel>Select the fleet and subfleets to upload</ng-template>
      <div *ngIf="isFleetsShow" class="centered-fleet">
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
          <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
            <button mat-icon-button disabled></button>
            <mat-checkbox class="checklist-leaf-node" [checked]="checklistSelection.isSelected(node)"
              (change)="todoLeafItemSelectionToggle(node)">{{node.fleet}}</mat-checkbox>
          </mat-tree-node>

          <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.fleet">
              <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </button>
            <mat-checkbox [checked]="descendantsAllSelected(node)" [indeterminate]="descendantsPartiallySelected(node)"
              (change)="todoItemSelectionToggle(node)">
              {{node.fleet}}</mat-checkbox>
          </mat-tree-node>
        </mat-tree>
      </div>

      <div fxLayout="row" style="position: fixed;bottom: 20px;width: 94%;" *ngIf="myStepper?.selectedIndex == 1">
        <div fxFlex="50" style="text-align: start;">
          <button class='primary-button' (click)="goBackToFileUpload()" mat-raised-button>&nbsp;&nbsp;
            Back &nbsp;&nbsp;</button>
        </div>
        <div fxFlex="50" style="text-align: end;">
          <button [disabled]="checklistSelection.selected.length == 0"
            [ngClass]="checklistSelection.selected.length > 0 ? 'primary-button': ''" (click)="goNext()"
            mat-raised-button>&nbsp;&nbsp;
            Next &nbsp;&nbsp;</button>
        </div>
      </div>
    </mat-step>

    <mat-step state="upload">
      <ng-template matStepLabel>Selected fleet and subfleets to upload</ng-template>
      <div class="selected-fleet-div">
        <mat-progress-bar *ngIf="submitProgressBar" mode="indeterminate"></mat-progress-bar>
        <mat-list>
          <mat-list-item *ngFor="let fleet of selectedFleetsList; let i = index" style="height:58px !important">
            <mat-icon class="primary-color material-icons-outlined" mat-list-icon>flight</mat-icon>
            <div mat-line> Fleet - <span style="color:grey">{{fleet.fleet}}</span></div>
            <div mat-line style="margin-top:5px"> Sub Fleets - <span style="color:grey"
                *ngFor="let item of fleet.subfleets;let last = last;"> {{item.fleet}}<span [hidden]="last">,</span>
                &nbsp;</span></div>
            <mat-divider></mat-divider>
          </mat-list-item>
        </mat-list>
      </div>

      <div fxLayout="row" style="position: fixed;bottom: 20px;width: 94%;" *ngIf="myStepper?.selectedIndex == 2">
        <div fxFlex="50" style="text-align: start;">
          <button [ngClass]="submitProgressBar ? '': 'primary-button'" [disabled]="submitProgressBar"
            (click)="goBackToFleetSelect()" mat-raised-button>&nbsp;&nbsp;
            Back &nbsp;&nbsp;</button>
        </div>
        <div fxFlex="50" style="text-align: end;">
          <button [disabled]="selectedFleetsList.length == 0 || submitProgressBar"
            [ngClass]="(selectedFleetsList.length > 0 && !submitProgressBar) ? 'primary-button': ''"
            (click)="uploadToServer()" mat-raised-button>&nbsp;
            Upload &nbsp;</button>
        </div>
      </div>
    </mat-step>

    <ng-template matStepperIcon="check">
      <mat-icon>checklist</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="upload">
      <mat-icon>upload_file</mat-icon>
    </ng-template>
  </mat-stepper>
</div>
