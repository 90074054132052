import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnInit {
  public formGroup: FormGroup;

  constructor(public customerDialogRef: MatDialogRef<CommentComponent>) {
    this.formGroup = new FormGroup({
      comment: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {}

  closeDialog() {
    this.customerDialogRef.close();
  }

  saveComment() {
    this.customerDialogRef.close({
      comment: this.formGroup.controls.comment.value,
    });
  }
}
