import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddInvoiceComponent } from 'src/app/components/add-invoice/add-invoice.component';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { CustomerSelectComponent } from 'src/app/components/customer-select/customer-select.component';
import { ErrorAlertComponent } from 'src/app/components/error alert/error-alert.component';
import { AuthService } from 'src/app/services/auth.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { UtilService } from 'src/app/services/util.service';
import { ActionType, AppConstants } from 'src/app/shared/AppConstants';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: any;
  @ViewChild(MatSort, { static: true }) sort!: any;

  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  public tableColumns: string[] = [
    'INVOICE_NUMBER',
    'INVOICE_DATE',
    'INVOICE_AMOUNT',
    'PART_GROUP_NAME',
    'COMMENTS',
    'Actions'
  ];

  public invoiceDataSource: MatTableDataSource<any>;
  public searchTerm: string;
  public isSearchHasValue: boolean;
  public isShowInvoiceProgressBar: boolean;
  public invoiceLength: number;
  public paginatorLength: number;
  public constants: AppConstants;
  public loggedInUserId: any;
  public subscriptions: Subscription[] = [];
  public timeout: any = null;
  public version: string;
  public release: string;
  public appropriateClass: string = '';
  public events: string[] = [];

  @HostListener('window:resize', ['$event'])
  getScreenHeight(event?: any) {
    if (window.innerHeight <= 700) {
      this.appropriateClass = 'bottomRelative';
    } else {
      this.appropriateClass = 'bottomStick';
    }
  }
  constructor(
    public authService: AuthService,
    private breakpointObserver: BreakpointObserver,
    public utilService: UtilService,
    public dialog: MatDialog,
    public router: Router,
    public invoiceService: InvoiceService
  ) {
    this.getScreenHeight();
    this.constants = new AppConstants();
    this.version = this.constants.APP_RELEASE_NUMBER;
    this.release = this.constants.APP_RELEASE_DATE;
    this.searchTerm = '';
    this.invoiceLength = 0;
    this.paginatorLength = this.constants.INITIAL_PAGINATOR_LIMIT;
    this.isSearchHasValue = false;
    this.isShowInvoiceProgressBar = false;
    this.invoiceDataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    let customerId: any = localStorage.getItem('customerId');
    if (customerId && customerId != null) {
      if (customerId == 1) {
        this.authService.isAFSUser = true;
      } else {
        this.authService.isAFSUser = false;
      }
    }
    this.getInvoices(this.paginatorLength, 0, '', 'INVOICE_DATE', 'desc');
    let user: any = localStorage.getItem('user-details');
    if (user && user != null) {
      user = JSON.parse(user);
      this.loggedInUserId = user.USER_ID;
    }
  }

  // Logout from app
  logout() {
    this.authService.logout();
  }

  // Download users from server
  getInvoices(
    limit: number,
    offset: number,
    searchText?: any,
    sortField?: any,
    sortDirection?: any
  ) {
    this.isShowInvoiceProgressBar = true;
    let usersSubscription = this.invoiceService
      .getInvoices(limit, offset, searchText, sortField, sortDirection)
      .subscribe(
        (response: any) => {
          // Success callback
          if (response.status === 200) {
            if (response.body) {
              // Assign the data to the data source for the table to render
              response?.body?.INVOICES.forEach((invoice: any) => {
                invoice.INVOICE_DATE =  invoice.INVOICE_DATE.replace('Z', '')
              });
              this.invoiceDataSource = new MatTableDataSource(
                response.body.INVOICES
              );
              this.invoiceLength = response.body.INVOICE_COUNT;
            }
            this.isShowInvoiceProgressBar = false;
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug'); localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.isShowInvoiceProgressBar = false;
            this.router.navigate(['dashboard']);
          } else {
            this.isShowInvoiceProgressBar = false;
            this.errorDialog(response.error);
          }
        },
        (error: any) => {
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug'); localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.isShowInvoiceProgressBar = false;
            this.router.navigate(['dashboard']);
          } else {
            this.isShowInvoiceProgressBar = false;
            this.errorDialog(error.message);
          }
        }
      );
    this.subscriptions.push(usersSubscription);
  }

  errorDialog(msg: string) {
    const dialogRef = this.dialog.open(ErrorAlertComponent, {
      width: '400px',
      data: {
        errorMsg: msg,
      },
    });
  }

  sortfields(e: any) {
    let offset = this.paginator._pageIndex * this.paginator._pageSize;
    this.getInvoices(
      this.paginatorLength,
      offset,
      this.searchTerm != null && this.searchTerm != '' ? this.searchTerm : '',
      e.active,
      e.direction
    );
    this.paginator.pageIndex = this.paginator._pageIndex;
  }

  handlePaginator(e: any) {
    this.paginatorLength = e.pageSize;
    let limit = e.pageSize;
    let offset = e.pageIndex * limit;
    this.paginator.pageIndex = e.pageIndex;
    if (this.sort.active) {
      this.getInvoices(
        limit,
        offset,
        this.searchTerm != null && this.searchTerm != '' ? this.searchTerm : '',
        this.sort.active,
        this.sort._direction
      );
    } else {
      this.getInvoices(
        limit,
        offset,
        this.searchTerm != null && this.searchTerm != '' ? this.searchTerm : '', 'INVOICE_DATE', 'desc'
      );
    }
  }

  searchInvoiceFilter(event: any) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if ($this.sort.active) {
        $this.getInvoices(
          $this.paginatorLength,
          0,
          event.target.value,
          $this.sort.active,
          $this.sort._direction
        );
      } else {
        $this.getInvoices($this.paginatorLength, 0, event.target.value, 'INVOICE_DATE', 'desc');
      }
      $this.paginator.pageIndex = 0;
    }, 1000);
  }

  clearSearch() {
    this.cancelOutstandingRequests();
    this.searchTerm = '';
    this.paginator.pageIndex = 0;
    this.getInvoices(this.paginatorLength, 0, '', 'INVOICE_DATE', 'desc');
  }

  cancelOutstandingRequests() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  refreshInvoices() {
    this.searchTerm = '';
    this.cancelOutstandingRequests();
    this.paginator.pageIndex = 0;
    this.paginatorLength = this.constants.INITIAL_PAGINATOR_LIMIT;
    this.getInvoices(this.paginatorLength, 0, '', 'INVOICE_DATE', 'desc');
  }

  addNewInvoice() {
    const dialogRef = this.dialog.open(AddInvoiceComponent, {
      width: '800px',
      data: { mode: ActionType.Add, item: {} },
      panelClass: 'add-user-dialog',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.paginator.pageIndex = 0;
        this.getInvoices(this.paginatorLength, 0, '', 'INVOICE_DATE', 'desc');
      }
    });
  }

  updateInvoice(invoice: any) {
    const dialogRef = this.dialog.open(AddInvoiceComponent, {
      width: '800px',
      data: { mode: ActionType.Edit, item: invoice },
      panelClass: 'modify-user-dialog',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let offset = this.paginator._pageIndex * this.paginator._pageSize;
        this.paginator.pageIndex = this.paginator._pageIndex;

        if (this.sort.active) {
          this.getInvoices(
            this.paginatorLength,
            offset,
            this.searchTerm != null && this.searchTerm != ''
              ? this.searchTerm
              : '',
            this.sort.active,
            this.sort._direction
          );
        } else {
          this.getInvoices(
            this.paginatorLength,
            offset,
            this.searchTerm != null && this.searchTerm != ''
              ? this.searchTerm
              : '', 'INVOICE_DATE', 'desc'
          );
        }
      }
    });
  }

  deactivateInvoice(invoice: any) {
    const dialogRef = this.dialog.open(AlertComponent, {
      width: '400px',
      data: { errorMsg: 'Are you sure you want to delete this invoice?' },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result.data) {
        let body = {
          INVOICE_ID: invoice.INVOICE_ID
        };
        await this.invoiceService
          .deleteInvoice(body)
          .toPromise()
          .then(
            (response: any) => {
              if (response.status === 204) {
                let offset =
                  this.paginator._pageIndex * this.paginator._pageSize;
                this.paginator.pageIndex = this.paginator._pageIndex;
                if (this.sort.active) {
                  this.getInvoices(
                    this.paginatorLength,
                    offset,
                    this.searchTerm != null && this.searchTerm != ''
                      ? this.searchTerm
                      : '',
                    this.sort.active,
                    this.sort._direction
                  );
                } else {
                  this.getInvoices(
                    this.paginatorLength,
                    offset,
                    this.searchTerm != null && this.searchTerm != ''
                      ? this.searchTerm
                      : '', 'INVOICE_DATE', 'desc'
                  );
                }
              } else {
                this.showErrorDialog(response?.message);
              }
            },
            (error) => {
              this.showErrorDialog(error?.message);
            }
          );
      }
    });
  }

  showErrorDialog(msg: string) {
    const dialogRef = this.dialog.open(ErrorAlertComponent, {
      width: '400px',
      data: {
        errorMsg: msg,
      },
    });
  }

  switchCompany() {
    let userTenants: any = localStorage.getItem('userTenants');
    if (userTenants && userTenants != null) {
      userTenants = JSON.parse(userTenants);
      const dialogRef = this.dialog.open(CustomerSelectComponent, {
        panelClass: 'add-customer-dialog',
        width: '550px',
        height: '380px',
        disableClose: false,
        data: { mode: 0, item: userTenants },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.refreshInvoices();
        }
      });
    }
  }

  downloadPdf(row: any) {
    var b64Pdf = row.INVOICE_DOCUMENT;
    const byteArray = new Uint8Array(
      atob(b64Pdf)
        .split('')
        .map((char) => char.charCodeAt(0))
    );
    var file = new Blob([byteArray], {
      type: 'application/pdf',
    });
    var fileURL = URL.createObjectURL(file);
    var link = document.createElement('a');
    link.href = fileURL;
    window.open(fileURL, '_blank');
  }
}
