import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ChoicesService } from 'src/app/services/choices.service';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-manage-sla',
  templateUrl: './manage-sla.component.html',
  styleUrls: ['./manage-sla.component.scss'],
})
export class ManageSlaComponent implements OnInit {
  isShowCategoryProgressBar: boolean = false;
  configuration: any;
  slaList: any[] = [];
  formGroup: FormGroup;
  errorMsg: string = '';

  constructor(
    public choiceService: ChoicesService,
    public configService: ConfigService,
    public manageSlaDialogRef: MatDialogRef<ManageSlaComponent>
  ) {
    this.formGroup = new FormGroup({});
    this.formGroup.valueChanges.subscribe((x) => {
      this.errorMsg = '';
    });
  }

  async ngOnInit(): Promise<void> {
    await this.getCustomization();
  }

  closeDialog(): void {
    this.manageSlaDialogRef.close(false);
  }

  async getCustomization() {
    this.isShowCategoryProgressBar = true;
    await this.choiceService
      .getCustomization()
      .toPromise()
      .then(
        (response: any) => {
          this.isShowCategoryProgressBar = false;
          if (response.status === 200) {
            this.configuration = response?.body;
            if (response?.body?.SLA && response?.body?.SLA?.length > 0) {
              this.slaList = response?.body?.SLA;
              let generalArray: any[] = [];
              let othersArray: any[] = [];
              this.slaList.forEach((element) => {
                if (
                  element.SLA_NAME == 'GENERAL' ||
                  element.SLA_NAME == 'SAFETY'
                ) {
                  generalArray.push(element);
                } else {
                  othersArray.push(element);
                }
              });

              let taskTypes: any[] = response?.body?.CHOICE?.TASK_TYPES;
              const results = taskTypes.filter(
                ({ CHOICE_VALUE: id1 }) =>
                  !othersArray.some(({ SLA_NAME: id2 }) => id2 === id1)
              );
              if (results.length > 0) {
                results.forEach((element) => {
                  othersArray.push({
                    SLA_NAME: element.CHOICE_VALUE,
                    SLA: null,
                  });
                });
              }
              generalArray = generalArray.sort((a, b) =>
                a.SLA_NAME.toLowerCase() > b.SLA_NAME.toLowerCase() ? 1 : -1
              );

              othersArray = othersArray.sort((a, b) =>
                a.SLA_NAME.toLowerCase() > b.SLA_NAME.toLowerCase() ? 1 : -1
              );
              let finalArray = generalArray.concat(othersArray);
              finalArray.forEach((element) => {
                let dCtrl = new FormControl(element.SLA, [Validators.required]);
                this.formGroup.addControl(element.SLA_NAME, dCtrl);
              });
              this.slaList = finalArray;
            }
          }
        },
        (error) => {
          this.isShowCategoryProgressBar = false;
        }
      );
  }

  async saveUser() {
    this.isShowCategoryProgressBar = true;
    let keys: any[] = [];
    Object.keys(this.formGroup.controls).forEach(async function (key) {
      keys.push(key);
    });
    keys.forEach(async (key) => {
      let body: any = {
        SLA_NAME: key,
        SLA: this.formGroup.controls[key].value,
      };
      await this.configService
        .updateSLA(body)
        .toPromise()
        .then(
          (response: any) => {
            this.isShowCategoryProgressBar = false;
            if (response.status != 204) {
              this.errorMsg = response.message;
              return;
            } else {
              this.manageSlaDialogRef.close(true);
            }
          },
          (error) => {
            this.isShowCategoryProgressBar = false;
            this.errorMsg = error.error;
            return;
          }
        );
    });
  }
}
