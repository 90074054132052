import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor() {}

  get customerName() {
    let name = localStorage.getItem('customerName');
    if (name && name != null) {
      return name;
    } else {
      return '';
    }
  }

  // sha256 converted password
  async sha256(password: string) {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(password);

    // hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // convert bytes to hex string
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('');
    return hashHex.toUpperCase();
  }

  dateTimeConversion(utc: string) {
    const utcDateTimeString = utc;
    const userTimeZone = localStorage.getItem('userTimeZone');
    const utcDate = new Date(utcDateTimeString);
    const chicagoFormatter = new Intl.DateTimeFormat('en-US', {
      timeZone: userTimeZone || 'America/Chicago',
      month: 'short',  // 'short' for abbreviated month (e.g., Aug)
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true    // 12-hour format with AM/PM
    });

    // Format the UTC date to the "America/Chicago" time zone
    const chicagoDateParts = chicagoFormatter.formatToParts(utcDate);

    // Build the formatted string manually
    const formatDate = (parts: any[]) => {
      const month = parts.find(part => part.type === 'month').value;
      const day = parts.find(part => part.type === 'day').value;
      const year = parts.find(part => part.type === 'year').value;
      const hour = parts.find(part => part.type === 'hour').value;
      const minute = parts.find(part => part.type === 'minute').value;
      const ampm = parts.find(part => part.type === 'dayPeriod').value;

      return `${month} ${day}, ${year} ${hour}:${minute} ${ampm}`;
    };

    const formattedChicagoDate = formatDate(chicagoDateParts);

    return formattedChicagoDate;
  }

}
