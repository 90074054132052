<!--#region Toolbar -->
<mat-toolbar class="app-toolbar-fixed">
    <span>Service Logs</span>
    <span class="example-spacer"></span>
    <button mat-icon-button (click)="closeDialog()" onclick="this.blur()">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>
<mat-progress-bar *ngIf="isShowProgressBar" mode="indeterminate"></mat-progress-bar>
<!--#endregion -->

<mat-list *ngIf="serviceLogs && serviceLogs?.length > 0" style="padding-top:0px !important;">
    <mat-list-item *ngFor="let log of serviceLogs">
        <mat-icon mat-list-icon class="material-icons-outlined" style="font-size: 26px;"
            [ngStyle]="{'color': log.ACTION | serviceLogIconColorFilter}">{{log.ACTION | serviceLogIconFilter}}
        </mat-icon>
        <p slot="left" style="color: black; opacity: 0.6;min-width: max-content !important;"># {{log.LOG_ID}} </p>
        <h3 mat-line style="color:#00629b"> {{log.LOG_MESSAGE}} </h3>
        <p mat-line style="display: flex;margin-left: 0px !important;">
            <span> Created By: &nbsp;</span>
            <span style="color: black; opacity: 0.6;"> {{log.CREATED_BY_NAME}} </span>
            <span style="margin-left: 15px;"> Created At: &nbsp;</span>
            <span style="color: black; opacity: 0.6;"> {{log.CREATED_AT | date: constants.DATE_FORMAT}} </span>
        </p>
        <mat-divider></mat-divider>
    </mat-list-item>

</mat-list>

<div class="centered" *ngIf="(!serviceLogs || serviceLogs.length == 0) && !isShowProgressBar">
    <h3 style="color:#00629b;">No service logs found / recorded</h3>
</div>