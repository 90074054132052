<div class="icon">
    <mat-icon>outgoing_mail</mat-icon>
</div>
<div class="title">
    <h3>Test Email Functionality</h3>
</div>

<div>
    <mat-form-field appearance="outline" style="width:100%">
        <mat-label>Enter email</mat-label>
        <input matInput placeholder="test@example.com" [formControl]="email" required
            (input)="errorMessage = '';successMessage = ''">
        <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>
</div>

<div style="margin:10px 0px 10px 0px" *ngIf="isShowProgressbar">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<div *ngIf="errorMessage.length > 0 && successMessage.length == 0" style="text-align: center;color: rgb(228, 0, 43);">
    <p>{{errorMessage}}</p>
</div>

<div *ngIf="successMessage.length > 0 && errorMessage.length == 0" style="text-align: center;color: #8bc34a;">
    <p>{{successMessage}}</p>
</div>


<div mat-dialog-actions align="center">
    <button mat-raised-button (click)="closeDialog()" onclick="this.blur()">Cancel</button> &nbsp;
    <button mat-raised-button (click)="testEmail()" [disabled]="email.invalid"
        [ngClass]="email.invalid ? '' : 'primary-button'">&nbsp;Send&nbsp;</button>
</div>