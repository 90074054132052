<mat-toolbar class="app-toolbar">
    <span>Enter new category name</span>
    <span class="example-spacer"></span>
    <button mat-icon-button onclick="this.blur()" (click)="onNoClick()">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>

<div [formGroup]="formGroup" style="margin:15px">
    <mat-form-field appearance="outline" style="width:100%;margin-top:10px;font-size: small;">
        <mat-label>Category Name</mat-label>
        <input matInput placeholder="Enter category name" required formControlName="name">
    </mat-form-field>

    <div *ngIf="errorMsg.length> 0" style="text-align: center;color: rgb(228, 0, 43)">
        <p>{{errorMsg}}</p>
    </div>
</div>
<div style="text-align: center;margin-bottom:20px">
    <button [disabled]="!formGroup.valid" [ngClass]="formGroup.valid ? 'success-button' : ''" mat-raised-button
        (click)="add()">&nbsp;&nbsp; Add &nbsp;&nbsp;</button>
</div>