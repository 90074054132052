import { SelectionModel } from '@angular/cdk/collections';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomerSelectComponent } from 'src/app/components/customer-select/customer-select.component';
import { ErrorAlertComponent } from 'src/app/components/error alert/error-alert.component';
import { LopaDetailsComponent } from 'src/app/components/lopa-details/lopa-details.component';
import { SuccessDialogComponent } from 'src/app/components/success-dialog/success-dialog.component';
import { UploadLopaFileComponent } from 'src/app/components/upload-lopa-file/upload-lopa-file.component';
import { UploadPdfComponent } from 'src/app/components/upload-pdf/upload-pdf.component';
import { AuthService } from 'src/app/services/auth.service';
import { LopaService } from 'src/app/services/lopa.service';
import { UtilService } from 'src/app/services/util.service';
import { AppConstants } from 'src/app/shared/AppConstants';

@Component({
  selector: 'app-lopa',
  templateUrl: './lopa.component.html',
  styleUrls: ['./lopa.component.scss'],
})
export class LopaComponent implements OnInit {
  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  @ViewChild(MatPaginator, { static: true }) paginator!: any;
  @ViewChild(MatSort, { static: true }) sort!: any;
  public selection = new SelectionModel<any>(true, []);

  public tableColumns: string[] = [
    'select',
    'LOPA_ID',
    'FLEET',
    'SUB_FLEET',
    'UPDATED_AT',
    'Actions',
  ];

  public constants: AppConstants;
  public version: string;
  public release: string;
  public isSearchHasValue: boolean = false;
  public searchTerm: string = '';
  public timeout: any = null;
  public isShowFleetsProgressBar: boolean = false;
  public paginatorLength: number;
  public fleetsLength: number = 0;
  public fleetsDataSource: MatTableDataSource<any>;
  public appropriateClass:string = '';
  public events: string[] = [];
  
  @HostListener('window:resize', ['$event'])
  getScreenHeight(event?: any){
    if(window.innerHeight<=700){
      this.appropriateClass = 'bottomRelative';
    }else{
      this.appropriateClass = 'bottomStick';
    }
  }
  constructor(
    public authService: AuthService,
    public utilService: UtilService,
    private breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,
    public lopaService: LopaService,
    public router: Router
  ) {
    this.getScreenHeight();
    this.constants = new AppConstants();
    this.version = this.constants.APP_RELEASE_NUMBER;
    this.release = this.constants.APP_RELEASE_DATE;
    this.paginatorLength = this.constants.INITIAL_PAGINATOR_LIMIT;
    this.fleetsDataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.getLopaList(this.constants.INITIAL_PAGINATOR_LIMIT, 0);
  }

  // Logout from app
  logout() {
    this.authService.logout();
  }

  addLopaFile() {
    this.selection.clear();
    const dialogRef = this.dialog.open(UploadLopaFileComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: 'add-user-dialog',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getLopaList(this.constants.INITIAL_PAGINATOR_LIMIT, 0);
      }
    });
  }

  getLopaList(
    limit: number,
    offset: number,
    searchText?: any,
    sortField?: any,
    sortDirection?: any
  ) {
    this.selection.clear();
    this.isShowFleetsProgressBar = true;
    this.lopaService
      .getLopaList(limit, offset, searchText, sortField, sortDirection)
      .subscribe(
        (response: any) => {
          this.isShowFleetsProgressBar = false;
          // Success callback
          if (response.status === 200) {
            if (response.body) {
              if (response.body.FLEETS && response.body.FLEETS.length > 0) {
                this.fleetsDataSource = new MatTableDataSource(
                  response.body.FLEETS
                );
                this.fleetsLength = response.body.FLEETS_COUNT;
              } else {
                this.fleetsDataSource = new MatTableDataSource();
                this.fleetsLength = 0;
              }
            }
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.router.navigate(['dashboard']);
          } else {
            this.showErrorDialog(response.error);
          }
        },
        (error) => {
          this.isShowFleetsProgressBar = false;
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.router.navigate(['dashboard']);
          } else {
            this.showErrorDialog(error.message);
          }
        }
      );
  }

  clearSearch() {
    this.searchTerm = '';
    this.getLopaList(this.constants.INITIAL_PAGINATOR_LIMIT, 0, '', '', '');
    this.paginator.pageIndex = 0;
  }

  searchFleetsFilter(event: any) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      $this.getLopaList(
        $this.constants.INITIAL_PAGINATOR_LIMIT,
        0,
        event.target.value,
        '',
        ''
      );
      $this.paginator.pageIndex = 0;
    }, 1000);
  }

  handlePaginator(e: any) {
    this.paginatorLength = e.pageSize;
    let limit = e.pageSize;
    let offset = e.pageIndex * limit;
    this.paginator.pageIndex = e.pageIndex;
    if (this.sort.active) {
      this.getLopaList(
        limit,
        offset,
        '',
        this.sort.active,
        this.sort._direction
      );
    } else {
      this.getLopaList(limit, offset, '', '', '');
    }
  }

  sortfields(e: any) {
    let offset = this.paginator._pageIndex * this.paginator._pageSize;
    this.getLopaList(this.paginatorLength, offset, '', e.active, e.direction);
    this.paginator.pageIndex = this.paginator._pageIndex;
  }

  showErrorDialog(msg: string) {
    const dialogRef = this.dialog.open(ErrorAlertComponent, {
      width: '400px',
      data: {
        errorMsg: msg,
      },
    });
  }

  downloadFiles(lopa: any) {
    this.isShowFleetsProgressBar = true;
    this.lopaService.downloadLopaDocs(lopa?.LOPA_ID).subscribe(
      (response: any) => {
        this.isShowFleetsProgressBar = false;
        // Success callback
        if (response.status === 200) {
          this.selection.clear();
          if (response?.body) {
            if (response?.body?.length > 0) {
              var b64Pdf = response?.body;
              const byteArray = new Uint8Array(
                atob(b64Pdf)
                  .split('')
                  .map((char) => char.charCodeAt(0))
              );
              var file = new Blob([byteArray], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });
              var fileURL = URL.createObjectURL(file);
              var link = document.createElement('a');
              link.href = fileURL;
              link.download = `${lopa?.LOPA_ID}_${lopa.FLEET}.xlsx`;
              link.click();
            } else {
              this.showErrorDialog('Not a valid file.');
            }
          }
        } else if (response.status === 401) {
          // Redirect to login for unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        } else if (response.status === 403) {
          this.showErrorDialog(response.error);
        } else {
          this.showErrorDialog(response.error);
        }
      },
      (error) => {
        this.isShowFleetsProgressBar = false;
        if (error.status === 401) {
          // Redirect to login for unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        } else if (error.status === 403) {
          this.showErrorDialog(error.error);
        } else {
          this.showErrorDialog(error.error);
        }
      }
    );
  }

  async uploadPdf(lopa: any) {
    this.selection.clear();
    const dialogRef = this.dialog.open(UploadPdfComponent, {
      width: '600px',
      data: { item: lopa },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const timeout = 3000;
        const dialogRef = this.dialog.open(SuccessDialogComponent, {
          width: '400px',
          data: { msg: 'Document Uploaded Successfully' },
        });
        dialogRef.afterOpened().subscribe((_) => {
          setTimeout(() => {
            dialogRef.close();
          }, timeout);
        });
      }
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.fleetsLength;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.fleetsDataSource.data.forEach((row) => this.selection.select(row));
  }

  downloadBulkExcelSheets() {
    if (this.selection?.selected?.length > 0) {
      let str = '';
      for (let l = 0; l < this.selection.selected.length; l++) {
        if (l == 0) {
          str = `${this.selection.selected[l].LOPA_ID}`;
        } else {
          str = `${str},${this.selection.selected[l].LOPA_ID}`;
        }
      }
      this.downloadFiles({ LOPA_ID: str });
    }
  }

  gotoLopaDetails(item: any) {
    const dialogRef = this.dialog.open(LopaDetailsComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      disableClose: true,
      data: { item: item },
      panelClass: 'add-customer-dialog',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  switchCompany() {
    let userTenants: any = localStorage.getItem('userTenants');
    if (userTenants && userTenants != null) {
      userTenants = JSON.parse(userTenants);
      const dialogRef = this.dialog.open(CustomerSelectComponent, {
        panelClass: 'add-customer-dialog',
        width: '550px',
        height: '380px',
        disableClose: false,
        data: { mode: 0, item: userTenants },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.getLopaList(this.constants.INITIAL_PAGINATOR_LIMIT, 0);
        }
      });
    }
  }

  returnDate(date: any) {
    return this.utilService.dateTimeConversion(date);
  }
}
