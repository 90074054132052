import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChoicesService } from 'src/app/services/choices.service';

export interface DialogData {
  item: any;
}

@Component({
  selector: 'app-add-new-extension-field',
  templateUrl: './add-new-extension-field.component.html',
  styleUrls: ['./add-new-extension-field.component.scss'],
})
export class AddNewExtensionFieldComponent implements OnInit {
  public formGroup: FormGroup;
  public typeList: any[] = [];
  public choiceTypeList: any[] = [];
  public isShowCategoryProgressBar: boolean = false;
  public choiceList: any[] = [];
  public errorMsg: string = '';

  constructor(
    public dialogRef: MatDialogRef<AddNewExtensionFieldComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public choiceService: ChoicesService
  ) {
    this.formGroup = new FormGroup({
      name: new FormControl('', [Validators.required]),
      type: new FormControl('', [Validators.required]),
      choiceType: new FormControl('', [Validators.required]),
      isMandatory: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.getCustomization();
    this.formGroup.controls.isMandatory.setValue(true);
    this.typeList = [
      {
        name: 'Text',
        value: 'TEXT',
      },
      {
        name: 'Choice',
        value: 'CHOICE',
      },
      {
        name: 'Text Area',
        value: 'TEXTAREA',
      },
      {
        name: 'Toggle',
        value: 'TOGGLE',
      },
      {
        name: 'Number',
        value: 'NUMBER',
      },
    ];

    this.formGroup.valueChanges.subscribe((x) => {
      this.errorMsg = '';
    });
  }

  close() {
    this.dialogRef.close(false);
  }

  add() {
    if (this.data.item && this.data.item.length > 0) {
      let tempList = this.data.item.filter(
        (e: { FIELD_NAME: any }) =>
          e.FIELD_NAME?.toLowerCase()?.trim() ===
          this.formGroup.controls.name.value?.toLowerCase()?.trim()
      );
      if (tempList && tempList.length > 0) {
        this.errorMsg =
          'Field name already exists, Please choose another name.';
      } else {
        let obj: any = {
          FIELD_CONTROL: this.formGroup.controls.type.value,
          FIELD_MANDATORY:
            this.formGroup.controls.isMandatory.value == true ? 'Y' : 'N',
          FIELD_NAME: this.formGroup.controls.name.value,
          FIELD_TECH_NAME: this.formGroup.controls.name.value?.replace(
            /\s+/g,
            ''
          ),
        };

        if (this.formGroup.controls.type.value == 'CHOICE') {
          obj['FIELD_CHOICE_TYPE'] = this.formGroup.controls.choiceType.value;
        }
        this.dialogRef.close(obj);
      }
    } else {
      let obj: any = {
        FIELD_CONTROL: this.formGroup.controls.type.value,
        FIELD_MANDATORY:
          this.formGroup.controls.isMandatory.value == true ? 'Y' : 'N',
        FIELD_NAME: this.formGroup.controls.name.value,
        FIELD_TECH_NAME: this.formGroup.controls.name.value?.replace(
          /\s+/g,
          ''
        ),
      };

      if (this.formGroup.controls.type.value == 'CHOICE') {
        obj['FIELD_CHOICE_TYPE'] = this.formGroup.controls.choiceType.value;
      }
      this.dialogRef.close(obj);
    }
  }

  async getCustomization() {
    this.isShowCategoryProgressBar = true;
    await this.choiceService
      .getCustomization()
      .toPromise()
      .then(
        (response: any) => {
          this.isShowCategoryProgressBar = false;
          if (response.status === 200) {
            if (response?.body) {
              let tempChoiceKeys = Object.keys(response?.body?.CHOICE);
              this.choiceList = tempChoiceKeys;
              this.formGroup.controls.choiceType.setValue(this.choiceList[0]);
            } else {
              this.choiceList = [];
            }
          }
        },
        (error) => {
          if (error.status === 401) {
          }
        }
      );
  }
}
