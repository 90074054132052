import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '../app.config';
import { AppConstants } from '../shared/AppConstants';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  public constants: AppConstants;
  public extensionFields: any = null;

  constructor(
    private appConfigService: AppConfigService,
    private http: HttpClient
  ) {
    this.constants = new AppConstants();
  }

  // Download Locations
  getLocations() {
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(
      url + this.constants.GET_LOCATIONS_HEIRARCHY_URL,
      httpOptions
    );
  }

  // Download Locations
  getLocationById(id: string) {
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(
      url + this.constants.GET_LOACTIONS_URL + `/${id}`,
      httpOptions
    );
  }

  getLocationCategories() {
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(
      url + this.constants.GET_LOCATION_CATEGORIES_URL,
      httpOptions
    );
  }

  getEquipmentCategories() {
    let tempParams: any = {
      limit: 999,
      offset: 0,
    };
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      params: tempParams,
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(
      url + this.constants.GET_EQUIMENT_CATEGORIES_URL,
      httpOptions
    );
  }

  // Add Location
  addLocation(body: any) {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.post<any>(
      url + this.constants.GET_LOACTIONS_URL,
      body,
      httpOptions
    );
  }

  updateLocationDetails(body: any) {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.put<any>(
      url + this.constants.GET_LOACTIONS_URL,
      body,
      httpOptions
    );
  }

  // Add Location Category
  addLocationCategory(body: any) {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.post<any>(
      url + this.constants.GET_LOCATION_CATEGORIES_URL,
      body,
      httpOptions
    );
  }

  updateLocationCategory(body: any) {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.put<any>(
      url + this.constants.GET_LOCATION_CATEGORIES_URL,
      body,
      httpOptions
    );
  }

  getEquipmentsByLocationId(
    id: any,
    status?: any,
    limit?: number,
    offset?: number,
    searchText?: any,
    sortField?: any,
    sortDirection?: any,
    categoryId?:any
  ) {
    let tempParams: any = {
      locationId: id,
      limit: limit,
      offset: offset,
    };
    if (status) {
      tempParams.status = status;
    }
    if (searchText) {
      tempParams.search = searchText;
    }
    if (sortField) {
      tempParams.sortingField = sortField;
    }
    if (sortDirection) {
      tempParams.sortingOrder = sortDirection;
    }
    if (categoryId) {
      tempParams.categoryId = categoryId;
    }
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      params: tempParams,
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(
      url + this.constants.GET_CATEGORIES_BY_LOCATION_ID_URL,
      httpOptions
    );
  }

  // Add Equipmen Category
  addEquipmentCategory(body: any) {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.post<any>(
      url + this.constants.UPDATE_EQUIPMENT_CATEGORIES_URL,
      body,
      httpOptions
    );
  }

  updateEquipmenCategory(body: any) {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.put<any>(
      url + this.constants.UPDATE_EQUIPMENT_CATEGORIES_URL,
      body,
      httpOptions
    );
  }

  addEquipmentByLocation(body: any) {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.post<any>(
      url + this.constants.GET_SERVICE_REQUESTS_EQUIPMENT_DETAILS_URL,
      body,
      httpOptions
    );
  }

  updateEquipmentByLocation(body: any) {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.put<any>(
      url + this.constants.GET_SERVICE_REQUESTS_EQUIPMENT_DETAILS_URL,
      body,
      httpOptions
    );
  }
}
