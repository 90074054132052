import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from 'src/app/app.config';
import { CustomerSelectComponent } from 'src/app/components/customer-select/customer-select.component';
import { ErrorAlertComponent } from 'src/app/components/error alert/error-alert.component';
import { AuthService } from 'src/app/services/auth.service';
import { ChoicesService } from 'src/app/services/choices.service';
import { UtilService } from 'src/app/services/util.service';
import { AppConstants } from 'src/app/shared/AppConstants';

@Component({
  selector: 'app-report-dashboard',
  templateUrl: './report-dashboard.component.html',
  styleUrls: ['./report-dashboard.component.scss']
})
export class ReportDashboardComponent implements OnInit {
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  public version: string;
  public release: string;
  public constants: AppConstants;
  public userCompanyList: any[] = [];
  public selectedCustomer: any;
  public token: any;
  public url: string = '';
  public superSetUrl: any;
  public dashboardSlug: any;
  public isShowProgressBar: boolean = false;
  public appropriateClass:string = '';
  public events: string[] = [];
  
  @HostListener('window:resize', ['$event'])
  getScreenHeight(event?: any){
    if(window.innerHeight<=700){
      this.appropriateClass = 'bottomRelative';
    }else{
      this.appropriateClass = 'bottomStick';
    }
  }
  
  constructor(
    public authService: AuthService,
    private breakpointObserver: BreakpointObserver,
    public utilService: UtilService,
    private sanitizer: DomSanitizer,
    private appConfigService: AppConfigService,
    public choiceService: ChoicesService,
    public router: Router,
    public dialog: MatDialog
  ) {
    this.getScreenHeight();
    this.constants = new AppConstants();
    this.version = this.constants.APP_RELEASE_NUMBER;
    this.release = this.constants.APP_RELEASE_DATE;
  }

  ngOnInit(): void {
    this.superSetUrl = this.appConfigService.superSetUrl;
    this.token = localStorage.getItem('token');
    let user: any = localStorage.getItem('user-details');
    if (user && user != null) {
      user = JSON.parse(user);
      if (user?.CUSTOMER_TYPE == "AIRPORT") {
        this.dashboardSlug = 'airport-report';
      } else if (user?.CUSTOMER_TYPE == "AIRLINE") {
        this.dashboardSlug = 'airline-report';
      }
    }

    /**
     * Send the timezone to update the dashboards based on the user's timezone.
     */
    let currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    this.url = `${this.superSetUrl}/login/?apitoken=${this.token}&redirect=${this.dashboardSlug}&standalone=2&expand_filters=1&localtz=${currentTimeZone}`;
    console.log(this.url)
    this.selectedCustomer = localStorage.getItem('customerId');
    if (this.selectedCustomer && this.selectedCustomer != null) {
      this.selectedCustomer == 1
        ? (this.authService.isAFSUser = true)
        : (this.authService.isAFSUser = false);
    }
  }

  showErrorDialog(msg: string) {
    this.dialog.open(ErrorAlertComponent, {
      width: '400px',
      data: {
        errorMsg: msg,
      },
    });
  }

  logout() {
    this.authService.logout();
  }

  switchCompany() {
    let userTenants: any = localStorage.getItem('userTenants');
    if (userTenants && userTenants != null) {
      userTenants = JSON.parse(userTenants);
      const dialogRef = this.dialog.open(CustomerSelectComponent, {
        panelClass: 'add-customer-dialog',
        width: '550px',
        height: '380px',
        disableClose: false,
        data: { mode: 0, item: userTenants },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.superSetUrl = this.appConfigService.superSetUrl;
          this.token = localStorage.getItem('token');
          let user: any = localStorage.getItem('user-details');
          if (user && user != null) {
            user = JSON.parse(user);
            if (user?.CUSTOMER_TYPE == "AIRPORT") {
              this.dashboardSlug = 'airport-report';
            } else if (user?.CUSTOMER_TYPE == "AIRLINE") {
              this.dashboardSlug = 'airline-report';
            }
          }
      
          /**
           * Send the timezone to update the dashboards based on the user's timezone.
           */
          let currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      
          this.url = `${this.superSetUrl}/login/?apitoken=${this.token}&redirect=${this.dashboardSlug}&standalone=2&expand_filters=1&localtz=${currentTimeZone}`;
          console.log(this.url)
          this.selectedCustomer = localStorage.getItem('customerId');
          if (this.selectedCustomer && this.selectedCustomer != null) {
            this.selectedCustomer == 1
              ? (this.authService.isAFSUser = true)
              : (this.authService.isAFSUser = false);
          }
        }
      });
    }
  }
}
