<!--#region Toolbar -->
<mat-toolbar class="app-toolbar">
    <span>{{title}}</span>
    <span class="example-spacer"></span>
    <button mat-icon-button (click)="closeDialog()" onclick="this.blur()">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>
<!--#endregion -->
<mat-progress-bar *ngIf="isShowProgressBar" mode="indeterminate"></mat-progress-bar>
<form style="padding: 20px;font-size: 14.5px !important;" [formGroup]="formGroup" (ngSubmit)="saveUser()">


    <mat-form-field appearance="outline">
        <mat-label>Invoice Number</mat-label>
        <input matInput type="text" required formControlName="INVOICE_NUMBER">
    </mat-form-field>

    <table style="width:100%" cellspacing="0">
        <tr>
            <td style="padding-right: 10px;">
                <mat-form-field appearance="outline">
                    <mat-label>Invoice Date</mat-label>
                    <input (dateChange)="onDateChange($event)" (click)="dp3.open()" matInput [matDatepicker]="dp3" required
                        formControlName="INVOICE_DATE" [readonly]="true">
                    <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                    <mat-datepicker disabled="false" #dp3></mat-datepicker>
                </mat-form-field>
            </td>
            <td style="padding-left: 10px;">
                <mat-form-field appearance="outline">
                    <mat-label>Invoice Amount (in $)</mat-label>
                    <input matInput type="number" required formControlName="INVOICE_AMOUNT">
                </mat-form-field>
            </td>
        </tr>
    </table>

    <mat-form-field appearance="outline">
        <mat-label>Budget Group</mat-label>
        <mat-select formControlName="PART_GROUP_NAME" placeholder="Group Name" disableOptionCentering>
            <mat-option value="">Select</mat-option>
            <mat-option *ngFor="let group of groupList" [value]="group.PART_GROUP_NAME">
                {{group.PART_GROUP_NAME}}</mat-option>
        </mat-select>
    </mat-form-field>


    <mat-form-field appearance="outline">
        <mat-label>Comments</mat-label>
        <textarea matInput type="text" formControlName="COMMENTS"></textarea>
    </mat-form-field>


    <div class="centered">
        <mat-label>PDF attachment of the invoice</mat-label>
        <ngx-dropzone (change)="onSelect($event)" [multiple]="false" style="margin-top: 10px;border: 1px solid #dbdbdb;"
            accept="application/pdf">
            <ngx-dropzone-label>
                <p>Drop a pdf document file here</p>
                <p>Or </p>
                <p>click to browse and add a pdf file </p>
            </ngx-dropzone-label>
            <ngx-dropzone-preview *ngFor="let f of file" [removable]="true" (removed)="onRemove(f)">
                <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
            </ngx-dropzone-preview>
        </ngx-dropzone>
    </div>

    <div *ngIf="errorMsg.length> 0" style="text-align: center;color: rgb(228, 0, 43)">
        <p>{{errorMsg}}</p>
    </div>

    <div style="text-align:center;padding-top:10px">
        <button mat-raised-button [ngClass]="formGroup.valid ? 'primary-button' : ''" [disabled]="!formGroup.valid">
            <span *ngIf="data.mode == 1"> Create </span>
            <span *ngIf="data.mode == 2"> Update </span>
        </button>
    </div>
</form>