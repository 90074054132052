<!--#region Toolbar -->
<mat-toolbar class="app-toolbar">
    <button mat-icon-button (click)="closeDialog()" onclick="this.blur()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <span>{{title}}</span>
    <span class="example-spacer"></span>
</mat-toolbar>
<!--#endregion -->
<mat-progress-bar *ngIf="isShowProgressBar" mode="indeterminate"></mat-progress-bar>


<div class="container">
    <div fxLayout="row" class="div-row">
        <div [fxFlex]="(formGroup.controls.BUDGET_GROUP.valid || data.mode == 2) ? 100: 100" class="part-div"
            style="display:flex">
            <div [fxFlex]="data.mode == 2 ? 25 : '25'">
                <p class="title-p-tag">Details</p>
                <form [formGroup]="formGroup">
                    <div fxLayout="row">
                        <div [fxFlex]="50">
                            <mat-form-field appearance="outline">
                                <mat-label>Order Number</mat-label>
                                <input matInput type="text" required formControlName="ORDER_NUMBER">
                            </mat-form-field>
                        </div>
                        <div [fxFlex]="48" style="margin-left: 10px;">
                            <mat-form-field appearance="outline">
                                <mat-label>Shipping Cost (in $)</mat-label>
                                <input matInput type="number" required formControlName="SHIPPING_COST">
                            </mat-form-field>
                        </div>
                    </div>

                    <mat-form-field appearance="outline">
                        <mat-label>Handling Fees (in %)</mat-label>
                        <input matInput type="number" min="0" max="100" required formControlName="MISC_COST">
                        <mat-icon style="cursor: pointer;margin-right: 8px;" matSuffix matTooltipPosition="above"
                            matTooltip="Percentage fee out of order amount + shipping cost"
                            class="material-icons-outlined">info</mat-icon>
                        <mat-error
                            *ngIf="formGroup.controls.MISC_COST.invalid && (formGroup.controls.MISC_COST.dirty || formGroup.controls.MISC_COST.touched)">
                            <span *ngIf="formGroup.controls.MISC_COST.errors?.required">Fees is required.</span>
                            <span
                                *ngIf="formGroup.controls.MISC_COST.errors?.min || formGroup.controls.MISC_COST.errors?.max">Fees
                                must be between 0 and 100.</span>
                        </mat-error>
                    </mat-form-field>

                    <div fxLayout="row">
                        <div [fxFlex]="50">
                            <mat-form-field appearance="outline">
                                <mat-label>Order Date</mat-label>
                                <input (dateChange)="onOrderDateChange($event)" (click)="dp3.open()" matInput
                                    [matDatepicker]="dp3" required formControlName="ORDER_DATE" [readonly]="true">
                                <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                                <mat-datepicker disabled="false" #dp3></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div [fxFlex]="48" style="margin-left: 10px;">
                            <mat-form-field appearance="outline">
                                <mat-label>Ship Date</mat-label>
                                <input matInput [matDatepicker]="dp4" required (click)="dp4.open()"
                                    formControlName="SHIP_DATE" [readonly]="true">
                                <mat-datepicker-toggle matSuffix [for]="dp4"></mat-datepicker-toggle>
                                <mat-datepicker disabled="false" #dp4></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>

                    <mat-form-field appearance="outline">
                        <mat-label>Manufacturer</mat-label>
                        <mat-select disableOptionCentering formControlName="MANUFACTURER" required
                            placeholder="Manufacturer">
                            <mat-option [value]="item" *ngFor="let item of manufacturerList">
                                {{item}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Budget Group</mat-label>
                        <mat-select formControlName="BUDGET_GROUP" (selectionChange)="groupChange($event.value)"
                            required placeholder="Group Name" disableOptionCentering>
                            <mat-option *ngFor="let group of groupList" [value]="group.PART_GROUP_NAME">
                                {{group.PART_GROUP_NAME}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div class="centered" style="margin-top: 10px">
                        <mat-label>Order PDF</mat-label>
                        <ngx-dropzone (change)="onSelect($event)" [multiple]="false"
                            style="margin-top: 10px;border: 1px solid #dbdbdb;" accept="application/pdf">
                            <ngx-dropzone-label>
                                <p>Drop a pdf document file here</p>
                                <p>Or </p>
                                <p>click to browse and add a pdf file </p>
                            </ngx-dropzone-label>
                            <ngx-dropzone-preview style="max-width: unset !important;" *ngFor="let f of file"
                                [removable]="true" (removed)="onRemove(f)">
                                <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
                            </ngx-dropzone-preview>
                        </ngx-dropzone>
                    </div>
                </form>
            </div>
            <div fxFlex="75" class="table-div" *ngIf="formGroup.controls.BUDGET_GROUP.valid ||  data.mode == 2">
                <div id="menu"></div>
                <div style="padding: 0px 0px 0px 30px;width: 100%;">
                    <p class="title-p-tag">Parts</p>
                    <div class="mat-elevation-z4" style="max-height:84%;overflow-y: auto;">
                        <mat-progress-bar *ngIf="isShowPartsProgressBar" class="primary-color" mode="indeterminate">
                        </mat-progress-bar>
                        <mat-toolbar class="container-toolbar">
                            <mat-toolbar-row style="padding-left: 0px;">
                                <button mat-button color="primary" onclick="this.blur()"
                                    [ngClass]="data.mode == 2 ? '' : 'primary-color'" (click)="addNewPart()"
                                    [disabled]="data.mode == 2">
                                    <mat-icon
                                        [ngClass]="data.mode == 2 ? 'material-icons-outlined' : 'primary-color material-icons-outlined'"
                                        style="font-size: 20px;">
                                        add</mat-icon>
                                    Add More
                                </button>
                                <span class="example-spacer"></span>
                            </mat-toolbar-row>
                        </mat-toolbar>

                        <table mat-table [dataSource]="partDataSource" matSort>

                            <ng-container matColumnDef="No">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> No </th>
                                <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="PART_ID">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Part </th>
                                <td mat-cell *matCellDef="let element; let i = index;">
                                    <mat-form-field appearance="outline" style="margin-top: 5px !important">
                                        <mat-select placeholder="Select Part" #singleSelect [disabled]="data.mode == 2"
                                            disableOptionCentering [(ngModel)]="element.PART_ID">
                                            <mat-option style="position: relative !important;">
                                                <ngx-mat-select-search placeholderLabel="Search"
                                                    (ngModelChange)="partfilter($event, element.PART_lIST,i )"
                                                    [ngModelOptions]="{standalone: true}" ngModel
                                                    noEntriesFoundLabel="No matches found"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let part of element.PART_lIST"
                                                [disabled]="disablePart(part.PART_ID)" [value]="part.PART_ID">
                                                {{part.NAME}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="QUANTITY">
                                <th mat-header-cell *matHeaderCellDef> Quantity
                                </th>
                                <td mat-cell *matCellDef="let element" style="text-align: right;">
                                    <div class="text">
                                        <input matInput type="number" [(ngModel)]="element.QUANTITY"
                                            [disabled]="data.mode == 2" (ngModelChange)="getTotalCost()"
                                            [ngModelOptions]="{standalone: true}">
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="UNIT_PRICE">
                                <th mat-header-cell *matHeaderCellDef> Unit Price
                                </th>
                                <td mat-cell *matCellDef="let element" style="text-align: right;">
                                    <div class="text">
                                        <input matInput type="number" [(ngModel)]="element.UNIT_PRICE"
                                            [disabled]="data.mode == 2" (ngModelChange)="getTotalCost()"
                                            [ngModelOptions]="{standalone: true}">
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="EXTENDED_PRICE">
                                <th mat-header-cell *matHeaderCellDef> Extended Price
                                </th>
                                <td mat-cell *matCellDef="let element" style="text-align: right;">
                                    <div class="text">
                                        <input matInput type="number"
                                            [value]="(element.QUANTITY != null && element.UNIT_PRICE != null) ? getExtendTotal(element.QUANTITY * element.UNIT_PRICE) : null"
                                            [disabled]="true">
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="Actions">
                                <th mat-header-cell *matHeaderCellDef style="padding-left: 10px;"> Action </th>
                                <td mat-cell *matCellDef="let row; let i = index;">
                                    <div class="button-row">
                                        <button mat-icon-button matTooltip="Delete" (click)="deletePart(i)"
                                            [disabled]="data.mode == 2">
                                            <mat-icon [ngClass]="data.mode == 2 ? '' : 'warning-color'">
                                                delete_outline</mat-icon>
                                        </button>
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="total">
                                <td mat-footer-cell *matFooterCellDef colspan="12">
                                    <div style="text-align:right;margin-right:9%;font-weight: bold;"
                                        class="warning-color">
                                        <span style="margin-right: 15px;font-weight: bold;">Total Cost (Including
                                            shipping cost + handling fees)</span>
                                        ${{totalCost | number : '1.2-2' }}
                                    </div>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="partColumns;"></tr>
                            <tr mat-row *matRowDef="let row; columns: partColumns;">
                            </tr>
                            <tr mat-footer-row *matFooterRowDef="['total']; sticky: true"
                                class="example-second-footer-row"></tr>
                        </table>
                    </div>
                </div>
            </div>

            <div *ngIf="formGroup.controls.BUDGET_GROUP.valid || data.mode == 2"
                [ngClass]="(formGroup.controls.BUDGET_GROUP.valid || data.mode == 2) ? 'button-large-div' : 'button-small-div'">
                <button mat-raised-button [ngClass]="(formGroup.valid) ? 'primary-button' : ''" (click)="saveOrder()"
                    [disabled]="!formGroup.valid">
                    <span *ngIf="data.mode == 1"> Create </span>
                    <span *ngIf="data.mode == 2"> Update </span>
                </button>
            </div>

            <div *ngIf="errorMsg.length> 0"
                style="color: rgb(228, 0, 43);position: absolute;bottom: 30px;width: 70%;text-align: left;">
                <p>{{errorMsg}}</p>
            </div>
        </div>
    </div>
</div>