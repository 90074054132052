import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { BudgetService } from 'src/app/services/budget.service';
import { PartService } from 'src/app/services/part.service';
import { ActionType } from 'src/app/shared/AppConstants';
import { ErrorAlertComponent } from '../error alert/error-alert.component';

export interface DialogData {
  mode: number;
  item: any;
  selectedYear: any;
}

@Component({
  selector: 'app-budget-details',
  templateUrl: './budget-details.component.html',
  styleUrls: ['./budget-details.component.scss'],
})
export class BudgetDetailsComponent implements OnInit {
  @ViewChild('picker') picker: any;
  @ViewChild(MatPaginator, { static: false }) paginator: any;
  @ViewChild(MatSort, { static: false }) sort: any;
  public timeout: any = null;
  public title: string = '';
  public isShowProgressBar: boolean = false;
  public errorMsg: string = '';
  public paginatorLength: number = 5;
  public partsLength = 0;
  public FormGroup: FormGroup = this._formBuilder.group({});
  public partColumns: string[] = [
    'select',
    'PART_ID',
    'NAME',
    'DESCRIPTION',
    'MANUFACTURER',
    'MFG_PART_NUMBER',
    'MFG_PART_NUMBER_2',
    'MFG_PART_NUMBER_3',
  ];
  partDataSource = new MatTableDataSource<any>([]);
  partsSelection = new SelectionModel<any>(true, []);

  constructor(
    private _formBuilder: FormBuilder,
    public router: Router,
    public dialog: MatDialog,
    public authService: AuthService,
    public budgetDialogRef: MatDialogRef<BudgetDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public partService: PartService,
    public budgetService: BudgetService
  ) {
    this.title =
      this.data.mode == ActionType.Add ? 'Create New Budget' : 'Update Budget';
  }

  ngOnInit(): void {
    this.FormGroup = this._formBuilder.group({
      groupNameCtrl: ['', Validators.required],
      budgetCtrl: ['', Validators.required],
      partSearchCtrl: [''],
      budgetYearCtrl: ['', Validators.required],
    });
    this.FormGroup.controls.budgetYearCtrl.setValue(this.data?.selectedYear);

    if (this.data.mode == ActionType.Edit) {
      this.FormGroup.controls.groupNameCtrl.setValue(
        this.data?.item?.PART_GROUP_NAME
      );
      this.FormGroup.controls.budgetCtrl.setValue(this.data?.item?.BUDGET);
      if (
        this.data?.item?.PART_GROUPS &&
        this.data?.item?.PART_GROUPS != null &&
        this.data?.item?.PART_GROUPS?.length > 0
      ) {
        this.partsSelection = new SelectionModel<any>(true, []);
        for (let c = 0; c < this.data.item.PART_GROUPS.length; c++) {
          this.partsSelection.select({
            PART_ID: this.data.item.PART_GROUPS[c],
          });
        }
      }
    }

    this.getParts(this.paginatorLength, 0, '', '', '', 'PART');
  }

  closeDialog(): void {
    this.budgetDialogRef.close(false);
  }

  clearPartSerach() {
    this.FormGroup.controls.partSearchCtrl.setValue('');
    this.getParts(this.paginatorLength, 0, '', '', '', 'PART');
    this.paginator.pageIndex = 0;
  }

  handlePartsPaginator(e: any) {
    this.paginatorLength = e.pageSize;
    let limit = e.pageSize;
    let offset = e.pageIndex * limit;
    this.paginator.pageIndex = e.pageIndex;
    if (this.sort.active) {
      this.getParts(
        limit,
        offset,
        this.FormGroup.controls.partSearchCtrl.value != null ||
          this.FormGroup.controls.partSearchCtrl.value != ''
          ? this.FormGroup.controls.partSearchCtrl.value
          : '',
        this.sort.active,
        this.sort._direction,
        'PART'
      );
    } else {
      this.getParts(
        limit,
        offset,
        this.FormGroup.controls.partSearchCtrl.value != null ||
          this.FormGroup.controls.partSearchCtrl.value != ''
          ? this.FormGroup.controls.partSearchCtrl.value
          : '',
        '',
        '',
        'PART'
      );
    }
  }

  sortPartsfields(e: any) {
    let offset = this.paginator._pageIndex * this.paginator._pageSize;
    this.getParts(
      this.paginatorLength,
      offset,
      this.FormGroup.controls.partSearchCtrl.value != null ||
        this.FormGroup.controls.partSearchCtrl.value != ''
        ? this.FormGroup.controls.partSearchCtrl.value
        : '',
      e.active,
      e.direction,
      'PART'
    );
    this.paginator.pageIndex = this.paginator._pageIndex;
  }

  partsMasterToggle() {
    if (this.isPartsAllSelected()) {
      this.deleteOnlyPartsDatasourceItems();
    } else {
      this.partsCheckAndSelect();
    }
  }

  isPartsAllSelected() {
    if (
      this.partsSelection.selected &&
      this.partsSelection.selected.length > 0
    ) {
      const results = this.partDataSource.data.filter(
        ({ PART_ID: id1 }) =>
          !this.partsSelection.selected.some(({ PART_ID: id2 }) => id2 === id1)
      );
      if (results && results.length > 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  partsCheckAndSelect() {
    this.partDataSource.data.forEach((row) => {
      if (
        this.partsSelection.selected.some(
          (item) => item.PART_ID === row.PART_ID
        )
      ) {
      } else {
        this.partsSelection.select(row);
      }
    });
  }

  deleteOnlyPartsDatasourceItems() {
    if (
      this.partsSelection.selected &&
      this.partsSelection.selected.length > 0
    ) {
      let temp = this.partsSelection.selected;
      this.partsSelection.clear();

      temp.forEach((element) => {
        let tempIndex = this.partDataSource.data.findIndex(
          (row) => row.PART_ID == element.PART_ID
        );
        if (tempIndex == -1) {
          this.partsSelection.select(element);
        }
      });
    }
  }

  selectedPart(row: any): boolean {
    if (
      this.partsSelection?.selected &&
      this.partsSelection.selected.length > 0
    ) {
      let temp = false;
      for (let i = 0; i < this.partsSelection.selected.length; i++) {
        if (this.partsSelection.selected[i].PART_ID == row.PART_ID) {
          temp = true;
        }
      }
      return temp;
    } else {
      return false;
    }
  }

  toggelePartsSelection(ev: any, row: any) {
    if (ev.checked) {
      let ar = [
        ...new Map(
          this.partsSelection.selected.map((item) => [item.PART_ID, item])
        ).values(),
      ];

      if (ar?.length > 0) {
        for (let i = 0; i < ar.length; i++) {
          this.partsSelection.select(row);
        }
      } else {
        this.partsSelection.select(row);
      }
    } else {
      let temp = this.partsSelection.selected;
      if (temp && temp.length > 0) {
        temp.splice(
          temp.findIndex((a) => a.PART_ID === row.PART_ID),
          1
        );
        this.partsSelection.clear();
        for (let t = 0; t < temp?.length; t++) {
          this.partsSelection.select(temp[t]);
        }
      }
    }
  }

  searchPart(event: any) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if ($this.sort.active) {
        $this.getParts(
          $this.paginatorLength,
          0,
          event.target.value,
          $this.sort.active,
          $this.sort._direction,
          'PART'
        );
      } else {
        $this.getParts(
          $this.paginatorLength,
          0,
          event.target.value,
          '',
          '',
          'PART'
        );
      }
      $this.paginator.pageIndex = 0;
    }, 1000);
  }

  getParts(
    limit: number,
    offset: number,
    searchText?: any,
    sortField?: any,
    sortDirection?: any,
    type?: any
  ) {
    this.isShowProgressBar = true;
    this.partService
      .getParts(limit, offset, searchText, sortField, sortDirection, type)
      .subscribe(
        (response: any) => {
          // Success callback
          this.isShowProgressBar = false;
          if (response.status === 200) {
            if (response.body) {
              // Assign the data to the data source for the table to render
              this.partDataSource = new MatTableDataSource(
                response.body?.PARTS
              );
              this.partsLength = response.body?.PARTS_COUNT;
            }
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.showErrorDialog(response.error);
          } else {
            this.showErrorDialog(response.error);
          }
        },
        (error) => {
          this.isShowProgressBar = false;
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.showErrorDialog(error.error);
          } else {
            this.showErrorDialog(error.message);
          }
        }
      );
  }

  showErrorDialog(msg: string) {
    const dialogRef = this.dialog.open(ErrorAlertComponent, {
      width: '400px',
      data: {
        errorMsg: msg,
      },
    });
  }

  async saveBudget() {
    this.isShowProgressBar = true;
    let body: any = {};
    body = {
      BUDGET_YEAR: this.FormGroup.controls.budgetYearCtrl.value,
      BUDGET: this.FormGroup.controls.budgetCtrl.value,
      PART_GROUPS: [],
      PART_GROUP_NAME: this.FormGroup.controls.groupNameCtrl.value,
    };
    if (
      this.partsSelection?.selected &&
      this.partsSelection.selected.length > 0
    ) {
      for (let i = 0; i < this.partsSelection.selected.length; i++) {
        body.PART_GROUPS.push(this.partsSelection.selected[i].PART_ID);
      }
    }

    if (this.data.mode == ActionType.Add) {
      await this.budgetService
        .createBudget(body)
        .toPromise()
        .then(
          (response: any) => {
            this.isShowProgressBar = false;
            if (response.status === 204) {
              this.budgetDialogRef.close(true);
            } else {
              this.showErrorDialog(response.message);
            }
          },
          (error) => {
            this.isShowProgressBar = false;
            this.showErrorDialog(error.error);
          }
        );
    } else {
      body['BUDGET_ID'] = this.data?.item?.BUDGET_ID
        ? this.data.item.BUDGET_ID
        : '';
      await this.budgetService
        .updateBudget(body)
        .toPromise()
        .then(
          (response: any) => {
            this.isShowProgressBar = false;
            if (response.status === 204) {
              this.budgetDialogRef.close(true);
            } else {
              this.showErrorDialog(response.message);
            }
          },
          (error) => {
            this.isShowProgressBar = false;
            this.showErrorDialog(error.error);
          }
        );
    }
  }
}
