import { ConfigService } from 'src/app/services/config.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  mode: any;
  item: any;
  type: any;
  selectedObj: any;
}

@Component({
  selector: 'app-add-attribute',
  templateUrl: './add-attribute.component.html',
  styleUrls: ['./add-attribute.component.scss']
})
export class AddAttributeComponent implements OnInit {
  public title: string = '';
  public formGroup: FormGroup = new FormGroup({});
  public errorMsg: string = '';
  public isShowProgressBar: boolean = false;

  constructor(public dialogRef: MatDialogRef<AddAttributeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public configService: ConfigService) { }

  ngOnInit(): void {
    this.title = this.data?.type == 'add' ? 'Add New Attribute' : 'Update Attribute';
    if (this.data?.type == 'add') {
      this.formGroup = new FormGroup({
        description: new FormControl('', [Validators.required]),
        name: new FormControl('', [Validators.required])
      });
    } else {
      this.formGroup = new FormGroup({
        description: new FormControl(
          { value: this.data?.selectedObj?.ATTRIBUTE_DESCRIPTION, disabled: false },
          [Validators.required]
        ),
        name: new FormControl(
          { value: this.data?.selectedObj?.ATTRIBUTE_NAME, disabled: true },
          [Validators.required]
        ),
      });
    }
  }

  closeDialog(): void {
    this.dialogRef.close(false);
  }

  async save() {
    if (this.data?.type == 'add') {
      let body: any = {
        CATEGORY_ID: this.data.item.CATEGORY_ID,
        CATEGORY_NAME: this.data.item.NAME.trim(),
        ATTRIBUTE_NAME: this.formGroup.controls.name.value.trim(),
        ATTRIBUTE_DESCRIPTION: this.formGroup.controls.description.value.trim(),
      };
      this.isShowProgressBar = true;
      await this.configService
        .saveAttribute(body)
        .toPromise()
        .then(
          (response) => {
            this.isShowProgressBar = false;
            if (response.status === 204) {
              this.dialogRef.close(true);
            } else {
              this.errorMsg = response.message;
            }
          },
          (error) => {
            this.isShowProgressBar = false;
            this.errorMsg = error.error;
          }
        );
    } else {
      this.isShowProgressBar = true;
      let body: any = {
        CATEGORY_ID: this.data.item.CATEGORY_ID,
        ATTRIBUTE_NAME: this.formGroup.controls.name.value.trim(),
        ATTRIBUTE_DESCRIPTION: this.formGroup.controls.description.value.trim(),
      };
      await this.configService
        .updateAttribute(body)
        .toPromise()
        .then(
          (response) => {
            this.isShowProgressBar = false;
            if (response.status === 204) {
              this.dialogRef.close(true);
            } else {
              this.errorMsg = response.message;
            }
          },
          (error) => {
            this.isShowProgressBar = false;
            this.errorMsg = error.error;
          }
        );
    }
  }
}
