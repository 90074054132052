import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '../app.config';
import { AppConstants } from '../shared/AppConstants';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  public filterDetails: any;
  public constants: AppConstants;
  public notificationsPageStateDetails: any;
  constructor(
    private appConfigService: AppConfigService,
    private http: HttpClient
  ) {
    this.constants = new AppConstants();
  }

  // Download Service Requests from server
  getServiceRequests(
    limit: number,
    offset: number,
    searchText?: any,
    sortField?: any,
    sortDirection?: any,
    type?: any,
    status?: any,
    auditId?: any,
    location?: any,
    barcode?: any,
    part?: any,
    unit?: any,
    task?: any,
  ) {
    let tempParams: any = {
      client: 'admin',
      limit: limit,
      offset: offset,
    };
    if (searchText) {
      tempParams.search = searchText;
    }
    if (sortField) {
      tempParams.sortingField = sortField;
    }
    if (sortDirection) {
      tempParams.sortingOrder = sortDirection;
    }
    if (type) {
      tempParams.requestType = type;
    }
    if (status) {
      tempParams.status = status;
    }
    if (auditId) {
      tempParams.auditId = auditId;
    }
    if (location) {
      tempParams.locationId = location;
    }

    if (barcode) {
      tempParams.barcode = barcode;
    }
    if (part) {
      tempParams.partno = part;
    }
    if (unit) {
      tempParams.unittype = unit;
    }
    if (task) {
      tempParams.tasktype = task;
    }
    
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      params: tempParams,
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(
      url + this.constants.GET_SERVICE_REQUESTS_URL,
      httpOptions
    );
  }

  // Update Service Request
  updateServiceRequest(body: any) {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
      params: { client: 'admin' },
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.put<any>(
      url + this.constants.UPDATE_SERVICE_REQUESTS_URL,
      body,
      httpOptions
    );
  }

  // Download Service Request by id from server
  getServiceRequestById(id: any) {
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      params: { client: 'admin' },
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(
      url + this.constants.GET_SERVICE_REQUESTS_URL + `/${id}`,
      httpOptions
    );
  }

  getPickListByRequestId(id: any) {
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      params: { requestIds: id },
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(
      url + this.constants.GET_PICK_LIST_BY_REQUEST_ID,
      httpOptions
    );
  }
}
