import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { LocationService } from 'src/app/services/location.service';
import { ActionType, AppConstants } from 'src/app/shared/AppConstants';

export interface DialogData {
  mode: number;
  item: any;
}

@Component({
  selector: 'app-add-location-category',
  templateUrl: './add-location-category.component.html',
  styleUrls: ['./add-location-category.component.scss'],
})
export class AddLocationCategoryComponent implements OnInit {
  public constants: AppConstants;
  public title: string;
  public formGroup: FormGroup;
  public errorMsg: string;
  public isShowProgressBar: boolean = false;

  constructor(
    public locationService: LocationService,
    public authService: AuthService,
    public router: Router,
    public dialogRef: MatDialogRef<AddLocationCategoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.constants = new AppConstants();
    this.errorMsg = '';
    this.title =
      this.data.mode == ActionType.Add ? 'Add Category' : 'Update Category';

    if (this.data.mode == ActionType.Add) {
      this.formGroup = new FormGroup({
        categoryId: new FormControl('', [Validators.required]),
        name: new FormControl('', [Validators.required]),
        description: new FormControl(''),
      });
    } else {
      this.formGroup = new FormGroup({
        categoryId: new FormControl(
          { value: this.data.item.CATEGORY_ID, disabled: true },
          [Validators.required]
        ),
        name: new FormControl(this.data.item.NAME, [Validators.required]),
        description: new FormControl(this.data.item.DESCRIPTION),
      });
    }
  }

  ngOnInit(): void {
    this.formGroup.valueChanges.subscribe((x) => {
      this.errorMsg = '';
    });
  }

  closeDialog(): void {
    this.dialogRef.close(false);
  }

  save() {
    let body = {
      CATEGORY_ID: this.formGroup.controls.categoryId.value,
      NAME: this.formGroup.controls.name.value,
      DESCRIPTION: this.formGroup.controls.description.value,
    };
    if (this.data.mode == ActionType.Add) {
      this.isShowProgressBar = true;
      this.locationService.addLocationCategory(body).subscribe(
        (response: any) => {
          // Success callback
          this.isShowProgressBar = false;
          if (response.status === 204) {
            this.dialogRef.close(body.CATEGORY_ID);
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else {
            this.errorMsg = response.message;
          }
        },
        (error) => {
          this.isShowProgressBar = false;
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else {
            this.errorMsg = error.error;
          }
        }
      );
    } else {
      this.isShowProgressBar = true;
      this.locationService.updateLocationCategory(body).subscribe(
        (response: any) => {
          // Success callback
          this.isShowProgressBar = false;
          if (response.status === 204) {
            this.dialogRef.close(body.CATEGORY_ID);
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else {
            this.errorMsg = response.message;
          }
        },
        (error) => {
          this.isShowProgressBar = false;
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else {
            this.errorMsg = error.error;
          }
        }
      );
    }
  }
}
