import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddNewReportTypeComponent } from 'src/app/components/add-new-report-type/add-new-report-type.component';
import { CustomerSelectComponent } from 'src/app/components/customer-select/customer-select.component';
import { ErrorAlertComponent } from 'src/app/components/error alert/error-alert.component';
import { AuthService } from 'src/app/services/auth.service';
import { BillableReportService } from 'src/app/services/billable-report.service';
import { UtilService } from 'src/app/services/util.service';
import { ActionType, AppConstants } from 'src/app/shared/AppConstants';

@Component({
  selector: 'app-billable-reports',
  templateUrl: './billable-reports.component.html',
  styleUrls: ['./billable-reports.component.scss']
})
export class BillableReportsComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;

  @HostListener('window:resize', ['$event'])
  getScreenHeight(event?: any) {
    if (window.innerHeight <= 700) {
      this.appropriateClass = 'bottomRelative';
    } else {
      this.appropriateClass = 'bottomStick';
    }
  }
  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result: { matches: any }) => result.matches));

  public constants: AppConstants;
  public subscriptions: Subscription[] = [];
  public loggedInCustomerId: any;
  public version: string;
  public release: string;
  public appropriateClass: string = '';
  public isShowBillableReportsProgressBar: boolean;
  public reportTypes: any[] = [];
  public isCustom: boolean = false;
  public formGroup: FormGroup;
  public reportsDataSource = new MatTableDataSource<any>();
  public tableColumns: string[] = [
    'TAG',
    'TERMINAL',
    'LOCATION',
    'EQUIPMENT_CATEGORY_ATTRIBUTE',
    'COUNT_OF_UNITS'
  ];
  public reportsList: any[] = [];
  public reportsLength: number = 0;

  constructor(
    public authService: AuthService,
    private breakpointObserver: BreakpointObserver,
    public utilService: UtilService,
    public dialog: MatDialog,
    public router: Router,
    public billableService: BillableReportService
  ) {
    this.getScreenHeight();
    this.constants = new AppConstants();
    this.version = this.constants.APP_RELEASE_NUMBER;
    this.release = this.constants.APP_RELEASE_DATE;
    this.isShowBillableReportsProgressBar = false;
    this.formGroup = new FormGroup({
      reportType: new FormControl('', [Validators.required]),
      snapshot: new FormControl('', [Validators.required]),
      startDate: new FormControl(''),
      endDate: new FormControl(''),
      isCustom: new FormControl(false),
    });

    this.formGroup.controls.isCustom.valueChanges.subscribe((x) => {
      if (x) {
        this.formGroup.controls.snapshot.setValue('');
        this.formGroup.controls.startDate.setValue('');
        this.formGroup.controls.endDate.setValue('');
        this.formGroup.controls.startDate.setValidators([Validators.required]);
        this.formGroup.controls.endDate.setValidators([Validators.required]);
        this.formGroup.controls.startDate.updateValueAndValidity();
        this.formGroup.controls.endDate.updateValueAndValidity();
        this.formGroup.controls.snapshot.clearValidators();
        this.formGroup.controls.snapshot.updateValueAndValidity();
      } else {
        this.formGroup.controls.snapshot.setValue('');
        this.formGroup.controls.startDate.setValue('');
        this.formGroup.controls.endDate.setValue('');
        this.formGroup.controls.startDate.clearValidators();
        this.formGroup.controls.endDate.clearValidators();
        this.formGroup.controls.startDate.updateValueAndValidity();
        this.formGroup.controls.endDate.updateValueAndValidity();
        this.formGroup.controls.snapshot.setValidators([Validators.required]);
        this.formGroup.controls.snapshot.updateValueAndValidity();
      }
    });
  }

  async ngOnInit(): Promise<void> {
    await this.getBillableReports();
  }


  logout() {
    this.authService.logout();
  }

  switchCompany() {
    let userTenants: any = localStorage.getItem('userTenants');
    if (userTenants && userTenants != null) {
      userTenants = JSON.parse(userTenants);
      const dialogRef = this.dialog.open(CustomerSelectComponent, {
        panelClass: 'add-customer-dialog',
        width: '550px',
        height: '380px',
        disableClose: false,
        data: { mode: 0, item: userTenants },
      });

      dialogRef.afterClosed().subscribe(async (result) => {
        if (result) {
          await this.refreshBillableReports();
        }
      });
    }
  }

  async refreshBillableReports() {
    this.formGroup = new FormGroup({});
    this.reportsDataSource.data = [];
    this.reportsList = [];
    this.reportsLength = 0;
    await this.getBillableReports();
    this.formGroup = new FormGroup({
      reportType: new FormControl('', [Validators.required]),
      snapshot: new FormControl('', [Validators.required]),
      startDate: new FormControl(''),
      endDate: new FormControl(''),
      isCustom: new FormControl(false),
    });
  }

  async getBillableReports() {
    this.isShowBillableReportsProgressBar = true;
    let PartsSubscription = await this.billableService
      .getBillableReports()
      .toPromise()
      .then(
        (response: any) => {
          this.isShowBillableReportsProgressBar = false;
          // Success callback
          if (response.status === 200) {
            this.reportTypes = response?.body?.BILLABLE_UNIT_REPORT;
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug'); localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.router.navigate(['dashboard']);
          } else {
            this.showErrorDialog(response.error);
          }
        },
        (error) => {
          this.isShowBillableReportsProgressBar = false;
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug'); localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.router.navigate(['dashboard']);
          } else {
            this.showErrorDialog(error.message);
          }
        }
      );
  }

  showErrorDialog(msg: string) {
    this.dialog.open(ErrorAlertComponent, {
      width: '400px',
      data: {
        errorMsg: msg,
      },
    });
  }

  async generateReport() {
    this.reportsDataSource.data = [];
    this.reportsList = [];
    this.reportsLength = 0;
    this.isShowBillableReportsProgressBar = true;
    let reportId = this.formGroup.controls.reportType.value;
    let startDate = this.formGroup.controls.isCustom.value == true ? this.getFormattedYear(this.formGroup.controls.startDate.value) : '2020-01-01';
    let endDate = this.formGroup.controls.isCustom.value == true ? this.getFormattedYear(this.formGroup.controls.endDate.value) : this.getFormattedYear(this.formGroup.controls.snapshot.value);

    await this.billableService.generateReport(reportId, startDate, endDate).toPromise()
      .then(
        (response: any) => {
          // Success callback
          this.isShowBillableReportsProgressBar = false;
          if (response.status === 200) {
            this.reportsLength = response?.body?.TOTAL_COUNT_OF_UNITS;
            this.reportsList = response?.body?.REPORT_DETAILS
            this.reportsDataSource = new MatTableDataSource(
              this.reportsList,
            );
            setTimeout(() => this.reportsDataSource.paginator = this.paginator);
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug'); localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else {
            console.log(response.error);
          }
        },
        (error) => {
          this.isShowBillableReportsProgressBar = false;
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug'); localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          }
        }
      );
  }

  getFormattedYear(date: any) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const twoDigitMonth = month.toString().padStart(2, '0');
    const twoDigitDay = day.toString().padStart(2, '0');
    return `${year}-${twoDigitMonth}-${twoDigitDay}`;
  }

  getTotalCost() {
    return this.reportsList.map(t => t.COUNT_OF_UNITS).reduce((acc, value) => acc + value, 0);
  }

  async downloadCsvFile() {
    return new Promise((resolve, reject) => {
      console.log('Preparing CSV file from JSON data...');
      try {
        var reportRowString =
          '' +
          'Tag' +
          ',' +
          'Terminal' +
          ',' +
          'Location' +
          ',' +
          'Equipment Category' +
          ',' +
          'Count of Units' +
          '\n';

        for (let row of this.reportsList) {
          reportRowString =
            reportRowString +
            row.TAG +
            ',' +
            row.TERMINAL +
            ',' +
            row.LOCATION +
            ',' +
            row.EQUIPMENT_CATEGORY_ATTRIBUTE +
            ',' +
            row.COUNT_OF_UNITS +
            '\n';
        }

        reportRowString =
          reportRowString +
          'Total' +
          ',' +
          '' +
          ',' +
          '' +
          ',' +
          '' +
          ',' +
          this.getTotalCost() +
          '\n';
        return resolve(
          this.downloadAsCSV(reportRowString, 'billable-report.csv')
        );
      } catch (error) {
        return reject(error);
      }
    });
  }

  async downloadAsCSV(csvString: any, filenames: string) {
    return new Promise((resolve, reject) => {
      try {
        console.log('CSV file is prepared. Downloading to the client');
        var csvFile;
        var downloadLink: any;
        csvFile = new Blob([csvString], { type: 'text/csv' });

        // IE10+ : (has Blob, but not a[download] or URL)
        if (navigator.msSaveBlob) {
          return navigator.msSaveBlob(csvFile, filenames);
        }

        downloadLink = document.createElement('a');
        downloadLink.download = filenames;
        let url = window.URL.createObjectURL(csvFile);
        downloadLink.href = url;
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);

        /**
         * Delay the download process to make sure that the DOM is built.
         */
        setTimeout(() => {
          downloadLink.click();

          document.body.removeChild(downloadLink);
          window.URL.revokeObjectURL(url);
          return resolve('Download process is complete');
        }, 1000);
      } catch (error) {
        return reject(error);
      }
    });
  }

  addNewReportType() {
    const dialogRef = this.dialog.open(AddNewReportTypeComponent, {
      width: '60%',
      height: '75%',
      data: { mode: ActionType.Add, item: {} },
      panelClass: 'add-user-dialog',
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        await this.getBillableReports();
      }
    });
  }

  updateReportType(report: any) {
    let repItem = this.reportTypes.find((rep) => rep.REPORT_ID == report);
    const dialogRef = this.dialog.open(AddNewReportTypeComponent, {
      width: '60%',
      height: '75%',
      data: { mode: ActionType.Edit, item: repItem },
      panelClass: 'modify-user-dialog',
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        await this.getBillableReports();
        let repItem = this.reportTypes.find((rep) => rep.REPORT_ID == report);
        if(repItem){} else {
          this.formGroup.controls.reportType.setValue('');
          this.formGroup.controls.reportType.setValidators([Validators.required]);
          this.formGroup.controls.reportType.updateValueAndValidity();
        }
        this.formGroup.valid ? await this.generateReport() : null;
      }
    });
  }
}