import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  item: any;
}

@Component({
  selector: 'app-add-new-extension-section',
  templateUrl: './add-new-extension-section.component.html',
  styleUrls: ['./add-new-extension-section.component.scss'],
})
export class AddNewExtensionSectionComponent implements OnInit {
  public errorMsg: string = '';
  public formGroup: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AddNewExtensionSectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.formGroup = new FormGroup({
      name: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.formGroup.valueChanges.subscribe((x) => {
      this.errorMsg = '';
    });
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  add() {
    let tempKeys = Object.keys(this.data.item);
    if (tempKeys && tempKeys.length > 0) {
      let tempArr: any[] = [];
      tempKeys.forEach((element) => {
        tempArr.push(element.toLowerCase().trim());
      });
      if (
        tempArr.includes(
          this.formGroup.controls.name.value?.toLowerCase().trim()
        )
      ) {
        this.errorMsg = 'Name already exists, Please choose another name.';
      } else {
        this.dialogRef.close(this.formGroup.controls.name.value);
      }
    } else {
      this.dialogRef.close(this.formGroup.controls.name.value);
    }
  }
}
