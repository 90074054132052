import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { ServiceRequestService } from 'src/app/services/service-request.service';
import { AppConstants } from 'src/app/shared/AppConstants';

export interface DialogData {
  item: any;
}

@Component({
  selector: 'app-equipment-details',
  templateUrl: './equipment-details.component.html',
  styleUrls: ['./equipment-details.component.scss'],
})
export class EquipmentDetailsComponent implements OnInit {
  public isShowProgressBar: boolean = false;
  public equipmentDetails: any = null;
  public equipmentLogs: any = [];
  public subscriptions: Subscription[] = [];
  public serviceRequestDetails: any;
  public isShowSpinner: boolean = false;
  public noDetailsFlag: boolean = false;
  public constants: AppConstants;

  constructor(
    public serviceRequestService: ServiceRequestService,
    public authService: AuthService,
    public router: Router,
    public dialogRef: MatDialogRef<EquipmentDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.constants = new AppConstants();
  }

  ngOnInit(): void {
    this.getEquipmentLogsByEquipmentId();
    this.getEquipmentDetailsByEquipmentId();
  }

  getEquipmentDetailsByEquipmentId() {
    this.isShowProgressBar = true;
    this.serviceRequestService
      .getEquipmentDetailsByEquipmentId(this.data.item.EQUIPMENT_ID)
      .subscribe(
        (response: any) => {
          // Success callback
          this.isShowProgressBar = false;
          if (response.status === 200) {
            if (response.body) {
              // Assign the data to the data source for the table to render
              this.equipmentDetails = response.body?.EQUIPMENT;
            }
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            console.log(response.error);
          } else {
          }
        },
        (error) => {
          this.isShowProgressBar = false;
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            console.log(error.error);
          } else {
            console.log(
              'Error while downloading equipment details, do you want to retry again?'
            );
          }
        }
      );
  }

  closeDialog() {
    this.dialogRef.close();
  }

  // Get equipment logs by equipment id
  getEquipmentLogsByEquipmentId() {
    // this.isShowProgressBar = true;
    this.serviceRequestService
      .getEquipmentLogsByEquipmentId(this.data.item.EQUIPMENT_ID)
      .subscribe(
        (response: any) => {
          // Success callback
          // this.isShowProgressBar = false;
          if (response.status === 200) {
            if (response.body) {
              // Assign the data to the data source for the table to render
              this.equipmentLogs = response.body;
            }
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            console.log(response.error);
          } else {
          }
        },
        (error) => {
          // this.isShowProgressBar = false;
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            console.log(error.error);
          } else {
            console.log(
              'Error while downloading service requests, do you want to retry again?'
            );
          }
        }
      );
  }

  // Modify variables when accordian collapsed
  collpase() {
    this.serviceRequestDetails = null;
    this.noDetailsFlag = false;
  }

  // Fetch inspection log obj details
  expand(log: any) {
    //  Get Service request by obj ref id
    this.isShowSpinner = true;
    this.cancelOutstandingRequests();
    // Make get service request by id call
    let serviceRequestByIdSubscription = this.serviceRequestService
      .getServiceRequestById(log.OBJ_REF_ID)
      .subscribe(
        (response: any) => {
          this.isShowSpinner = false;
          // Success callback
          if (response.status === 200) {
            if (response.body) {
              // Assign the data to the data source for the table to render
              this.serviceRequestDetails = response.body;
            }
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            this.noDetailsFlag = true;
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.noDetailsFlag = true;
            // this.showErrorDialog(response.error);
          } else {
            this.noDetailsFlag = true;
            // this.showErrorDialog(
            //   'Error while downloading service requests, do you want to retry again?'
            // );
          }
        },
        (error) => {
          this.isShowSpinner = false;
          this.noDetailsFlag = true;
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            // this.showErrorDialog(error.error);
          } else {
            // this.showErrorDialog(
            //   'Error while downloading service requests, do you want to retry again?'
            // );
          }
        }
      );
    this.subscriptions.push(serviceRequestByIdSubscription);
  }

  // Cancel on going http calls when page leaving
  cancelOutstandingRequests() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  detailsMsg(log: any) {
    let msg = `${new Date(log.CREATED_AT).toLocaleString()}`;
    return msg;
  }
}
