import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { PartOrderService } from 'src/app/services/part-order.service';
import { PartService } from 'src/app/services/part.service';

export interface DialogData {
  mode: number;
  item: any;
}

@Component({
  selector: 'app-receive-part',
  templateUrl: './receive-part.component.html',
  styleUrls: ['./receive-part.component.scss']
})
export class ReceivePartComponent implements OnInit {
  public isShowProgressBar: boolean = false;
  public partColumns: string[] = ['No', 'PART_ID', 'RECEIVED_QUANTITY', 'QUANTITY', 'ERROR'];
  public partDataSource = new MatTableDataSource<any>([]);
  public errorMsg: string = '';
  public inventoryLocations: any[] = [];
  public location: string = '';
  public reciveDate: any = new Date();

  constructor(public partOrderDialogRef: MatDialogRef<ReceivePartComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public partOrderService: PartOrderService,
    public authService: AuthService,
    public router: Router,
    public partService: PartService) { }

  async ngOnInit(): Promise<void> {
    await this.getPartDetails();
    await this.getInventoryLocations();
  }

  closeDialog(): void {
    this.partOrderDialogRef.close(false);
  }

  async getPartDetails() {
    this.isShowProgressBar = true;
    await this.partOrderService
      .getPartOrderById(this.data.item.ORDER_ID)
      .toPromise()
      .then(
        (response: any) => {
          this.isShowProgressBar = false;
          // Success callback
          if (response.status === 200) {
            if (response.body) {
              // this.reciveDate = new Date(response?.body?.PART_ORDER_DETAILS[0]?.RECEIVED_AT);
              let list: any = [];
              response?.body?.PART_ORDER_DETAILS.forEach((part: any) => {
                part['R_Quantity'] = part.QUANTITY - part.RECEIVED_QUANTITY;
                list.push(part);
              });
              this.partDataSource = new MatTableDataSource(
                list
              );
            }
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId')
            localStorage.removeItem('dashboardSlug'); localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.errorMsg = response.error;
          } else {
            this.errorMsg = 'Error while downloading part orders, do you want to retry again?';
          }
        },
        (error) => {
          this.isShowProgressBar = false;
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug'); localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.errorMsg = error.error;
          } else {
            this.errorMsg = 'Error while downloading part orders, do you want to retry again?';
          }
        }
      );
  }

  async getInventoryLocations() {
    this.isShowProgressBar = true;
    this.inventoryLocations = [];
    await this.partService.getInventoryLocations().toPromise()
      .then(
        (response: any) => {
          // Success callback
          this.isShowProgressBar = false;
          if (response.status === 200) {
            if (response.body) {
              // Assign the data
              this.inventoryLocations = response.body;
              if (this.inventoryLocations.length > 0) {
                this.location = this.inventoryLocations[0].LOCATION_ID
              }
            }
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug'); localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.errorMsg = response.message;
          } else {
            this.errorMsg = response.message;
          }
        },
        (error) => {
          this.isShowProgressBar = false;
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug'); localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.errorMsg = error.error;
          } else {
            this.errorMsg = error.error;
          }
        }
      );
  }

  async save() {
    let list: any = [];
    this.partDataSource.data.forEach(element => {
      if (element.R_Quantity != '' && element.R_Quantity != null && element.R_Quantity != undefined && (element.QUANTITY - element.RECEIVED_QUANTITY) != 0) {
        list.push(element);
      }
    });

    if (list.length > 0) {
      this.isShowProgressBar = true;
      let body: any = {
        ORDER_ID: this.data.item.ORDER_ID,
        LOCATION_ID: this.location,
        PART_DETAILS: []
      }
      list.forEach((element: any) => {
        let obj = { 'PART_ID': element.PART_ID, 'QUANTITY': element.R_Quantity, 'RECEIVED_AT': new Date(this.removeTime(this.reciveDate)).valueOf() };
        body.PART_DETAILS.push(obj);
      });
      body.PART_DETAILS = JSON.stringify(body.PART_DETAILS);

      await this.partOrderService
        .receivePartOrderOrder(body)
        .toPromise()
        .then(
          (response) => {
            this.isShowProgressBar = false;
            if (response.status === 204) {
              this.partOrderDialogRef.close(true);
            } else {
              this.errorMsg = response.message;
            }
          },
          (error) => {
            this.isShowProgressBar = false;
            this.errorMsg = error.error;
          }
        );
    } else this.partOrderDialogRef.close(false);
  }

  removeTime(date = new Date()) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const twoDigitMonth = month.toString().padStart(2, '0');
    const twoDigitDay = day.toString().padStart(2, '0')
    let finalDate = `${year}-${twoDigitMonth}-${twoDigitDay}T00:00:00Z`;
    return finalDate;
  }
}
