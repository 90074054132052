import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';
import { PartService } from 'src/app/services/part.service';

export interface DialogData {
  item: any;
  list: any[];
}

@Component({
  selector: 'app-add-new-bom',
  templateUrl: './add-new-bom.component.html',
  styleUrls: ['./add-new-bom.component.scss']
})
export class AddNewBomComponent implements OnInit {
  public title: string = '';
  public formGroup: FormGroup = new FormGroup({});
  public errorMsg: string = '';
  public isShowProgressBar: boolean = false;
  public bomsList: any[] = [];

  constructor(public dialogRef: MatDialogRef<AddNewBomComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public configService: ConfigService,
    public authService: AuthService, public router: Router, public partService: PartService,) { }

  ngOnInit(): void {
    this.title = 'Add New BOMs';
    this.formGroup = new FormGroup({
      category: new FormControl({ value: this.data?.item?.CATEGORY_ID, disabled: true }, [Validators.required]),
      boms: new FormControl('', [Validators.required])
    });
    if (this.data.list.length > 0) {
      this.formGroup.controls.boms.setValue(this.data.list.map((el) => el.BOM_ID))
    }
    this.getBoms();
  }

  closeDialog(): void {
    this.dialogRef.close(false);
  }

  getBoms() {
    this.isShowProgressBar = true;
    this.partService
      .getBomParts(
        'BOM'
      )
      .subscribe(
        (response: any) => {
          // Success callback
          if (response.status === 200) {
            if (response.body) {
              console.log(response.body);
              this.bomsList = response.body?.PARTS;
            }
            this.isShowProgressBar = false;
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.isShowProgressBar = false;
            // this.showErrorDialog(response.error);
          } else {
            this.isShowProgressBar = false;
            // this.showErrorDialog(response.error);
          }
        },
        (error) => {
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.isShowProgressBar = false;
            // this.showErrorDialog(error.error);
          } else {
            this.isShowProgressBar = false;
            // this.showErrorDialog(error.message);
          }
        }
      );
    // this.subscriptions.push(PartsSubscription);
  }

  async save() {
    this.isShowProgressBar = true;
    let body: any = {
      EQUIPMENT_CATEGORY_ID: this.data.item.CATEGORY_ID,
      BOM_IDS: this.formGroup.controls.boms.value
    };
    await this.configService
      .addBomEquipmentCategoryMap(body)
      .toPromise()
      .then(
        (response) => {
          this.isShowProgressBar = false;
          if (response.status === 204) {
            this.dialogRef.close(true);
          } else {
            this.errorMsg = response.message;
          }
        },
        (error) => {
          this.isShowProgressBar = false;
          this.errorMsg = error.error;
        }
      );
  }
}
