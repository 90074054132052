<mat-toolbar class="toolbar">
    <button mat-icon-button (click)="authService.isExpanded = !authService.isExpanded">
        <mat-icon>menu</mat-icon>
    </button>
    {{utilService.customerName}}
    <div class="company-logo">
        <button mat-button routerLink="/dashboard">
            <img class="company-image" src="assets/logo/AFS_PrimaryLogo_1C_White.png" />
        </button>
    </div>
    <span class="example-spacer"></span>
    <button matTooltip="Switch Company" mat-icon-button *ngIf="authService.isAfsUser()" (click)="switchCompany()" >
        <mat-icon style="font-size: 29px;" class="material-icons-outlined">swap_horiz</mat-icon>
      </button> &nbsp;
    <button matTooltip="Logout" mat-icon-button (click)="logout()">
        <mat-icon class="material-icons-outlined">logout</mat-icon>
    </button>
</mat-toolbar>

<mat-sidenav-container class="sidenav-container" autosize [hasBackdrop]="false">
    <mat-sidenav #drawer [fixedInViewport]="true" [disableClose]="true" [fixedTopGap]="55"
        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'side' : 'side'"
        [opened]="true">
        <div class="side-menu-height">
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('ADMIN')  && authService.isNotAfsCustomer()">
                <a mat-list-item [routerLink]="['/dashboard']" matTooltip="Dashboard" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">dashboard</mat-icon>
                    <span [translate]="'Dashboard'" *ngIf="authService.isExpanded"
                        class="menu-icon-spacing">Dashboard</span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('ADMIN') && authService.isNotAfsCustomer()">
                <a mat-list-item [routerLink]="['/reports']" matTooltip="Reports" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">auto_awesome_mosaic</mat-icon>
                    <span [translate]="'Reports'" *ngIf="authService.isExpanded"
                        class="menu-icon-spacing">Reports</span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('CUSTOMER')">
                <a mat-list-item [routerLink]="['/customers']" matTooltip="Customers" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon>corporate_fare</mat-icon>
                    <span [translate]="'Customers'" *ngIf="authService.isExpanded"
                        class="menu-icon-spacing">Customers</span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('USER')">
                <a mat-list-item [routerLink]="['/users']" matTooltip="Users" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">people</mat-icon>
                    <span [translate]="'Users'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Users</span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('SERVICE_REQUEST')">
                <a mat-list-item [routerLink]="['/service-requests']" matTooltipPosition="right"
                    matTooltip="Service Requests" routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">content_paste</mat-icon>
                    <span [translate]="'Service Requests'" *ngIf="authService.isExpanded"
                        class="menu-icon-spacing">Service
                        Requests</span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('SCHEDULED_SERVICE_REQUEST')">
                <a mat-list-item [routerLink]="['/timed-services']" matTooltipPosition="right"
                    matTooltip="Timed Services" routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">timer</mat-icon>
                    <span [translate]="'Timed Services'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Timed
                        Services</span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('SCHEDULED_SERVICE_REQUEST')">
                <a mat-list-item [routerLink]="['/timed-service-schedules']" matTooltipPosition="right"
                    matTooltip="Timed Service Schedules" routerLinkActive="active-list-item">
                    <mat-icon class="material-icons">pending_actions</mat-icon>
                    <span [translate]="'Timed Service Schedules'" *ngIf="authService.isExpanded"
                        class="menu-icon-spacing">Timed Service Schedules</span>
                </a>
            </mat-nav-list>
            <mat-nav-list
                *ngIf="(authService.checkRoleBasedModuleAccess('MESSAGE') || authService.checkRoleBasedModuleAccess('SERVICE_REQUEST'))">
                <a mat-list-item [routerLink]="['/notifications']" matTooltip="Notifications" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">feedback</mat-icon>
                    <span [translate]="'Notifications'" *ngIf="authService.isExpanded"
                        class="menu-icon-spacing">Notifications</span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('AUDIT')">
                <a mat-list-item [routerLink]="['/audits']" matTooltip="Services " matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon>checklist_rtl</mat-icon>
                    <span [translate]="'Services'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Services
                    </span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('AUDIT')">
                <a mat-list-item [routerLink]="['/audit-schedule']" matTooltip="Scheduled Services"
                    matTooltipPosition="right" routerLinkActive="active-list-item">
                    <mat-icon>schedule</mat-icon>
                    <span [translate]="'Scheduled Services'" *ngIf="authService.isExpanded"
                        class="menu-icon-spacing">Scheduled Services
                    </span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('LOPA')">
                <a mat-list-item [routerLink]="['/lopa']" matTooltip="LOPA Management" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">flight</mat-icon>
                    <span [translate]="'Lopa'" *ngIf="authService.isExpanded" class="menu-icon-spacing">LOPA Management
                    </span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('EQUIPMENT')">
                <a mat-list-item [routerLink]="['/locations']" matTooltip="Locations" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">my_location</mat-icon>
                    <span [translate]="'Locations'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Locations
                    </span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('EQUIPMENT')">
                <a mat-list-item [routerLink]="['/parts']" matTooltip="Parts" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">chair_alt</mat-icon>
                    <span [translate]="'Parts'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Parts
                    </span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('BUDGET')">
                <a mat-list-item [routerLink]="['/budget']" matTooltip="Budget" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">card_travel</mat-icon>
                    <span [translate]="'Budget'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Budget
                    </span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('INVOICE')">
                <a mat-list-item [routerLink]="['/invoice']" matTooltip="Invoice" matTooltipPosition="right"
                  routerLinkActive="active-list-item">
                  <mat-icon class="material-icons-outlined">description</mat-icon>
                  <span [translate]="'Invoice'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Invoice
                  </span>
                </a>
              </mat-nav-list>
              <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('PART_ORDER') || authService.checkRoleBasedModuleAccess('PART_ORDER_READ_ONLY')">
                <a mat-list-item [routerLink]="['/part-orders']" matTooltip="Part Orders" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">add_shopping_cart</mat-icon>
                    <span [translate]="'Part Orders'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Part Orders
                    </span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('BILLABLE_UNIT_REPORT') || authService.checkRoleBasedModuleAccess('BILLABLE_UNIT_REPORT_READ_ONLY')">
                <a mat-list-item [routerLink]="['/billable-reports']" matTooltip="Billable Reports" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-symbols-outlined material-icons-outlined">receipt_long</mat-icon>
                    <span [translate]="'Billable Reports'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Billable Reports
                    </span>
                </a>
                </mat-nav-list>
        </div>
        <div [ngClass]='appropriateClass'>
            <mat-nav-list>
                <a mat-list-item [routerLink]="['/settings']" matTooltip="Settings" matTooltipPosition="right"
                    routerLinkActive="active-list-item" *ngIf="authService.checkRoleBasedModuleAccess('SETTINGS')">
                    <mat-icon class="material-icons-outlined">settings</mat-icon>
                    <span [translate]="'Settings'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Settings
                    </span>
                </a>

                <a (click)="authService.isExpanded = !authService.isExpanded" mat-list-item>
                    <mat-icon *ngIf="authService.isExpanded">keyboard_double_arrow_left</mat-icon>
                    <mat-icon *ngIf="!authService.isExpanded">keyboard_double_arrow_right</mat-icon>
                    <span [translate]="'Collapse sidebar'" *ngIf="authService.isExpanded"
                        class="menu-icon-spacing">Collapse
                        sidebar</span>
                    <span slot="right" style="color: grey;font-size: small; margin: 0 0 0 auto;"
                        *ngIf="authService.isExpanded" class="menu-icon-spacing">
                        {{version }}</span>
                </a>
            </mat-nav-list>
        </div>
    </mat-sidenav>

    <mat-sidenav-content>
        <mat-toolbar class="toolbar-height-50">
            <button mat-icon-button onclick="this.blur()" (click)="goBack()">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <span>Equipment Category Management</span>
            <span class="toolbar-spacer"></span>

        </mat-toolbar>

        <div class="container">
            <div fxLayout="row" class="div-row">

                <div fxFlex="33" class="location-div">
                    <mat-toolbar class="toolbar-fixed-1" style="height:50px !important;">
                        Equipment Categories
                        <span class="example-spacer"></span>
                        <button mat-icon-button (click)="addCategory()" matTooltip="Add New Category">
                            <mat-icon style="font-size:27px" class="material-icons-outlined">add</mat-icon>
                        </button>
                        <button mat-icon-button (click)="refreshCategories()" matTooltip="Refresh">
                            <mat-icon class="material-icons-outlined">refresh</mat-icon>
                        </button>
                    </mat-toolbar>

                    <mat-progress-bar *ngIf="isShowCategoryProgressBar" mode="indeterminate">
                    </mat-progress-bar>

                    <mat-nav-list style="margin-top: -3px !important;">
                        <mat-list-item *ngFor="let category of equipmentCategoryList; index as i"
                            [class.selected]="i === selectedCategoryIndex" (click)="selectedCategory(i, category)">
                            <h4 matLine>{{category.NAME}}</h4>
                            <button mat-icon-button
                            (click)="updateCategory(category );$event.stopPropagation();">
                            <mat-icon class="primary-color material-icons-outlined" style="font-size: 20px;">edit</mat-icon>
                        </button>
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            <mat-divider></mat-divider>
                        </mat-list-item>
                    </mat-nav-list>
                </div>

                <div fxFlex="33"
                    [ngClass]="(selectedCategoryIndex >= 0 && selectedCategoryIndex != null) ? 'category-div' : ''">
                    <div fxLayout="column" style="height: 50%;">
                       <div fxFill="40" style="overflow-y: auto;">
                        <span *ngIf="selectedCategoryIndex >= 0 && selectedCategoryIndex != null">
                            <mat-toolbar class="toolbar-fixed-1" style="height:50px !important;">
                                Attributes
                                <span class="example-spacer"></span>
                                <button matTooltip="Add Value" mat-icon-button (click)="addAttribute('add', {})">
                                    <mat-icon class="material-icons-outlined">add</mat-icon>
                                </button>
                            </mat-toolbar>
    
                            <mat-progress-bar *ngIf="isShowVattributesProgressBar" mode="indeterminate">
                            </mat-progress-bar>
    
                            <mat-nav-list style="margin-top: -3px !important;" *ngIf="attributesList.length > 0">
                                <mat-list-item *ngFor="let value of attributesList; index as i">
                                    <h4 matLine>{{value.ATTRIBUTE_NAME}}</h4>
                                    <button mat-icon-button
                                        (click)="addAttribute('update', value );$event.stopPropagation();">
                                        <mat-icon class="primary-color material-icons-outlined" style="font-size: 20px;">edit</mat-icon>
                                    </button>
                                    <button mat-icon-button (click)="deleteAttribute(value)">
                                        <mat-icon class="warning-color">
                                            delete_outline</mat-icon>
                                    </button>
                                    <mat-divider></mat-divider>
                                </mat-list-item>
                            </mat-nav-list>
    
                            <div class="centered" *ngIf="attributesList.length == 0">
                                <button mat-button (click)="addAttribute('add', {})" class="add-location-button">
                                    <mat-icon>add</mat-icon>
                                    Add Attribute
                                </button>
                            </div>
                        </span>
                       </div>
                       <div fxFill="40" style="overflow-y: auto;">
                        <span *ngIf="selectedCategoryIndex >= 0 && selectedCategoryIndex != null">
                            <mat-toolbar class="toolbar-fixed-1" style="height:50px !important;">
                                BOMs
                                <span class="example-spacer"></span>
                                <button matTooltip="Add Value" mat-icon-button (click)="addNewBom()">
                                    <mat-icon class="material-icons-outlined">add</mat-icon>
                                </button>
                            </mat-toolbar>
                    
                            <mat-progress-bar *ngIf="isShowBomProgressBar" mode="indeterminate">
                            </mat-progress-bar>
                    
                            <mat-nav-list style="margin-top: -3px !important;" *ngIf="bomsList.length > 0">
                                <mat-list-item *ngFor="let bom of bomsList; index as i">
                                    <h4 matLine>{{bom.NAME}}</h4>
                                    <button mat-icon-button (click)="deleteBom(bom)">
                                        <mat-icon class="warning-color">
                                            delete_outline</mat-icon>
                                    </button>
                                    <mat-divider></mat-divider>
                                </mat-list-item>
                            </mat-nav-list>
    
                            <div class="centered" *ngIf="bomsList.length == 0">
                                <button mat-button (click)="addNewBom()" class="add-location-button">
                                    <mat-icon>add</mat-icon>
                                    Add BOM
                                </button>
                            </div>
                        </span>
                       </div>
                    </div>
                </div>
            </div>
        </div>

    </mat-sidenav-content>
</mat-sidenav-container>