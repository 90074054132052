import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  mode: number;
  item: any;
}


@Component({
  selector: 'app-email-cofirmation',
  templateUrl: './email-cofirmation.component.html',
  styleUrls: ['./email-cofirmation.component.scss']
})
export class EmailCofirmationComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<EmailCofirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
}
