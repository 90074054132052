import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from '../app.config';
import { AppConstants } from '../shared/AppConstants';

@Injectable({
  providedIn: 'root',
})
export class BillableReportService {
  public constants: AppConstants;
  constructor(
    private appConfigService: AppConfigService,
    private http: HttpClient
  ) {
    this.constants = new AppConstants();
  }

  getBillableReports() {
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(
      url + this.constants.GET_BILLABLE_UNIT_REPORT_URL,
      httpOptions
    );
  }

  generateReport(
    reportId: any,
    startDate: any,
    endDate?: any,
  ) {
    let tempParams: any = {
      REPORT_ID: reportId,
      START_DATE: startDate,
      END_DATE: endDate ? endDate : ''
    };
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      params: tempParams,
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(url + this.constants.GENERATE_BILLABLE_REPORTS_URL, httpOptions);
  }

  createReportType(body: any): Observable<any> {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.post<any>(
      url + this.constants.GET_BILLABLE_UNIT_REPORT_URL,
      body,
      httpOptions
    );
  }

  updateReportType(body: any) {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.put<any>(
      url + this.constants.GET_BILLABLE_UNIT_REPORT_URL,
      body,
      httpOptions
    );
  }

  deleteReportType(body: any): Observable<any> {
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      body: body,
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.delete<any>(
      url + this.constants.GET_BILLABLE_UNIT_REPORT_URL,
      httpOptions
    );
  }
}
