<mat-toolbar class="app-toolbar-fixed">
    <span> Select Customer</span>
    <span class="example-spacer"></span>
    <button mat-icon-button (click)="closeDialog()" onclick="this.blur()" *ngIf="data.mode == 0">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>

<mat-sidenav-container>
    <mat-progress-bar *ngIf="isShowProgressBar" class="primary-color" mode="indeterminate">
    </mat-progress-bar>
    <mat-list role="list" style="padding-top:0px !important">
        <mat-list-item style="cursor: pointer" role="listitem" *ngFor="let customer of this.data.item" [ngClass]="selectedTenant == customer.CUSTOMER_ID ? 'active': ''"
            (click)="selectedCustomer(customer)">
            <mat-icon class="primary-color material-icons-outlined" mat-list-icon>corporate_fare</mat-icon>
            <div mat-line>{{customer.CUSTOMER_NAME}}</div>
        </mat-list-item>
    </mat-list>
</mat-sidenav-container>