<mat-toolbar layout="row">
    <span> {{title}} </span>
    <span class="example-spacer"></span>
    <button mat-icon-button (click)="deleteChoice()" onclick="this.blur()" matTooltip="Delete"
        *ngIf="data?.type == 'update' && (data?.item?.type != 'AUDIT_SCORES' && data?.item?.type != 'UOM')">
        <mat-icon>delete_outline</mat-icon>
    </button>
    <button mat-icon-button (click)="closeDialog()" onclick="this.blur()">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>

<ng-container>
    <mat-progress-bar *ngIf="isShowProgressBar" mode="indeterminate">
    </mat-progress-bar>
    <form style="padding: 15px;font-size: 14.5px !important;" [formGroup]="formGroup">
        <mat-form-field appearance="legacy">
            <mat-label>Enter Name</mat-label>
            <input matInput type="text" required formControlName="name">
        </mat-form-field>
        <mat-form-field appearance="legacy">
            <mat-label>Enter Value</mat-label>
            <input matInput type="text" required formControlName="value">
        </mat-form-field>
        <mat-form-field appearance="legacy" *ngIf="data?.item?.type == 'AUDIT_SCORES'">
            <mat-label>Enter Score</mat-label>
            <input matInput type="number" required formControlName="score">
        </mat-form-field>

        <div style="text-align:center;padding-top:10px">
            <button mat-raised-button (click)="saveValue()" [ngClass]="formGroup.valid ? 'success-button' : ''"
                [disabled]="!formGroup.valid || isShowProgressBar">
                <span *ngIf="data?.type == 'add'">&nbsp;&nbsp;&nbsp;&nbsp; Add &nbsp;&nbsp;&nbsp;&nbsp;</span>
                <span *ngIf="data?.type == 'update'">&nbsp;&nbsp; Update &nbsp;&nbsp;</span>
            </button>
        </div>

        <div *ngIf="errorMsg.length> 0" style="text-align: center;color: rgb(228, 0, 43)">
            <p>{{errorMsg}}</p>
        </div>
    </form>
</ng-container>