import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { AppConstants } from 'src/app/shared/AppConstants';

export interface DialogData {
  item: any;
}

@Component({
  selector: 'app-audit-equipments',
  templateUrl: './audit-equipments.component.html',
  styleUrls: ['./audit-equipments.component.scss'],
})
export class AuditEquipmentsComponent implements OnInit {
  public equipments: any = [];
  public subscriptions: Subscription[] = [];
  public serviceRequestDetails: any;
  public isShowProgressBar: boolean = false;
  public isShowSpinner: boolean = false;
  public noDetailsFlag: boolean = false;
  public constants: AppConstants;

  constructor(
    public authService: AuthService,
    public router: Router,
    public dialogRef: MatDialogRef<AuditEquipmentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.constants = new AppConstants();
  }

  ngOnInit(): void {
    this.data.item.RESULTS == null ?  this.equipments = this.data?.item?.EQUIPMENTS : [];
    this.data.item.RESULTS != null ? this.equipments = this.data?.item?.RESULTS: [];
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
