<p>&nbsp</p>
<mat-toolbar class="toolbar">
  <button mat-icon-button (click)="authService.isExpanded = !authService.isExpanded">
    <mat-icon>menu</mat-icon>
  </button>
    {{utilService.customerName}}
  <div class="company-logo">
    <button mat-button routerLink="/dashboard">
      <img class="company-image" src="assets/logo/AFS_PrimaryLogo_1C_White.png" />
    </button>
  </div>
  <span class="example-spacer"></span>
  <button matTooltip="Switch Company" mat-icon-button *ngIf="authService.isAfsUser()" (click)="switchCompany()" >
    <mat-icon style="font-size: 29px;" class="material-icons-outlined">swap_horiz</mat-icon>
  </button> &nbsp;
  <button matTooltip="Logout" mat-icon-button (click)="logout()">
    <mat-icon class="material-icons-outlined">logout</mat-icon>
  </button>
</mat-toolbar>

<mat-sidenav-container class="sidenav-container" autosize [hasBackdrop]="false" style="margin-top:28px !important">
  <mat-sidenav #drawer [fixedInViewport]="true" [disableClose]="true" [fixedTopGap]="55"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'side' : 'side'"
    [opened]="true">
    <div class="side-menu-height">
    <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('ADMIN')  && authService.isNotAfsCustomer()">
      <a mat-list-item [routerLink]="['/dashboard']" matTooltip="Dashboard" matTooltipPosition="right"
        routerLinkActive="active-list-item">
        <mat-icon class="material-icons-outlined">dashboard</mat-icon>
        <span [translate]="'Dashboard'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Dashboard</span>
      </a>
    </mat-nav-list>
    <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('ADMIN') && authService.isNotAfsCustomer()">
      <a mat-list-item [routerLink]="['/reports']" matTooltip="Reports" matTooltipPosition="right"
        routerLinkActive="active-list-item">
        <mat-icon class="material-icons-outlined">auto_awesome_mosaic</mat-icon>
        <span [translate]="'Reports'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Reports</span>
      </a>
    </mat-nav-list>
    <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('CUSTOMER')">
      <a mat-list-item [routerLink]="['/customers']" matTooltip="Customers" matTooltipPosition="right"
        routerLinkActive="active-list-item">
        <mat-icon>corporate_fare</mat-icon>
        <span [translate]="'Customers'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Customers</span>
      </a>
    </mat-nav-list>
    <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('USER')">
      <a mat-list-item [routerLink]="['/users']" matTooltip="Users" matTooltipPosition="right"
        routerLinkActive="active-list-item">
        <mat-icon class="material-icons-outlined">people</mat-icon>
        <span [translate]="'Users'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Users</span>
      </a>
    </mat-nav-list>
    <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('SERVICE_REQUEST')">
      <a mat-list-item [routerLink]="['/service-requests']" matTooltipPosition="right" matTooltip="Service Requests"
        routerLinkActive="active-list-item">
        <mat-icon class="material-icons-outlined">content_paste</mat-icon>

        <span [translate]="'Service Requests'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Service
          Requests</span>
      </a>
    </mat-nav-list>
    <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('SCHEDULED_SERVICE_REQUEST')">
      <a mat-list-item [routerLink]="['/timed-services']" matTooltipPosition="right"
          matTooltip="Timed Services" routerLinkActive="active-list-item">
          <mat-icon class="material-icons-outlined">timer</mat-icon>
          <span [translate]="'Timed Services'" *ngIf="authService.isExpanded"
              class="menu-icon-spacing">Timed Services</span>
      </a>
  </mat-nav-list>
  <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('SCHEDULED_SERVICE_REQUEST')">
      <a mat-list-item [routerLink]="['/timed-service-schedules']" matTooltipPosition="right"
          matTooltip="Timed Service Schedules" routerLinkActive="active-list-item">
          <mat-icon class="material-icons">pending_actions</mat-icon>
          <span [translate]="'Timed Service Schedules'" *ngIf="authService.isExpanded"
              class="menu-icon-spacing">Timed Service Schedules</span>
      </a>
  </mat-nav-list>
    <mat-nav-list
      *ngIf="(authService.checkRoleBasedModuleAccess('MESSAGE') || authService.checkRoleBasedModuleAccess('SERVICE_REQUEST'))">
      <a mat-list-item [routerLink]="['/notifications']" matTooltip="Notifications" matTooltipPosition="right"
        routerLinkActive="active-list-item">
        <mat-icon class="material-icons-outlined">feedback</mat-icon>
        <span [translate]="'Notifications'" *ngIf="authService.isExpanded"
          class="menu-icon-spacing">Notifications</span>
      </a>
    </mat-nav-list>
    <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('AUDIT')">
      <a mat-list-item [routerLink]="['/audits']" matTooltip="Services " matTooltipPosition="right"
        routerLinkActive="active-list-item">
        <mat-icon>checklist_rtl</mat-icon>
        <span [translate]="'Services'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Services </span>
      </a>
    </mat-nav-list>
    <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('AUDIT')">
      <a mat-list-item [routerLink]="['/audit-schedule']" matTooltip="Scheduled Services" matTooltipPosition="right"
        routerLinkActive="active-list-item">
        <mat-icon>schedule</mat-icon>
        <span [translate]="'Scheduled Services'" *ngIf="authService.isExpanded" class="menu-icon-spacing">
          Scheduled Services
        </span>
      </a>
    </mat-nav-list>
    <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('LOPA')">
      <a mat-list-item [routerLink]="['/lopa']" matTooltip="LOPA Management" matTooltipPosition="right"
        routerLinkActive="active-list-item">
        <mat-icon class="material-icons-outlined">flight</mat-icon>
        <span [translate]="'Lopa'" *ngIf="authService.isExpanded" class="menu-icon-spacing">LOPA Management
        </span>
      </a>
    </mat-nav-list>
    <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('EQUIPMENT')">
      <a mat-list-item [routerLink]="['/locations']" matTooltip="Locations" matTooltipPosition="right"
        routerLinkActive="active-list-item">
        <mat-icon class="material-icons-outlined">my_location</mat-icon>
        <span [translate]="'Locations'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Locations
        </span>
      </a>
    </mat-nav-list>
    <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('EQUIPMENT')">
      <a mat-list-item [routerLink]="['/parts']" matTooltip="Parts" matTooltipPosition="right"
        routerLinkActive="active-list-item">
        <mat-icon class="material-icons-outlined">chair_alt</mat-icon>
        <span [translate]="'Parts'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Parts
        </span>
      </a>
    </mat-nav-list>
    <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('BUDGET')">
      <a mat-list-item [routerLink]="['/budget']" matTooltip="Budget" matTooltipPosition="right"
        routerLinkActive="active-list-item">
        <mat-icon class="material-icons-outlined">card_travel</mat-icon>
        <span [translate]="'Budget'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Budget
        </span>
      </a>
    </mat-nav-list>
    <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('INVOICE')">
      <a mat-list-item [routerLink]="['/invoice']" matTooltip="Invoice" matTooltipPosition="right"
        routerLinkActive="active-list-item">
        <mat-icon class="material-icons-outlined">description</mat-icon>
        <span [translate]="'Invoice'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Invoice
        </span>
      </a>
    </mat-nav-list>
  <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('PART_ORDER') || authService.checkRoleBasedModuleAccess('PART_ORDER_READ_ONLY')">
    <a mat-list-item [routerLink]="['/part-orders']" matTooltip="Part Orders" matTooltipPosition="right"
        routerLinkActive="active-list-item">
        <mat-icon class="material-icons-outlined">add_shopping_cart</mat-icon>
        <span [translate]="'Part Orders'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Part Orders
        </span>
    </a>
</mat-nav-list>
<mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('BILLABLE_UNIT_REPORT') || authService.checkRoleBasedModuleAccess('BILLABLE_UNIT_REPORT_READ_ONLY')">
    <a mat-list-item [routerLink]="['/billable-reports']" matTooltip="Billable Reports" matTooltipPosition="right"
        routerLinkActive="active-list-item">
        <mat-icon class="material-symbols-outlined material-icons-outlined">receipt_long</mat-icon>
        <span [translate]="'Billable Reports'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Billable Reports
        </span>
    </a>
    </mat-nav-list>
    </div>
    <div [ngClass]='appropriateClass'>
    <mat-nav-list>
      <a mat-list-item [routerLink]="['/settings']" matTooltip="Settings" matTooltipPosition="right"
        routerLinkActive="active-list-item" *ngIf="authService.checkRoleBasedModuleAccess('SETTINGS')">
        <mat-icon class="material-icons-outlined">settings</mat-icon>
        <span [translate]="'Settings'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Settings
        </span>
      </a>

      <a (click)="authService.isExpanded = !authService.isExpanded" mat-list-item>
        <mat-icon *ngIf="authService.isExpanded">keyboard_double_arrow_left</mat-icon>
        <mat-icon *ngIf="!authService.isExpanded">keyboard_double_arrow_right</mat-icon>
        <span [translate]="'Collapse sidebar'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Collapse
          sidebar</span>
        <span slot="right" style="color: grey;font-size: small; margin: 0 0 0 auto;" *ngIf="authService.isExpanded"
          class="menu-icon-spacing">
          {{version }}</span>
      </a>
    </mat-nav-list>
    </div>
  </mat-sidenav>

  <!--#region SideNav Content-->
  <mat-sidenav-content>
    <mat-toolbar class="toolbar-height-50">
      <span>Services</span>
      <span class="toolbar-spacer"></span>
      <button mat-icon-button (click)="filterAudits()" matTooltip="Filter">
        <mat-icon class="material-icons-outlined">filter_alt</mat-icon>
      </button>
      <button mat-icon-button (click)="refreshAudits()" matTooltip="Refresh">
        <mat-icon class="material-icons-outlined">refresh</mat-icon>
      </button>
    </mat-toolbar>

    <div class="container mat-elevation-z4">
      <mat-progress-bar *ngIf="isShowAuditsProgressBar" class="primary-color" mode="indeterminate">
      </mat-progress-bar>
      <mat-toolbar class="container-toolbar">
        <mat-toolbar-row class="container-toolbar-row">
          <span *ngIf="!isScheduleIdPresent" style="padding-left: 15px; color:#00629b;font-size:18px">Status -
            <span style="font-size:14px;color:black">
              {{auditStatus}}
            </span>
          </span>
          <span *ngIf="isScheduleIdPresent" style="color:#00629b;font-size:18px">
            <button mat-button (click)="goToAuditSchedule()">
              <mat-icon style="color:#00629b;">arrow_back</mat-icon> &nbsp; <span>
                Go back to schedules
              </span>
            </button>
          </span>
          <span class="example-spacer"></span>
          <button mat-raised-button color="warn" [disabled]="!auditSelection.hasValue()" (click)="deleteAuditsConfirmation()" *ngIf="auditStatus == 'OPEN'">Delete</button>
          <mat-paginator #paginator [pageSize]="paginatorLength" [pageSizeOptions]="[5, 10, 25, 50, 100]"
            (page)="handlePaginator($event);" [length]="auditsLength">
          </mat-paginator>
        </mat-toolbar-row>
      </mat-toolbar>

      <div class="table-container">
        <table mat-table (matSortChange)="sortfields($event)" [dataSource]="auditsDataSource" matSort
          class="example-table">

          <ng-container matColumnDef="select" *ngIf="auditStatus == 'OPEN'">
            <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? auditsMasterToggle() : null"
                    [checked]="auditSelection.hasValue() && isAuditsAllSelected()"
                    [indeterminate]="auditSelection.hasValue() && !isAuditsAllSelected()">
                </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()"
                    (change)="toggeleAuditsSelection($event, row)"
                    [checked]="selectedAudit(row)">
                </mat-checkbox>
            </td>
        </ng-container>

          <!-- ID Column -->
          <ng-container matColumnDef="AUDIT_ID">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
            <td mat-cell *matCellDef="let row"> {{row.AUDIT_ID}} </td>
          </ng-container>

          <!-- Location Column -->
          <ng-container matColumnDef="LOCATION">
            <th mat-header-cell *matHeaderCellDef> Location </th>
            <td mat-cell *matCellDef="let row">
                <span *ngFor="let loc of row.LOCATION; let last = last;">
                  {{loc.NAME}}<span *ngIf="!last">,</span>
                </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="SERVICE_TYPE">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Service Type </th>
            <td mat-cell *matCellDef="let row"> {{row.SERVICE_TYPE |  UppercaseToLowerCaseFilter}} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="SCHEDULE">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
            <td mat-cell *matCellDef="let row"> {{row.SCHEDULE}} </td>
          </ng-container>

          <!-- City Column -->
          <ng-container matColumnDef="DESCRIPTION">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
            <td mat-cell *matCellDef="let row"> {{row.DESCRIPTION}} </td>
          </ng-container>


          <!-- Status Column -->
          <ng-container matColumnDef="STATUS">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-left: 5px;"> Status </th>
            <td mat-cell *matCellDef="let row">
              <div class="badge open" style="padding-left:15.5px !important; padding-right: 15.5px !important;"
                *ngIf="row.STATUS == 'OPEN'">
                {{row.STATUS}}
              </div>
              <div class="badge pause" *ngIf="row.STATUS == 'PAUSE'">
                {{row.STATUS}}
              </div>
              <div class="badge completed" *ngIf="row.STATUS == 'CLOSED'">
                {{row.STATUS}}
              </div>
              <div class="badge danger" *ngIf="row.STATUS == 'CANCEL'">
                {{row.STATUS}}
              </div>
              <div class="badge blue" *ngIf="row.STATUS == 'INCOMPLETE'">
                {{row.STATUS}}
              </div>
            </td>
          </ng-container>

          <!-- Country Column -->
          <ng-container matColumnDef="UPDATED_BY_NAME">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Updated By </th>
            <td mat-cell *matCellDef="let row"> {{row.UPDATED_BY_NAME}} </td>
          </ng-container>

          <!-- State Column -->
          <ng-container matColumnDef="UPDATED_AT">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Updated At </th>
            <td mat-cell *matCellDef="let row"> {{returnDate(row.UPDATED_AT)}} </td>
          </ng-container>

          <!-- Equipment Status Column -->
          <ng-container matColumnDef="Equipment_Status">
            <th mat-header-cell *matHeaderCellDef> Equipment Status </th>
            <td mat-cell *matCellDef="let row">
              <span matTooltip="Scanned" *ngIf="row.STATUS == 'CLOSED'">
                <mat-icon class="scanned material-icons-outlined" style="font-size: 21.5px;">
                  qr_code_scanner </mat-icon>
                <span class="scanned" style="vertical-align: super;">
                  <span *ngIf="row.EQUIPMENT_COUNTS?.Scanned != null">
                    {{row.EQUIPMENT_COUNTS?.Scanned}}
                  </span>
                  <span *ngIf="row.EQUIPMENT_COUNTS?.Scanned == null">
                    0
                  </span>
                </span>
              </span>
              &nbsp;
              <span matTooltip="Movement" *ngIf="row.STATUS == 'CLOSED'">
                <mat-icon class="moved material-icons-outlined"> local_shipping </mat-icon>
                <span class="moved" style="vertical-align: super;">
                  <span *ngIf="row.EQUIPMENT_COUNTS?.Movement != null">
                    {{row.EQUIPMENT_COUNTS?.Movement}}
                  </span>
                  <span *ngIf="row.EQUIPMENT_COUNTS?.Movement == null">
                    0
                  </span>
                </span>
              </span>
              &nbsp;
              <span matTooltip="Unscanned" *ngIf="row.STATUS == 'CLOSED'">
                <mat-icon class="unscanned material-icons-outlined" style="font-size: 21.5px;">
                  qr_code_scanner </mat-icon>
                <span class="unscanned" style="vertical-align: super;">
                  <span *ngIf="row.EQUIPMENT_COUNTS?.Unscanned != null">
                    {{row.EQUIPMENT_COUNTS?.Unscanned}}
                  </span>
                  <span *ngIf="row.EQUIPMENT_COUNTS?.Unscanned == null">
                    0
                  </span>
                </span>
              </span>
              &nbsp;
              <span matTooltip="Inactive" *ngIf="row.STATUS == 'CLOSED'">
                <mat-icon class="inactive material-icons-outlined"> highlight_off </mat-icon>
                <span class="inactive" style="vertical-align: super;">
                  <span *ngIf="row.EQUIPMENT_COUNTS?.Inactive != null">
                    {{row.EQUIPMENT_COUNTS?.Inactive}}
                  </span>
                  <span *ngIf="row.EQUIPMENT_COUNTS?.Inactive == null">
                    0
                  </span>
                </span>
              </span>
              &nbsp;
              <span mat-icon-button matTooltip="Open" *ngIf="row.STATUS == 'OPEN' || row.STATUS == 'INCOMPLETE'">
                <mat-icon class="moved material-icons-outlined"> qr_code_scanner </mat-icon>
                <span class="moved" style="vertical-align: super;"> {{row.EQUIPMENTS.length}} </span>
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="SCORE">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Score </th>
            <td mat-cell *matCellDef="let row">
              <span *ngIf="scoreCalculation(row.SCORE) == 100"
                style="color:rgb(37, 155, 36);font-weight: bold;">{{scoreCalculation(row.SCORE)}}
                %</span>
              <span *ngIf="scoreCalculation(row.SCORE) != 100">{{scoreCalculation(row.SCORE)}} %</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="NOTIFICATION_COUNT">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Notifications </th>
            <td mat-cell *matCellDef="let row" style="padding-left: 18px;">
              <span>
                <button mat-icon-button matTooltip="Go To Notifications"
                  (click)="row.NOTIFICATION_COUNT > 0 ? gotoNotifications(row) : ''">
                  <mat-icon *ngIf="row.NOTIFICATION_COUNT > 0" class="moved material-icons-outlined">
                    assistant_direction </mat-icon>
                  <span class="moved" style="vertical-align: baseline;">
                    {{row.NOTIFICATION_COUNT}} </span>
                </button>
              </span>
            </td>
          </ng-container>

          <!-- Actions Column -->
          <ng-container matColumnDef="Actions">
            <th mat-header-cell *matHeaderCellDef style="padding-left: 15px;"> Actions </th>
            <td mat-cell *matCellDef="let row">
              <span style="padding-left: 15px;">
                <button mat-icon-button matTooltip="Equipment List" (click)="showAuditEquipments(row)">
                  <mat-icon class="primary-color material-icons-outlined"> list_alt </mat-icon>
                </button>

                <button mat-icon-button matTooltip="Go to Sub Audits" (click)="goToSubAudits(row)">
                  <mat-icon class="primary-color material-icons-outlined">assistant_direction
                  </mat-icon>
              </button>
              </span>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="tableColumnsTest(); sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: tableColumnsTest();"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="9999" style="color:rgb(228, 0, 43)">
              <span *ngIf="auditsDataSource?.data?.length == 0 && !isShowAuditsProgressBar">
                No services found
              </span>
              <span *ngIf="auditsDataSource?.data?.length == 0 && isShowAuditsProgressBar">
              </span>
            </td>
          </tr>
        </table>
      </div>

    </div>
  </mat-sidenav-content>
  <!--#endregion -->
</mat-sidenav-container>