import { Component, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AuthService } from 'src/app/services/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppConstants } from 'src/app/shared/AppConstants';
import { ServiceRequestFilterComponent } from 'src/app/components/service-request-filter/service-request-filter.component';
import { MatDialog } from '@angular/material/dialog';
import { ServiceRequestService } from 'src/app/services/service-request.service';
import { ErrorComponent } from 'src/app/components/error/error.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { CommentComponent } from 'src/app/components/comment/comment.component';
import { FullScreenImageComponent } from 'src/app/components/full-screen-image/full-screen-image.component';
import { ServiceLogsComponent } from 'src/app/components/service-logs/service-logs.component';
import { EquipmentDetailsComponent } from 'src/app/components/equipment-details/equipment-details.component';
import { ErrorAlertComponent } from 'src/app/components/error alert/error-alert.component';
import { NotificationsService } from 'src/app/services/notifications.service';
import { ScheduledServiceRequestService } from 'src/app/services/scheduled-service-request.service';
import { SelectionModel } from '@angular/cdk/collections';
import { CustomerSelectComponent } from 'src/app/components/customer-select/customer-select.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-service-requests',
  templateUrl: './service-requests.component.html',
  styleUrls: ['./service-requests.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ServiceRequestsComponent implements OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator!: any;
  @ViewChild(MatSort, { static: true }) sort!: any;

  @ViewChild(MatPaginator, { static: false }) paginator1!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort1!: MatSort;

  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  public selectedItem: any = {};
  public expandedElement: any;
  public tableColumns: string[] = [
    'SAFETY',
    'REQUEST_ID',
    'EQUIPMENT_NAME',
    'TASK_TYPE',
    'LOCATION',
    'STATUS',
    'CREATED_BY',
    'CREATED_AT',
    'REMAINING_REQUEST_TIME',
    'Actions',
  ];

  public serviceRequestsDataSource: MatTableDataSource<any>;
  public searchTerm: string;
  public isSearchHasValue: boolean;
  public isShowSRequestsProgressBar: boolean;
  public usersLength: number;
  public paginatorLength: number;
  public constants: AppConstants;
  public subscriptions: Subscription[] = [];
  public loggedInCustomerId: any;
  public serviceRequestDetails: any;
  public timeout: any = null;
  public requestId: any;
  public scheduleId: any;
  public isServiceRequestFromQueryParam: boolean;
  public isServiceRequestSchedulePresent: boolean;
  public version: string;
  public release: string;
  public airlinePartsObj = {};
  public partsColumns: string[] = [
    'PART_ID',
    'NAME',
    'MANUFACTURER',
    'MFG_PART_NUMBER',
    'COUNT',
    'PART_TYPE',
    'UOM',
    'AVAILABILITY',
    'COST'
  ];
  public partDataSource: MatTableDataSource<any>;
  public isAirlineCusromer: boolean = false;
  public serviceRequestsSelection = new SelectionModel<any>(true, []);
  public isTableEditable: boolean = false;
  public appropriateClass:string = '';
  public events: string[] = [];
  
  @HostListener('window:resize', ['$event'])
  getScreenHeight(event?: any){
    if(window.innerHeight<=700){
      this.appropriateClass = 'bottomRelative';
    }else{
      this.appropriateClass = 'bottomStick';
    }
  }
  constructor(
    public authService: AuthService,
    private breakpointObserver: BreakpointObserver,
    public utilService: UtilService,
    public dialog: MatDialog,
    public serviceRequestService: ServiceRequestService,
    public router: Router,
    public sanitizer: DomSanitizer,
    public notificationsService: NotificationsService,
    public scheduledServiceRequest: ScheduledServiceRequestService,
  ) {
    this.getScreenHeight();
    this.isServiceRequestFromQueryParam = false;
    this.isServiceRequestSchedulePresent = false;
    this.constants = new AppConstants();
    this.version = this.constants.APP_RELEASE_NUMBER;
    this.release = this.constants.APP_RELEASE_DATE;
    this.searchTerm = '';
    this.usersLength = 0;
    this.requestId = null;
    this.scheduleId = null;
    this.paginatorLength = this.constants.INITIAL_PAGINATOR_LIMIT;
    this.isSearchHasValue = false;
    this.isShowSRequestsProgressBar = false;
    this.serviceRequestsDataSource = new MatTableDataSource();
    this.partDataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    let customerId: any = localStorage.getItem('customerId');
    let temp: any = localStorage.getItem('user-details');
    let user: any = JSON.parse(temp);
    this.isAirlineCusromer = user.CUSTOMER_TYPE == 'AIRLINE' ? true : false;
    if (!this.isAirlineCusromer) {
      this.tableColumns = [
        'SAFETY',
        'REQUEST_ID',
        'TAG',
        'EQUIPMENT_NAME',
        'TASK_TYPE',
        'LOCATION',
        'STATUS',
        'CREATED_BY',
        'CREATED_AT',
        'REMAINING_REQUEST_TIME',
        'Actions',
      ];
    } else {
      this.tableColumns = [
        'SAFETY',
        'REQUEST_ID',
        'EQUIPMENT_NAME',
        'TASK_TYPE',
        'LOCATION',
        'STATUS',
        'CREATED_BY',
        'CREATED_AT',
        'REMAINING_REQUEST_TIME',
        'Actions',
      ];
    }
    if (customerId && customerId != null) {
      customerId == 1
        ? (this.authService.isAFSUser = true)
        : (this.authService.isAFSUser = false);
    }
    if (this.notificationsService.notificationsPageStateDetails != null) {
      this.requestId =
        this.notificationsService.notificationsPageStateDetails?.requestId;
      if (this.requestId) {
        this.isServiceRequestFromQueryParam = true;
        this.getNotificationServiceRequestById(this.requestId);
      }
    } else if (
      this.scheduledServiceRequest.scheduledServiceRequestPageStateDetails !=
      null
    ) {
      this.scheduleId = this.scheduledServiceRequest
        .scheduledServiceRequestPageStateDetails?.scheduleId
        ? this.scheduledServiceRequest.scheduledServiceRequestPageStateDetails
          ?.scheduleId
        : '';
      if (this.scheduleId) {
        this.isServiceRequestSchedulePresent = true;
        this.getServiceRequests(
          this.paginatorLength,
          0,
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          this.scheduleId
        );
      }
    } else {
      // Get all service requests
      this.getServiceRequests(this.paginatorLength, 0, '');
    }
  }

  // Logout from the app
  logout() {
    this.authService.logout();
  }

  // Destroy all the http calls when page leaving
  ngOnDestroy() {
    if (this.router?.url && this.router?.url?.length > 0) {
      if (this.router?.url != '/notifications') {
        this.notificationsService.notificationsPageStateDetails = null;
      }

      if (this.router?.url != '/timed-service-schedules') {
        this.scheduledServiceRequest.scheduledServiceRequestPageStateDetails =
          null;
      }
    }
    this.serviceRequestService.filterDetails = null;
    this.cancelOutstandingRequests();
  }

  // Cancel on going http calls when page leaving
  cancelOutstandingRequests() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  // Search filter
  searchServiceRequestsFilter(event: any) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      let status = '';
      if (
        $this.serviceRequestService.filterDetails?.status &&
        $this.serviceRequestService.filterDetails?.status != ''
      ) {
        status = $this.serviceRequestService.filterDetails?.status;
      }

      let barcode = '';
      if (
        $this.serviceRequestService.filterDetails?.barcode &&
        $this.serviceRequestService.filterDetails?.barcode != ''
      ) {
        barcode = $this.serviceRequestService.filterDetails?.barcode;
      }

      let part = '';
      if (
        $this.serviceRequestService.filterDetails?.part &&
        $this.serviceRequestService.filterDetails?.part != ''
      ) {
        part = $this.serviceRequestService.filterDetails?.part;
      }

      let unit = '';
      if (
        $this.serviceRequestService.filterDetails?.unit &&
        $this.serviceRequestService.filterDetails?.unit != ''
      ) {
        unit = $this.serviceRequestService.filterDetails?.unit;
      }

      let task = '';
      if (
        $this.serviceRequestService.filterDetails?.task &&
        $this.serviceRequestService.filterDetails?.task != ''
      ) {
        task = $this.serviceRequestService.filterDetails?.task;
      }

      let location = '';
      if (
        $this.serviceRequestService.filterDetails?.location &&
        $this.serviceRequestService.filterDetails?.location != ''
      ) {
        location = $this.serviceRequestService.filterDetails?.location;
      }

      if (
        $this.serviceRequestService.filterDetails?.isSafetyServiceRequests &&
        $this.serviceRequestService.filterDetails?.isSafetyServiceRequests == true
      ) {
        $this.getServiceRequests(
          $this.constants.INITIAL_PAGINATOR_LIMIT,
          0,
          event.target.value,
          '',
          '',
          status,
          barcode,
          part,
          unit,
          task,
          location,
          '',
          '',
          '',
          'Y'
        );
      } else {
        $this.getServiceRequests(
          $this.constants.INITIAL_PAGINATOR_LIMIT,
          0,
          event.target.value,
          '',
          '',
          status,
          barcode,
          part,
          unit,
          task,
          location
        );
      }
      $this.paginator.pageIndex = 0;
    }, 1000);
  }

  refreshServiceRequests() {
    this.isTableEditable = false;
    this.serviceRequestsSelection.clear();
    this.serviceRequestService.filterDetails = null;
    this.displayedColumns();
    this.cancelOutstandingRequests();
    this.paginator.pageIndex = 0;
    this.getServiceRequests(this.constants.INITIAL_PAGINATOR_LIMIT, 0);
  }

  // Clear search
  clearSearch() {
    this.searchTerm = '';
    let status = '';
    if (
      this.serviceRequestService.filterDetails?.status &&
      this.serviceRequestService.filterDetails?.status != ''
    ) {
      status = this.serviceRequestService.filterDetails?.status;
    }

    let barcode = '';
    if (
      this.serviceRequestService.filterDetails?.barcode &&
      this.serviceRequestService.filterDetails?.barcode != ''
    ) {
      barcode = this.serviceRequestService.filterDetails?.barcode;
    }

    let part = '';
    if (
      this.serviceRequestService.filterDetails?.part &&
      this.serviceRequestService.filterDetails?.part != ''
    ) {
      part = this.serviceRequestService.filterDetails?.part;
    }

    let unit = '';
    if (
      this.serviceRequestService.filterDetails?.unit &&
      this.serviceRequestService.filterDetails?.unit != ''
    ) {
      unit = this.serviceRequestService.filterDetails?.unit;
    }

    let task = '';
    if (
      this.serviceRequestService.filterDetails?.task &&
      this.serviceRequestService.filterDetails?.task != ''
    ) {
      task = this.serviceRequestService.filterDetails?.task;
    }

    let location = '';
    if (
      this.serviceRequestService.filterDetails?.location &&
      this.serviceRequestService.filterDetails?.location != ''
    ) {
      location = this.serviceRequestService.filterDetails?.location;
    }

    if (
      this.serviceRequestService.filterDetails?.isSafetyServiceRequests &&
      this.serviceRequestService.filterDetails?.isSafetyServiceRequests == true
    ) {
      this.getServiceRequests(
        this.constants.INITIAL_PAGINATOR_LIMIT,
        0,
        '',
        '',
        '',
        status,
        barcode,
        part,
        unit,
        task,
        location,
        '',
        '',
        '',
        'Y'
      );
    } else {
      this.getServiceRequests(
        this.constants.INITIAL_PAGINATOR_LIMIT,
        0,
        '',
        '',
        '',
        status,
        barcode,
        part,
        unit,
        task,
        location
      );
    }
    this.paginator.pageIndex = 0;
  }

  // Get service requests
  getServiceRequests(
    limit: number,
    offset: number,
    searchText?: any,
    sortField?: any,
    sortDirection?: any,
    status?: any,
    barcode?: any,
    part?: any,
    unit?: any,
    task?: any,
    location?: any,
    equipmentName?: any,
    scheduleId?: any,
    requestType?: any,
    isSafetyServiceRequests?:any
  ) {
    this.isShowSRequestsProgressBar = true;
    let serviceRequestsSubscription = this.serviceRequestService
      .getServiceRequests(
        limit,
        offset,
        searchText,
        sortField,
        sortDirection,
        status,
        barcode,
        part,
        unit,
        task,
        location,
        equipmentName,
        scheduleId,
        requestType,
        isSafetyServiceRequests
      )
      .subscribe(
        (response: any) => {
          // Success callback
          if (response.status === 200) {
            if (response.body) {
              // Assign the data to the data source for the table to render
              this.serviceRequestsDataSource = new MatTableDataSource(
                response.body.SERVICE_REQUESTS
              );
              this.usersLength = response.body.SERVICE_REQUESTS_COUNT;
            }
            this.isShowSRequestsProgressBar = false;
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.isShowSRequestsProgressBar = false;
            this.router.navigate(['dashboard']);
          } else {
            this.isShowSRequestsProgressBar = false;
            this.errorDialog(response.error);
          }
        },
        (error) => {
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.isShowSRequestsProgressBar = false;
            this.router.navigate(['dashboard']);
          } else {
            this.isShowSRequestsProgressBar = false;
            this.errorDialog(error.message);
          }
        }
      );
    this.subscriptions.push(serviceRequestsSubscription);
  }

  errorDialog(msg: string) {
    const dialogRef = this.dialog.open(ErrorAlertComponent, {
      width: '400px',
      data: {
        errorMsg: msg,
      },
    });
  }

  // Filter service requests
  filterServiceRequests() {
    console.log(this.serviceRequestService.filterDetails)
    let tempFilterDetails;
    if (
      this.serviceRequestService.filterDetails &&
      this.serviceRequestService.filterDetails != null &&
      this.serviceRequestService.filterDetails != undefined
    ) {
      tempFilterDetails = this.serviceRequestService.filterDetails;
    }
    let dialogRef = this.dialog.open(ServiceRequestFilterComponent, {
      width: '350px',
      data: { item: tempFilterDetails },
      hasBackdrop: true,
      panelClass: 'service-request-filter',
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.serviceRequestsDataSource = new MatTableDataSource();
        this.paginator.pageIndex = 0;
        this.serviceRequestService.filterDetails = result.data;

        if (!this.isAirlineCusromer) {
          let status = '';
          if (result?.data?.status && result?.data?.status != '') {
            status = result?.data?.status;
            this.displayedColumns();
          }

          let barcode = '';
          if (result?.data?.barcode && result?.data?.barcode != '') {
            barcode = result?.data?.barcode;
          }

          let part = '';
          if (result?.data?.part && result?.data?.part != '') {
            part = result?.data?.part;
          }

          let unit = '';
          if (result?.data?.unit && result?.data?.unit != '') {
            unit = result?.data?.unit;
          }

          let task = '';
          if (result?.data?.task && result?.data?.task != '') {
            task = result?.data?.task;
          }

          let location = '';
          if (result?.data?.location && result?.data?.location != '') {
            location = result?.data?.location;
          }

          if (result?.data?.isSafetyServiceRequests && result?.data?.isSafetyServiceRequests == true) {
            this.getServiceRequests(
              this.paginatorLength,
              0,
              '',
              '',
              '',
              status,
              barcode,
              part,
              unit,
              task,
              location,
              '',
              '',
              '',
              'Y'
            );
          } else {
            this.getServiceRequests(
              this.paginatorLength,
              0,
              '',
              '',
              '',
              status,
              barcode,
              part,
              unit,
              task,
              location
            );
          }
        } else if (this.isAirlineCusromer) {
          let equipmentNo = '';
          if (result?.data?.equipmentNo && result?.data?.equipmentNo != '') {
            equipmentNo = result?.data?.equipmentNo;
          }
          let location = '';
          if (result?.data?.location && result?.data?.location != '') {
            location = result?.data?.location;
          }

          let status = '';
          if (result?.data?.status && result?.data?.status != '') {
            status = result?.data?.status;
            this.displayedColumns();
          }

          let unit = '';
          if (result?.data?.unit && result?.data?.unit != '') {
            unit = result?.data?.unit;
          }

          let task = '';
          if (result?.data?.task && result?.data?.task != '') {
            task = result?.data?.task;
          }

          if (result?.data?.isSafetyServiceRequests && result?.data?.isSafetyServiceRequests == true) {
            this.getServiceRequests(
              this.paginatorLength,
              0,
              '',
              '',
              '',
              status,
              '',
              '',
              unit,
              task,
              location,
              equipmentNo,
              '',
              '',
              'Y'
            );
          } else {
            this.getServiceRequests(
              this.paginatorLength,
              0,
              '',
              '',
              '',
              status,
              '',
              '',
              unit,
              task,
              location,
              equipmentNo
            );
          }
        }
      }
    });
  }

  // Sorting col based on selected field
  sortfields(e: any) {
    let offset = this.paginator._pageIndex * this.paginator._pageSize;
    if (!this.isAirlineCusromer) {
      let status = '';
      if (
        this.serviceRequestService.filterDetails?.status &&
        this.serviceRequestService.filterDetails?.status != ''
      ) {
        status = this.serviceRequestService.filterDetails?.status;
      }

      let barcode = '';
      if (
        this.serviceRequestService.filterDetails?.barcode &&
        this.serviceRequestService.filterDetails?.barcode != ''
      ) {
        barcode = this.serviceRequestService.filterDetails?.barcode;
      }

      let part = '';
      if (
        this.serviceRequestService.filterDetails?.part &&
        this.serviceRequestService.filterDetails?.part != ''
      ) {
        part = this.serviceRequestService.filterDetails?.part;
      }

      let unit = '';
      if (
        this.serviceRequestService.filterDetails?.unit &&
        this.serviceRequestService.filterDetails?.unit != ''
      ) {
        unit = this.serviceRequestService.filterDetails?.unit;
      }

      let task = '';
      if (
        this.serviceRequestService.filterDetails?.task &&
        this.serviceRequestService.filterDetails?.task != ''
      ) {
        task = this.serviceRequestService.filterDetails?.task;
      }

      let location = '';
      if (
        this.serviceRequestService.filterDetails?.location &&
        this.serviceRequestService.filterDetails?.location != ''
      ) {
        location = this.serviceRequestService.filterDetails?.location;
      }

      if (
        this.serviceRequestService.filterDetails?.isSafetyServiceRequests &&
        this.serviceRequestService.filterDetails?.isSafetyServiceRequests == true
      ) {
        this.getServiceRequests(
          this.paginatorLength,
          offset,
          '',
          e.active,
          e.direction,
          status,
          barcode,
          part,
          unit,
          task,
          location,
          '',
          '',
          '',
          'Y'
        );
      } else {
        this.getServiceRequests(
          this.paginatorLength,
          offset,
          '',
          e.active,
          e.direction,
          status,
          barcode,
          part,
          unit,
          task,
          location
        );
      }
    } else if (this.isAirlineCusromer) {
       let location = '';
      if (
        this.serviceRequestService.filterDetails?.location &&
        this.serviceRequestService.filterDetails?.location != ''
      ) {
        location = this.serviceRequestService.filterDetails?.location;
      }

      let equipmentNo = '';
      if (
        this.serviceRequestService.filterDetails?.equipmentNo &&
        this.serviceRequestService.filterDetails?.equipmentNo != ''
      ) {
        equipmentNo = this.serviceRequestService.filterDetails?.equipmentNo;
      }

      let status = '';
      if (
        this.serviceRequestService.filterDetails?.status &&
        this.serviceRequestService.filterDetails?.status != ''
      ) {
        status = this.serviceRequestService.filterDetails?.status;
        this.displayedColumns();
      }

      let unit = '';
      if (
        this.serviceRequestService.filterDetails?.unit &&
        this.serviceRequestService.filterDetails?.unit != ''
      ) {
        unit = this.serviceRequestService.filterDetails?.unit;
      }

      let task = '';
      if (
        this.serviceRequestService.filterDetails?.task &&
        this.serviceRequestService.filterDetails?.task != ''
      ) {
        task = this.serviceRequestService.filterDetails?.task;
      }

      if (
        this.serviceRequestService.filterDetails?.isSafetyServiceRequests &&
        this.serviceRequestService.filterDetails?.isSafetyServiceRequests == true
      ) {
        this.getServiceRequests(
          this.paginatorLength,
          offset,
          '',
          e.active,
          e.direction,
          status,
          '',
          '',
          unit,
          task,
          location,
          equipmentNo,
          '',
          '',
          'Y'
        );
      } else {
        this.getServiceRequests(
          this.paginatorLength,
          offset,
          '',
          e.active,
          e.direction,
          status,
          '',
          '',
          unit,
          task,
          location,
          equipmentNo
        );
      }
    }
    this.paginator.pageIndex = this.paginator._pageIndex;
  }

  //Paginator page items handle
  handlePaginator(e: any) {
    this.paginatorLength = e.pageSize;
    let limit = e.pageSize;
    let offset = e.pageIndex * limit;
    this.paginator.pageIndex = e.pageIndex;
    if (!this.isAirlineCusromer) {
      if (this.sort.active) {
        let status = '';
        if (
          this.serviceRequestService.filterDetails?.status &&
          this.serviceRequestService.filterDetails?.status != ''
        ) {
          status = this.serviceRequestService.filterDetails?.status;
        }

        let barcode = '';
        if (
          this.serviceRequestService.filterDetails?.barcode &&
          this.serviceRequestService.filterDetails?.barcode != ''
        ) {
          barcode = this.serviceRequestService.filterDetails?.barcode;
        }

        let part = '';
        if (
          this.serviceRequestService.filterDetails?.part &&
          this.serviceRequestService.filterDetails?.part != ''
        ) {
          part = this.serviceRequestService.filterDetails?.part;
        }

        let unit = '';
        if (
          this.serviceRequestService.filterDetails?.unit &&
          this.serviceRequestService.filterDetails?.unit != ''
        ) {
          unit = this.serviceRequestService.filterDetails?.unit;
        }

        let task = '';
        if (
          this.serviceRequestService.filterDetails?.task &&
          this.serviceRequestService.filterDetails?.task != ''
        ) {
          task = this.serviceRequestService.filterDetails?.task;
        }

        let location = '';
        if (
          this.serviceRequestService.filterDetails?.location &&
          this.serviceRequestService.filterDetails?.location != ''
        ) {
          location = this.serviceRequestService.filterDetails?.location;
        }

        if (
          this.serviceRequestService.filterDetails?.isSafetyServiceRequests &&
          this.serviceRequestService.filterDetails?.isSafetyServiceRequests == true
        ) {
          this.getServiceRequests(
            limit,
            offset,
            '',
            this.sort.active,
            this.sort._direction,
            status,
            barcode,
            part,
            unit,
            task,
            location,
            '',
            '',
            '',
            'Y'
          );
        } else {
          this.getServiceRequests(
            limit,
            offset,
            '',
            this.sort.active,
            this.sort._direction,
            status,
            barcode,
            part,
            unit,
            task,
            location
          );
        }
      } else {
        let status = '';
        if (
          this.serviceRequestService.filterDetails?.status &&
          this.serviceRequestService.filterDetails?.status != ''
        ) {
          status = this.serviceRequestService.filterDetails?.status;
        }

        let barcode = '';
        if (
          this.serviceRequestService.filterDetails?.barcode &&
          this.serviceRequestService.filterDetails?.barcode != ''
        ) {
          barcode = this.serviceRequestService.filterDetails?.barcode;
        }

        let part = '';
        if (
          this.serviceRequestService.filterDetails?.part &&
          this.serviceRequestService.filterDetails?.part != ''
        ) {
          part = this.serviceRequestService.filterDetails?.part;
        }

        let unit = '';
        if (
          this.serviceRequestService.filterDetails?.unit &&
          this.serviceRequestService.filterDetails?.unit != ''
        ) {
          unit = this.serviceRequestService.filterDetails?.unit;
        }

        let task = '';
        if (
          this.serviceRequestService.filterDetails?.task &&
          this.serviceRequestService.filterDetails?.task != ''
        ) {
          task = this.serviceRequestService.filterDetails?.task;
        }

        let location = '';
        if (
          this.serviceRequestService.filterDetails?.location &&
          this.serviceRequestService.filterDetails?.location != ''
        ) {
          location = this.serviceRequestService.filterDetails?.location;
        }
        if (
          this.serviceRequestService.filterDetails?.isSafetyServiceRequests &&
          this.serviceRequestService.filterDetails?.isSafetyServiceRequests == true
        ) {
          this.getServiceRequests(
            limit,
            offset,
            '',
            '',
            '',
            status,
            barcode,
            part,
            unit,
            task,
            location,
            '',
            '',
            '',
            'Y'
          );
        } else {
          this.getServiceRequests(
            limit,
            offset,
            '',
            '',
            '',
            status,
            barcode,
            part,
            unit,
            task,
            location
          );
        }
      }
    } else if (this.isAirlineCusromer) {
      let location = '';
      if (
        this.serviceRequestService.filterDetails?.location &&
        this.serviceRequestService.filterDetails?.location != ''
      ) {
        location = this.serviceRequestService.filterDetails?.location;
      }

      let equipmentNo = '';
      if (
        this.serviceRequestService.filterDetails?.equipmentNo &&
        this.serviceRequestService.filterDetails?.equipmentNo != ''
      ) {
        equipmentNo = this.serviceRequestService.filterDetails?.equipmentNo;
      }

      let status = '';
      if (
        this.serviceRequestService.filterDetails?.status &&
        this.serviceRequestService.filterDetails?.status != ''
      ) {
        status = this.serviceRequestService.filterDetails?.status;
        this.displayedColumns();
      }

      let unit = '';
      if (
        this.serviceRequestService.filterDetails?.unit &&
        this.serviceRequestService.filterDetails?.unit != ''
      ) {
        unit = this.serviceRequestService.filterDetails?.unit;
      }

      let task = '';
      if (
        this.serviceRequestService.filterDetails?.task &&
        this.serviceRequestService.filterDetails?.task != ''
      ) {
        task = this.serviceRequestService.filterDetails?.task;
      }

      if (this.sort.active) {
        if (
          this.serviceRequestService.filterDetails?.isSafetyServiceRequests &&
          this.serviceRequestService.filterDetails?.isSafetyServiceRequests == true
        ) {
          this.getServiceRequests(
            limit,
            offset,
            '',
            this.sort.active,
            this.sort._direction,
            status,
            '',
            '',
            unit,
            task,
            location,
            equipmentNo,
            '',
            '',
            'Y'
          );
        } else {
          this.getServiceRequests(
            limit,
            offset,
            '',
            this.sort.active,
            this.sort._direction,
            status,
            '',
            '',
            unit,
            task,
            location,
            equipmentNo
          );
        }
      } else {
        if (
          this.serviceRequestService.filterDetails?.isSafetyServiceRequests &&
          this.serviceRequestService.filterDetails?.isSafetyServiceRequests == true
        ) {
          this.getServiceRequests(
            limit,
            offset,
            '',
            '',
            '',
            status,
            '',
            '',
            unit,
            task,
            location,
            equipmentNo,
            '',
            '',
            'Y'
          );
        } else {
          this.getServiceRequests(
            limit,
            offset,
            '',
            '',
            '',
            status,
            '',
            '',
            unit,
            task,
            location,
            equipmentNo
          );
        }
      }
    }
  }

  // Error dialog when any server erros
  showErrorDialog(msg: string) {
    const dialogRef = this.dialog.open(ErrorComponent, {
      width: '400px',
      data: {
        msg: msg,
        mode: null,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        let offset = this.paginator._pageIndex * this.paginator._pageSize;
        if (!this.isAirlineCusromer) {
          if (this.sort.active) {
            let status = '';
            if (
              this.serviceRequestService.filterDetails?.status &&
              this.serviceRequestService.filterDetails?.status != ''
            ) {
              status = this.serviceRequestService.filterDetails?.status;
            }
    
            let barcode = '';
            if (
              this.serviceRequestService.filterDetails?.barcode &&
              this.serviceRequestService.filterDetails?.barcode != ''
            ) {
              barcode = this.serviceRequestService.filterDetails?.barcode;
            }
    
            let part = '';
            if (
              this.serviceRequestService.filterDetails?.part &&
              this.serviceRequestService.filterDetails?.part != ''
            ) {
              part = this.serviceRequestService.filterDetails?.part;
            }
    
            let unit = '';
            if (
              this.serviceRequestService.filterDetails?.unit &&
              this.serviceRequestService.filterDetails?.unit != ''
            ) {
              unit = this.serviceRequestService.filterDetails?.unit;
            }
    
            let task = '';
            if (
              this.serviceRequestService.filterDetails?.task &&
              this.serviceRequestService.filterDetails?.task != ''
            ) {
              task = this.serviceRequestService.filterDetails?.task;
            }
    
            let location = '';
            if (
              this.serviceRequestService.filterDetails?.location &&
              this.serviceRequestService.filterDetails?.location != ''
            ) {
              location = this.serviceRequestService.filterDetails?.location;
            }
    
            if (
              this.serviceRequestService.filterDetails?.isSafetyServiceRequests &&
              this.serviceRequestService.filterDetails?.isSafetyServiceRequests == true
            ) {
              this.getServiceRequests(
                this.paginatorLength,
                offset,
                '',
                this.sort.active,
                this.sort._direction,
                status,
                barcode,
                part,
                unit,
                task,
                location,
                '',
                '',
                '',
                'Y'
              );
            } else {
              this.getServiceRequests(
                this.paginatorLength,
                offset,
                '',
                this.sort.active,
                this.sort._direction,
                status,
                barcode,
                part,
                unit,
                task,
                location
              );
            }
          } else {
            let status = '';
            if (
              this.serviceRequestService.filterDetails?.status &&
              this.serviceRequestService.filterDetails?.status != ''
            ) {
              status = this.serviceRequestService.filterDetails?.status;
            }
    
            let barcode = '';
            if (
              this.serviceRequestService.filterDetails?.barcode &&
              this.serviceRequestService.filterDetails?.barcode != ''
            ) {
              barcode = this.serviceRequestService.filterDetails?.barcode;
            }
    
            let part = '';
            if (
              this.serviceRequestService.filterDetails?.part &&
              this.serviceRequestService.filterDetails?.part != ''
            ) {
              part = this.serviceRequestService.filterDetails?.part;
            }
    
            let unit = '';
            if (
              this.serviceRequestService.filterDetails?.unit &&
              this.serviceRequestService.filterDetails?.unit != ''
            ) {
              unit = this.serviceRequestService.filterDetails?.unit;
            }
    
            let task = '';
            if (
              this.serviceRequestService.filterDetails?.task &&
              this.serviceRequestService.filterDetails?.task != ''
            ) {
              task = this.serviceRequestService.filterDetails?.task;
            }
    
            let location = '';
            if (
              this.serviceRequestService.filterDetails?.location &&
              this.serviceRequestService.filterDetails?.location != ''
            ) {
              location = this.serviceRequestService.filterDetails?.location;
            }
            if (
              this.serviceRequestService.filterDetails?.isSafetyServiceRequests &&
              this.serviceRequestService.filterDetails?.isSafetyServiceRequests == true
            ) {
              this.getServiceRequests(
                this.paginatorLength,
                offset,
                '',
                '',
                '',
                status,
                barcode,
                part,
                unit,
                task,
                location,
                '',
                '',
                '',
                'Y'
              );
            } else {
              this.getServiceRequests(
                this.paginatorLength,
                offset,
                '',
                '',
                '',
                status,
                barcode,
                part,
                unit,
                task,
                location
              );
            }
          }
        } else if (this.isAirlineCusromer) {
          let location = '';
          if (
            this.serviceRequestService.filterDetails?.location &&
            this.serviceRequestService.filterDetails?.location != ''
          ) {
            location = this.serviceRequestService.filterDetails?.location;
          }
    
          let equipmentNo = '';
          if (
            this.serviceRequestService.filterDetails?.equipmentNo &&
            this.serviceRequestService.filterDetails?.equipmentNo != ''
          ) {
            equipmentNo = this.serviceRequestService.filterDetails?.equipmentNo;
          }
    
          let status = '';
          if (
            this.serviceRequestService.filterDetails?.status &&
            this.serviceRequestService.filterDetails?.status != ''
          ) {
            status = this.serviceRequestService.filterDetails?.status;
            this.displayedColumns();
          }
    
          let unit = '';
          if (
            this.serviceRequestService.filterDetails?.unit &&
            this.serviceRequestService.filterDetails?.unit != ''
          ) {
            unit = this.serviceRequestService.filterDetails?.unit;
          }
    
          let task = '';
          if (
            this.serviceRequestService.filterDetails?.task &&
            this.serviceRequestService.filterDetails?.task != ''
          ) {
            task = this.serviceRequestService.filterDetails?.task;
          }
    
          if (this.sort.active) {
            if (
              this.serviceRequestService.filterDetails?.isSafetyServiceRequests &&
              this.serviceRequestService.filterDetails?.isSafetyServiceRequests == true
            ) {
              this.getServiceRequests(
                this.paginatorLength,
                offset,
                '',
                this.sort.active,
                this.sort._direction,
                status,
                '',
                '',
                unit,
                task,
                location,
                equipmentNo,
                '',
                '',
                'Y'
              );
            } else {
              this.getServiceRequests(
                this.paginatorLength,
                offset,
                '',
                this.sort.active,
                this.sort._direction,
                status,
                '',
                '',
                unit,
                task,
                location,
                equipmentNo
              );
            }
          } else {
            if (
              this.serviceRequestService.filterDetails?.isSafetyServiceRequests &&
              this.serviceRequestService.filterDetails?.isSafetyServiceRequests == true
            ) {
              this.getServiceRequests(
                this.paginatorLength,
                offset,
                '',
                '',
                '',
                status,
                '',
                '',
                unit,
                task,
                location,
                equipmentNo,
                '',
                '',
                'Y'
              );
            } else {
              this.getServiceRequests(
                this.paginatorLength,
                offset,
                '',
                'CREATED_AT',
                'desc',
                status,
                '',
                '',
                unit,
                task,
                location,
                equipmentNo
              );
            }
          }
        }
      }
    });
  }

  // Show service request details
  showServiceRequestDetails(request: any, type: string) {
    if (type === 'expand') {
      this.airlinePartsObj = {};
      this.serviceRequestDetails = false;
      this.selectedItem = request;
      this.cancelOutstandingRequests();
      this.partDataSource = new MatTableDataSource();
      // Make get service request by id call
      let serviceRequestByIdSubscription = this.serviceRequestService
        .getServiceRequestById(request.REQUEST_ID)
        .subscribe(
          (response: any) => {
            // Success callback
            if (response.status === 200) {
              if (response.body) {
                // Assign the data to the data source for the table to render
                this.serviceRequestDetails = response.body;
                if(this.serviceRequestDetails.STATUS == "CLOSED") {
                  if (this.serviceRequestDetails?.REPAIR?.PARTS) {
                    if (
                      Array.isArray(this.serviceRequestDetails?.REPAIR?.PARTS)
                    ) {
                      this.partDataSource = new MatTableDataSource(
                        this.serviceRequestDetails?.REPAIR?.PARTS
                      );
                    } else {
                      if (
                        Object.keys(this.serviceRequestDetails?.REPAIR?.PARTS)
                          .length > 0
                      ) {
                        this.airlinePartsObj =
                          this.serviceRequestDetails?.REPAIR?.PARTS;
                      } else {
                        this.airlinePartsObj = {};
                      }
                    }
                  } 
                } else {
                  if (this.serviceRequestDetails?.PARTS) {
                    if (
                      Array.isArray(this.serviceRequestDetails?.PARTS)
                    ) {
                      this.partDataSource = new MatTableDataSource(
                        this.serviceRequestDetails?.PARTS
                      );
                    } else {
                      if (
                        Object.keys(this.serviceRequestDetails?.PARTS)
                          .length > 0
                      ) {
                        this.airlinePartsObj =
                          this.serviceRequestDetails?.PARTS;
                      } else {
                        this.airlinePartsObj = {};
                      }
                    }
                  }
                }
            
              }
            } else if (response.status === 401) {
              // Redirect to login for unauthorized
              localStorage.removeItem('token');
              localStorage.removeItem('user-details');
              localStorage.removeItem('customerName');
              localStorage.removeItem('customerId');
              localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
              this.authService.loggedIn.next(false);
              this.router.navigate(['login']);
            } else if (response.status === 403) {
              this.showErrorDialog(response.error);
            } else {
              this.showErrorDialog(
                'Error while downloading service requests, do you want to retry again?'
              );
            }
          },
          (error) => {
            if (error.status === 401) {
              // Redirect to login for unauthorized
              localStorage.removeItem('token');
              localStorage.removeItem('user-details');
              localStorage.removeItem('customerName');
              localStorage.removeItem('customerId');
              localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
              this.authService.loggedIn.next(false);
              this.router.navigate(['login']);
            } else if (error.status === 403) {
              this.showErrorDialog(error.error);
            } else {
              this.showErrorDialog(
                'Error while downloading service requests, do you want to retry again?'
              );
            }
          }
        );
      this.subscriptions.push(serviceRequestByIdSubscription);
    } else {
      this.selectedItem = null;
    }
  }

  // Show service logs
  showServiceLogs(item: any) {
    this.dialog.open(ServiceLogsComponent, {
      width: '720px',
      maxHeight: '600px',
      height: '500px',
      data: { item: item },
      panelClass: 'add-customer-dialog',
    });
  }

  // Pause, Resume and Cancel service requests
  modifyServiceRequest(item: any, type: string) {
    if (type == 'OPEN') {
      let msg = type == 'OPEN' ? 'Resume service request' : '';
      const dialogRef = this.dialog.open(AlertComponent, {
        width: '400px',
        data: {
          errorMsg: `${msg}?`,
        },
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        if (result.data) {
          this.isShowSRequestsProgressBar = true;
          let body: any = {
            REQUEST_ID: item.REQUEST_ID,
            STATUS: type,
          };

          await this.serviceRequestService
            .updateServiceRequest(body)
            .toPromise()
            .then(
              (response: any) => {
                this.isShowSRequestsProgressBar = false;
                if (response.status === 204) {
                  let offset =
                    this.paginator._pageIndex * this.paginator._pageSize;
                  if (this.sort.active) {
                    if (!this.isAirlineCusromer) {
                      let status = '';
                      if (
                        this.serviceRequestService.filterDetails?.status &&
                        this.serviceRequestService.filterDetails?.status != ''
                      ) {
                        status =
                          this.serviceRequestService.filterDetails?.status;
                      }

                      let barcode = '';
                      if (
                        this.serviceRequestService.filterDetails?.barcode &&
                        this.serviceRequestService.filterDetails?.barcode != ''
                      ) {
                        barcode =
                          this.serviceRequestService.filterDetails?.barcode;
                      }

                      let part = '';
                      if (
                        this.serviceRequestService.filterDetails?.part &&
                        this.serviceRequestService.filterDetails?.part != ''
                      ) {
                        part = this.serviceRequestService.filterDetails?.part;
                      }

                      let unit = '';
                      if (
                        this.serviceRequestService.filterDetails?.unit &&
                        this.serviceRequestService.filterDetails?.unit != ''
                      ) {
                        unit = this.serviceRequestService.filterDetails?.unit;
                      }

                      let task = '';
                      if (
                        this.serviceRequestService.filterDetails?.task &&
                        this.serviceRequestService.filterDetails?.task != ''
                      ) {
                        task = this.serviceRequestService.filterDetails?.task;
                      }

                      let location = '';
                      if (
                        this.serviceRequestService.filterDetails?.location &&
                        this.serviceRequestService.filterDetails?.location != ''
                      ) {
                        location =
                          this.serviceRequestService.filterDetails?.location;
                      }

                      if (
                        this.serviceRequestService.filterDetails?.isSafetyServiceRequests &&
                        this.serviceRequestService.filterDetails?.isSafetyServiceRequests ==
                        true
                      ) {
                        this.getServiceRequests(
                          this.paginatorLength,
                          offset,
                          '',
                          this.sort.active,
                          this.sort._direction,
                          status,
                          barcode,
                          part,
                          unit,
                          task,
                          location,
                          '',
                          '',
                          '',
                          'Y'
                        );
                      } else {
                        this.getServiceRequests(
                          this.paginatorLength,
                          offset,
                          '',
                          this.sort.active,
                          this.sort._direction,
                          status,
                          barcode,
                          part,
                          unit,
                          task,
                          location
                        );
                      }
                    } else if (this.isAirlineCusromer) {
                      let location = '';
                      if (
                        this.serviceRequestService.filterDetails?.location &&
                        this.serviceRequestService.filterDetails?.location != ''
                      ) {
                        location =
                          this.serviceRequestService.filterDetails?.location;
                      }

                      let equipmentNo = '';
                      if (
                        this.serviceRequestService.filterDetails?.equipmentNo &&
                        this.serviceRequestService.filterDetails?.equipmentNo !=
                        ''
                      ) {
                        equipmentNo =
                          this.serviceRequestService.filterDetails?.equipmentNo;
                      }

                      let status = '';
                      if (
                        this.serviceRequestService.filterDetails?.status &&
                        this.serviceRequestService.filterDetails?.status != ''
                      ) {
                        status =
                          this.serviceRequestService.filterDetails?.status;
                        this.displayedColumns();
                      }

                      let unit = '';
                      if (
                        this.serviceRequestService.filterDetails?.unit &&
                        this.serviceRequestService.filterDetails?.unit != ''
                      ) {
                        unit = this.serviceRequestService.filterDetails?.unit;
                      }

                      let task = '';
                      if (
                        this.serviceRequestService.filterDetails?.task &&
                        this.serviceRequestService.filterDetails?.task != ''
                      ) {
                        task = this.serviceRequestService.filterDetails?.task;
                      }
                      if (
                        this.serviceRequestService.filterDetails?.isSafetyServiceRequests &&
                        this.serviceRequestService.filterDetails?.isSafetyServiceRequests ==
                        true
                      ) {
                        this.getServiceRequests(
                          this.paginatorLength,
                          offset,
                          '',
                          this.sort.active,
                          this.sort._direction,
                          status,
                          '',
                          '',
                          unit,
                          task,
                          location,
                          equipmentNo,
                          '',
                          '',
                          'Y'
                        );
                      } else {
                        this.getServiceRequests(
                          this.paginatorLength,
                          offset,
                          '',
                          this.sort.active,
                          this.sort._direction,
                          status,
                          '',
                          '',
                          unit,
                          task,
                          location,
                          equipmentNo
                        );
                      }
                    }
                  } else {
                    this.getServiceRequests(this.paginatorLength, offset, '');
                  }
                  this.paginator.pageIndex = this.paginator._pageIndex;
                } else {
                  this.dispalyErrorAlert(response?.message);
                }
              },
              (error) => {
                this.isShowSRequestsProgressBar = false;
                this.dispalyErrorAlert(error?.error);
              }
            );
        }
      });
    } else if (type == 'PAUSE' || type == 'CANCEL') {
      const dialogRef = this.dialog.open(CommentComponent, {
        width: '400px',
        panelClass: 'add-customer-dialog',
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        if (result?.comment && result?.comment?.length > 0) {
          this.isShowSRequestsProgressBar = true;
          let body: any = {
            REQUEST_ID: item.REQUEST_ID,
            STATUS: type,
            COMMENT: result.comment,
          };

          await this.serviceRequestService
            .updateServiceRequest(body)
            .toPromise()
            .then(
              (response: any) => {
                this.isShowSRequestsProgressBar = false;
                if (response.status === 204) {
                  let offset =
                    this.paginator._pageIndex * this.paginator._pageSize;
                  if (this.sort.active) {
                    this.getServiceRequests(
                      this.paginatorLength,
                      offset,
                      '',
                      this.sort.active,
                      this.sort._direction
                    );
                  } else {
                    this.getServiceRequests(this.paginatorLength, offset);
                  }
                  this.paginator.pageIndex = this.paginator._pageIndex;
                } else {
                  this.dispalyErrorAlert(response.message);
                }
              },
              (error) => {
                this.isShowSRequestsProgressBar = false;
                this.dispalyErrorAlert(error?.error);
              }
            );
        }
      });
    }
  }

  // Sanitize base64 to show thumbnail
  displayImage(type: string, content: any) {
    return (
      `data:${type};base64,` +
      (this.sanitizer.bypassSecurityTrustResourceUrl(content) as any)
        .changingThisBreaksApplicationSecurity
    );
  }

  // View thumbnail in full screen view
  viewFullScreenImage(imageBase64: string, mimetype: string) {
    this.dialog.open(FullScreenImageComponent, {
      data: { item: { imageBase64: imageBase64, mimetype: mimetype } },
    });
  }

  // Show equipment details for selected service request
  showEquipmentDetails(item: any) {
    const dialogRef = this.dialog.open(EquipmentDetailsComponent, {
      width: '700px',
      height: '500px',
      data: { item: item },
      panelClass: 'add-customer-dialog',
    });
  }

  // Show table columns based on service request status
  displayedColumns() {
    this.partsColumns = [
      'PART_ID',
      'NAME',
      'MANUFACTURER',
      'MFG_PART_NUMBER',
      'COUNT',
      'PART_TYPE',
      'UOM',
      'AVAILABILITY',
      'COST'
    ];
    let status = '';
    if (
      this.serviceRequestService.filterDetails?.status &&
      this.serviceRequestService.filterDetails?.status != ''
    ) {
      status = this.serviceRequestService.filterDetails?.status;
      if (status == 'CANCEL' || status == 'CLOSED') {
        if (!this.isAirlineCusromer) { 
          this.tableColumns = [
            'SAFETY',
            'REQUEST_ID',
            'TAG',
            'EQUIPMENT_NAME',
            'TASK_TYPE',
            'LOCATION',
            'STATUS',
            'CREATED_BY',
            'CREATED_AT',
            'REPAIRED_AT',
            'Actions',
          ]
        } else {
          this.tableColumns = [
            'SAFETY',
            'REQUEST_ID',
            'EQUIPMENT_NAME',
            'TASK_TYPE',
            'LOCATION',
            'STATUS',
            'CREATED_BY',
            'CREATED_AT',
            'REPAIRED_AT',
            'Actions',
          ]
        }

        if (status == 'CLOSED') {
          this.partsColumns = [
            'PART_ID',
            'NAME',
            'MANUFACTURER',
            'MFG_PART_NUMBER',
            'COUNT',
            'PART_TYPE',
            'UOM',
            'AVAILABILITY',
            'COST'
          ];
        }

      } else {
        if (this.isTableEditable) {
          if (!this.isAirlineCusromer) {
            this.tableColumns = [
              'SELECT',
              'SAFETY',
              'REQUEST_ID',
              'TAG',
              'EQUIPMENT_NAME',
              'TASK_TYPE',
              'LOCATION',
              'STATUS',
              'CREATED_BY',
              'CREATED_AT',
              'REMAINING_REQUEST_TIME',
              'Actions'
            ]
          } else {
            this.tableColumns = [
              'SELECT',
              'SAFETY',
              'REQUEST_ID',
              'EQUIPMENT_NAME',
              'TASK_TYPE',
              'LOCATION',
              'STATUS',
              'CREATED_BY',
              'CREATED_AT',
              'REMAINING_REQUEST_TIME',
              'Actions'
            ]
          }
        }
        else {
          if (!this.isAirlineCusromer) {
            this.tableColumns = [
              'SAFETY',
              'REQUEST_ID',
              'TAG',
              'EQUIPMENT_NAME',
              'TASK_TYPE',
              'LOCATION',
              'STATUS',
              'CREATED_BY',
              'CREATED_AT',
              'REMAINING_REQUEST_TIME',
              'Actions'
            ]
          } {
            this.tableColumns = [
              'SAFETY',
              'REQUEST_ID',
              'EQUIPMENT_NAME',
              'TASK_TYPE',
              'LOCATION',
              'STATUS',
              'CREATED_BY',
              'CREATED_AT',
              'REMAINING_REQUEST_TIME',
              'Actions'
            ]
          }

        }
      }
    } else {
      if (this.isTableEditable) {
        if (!this.isAirlineCusromer) {
          this.tableColumns = [
            'SELECT',
            'SAFETY',
            'REQUEST_ID',
            'TAG',
            'EQUIPMENT_NAME',
            'TASK_TYPE',
            'LOCATION',
            'STATUS',
            'CREATED_BY',
            'CREATED_AT',
            'REMAINING_REQUEST_TIME',
            'Actions',
          ]
        } else {
          this.tableColumns = [
            'SELECT',
            'SAFETY',
            'REQUEST_ID',
            'EQUIPMENT_NAME',
            'TASK_TYPE',
            'LOCATION',
            'STATUS',
            'CREATED_BY',
            'CREATED_AT',
            'REMAINING_REQUEST_TIME',
            'Actions',
          ]
        }
      } else {
        if (!this.isAirlineCusromer) { 
          this.tableColumns = [
            'SAFETY',
            'REQUEST_ID',
            'TAG',
            'EQUIPMENT_NAME',
            'TASK_TYPE',
            'LOCATION',
            'STATUS',
            'CREATED_BY',
            'CREATED_AT',
            'REMAINING_REQUEST_TIME',
            'Actions',
          ]
        } else {
          this.tableColumns = [
            'SAFETY',
            'REQUEST_ID',
            'EQUIPMENT_NAME',
            'TASK_TYPE',
            'LOCATION',
            'STATUS',
            'CREATED_BY',
            'CREATED_AT',
            'REMAINING_REQUEST_TIME',
            'Actions',
          ]
        }
      }
    }
  }

  // Get service requests
  async getNotificationServiceRequestById(id: any) {
    this.isShowSRequestsProgressBar = true;
    this.cancelOutstandingRequests();
    // Make get service request by id call
    let serviceRequestByIdSubscription = this.serviceRequestService
      .getServiceRequestById(Number(id))
      .subscribe(
        (response: any) => {
          this.isShowSRequestsProgressBar = false;
          // Success callback
          if (response.status === 200) {
            if (response.body) {
              // Assign the data to the data source for the table to render
              this.serviceRequestsDataSource = new MatTableDataSource([
                response.body,
              ]);
              this.usersLength = 1;
              this.paginator.pageIndex = 0;
            }
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.showErrorDialog(response.error);
          } else {
            this.showErrorAlertDialog(
              `Error while getting service request - ${response.message}`
            );
          }
        },
        (error) => {
          this.isShowSRequestsProgressBar = false;
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');    localStorage.removeItem('userTenants');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.showErrorDialog(error.error);
          } else {
            this.showErrorAlertDialog(
              `Error while getting service request - ${error.error}`
            );
          }
        }
      );
    this.subscriptions.push(serviceRequestByIdSubscription);
  }

  // Error dialog when any server erros
  showErrorAlertDialog(msg: string) {
    const dialogRef = this.dialog.open(ErrorAlertComponent, {
      width: '400px',
      data: {
        msg: msg,
        mode: null,
      },
    });
  }

  partSearch(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.partDataSource.filter = filterValue.trim().toLowerCase();

    if (this.partDataSource.paginator) {
      this.partDataSource.paginator.firstPage();
    }
  }

  isArray(item: any) {
    return Array.isArray(item);
  }

  checkKeyPresent(obj: any) {
    if(obj != null && obj != undefined) {
      if (Object.keys(obj).length > 0) {
        return true;
      } else return false;
    } else return false;
  }

  goToNotifications() {
    this.router.navigate(['/notifications']);
  }

  getRepairProductCount(parts: any[]): any {
    if(parts && parts?.length > 0) {
      let qty = 0;
      parts.forEach(element => {
        qty = qty + element?.COUNT;
      });
      return qty;
    } else return 0;
  }

  serviceRequestsMasterToggle() {
    if (this.isServiceRequestsAllSelected()) {
      this.deleteOnlyServiceRequestsDatasourceItems();
    } else {
      this.serviceRequestsCheckAndSelect();
    }
  }

  serviceRequestsCheckAndSelect() {
    this.serviceRequestsDataSource.data.forEach((row) => {
      if (
        this.serviceRequestsSelection.selected.some(
          (item) => item.REQUEST_ID === row.REQUEST_ID
        )
      ) {
      } else {
        this.serviceRequestsSelection.select(row);
      }
    });
  }

  deleteOnlyServiceRequestsDatasourceItems() {
    if (
      this.serviceRequestsSelection.selected &&
      this.serviceRequestsSelection.selected.length > 0
    ) {
      let temp = this.serviceRequestsSelection.selected;
      this.serviceRequestsSelection.clear();

      temp.forEach((element) => {
        let tempIndex = this.serviceRequestsDataSource.data.findIndex(
          (row) => row.REQUEST_ID == element.REQUEST_ID
        );
        if (tempIndex == -1) {
          this.serviceRequestsSelection.select(element);
        }
      });
    }
  }

  isServiceRequestsAllSelected() {
    if (
      this.serviceRequestsSelection.selected &&
      this.serviceRequestsSelection.selected.length > 0
    ) {
      const results = this.serviceRequestsDataSource.data.filter(
        ({ REQUEST_ID: id1 }) =>
          !this.serviceRequestsSelection.selected.some(
            ({ REQUEST_ID: id2 }) => id2 === id1
          )
      );
      if (results && results.length > 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  toggeleServiceRequestSelection(ev: any, row: any) {
    if (ev.checked) {
      let ar = [
        ...new Map(
          this.serviceRequestsSelection.selected.map((item) => [
            item.REQUEST_ID,
            item,
          ])
        ).values(),
      ];

      if (ar?.length > 0) {
        for (let i = 0; i < ar.length; i++) {
          this.serviceRequestsSelection.select(row);
        }
      } else {
        this.serviceRequestsSelection.select(row);
      }
    } else {
      let temp = this.serviceRequestsSelection.selected;
      if (temp && temp.length > 0) {
        temp.splice(
          temp.findIndex((a) => a.REQUEST_ID === row.REQUEST_ID),
          1
        );
        this.serviceRequestsSelection.clear();
        for (let t = 0; t < temp?.length; t++) {
          this.serviceRequestsSelection.select(temp[t]);
        }
      }
    }
  }

  selectedServiceRequests(row: any): boolean {
    if (
      this.serviceRequestsSelection?.selected &&
      this.serviceRequestsSelection.selected.length > 0
    ) {
      let temp = false;
      for (let i = 0; i < this.serviceRequestsSelection.selected.length; i++) {
        if (
          this.serviceRequestsSelection.selected[i].REQUEST_ID == row.REQUEST_ID
        ) {
          temp = true;
        }
      }
      return temp;
    } else {
      return false;
    }
  }

  bulkPause() {
    let filteredItems: any[] = this.serviceRequestsSelection.selected.filter(
      item => item.STATUS === 'OPEN');

    if (filteredItems?.length > 0) {
      let dialogRef = this.dialog.open(AlertComponent, {
        width: '400px',
        data: {
          errorMsg: `Are you sure you want to pause ${filteredItems?.length} service requests?`,
        },
      });

      dialogRef.afterClosed().subscribe(async (result) => {
        if (result.data) {
          this.isShowSRequestsProgressBar = true;
          let body: any[] = [];
          filteredItems.forEach(element => {
            body.push({ 'REQUEST_ID': element.REQUEST_ID, 'STATUS': 'PAUSE', 'COMMENT': '' })
          });
          await this.serviceRequestService
            .updateServiceRequest({ 'SERVICE_REQUESTS': body })
            .toPromise()
            .then(
              (response: any) => {
                this.isShowSRequestsProgressBar = false;
                if (response.status === 204) { this.reloadToInitialState(); }
                else { this.dispalyErrorAlert(response?.message); }
              },
              (error) => {this.isShowSRequestsProgressBar = false; this.dispalyErrorAlert(error?.error); }
            );
        }
      });
    } else {this.isShowSRequestsProgressBar = false; this.dispalyErrorAlert('No open service requests to pause'); }
  }

  bulkResume() {
    let filteredItems: any[] = this.serviceRequestsSelection.selected.filter(
      item => item.STATUS === 'PAUSE');

    if (filteredItems?.length > 0) {
      let dialogRef = this.dialog.open(AlertComponent, {
        width: '400px',
        data: {
          errorMsg: `Are you sure you want to resume ${filteredItems?.length} service requests?`,
        },
      });

      dialogRef.afterClosed().subscribe(async (result) => {
        if (result.data) {
          this.isShowSRequestsProgressBar = true;
          let body: any[] = [];
          filteredItems.forEach(element => {
            body.push({ 'REQUEST_ID': element.REQUEST_ID, 'STATUS': 'OPEN', 'COMMENT': '' })
          });
          await this.serviceRequestService
            .updateServiceRequest({ 'SERVICE_REQUESTS': body })
            .toPromise()
            .then(
              (response: any) => {
                this.isShowSRequestsProgressBar = false;
                if (response.status === 204) { this.reloadToInitialState(); }
                else {this.dispalyErrorAlert(response?.message); }
              },
              (error) => {   this.isShowSRequestsProgressBar = false; this.dispalyErrorAlert(error?.error); }
            );
        }
      });
    } else {   this.isShowSRequestsProgressBar = false; this.dispalyErrorAlert('No paused service requests to resume'); }
  }

  bulkCancel() {
    let filteredItems: any[] = this.serviceRequestsSelection.selected.filter(
      item => (item.STATUS === 'PAUSE' || item.STATUS === 'OPEN'));

    if (filteredItems?.length > 0) {
      let dialogRef = this.dialog.open(AlertComponent, {
        width: '400px',
        data: {
          errorMsg: `Are you sure you want to cancel ${filteredItems?.length} service requests?`,
        },
      });

      dialogRef.afterClosed().subscribe(async (result) => {
        if (result.data) {
          this.isShowSRequestsProgressBar = true;
          let body: any[] = [];
          filteredItems.forEach(element => {
            body.push({ 'REQUEST_ID': element.REQUEST_ID, 'STATUS': 'CANCEL', 'COMMENT': '' })
          });
          await this.serviceRequestService
            .updateServiceRequest({ 'SERVICE_REQUESTS': body })
            .toPromise()
            .then(
              (response: any) => {
                this.isShowSRequestsProgressBar = false;
                if (response.status === 204) { this.reloadToInitialState(); }
                else { this.dispalyErrorAlert(response?.message); }
              },
              (error) => {   this.isShowSRequestsProgressBar = false; this.dispalyErrorAlert(error?.error); }
            );
        }
      });
    } else {   this.isShowSRequestsProgressBar = false; this.dispalyErrorAlert('No service requests were found for the cancel'); }
  }

  dispalyErrorAlert(msg: string) {
    this.dialog.open(ErrorAlertComponent, {
      width: '400px',
      data: {
        errorMsg: msg,
      },
    });
  }

  reloadToInitialState() {
    this.isTableEditable = false;
    this.serviceRequestsSelection.clear();
    this.refreshServiceRequests();
    this.displayedColumns();
  }

  isShowSelectButton(): boolean {
    let status = '';
    if (
      this.serviceRequestService.filterDetails?.status &&
      this.serviceRequestService.filterDetails?.status != ''
    ) {
      status = this.serviceRequestService.filterDetails?.status;
    }
    if (status == '') { return true }
    else if (status != '' && status.length > 0 && (status == 'PAUSE' || status == 'OPEN')) {
      return true
    } else return false;
  }

  getTotalCost() {
    return this.partDataSource.data.map(t => t.COST).reduce((acc, value) => acc + value, 0);
  }

    getTotal(parts : any) {
    let cost = 0;
    if(parts && Object.keys(parts).length > 0) {
      for(let key in parts) {
        let child = parts[key];
        child.forEach((element: { Cost: any; }) => {
          cost = cost + element.Cost;
        });
        }
    }
    return cost;
  }

  switchCompany() {
    let userTenants: any = localStorage.getItem('userTenants');
    if (userTenants && userTenants != null) {
      userTenants = JSON.parse(userTenants);
      const dialogRef = this.dialog.open(CustomerSelectComponent, {
        panelClass: 'add-customer-dialog',
        width: '550px',
        height: '380px',
        disableClose: false,
        data: { mode: 0, item: userTenants },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
        this.refreshServiceRequests();
        }
      });
    }
  }

  returnDate(date: any) {
    return this.utilService.dateTimeConversion(date);
  }
}
