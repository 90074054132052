import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { LopaService } from 'src/app/services/lopa.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { ErrorAlertComponent } from '../error alert/error-alert.component';
import { LopaMessageComponent } from '../lopa-message/lopa-message.component';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';

export interface DialogData {
  item: any;
}

@Component({
  selector: 'app-lopa-details',
  templateUrl: './lopa-details.component.html',
  styleUrls: ['./lopa-details.component.scss'],
  providers: [
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    },
  ],
})
export class LopaDetailsComponent implements OnInit, AfterViewInit {
  public isShowProgressBar: boolean = false;
  public firstSectionformGroup: FormGroup = new FormGroup({});
  public secondSectionformGroup: FormGroup = new FormGroup({});
  public tailNumbers: any = {};
  public isShowDetails: boolean = false;
  public partsToBeReplaced: any[] = [];
  public details: any[] = [];
  public isServiceRequest: boolean = true;
  public partLables: any[] = [];
  public serviceRequests: any[] = [];
  public notifications: any[] = [];
  public locations: any[] = [];

  constructor(
    public dialog: MatDialog,
    public authService: AuthService,
    public lopaDetailsDialogRef: MatDialogRef<LopaDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public router: Router,
    public lopaService: LopaService
  ) {}

  ngOnInit(): void {
    this.firstSectionformGroup = new FormGroup({
      tailNo: new FormControl('', [Validators.required]),
      location: new FormControl(''),
      serviceRequest: new FormControl('All parts are available', [
        Validators.required,
      ]),
      notification: new FormControl('All', [Validators.required]),
    });
  }

  ngAfterViewInit(): void {
    this.getCustomization();
    this.getLopaInitialData();
  }

  closeDialog(): void {
    this.lopaDetailsDialogRef.close();
  }

  getLopaInitialData() {
    this.isShowProgressBar = true;
    this.tailNumbers = {};
    this.lopaService
      .getLopaInitialData(this.data?.item?.FLEET, this.data?.item?.SUB_FLEET)
      .subscribe(
        (response: any) => {
          // Success callback
          if (response.status === 200) {
            if (response.body) {
              this.tailNumbers = response?.body?.TAIL_NO;
              this.locations = response?.body?.LOCATION;
              this.firstSectionformGroup.controls.location.setValue('');
            }
            this.isShowProgressBar = false;
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.isShowProgressBar = false;
            this.showErrorDialog(response.error);
          } else {
            this.isShowProgressBar = false;
            this.showErrorDialog(response.message);
          }
        },
        (error) => {
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.isShowProgressBar = false;
            this.showErrorDialog(error.error);
          } else {
            this.isShowProgressBar = false;
            this.showErrorDialog(error.error);
          }
        }
      );
  }

  showErrorDialog(msg: string) {
    const dialogRef = this.dialog.open(ErrorAlertComponent, {
      width: '400px',
      data: {
        errorMsg: msg,
      },
    });
  }

  search() {
    this.isShowProgressBar = true;
    this.partsToBeReplaced = [];
    this.details = [];
    this.lopaService
      .getLopaInventory(
        this.data?.item?.FLEET,
        this.data?.item?.SUB_FLEET,
        this.firstSectionformGroup.controls.tailNo.value,
        this.firstSectionformGroup.controls.location.value,
        this.isServiceRequest
          ? this.firstSectionformGroup.controls.serviceRequest.value
          : this.firstSectionformGroup.controls.notification.value,
        this.isServiceRequest ? 'SERVICE_REQUEST' : 'NOTIFICATION'
      )
      .subscribe(
        (response: any) => {
          // Success callback
          if (response.status === 200) {
            if (response.body) {
              console.log(response);
              this.partsToBeReplaced = response?.body['Parts Availability'];
              let details = response?.body;
              let tempMainKeys = Object.keys(details);
              let tempArr = [];
              for (let i = 0; i < tempMainKeys?.length; i++) {
                if (tempMainKeys[i] != 'Parts Availability') {
                  let obj: any = {
                    class: tempMainKeys[i],
                    seats: [],
                  };

                  let seatNumbersdetails =
                    details[tempMainKeys[i]]['Seat Number'];
                  let seatNumbersKeys = Object.keys(seatNumbersdetails);
                  for (let sn = 0; sn < seatNumbersKeys.length; sn++) {
                    let tempObj: any = { key: '', value: '' };
                    let tempSeatKeys =
                      details[tempMainKeys[i]]['Seat Number'][
                        seatNumbersKeys[sn]
                      ];
                    tempObj['key'] = seatNumbersKeys[sn];
                    tempObj['value'] = tempSeatKeys;
                    obj.seats.push(tempObj);
                  }
                  tempArr.push(obj);
                }
              }
              this.details = tempArr;
              this.isShowDetails = true;
            }
            this.isShowProgressBar = false;
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.isShowProgressBar = false;
            this.showErrorDialog(response.error);
          } else {
            this.isShowProgressBar = false;
            this.showErrorDialog(response.message);
          }
        },
        (error) => {
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.isShowProgressBar = false;
            this.showErrorDialog(error.error);
          } else {
            this.isShowProgressBar = false;
            this.showErrorDialog(error.error);
          }
        }
      );
  }

  getCustomization() {
    this.isShowProgressBar = true;
    this.partLables = [];
    this.lopaService.getCustomization().subscribe(
      (response: any) => {
        // Success callback
        if (response.status === 200) {
          if (response?.body && response?.body?.PART_LABELS) {
            let temp = response?.body?.PART_LABELS;
            for (const [key, value] of Object.entries(temp)) {
              let obj = {
                key: key,
                value: value,
                isCheck: this.partLables.length > 0 ? false : true,
              };
              this.partLables.push(obj);
            }
          }
          this.isShowProgressBar = false;
        } else if (response.status === 401) {
          // Redirect to login for unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        } else if (response.status === 403) {
          this.isShowProgressBar = false;
          this.showErrorDialog(response.error);
        } else {
          this.isShowProgressBar = false;
          this.showErrorDialog(response.message);
        }
      },
      (error) => {
        if (error.status === 401) {
          // Redirect to login for unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        } else if (error.status === 403) {
          this.isShowProgressBar = false;
          this.showErrorDialog(error.error);
        } else {
          this.isShowProgressBar = false;
          this.showErrorDialog(error.error);
        }
      }
    );
  }

  tailNumberChange(event: any) {
    this.serviceRequests = [];
    this.notifications = [];
    if (this.isServiceRequest) {
      this.serviceRequests = this.tailNumbers[event?.value]?.SERVICE_REQUEST;
      this.firstSectionformGroup.controls.serviceRequest.setValue(
        'All parts are available'
      );
    } else {
      this.notifications = this.tailNumbers[event?.value]?.NOTIFICATION;
      this.firstSectionformGroup.controls.notification.setValue('All');
    }
  }

  typeChange(ev: any) {
    this.serviceRequests = [];
    this.notifications = [];
    if (this.isServiceRequest) {
      this.serviceRequests =
        this.tailNumbers[
          this.firstSectionformGroup.controls.tailNo.value
        ]?.SERVICE_REQUEST;
      this.firstSectionformGroup.controls.serviceRequest.setValue(
        'All parts are available'
      );
    } else {
      this.notifications =
        this.tailNumbers[
          this.firstSectionformGroup.controls.tailNo.value
        ]?.NOTIFICATION;
      this.firstSectionformGroup.controls.notification.setValue('All');
    }
  }

  checkReplacePartsCount(parts: any): number {
    let count = 0;
    if (parts && parts.length > 0) {
      for (let p = 0; p < parts.length; p++) {
        if (parts[p]['Service Request']) {
          count = count + 1;
        }
      }
    }
    return count;
  }

  checkOtherPartsCount(parts: any): number {
    let count = 0;
    if (parts && parts.length > 0) {
      for (let p = 0; p < parts.length; p++) {
        if (!parts[p]['Service Request']) {
          count = count + 1;
        }
      }
    }
    return count;
  }

  seatsCountByClass(item: any): number {
    let count = 0;
    if (item?.seats && item?.seats?.length > 0) {
      let tempCount: any = Object.keys(item?.seats[0]?.value)?.length;
      if (tempCount && tempCount > 0) {
        count = item?.seats?.length * tempCount;
      }
    }
    return count;
  }

  openMessageDialog() {
    let dialogRef = this.dialog.open(LopaMessageComponent, {
      width: '350px',
      data: {
        requestId: this.firstSectionformGroup.controls.serviceRequest.value,
        item: {
          subfleet: this.data?.item?.SUB_FLEET
            ? this.data?.item?.SUB_FLEET
            : '',
          fleet: this.data?.item?.FLEET ? this.data?.item?.FLEET : '',
          tailno: this.firstSectionformGroup.controls.tailNo.value
            ? this.firstSectionformGroup.controls.tailNo.value
            : '',
          location: this.firstSectionformGroup.controls.location.value
            ? this.firstSectionformGroup.controls.location.value
            : '',
          requestid: this.firstSectionformGroup.controls.serviceRequest.value
            ? this.firstSectionformGroup.controls.serviceRequest.value
            : '',
          requesttype: 'SERVICE_REQUEST',
        },
      },
      hasBackdrop: true,
      panelClass: 'lopa-message',
    });
  }

  chairBackgroundColor(parts: any): boolean {
    let temp = false;
    if (parts && parts.length > 0) {
      for (let p = 0; p < parts.length; p++) {
        if (parts[p]['Service Request']) {
          if (parts[p]['Availability'] == 0) {
            temp = true;
            return temp;
          }
        }
      }
    }
    return temp;
  }

  async convertToServiceRequest() {
    this.isShowProgressBar = true;
    let body: any = {
      fleet: this.data?.item?.FLEET ? this.data?.item?.FLEET : '',
      subfleet: this.data?.item?.SUB_FLEET ? this.data?.item?.SUB_FLEET : '',
      tailno: this.firstSectionformGroup.controls.tailNo.value,
      requestid: this.firstSectionformGroup.controls.notification.value,
    };

    await this.lopaService
      .convertServiceRequest(body)
      .toPromise()
      .then(
        (response: any) => {
          this.isShowProgressBar = false;
          if (response.status === 204) {
            const timeout = 3000;
            const dialogRef = this.dialog.open(SuccessDialogComponent, {
              width: '400px',
              data: { msg: 'Converted Service Request Successfully' },
            });
            dialogRef.afterOpened().subscribe((_) => {
              setTimeout(() => {
                dialogRef.close();
              }, timeout);
            });
          } else {
            this.showErrorAlertDialog(response.message);
          }
        },
        (error) => {
          this.isShowProgressBar = false;
          this.showErrorAlertDialog(error.error);
        }
      );
  }

  showErrorAlertDialog(msg: string) {
    const dialogRef = this.dialog.open(ErrorAlertComponent, {
      width: '400px',
      data: {
        errorMsg: msg,
      },
    });
  }

  isShowPartNumber(type: any) {
    let res = this.partLables.filter((i) => i.isCheck == true);
    if (res && res.length > 0) {
      let val = false;
      for (let m = 0; m < this.partLables.length; m++) {
        if (this.partLables[m].value === type) {
          if (this.partLables[m].isCheck == true) {
            val = true;
          } else {
            val = false;
          }
        }
      }
      return val;
    } else {
      return true;
    }
  }

  partNumberChange(event: any) {
    let res = this.partLables.filter((i) => i.isCheck == true);
    if (res && res.length > 0) {
      this.isShowPartNumber(event);
    }
  }

  seatColorCssClass(item: any): string {
    let cssClass = 'primary-color material-icons-outlined';
    let res = item?.value.filter(
      (i: { [x: string]: boolean }) =>
        i['Service Request'] != null && i['Service Request'] != undefined
    );
    if (res && res.length > 0) {
      let lowAvialability = res.filter(
        (i: { Availability: number }) => i.Availability <= 0
      );
      if (lowAvialability && lowAvialability.length > 0) {
        cssClass = 'warning-color material-icons-outlined';
      } else {
        cssClass = 'success-color material-icons-outlined';
      }
    }
    return cssClass;
  }

  getAvailabilityBadge(item: any): string {
    let count = '';
    let res = item?.value.filter(
      (i: { [x: string]: boolean }) =>
        i['Service Request'] != null && i['Service Request'] != undefined
    );
    if (res && res.length > 0) {
      let lowAvialability = res.filter(
        (i: { Availability: number }) => i.Availability <= 0
      );
      if (lowAvialability && lowAvialability.length > 0) {
        let avialability = res.filter(
          (i: { Availability: number }) => i.Availability > 0
        );
        count = `${avialability.length}/${res.length}`;
      }
    }
    return count;
  }
}
