import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActionType } from 'src/app/shared/AppConstants';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CustomerService } from 'src/app/services/customer.service';
import { UtilService } from 'src/app/services/util.service';
import { EmailCofirmationComponent } from '../email-cofirmation/email-cofirmation.component';

export interface DialogData {
  mode: number;
  item: any;
}

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
})
export class CustomerComponent implements OnInit {
  public title: string;
  public formGroup: FormGroup;
  public errorMsg: string;
  public hide: boolean;
  public isShowProgressBar: boolean = false;

  constructor(
    public dialog: MatDialog,
    public customerService: CustomerService,
    public utilService: UtilService,
    public customerDialogRef: MatDialogRef<CustomerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.hide = true;
    this.errorMsg = '';
    this.title =
      this.data.mode == ActionType.Add ? 'Create Customer' : 'Update Customer';

    if (this.data.mode == ActionType.Add) {
      this.formGroup = new FormGroup({
        customerId: new FormControl(''),
        adminEmail: new FormControl('', [
          Validators.required,
          Validators.email,
        ]),
        adminFirstName: new FormControl('', [Validators.required]),
        adminLastName: new FormControl('', [Validators.required]),
        type: new FormControl('', [Validators.required]),
        // adminPassword: new FormControl('', [Validators.required]),
        name: new FormControl('', [Validators.required]),
        address: new FormControl(''),
        city: new FormControl('', [Validators.required]),
        state: new FormControl('', [Validators.required]),
        country: new FormControl('', [Validators.required]),
        zipcode: new FormControl('', [Validators.required]),
      });
    } else {
      this.formGroup = new FormGroup({
        customerId: new FormControl(
          { value: this.data.item.CUSTOMER_ID, disabled: true },
          [Validators.required]
        ),
        type: new FormControl({ value: this.data.item.TYPE, disabled: true }, [
          Validators.required,
        ]),
        name: new FormControl(this.data.item.NAME, [Validators.required]),
        address: new FormControl(this.data.item.ADDRESS),
        city: new FormControl(this.data.item.CITY, [Validators.required]),
        state: new FormControl(this.data.item.STATE, [Validators.required]),
        country: new FormControl(this.data.item.COUNTRY, [Validators.required]),
        zipcode: new FormControl(this.data.item.ZIPCODE, [Validators.required]),
      });
    }

    this.formGroup.valueChanges.subscribe((x) => {
      this.errorMsg = '';
    });
  }

  ngOnInit(): void { }

  closeDialog(): void {
    this.customerDialogRef.close(false);
  }

  async saveCustomer() {
    let body: any = {
      ADDRESS: this.formGroup.controls.address.value,
      NAME: this.formGroup.controls.name.value,
      CITY: this.formGroup.controls.city.value,
      STATE: this.formGroup.controls.state.value,
      COUNTRY: this.formGroup.controls.country.value,
      ZIPCODE: this.formGroup.controls.zipcode.value,
      TYPE: this.formGroup.controls.type.value,
    };

    if (this.data.mode === ActionType.Add) {
      const dialogRef = this.dialog.open(EmailCofirmationComponent, {
        data: { item: this.formGroup.controls.adminEmail.value },
      });

      dialogRef.afterClosed().subscribe(async result => {
        if (result) {
          // Create a new Customer
          this.isShowProgressBar = true;
          body.EMAIL = this.formGroup.controls.adminEmail.value;
          (body.FIRST_NAME = this.formGroup.controls.adminFirstName.value),
            (body.LAST_NAME = this.formGroup.controls.adminLastName.value),
            await this.customerService
              .createCustomer(body)
              .toPromise()
              .then(
                (response) => {
                  this.isShowProgressBar = false;
                  if (response.status === 204) {
                    this.customerDialogRef.close(true);
                  } else {
                    this.errorMsg = response.message;
                  }
                },
                (error) => {
                  this.isShowProgressBar = false;
                  this.errorMsg = error.error;
                }
              );
        }
      });
    } else {
      // Update existing Customer
      this.isShowProgressBar = true;
      body.CUSTOMER_ID = this.formGroup.controls.customerId.value;
      await this.customerService
        .updateCustomer(body)
        .toPromise()
        .then(
          (response: any) => {
            if (response.status === 204) {
              this.isShowProgressBar = false;
              this.customerDialogRef.close(true);
            } else {
              this.errorMsg = response.message;
            }
          },
          (error) => {
            this.isShowProgressBar = false;
            this.errorMsg = error.error;
          }
        );
    }
  }

  openDialog(): void {

  }
}
