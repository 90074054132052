import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'UppercaseToLowerCaseFilter',
})
export class UppercaseToLowerCaseFilterPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) return null;
    let tempVal = value.toLowerCase();
    return tempVal.charAt(0).toUpperCase() + tempVal.slice(1);
  }
}
