<!--#region Toolbar -->
<mat-toolbar class="app-toolbar-fixed">
    <span> Equipment </span>
    <span class="example-spacer"></span>
    <button mat-icon-button (click)="closeDialog()" onclick="this.blur()">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>
<mat-progress-bar *ngIf="isShowProgressBar" mode="indeterminate"></mat-progress-bar>
<!--#endregion -->

<mat-list *ngIf="equipments && equipments?.length > 0" style="padding-top:0px !important;">
    <mat-list-item *ngFor="let equipment of equipments">
        <mat-icon mat-list-icon class="material-icons-outlined" style="font-size: 26px;"
            *ngIf="data.item.RESULTS != null && equipment.STATUS != 'Scanned' && equipment.STATUS != 'Unscanned'"
            [ngStyle]="{'color': equipment.STATUS | auditEquipmentIconColorFilter}">
            {{equipment.STATUS
            | auditEquipmentIconFilterPipe}}
        </mat-icon>

        <mat-icon *ngIf="data.item.RESULTS != null && equipment.STATUS == 'Scanned'" mat-list-icon style="color:#8bc34a"
            class='material-icons-outlined'>qr_code_scanner
        </mat-icon>

        <mat-icon *ngIf="data.item.RESULTS != null && equipment.STATUS == 'Unscanned'" mat-list-icon
            style="color:#ff9800" class='material-icons-outlined'>qr_code_scanner
        </mat-icon>

        <p slot="left" style="color: black; opacity: 0.6;min-width: max-content !important;">#
            {{equipment.EQUIPMENT_ID}} </p>
        <h3 mat-line> {{equipment.NAME}} </h3>
        <mat-divider></mat-divider>
    </mat-list-item>
</mat-list>

<div class="centered" *ngIf="(!equipments || equipments.length == 0) && !isShowProgressBar">
    <h3 style="color:#00629b;">No equipment found</h3>
</div>