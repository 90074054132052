<mat-toolbar class="toolbar">
    <button mat-icon-button (click)="authService.isExpanded = !authService.isExpanded">
        <mat-icon>menu</mat-icon>
    </button>
        {{utilService.customerName}}
    <div class="company-logo">
        <button mat-button routerLink="/dashboard">
            <img class="company-image" src="assets/logo/AFS_PrimaryLogo_1C_White.png" />
        </button>
    </div>
    <span class="example-spacer"></span>
    <button matTooltip="Switch Company" mat-icon-button *ngIf="authService.isAfsUser()" (click)="switchCompany()" >
        <mat-icon style="font-size: 29px;" class="material-icons-outlined">swap_horiz</mat-icon>
      </button> &nbsp;
    <button matTooltip="Logout" mat-icon-button (click)="logout()">
        <mat-icon class="material-icons-outlined">logout</mat-icon>
    </button>
</mat-toolbar>

<mat-sidenav-container class="sidenav-container" autosize [hasBackdrop]="false">
    <mat-sidenav #drawer [fixedInViewport]="true" [disableClose]="true" [fixedTopGap]="55"
        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'side' : 'side'"
        [opened]="true">
        <div class="side-menu-height">
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('ADMIN')  && authService.isNotAfsCustomer()">
                <a mat-list-item [routerLink]="['/dashboard']" matTooltip="Dashboard" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">dashboard</mat-icon>
                    <span [translate]="'Dashboard'" *ngIf="authService.isExpanded"
                        class="menu-icon-spacing">Dashboard</span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('ADMIN') && authService.isNotAfsCustomer()">
                <a mat-list-item [routerLink]="['/reports']" matTooltip="Reports" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">auto_awesome_mosaic</mat-icon>
                    <span [translate]="'Reports'" *ngIf="authService.isExpanded"
                        class="menu-icon-spacing">Reports</span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('CUSTOMER')">
                <a mat-list-item [routerLink]="['/customers']" matTooltip="Customers" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon>corporate_fare</mat-icon>
                    <span [translate]="'Customers'" *ngIf="authService.isExpanded"
                        class="menu-icon-spacing">Customers</span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('USER')">
                <a mat-list-item [routerLink]="['/users']" matTooltip="Users" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">people</mat-icon>
                    <span [translate]="'Users'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Users</span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('SERVICE_REQUEST')">
                <a mat-list-item [routerLink]="['/service-requests']" matTooltipPosition="right"
                    matTooltip="Service Requests" routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">content_paste</mat-icon>
                    <span [translate]="'Service Requests'" *ngIf="authService.isExpanded"
                        class="menu-icon-spacing">Service
                        Requests</span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('SCHEDULED_SERVICE_REQUEST')">
                <a mat-list-item [routerLink]="['/timed-services']" matTooltipPosition="right"
                    matTooltip="Timed Services" routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">timer</mat-icon>
                    <span [translate]="'Timed Services'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Timed
                        Services</span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('SCHEDULED_SERVICE_REQUEST')">
                <a mat-list-item [routerLink]="['/timed-service-schedules']" matTooltipPosition="right"
                    matTooltip="Timed Service Schedules" routerLinkActive="active-list-item">
                    <mat-icon class="material-icons">pending_actions</mat-icon>
                    <span [translate]="'Timed Service Schedules'" *ngIf="authService.isExpanded"
                        class="menu-icon-spacing">Timed Service Schedules</span>
                </a>
            </mat-nav-list>
            <mat-nav-list
                *ngIf="(authService.checkRoleBasedModuleAccess('MESSAGE') || authService.checkRoleBasedModuleAccess('SERVICE_REQUEST'))">
                <a mat-list-item [routerLink]="['/notifications']" matTooltip="Notifications" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">feedback</mat-icon>
                    <span [translate]="'Notifications'" *ngIf="authService.isExpanded"
                        class="menu-icon-spacing">Notifications</span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('AUDIT')">
                <a mat-list-item [routerLink]="['/audits']" matTooltip="Services " matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon>checklist_rtl</mat-icon>
                    <span [translate]="'Services'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Services
                    </span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('AUDIT')">
                <a mat-list-item [routerLink]="['/audit-schedule']" matTooltip="Scheduled Services"
                    matTooltipPosition="right" routerLinkActive="active-list-item">
                    <mat-icon>schedule</mat-icon>
                    <span [translate]="'Scheduled Services'" *ngIf="authService.isExpanded"
                        class="menu-icon-spacing">Scheduled Services
                    </span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('LOPA')">
                <a mat-list-item [routerLink]="['/lopa']" matTooltip="LOPA Management" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">flight</mat-icon>
                    <span [translate]="'Lopa'" *ngIf="authService.isExpanded" class="menu-icon-spacing">LOPA Management
                    </span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('EQUIPMENT')">
                <a mat-list-item [routerLink]="['/locations']" matTooltip="Locations" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">my_location</mat-icon>
                    <span [translate]="'Locations'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Locations
                    </span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('EQUIPMENT')">
                <a mat-list-item [routerLink]="['/parts']" matTooltip="Parts" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">chair_alt</mat-icon>
                    <span [translate]="'Parts'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Parts
                    </span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('BUDGET')">
                <a mat-list-item [routerLink]="['/budget']" matTooltip="Budget" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">card_travel</mat-icon>
                    <span [translate]="'Budget'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Budget
                    </span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('INVOICE')">
                <a mat-list-item [routerLink]="['/invoice']" matTooltip="Invoice" matTooltipPosition="right"
                  routerLinkActive="active-list-item">
                  <mat-icon class="material-icons-outlined">description</mat-icon>
                  <span [translate]="'Invoice'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Invoice
                  </span>
                </a>
              </mat-nav-list>
              <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('PART_ORDER') || authService.checkRoleBasedModuleAccess('PART_ORDER_READ_ONLY')">
                <a mat-list-item [routerLink]="['/part-orders']" matTooltip="Part Orders" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-icons-outlined">add_shopping_cart</mat-icon>
                    <span [translate]="'Part Orders'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Part Orders
                    </span>
                </a>
            </mat-nav-list>
            <mat-nav-list *ngIf="authService.checkRoleBasedModuleAccess('BILLABLE_UNIT_REPORT') || authService.checkRoleBasedModuleAccess('BILLABLE_UNIT_REPORT_READ_ONLY')">
                <a mat-list-item [routerLink]="['/billable-reports']" matTooltip="Billable Reports" matTooltipPosition="right"
                    routerLinkActive="active-list-item">
                    <mat-icon class="material-symbols-outlined material-icons-outlined">receipt_long</mat-icon>
                    <span [translate]="'Billable Reports'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Billable Reports
                    </span>
                </a>
                </mat-nav-list>
        </div>
        <div [ngClass]='appropriateClass'>
            <mat-nav-list>
                <a mat-list-item [routerLink]="['/settings']" matTooltip="Settings" matTooltipPosition="right"
                    routerLinkActive="active-list-item" *ngIf="authService.checkRoleBasedModuleAccess('SETTINGS')">
                    <mat-icon class="material-icons-outlined">settings</mat-icon>
                    <span [translate]="'Settings'" *ngIf="authService.isExpanded" class="menu-icon-spacing">Settings
                    </span>
                </a>

                <a (click)="authService.isExpanded = !authService.isExpanded" mat-list-item>
                    <mat-icon *ngIf="authService.isExpanded">keyboard_double_arrow_left</mat-icon>
                    <mat-icon *ngIf="!authService.isExpanded">keyboard_double_arrow_right</mat-icon>
                    <span [translate]="'Collapse sidebar'" *ngIf="authService.isExpanded"
                        class="menu-icon-spacing">Collapse
                        sidebar</span>
                    <span slot="right" style="color: grey;font-size: small; margin: 0 0 0 auto;"
                        *ngIf="authService.isExpanded" class="menu-icon-spacing">
                        {{version }}</span>
                </a>
            </mat-nav-list>
        </div>
    </mat-sidenav>

    <mat-sidenav-content style="background: none !important;">
        <mat-toolbar class="toolbar-height-50">
            <span>Budget</span>
            <span class="toolbar-spacer"></span>
            <button mat-icon-button (click)="refreshBudgets()" matTooltip="Refresh">
                <mat-icon class="material-icons-outlined">refresh</mat-icon>
            </button>
        </mat-toolbar>

        <div class="container mat-elevation-z4">
            <mat-progress-bar *ngIf="isShowBudjetProgressBar" class="primary-color" mode="indeterminate">
            </mat-progress-bar>
            <mat-toolbar class="container-toolbar">
                <mat-toolbar-row class="container-toolbar-row">

                    <mat-form-field appearance="outline" class="fit-content"
                        style="font-size: 15px !important;margin: 12px 10px 0px 10px;">
                        <mat-label>Select Fiscal Year</mat-label>
                        <mat-select disableOptionCentering [(ngModel)]="selectedBudgetYear"
                            (selectionChange)="budgetYearChange($event)">
                            <mat-option *ngFor="let year of budgetYears" [value]="year.FY">
                                {{year.FY_LABEL}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <button mat-button [disabled]="isDisabledYear" [ngClass]="isDisabledYear? '': 'primary-color'"
                        (click)="addBudget()" onclick="this.blur()" style="margin-bottom: 5px;">
                        <mat-icon [ngClass]="isDisabledYear? '': 'primary-color'">add</mat-icon>
                        Add New Budget
                    </button>
                    <span class="example-spacer"></span>
                    <mat-paginator #paginator [pageSize]="paginatorLength" [pageSizeOptions]="[5, 10, 25, 50, 100]"
                        (page)="handlePaginator($event);" [length]="budgetsLength">
                    </mat-paginator>
                </mat-toolbar-row>
            </mat-toolbar>

            <div class="table-container">
                <table mat-table (matSortChange)="sortfields($event)" [dataSource]="budgetsDataSource" matSort
                    class="example-table">

                    <!-- ID Column -->
                    <ng-container matColumnDef="BUDGET_ID">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                        <td mat-cell *matCellDef="let row"> {{row.BUDGET_ID}} </td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="PART_GROUP_NAME">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Group Name </th>
                        <td mat-cell *matCellDef="let row"> {{row.PART_GROUP_NAME}} </td>
                    </ng-container>

                    <!-- City Column -->
                    <ng-container matColumnDef="PART_GROUPS">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Parts </th>
                        <td mat-cell *matCellDef="let row">
                            <span *ngFor="let part of row.PART_GROUPS">
                                <div class="badge" *ngIf="part != ''"> {{part}}</div>
                            </span>
                        </td>
                    </ng-container>

                    <!-- Country Column -->
                    <ng-container matColumnDef="BUDGET">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="direction: rtl"> Budget</th>
                        <td mat-cell *matCellDef="let row"> {{row.BUDGET | currency:'USD'}} </td>
                    </ng-container>

                    <!-- Country Column -->
                    <ng-container matColumnDef="BUDGET_YEAR">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="direction: rtl"> Fiscal Year </th>
                        <td mat-cell *matCellDef="let row"> {{row.BUDGET_YEAR}} </td>
                    </ng-container>

                    <!-- Actions Column -->
                    <ng-container matColumnDef="Actions">
                        <th mat-header-cell *matHeaderCellDef style="direction: rtl"> Actions </th>
                        <td mat-cell *matCellDef="let row">
                            <button mat-icon-button matTooltip="Edit" [disabled]="isDisabledYear"
                                (click)="editBudget(row)">
                                <mat-icon
                                    [ngClass]="isDisabledYear? 'material-icons-outlined': 'primary-color material-icons-outlined'">
                                    edit</mat-icon>
                            </button>
                            <button mat-icon-button matTooltip="Delete" (click)="deleteBudget(row)">
                                <mat-icon class='warning-color'>
                                    delete_outline</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>

                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="9999" style="color:rgb(228, 0, 43)">
                            <span *ngIf="budgetsDataSource?.data?.length == 0 && !isShowBudjetProgressBar">
                                No budgets found
                            </span>
                            <span *ngIf="budgetsDataSource?.data?.length == 0 && isShowBudjetProgressBar">
                            </span>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>