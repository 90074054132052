import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddScheduleServiceRequestComponent } from 'src/app/components/add-schedule-service-request/add-schedule-service-request.component';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { CustomerSelectComponent } from 'src/app/components/customer-select/customer-select.component';
import { ErrorAlertComponent } from 'src/app/components/error alert/error-alert.component';
import { ScheduledServiceRequestFilterComponent } from 'src/app/components/scheduled-service-request-filter/scheduled-service-request-filter.component';
import { AuthService } from 'src/app/services/auth.service';
import { ScheduledServiceRequestService } from 'src/app/services/scheduled-service-request.service';
import { ServiceRequestService } from 'src/app/services/service-request.service';
import { UtilService } from 'src/app/services/util.service';
import { ActionType, AppConstants } from 'src/app/shared/AppConstants';

@Component({
  selector: 'app-scheduled-service-request',
  templateUrl: './scheduled-service-request.component.html',
  styleUrls: ['./scheduled-service-request.component.scss'],
})
export class ScheduledServiceRequestComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: any;
  @ViewChild(MatSort, { static: true }) sort!: any;

  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));
  public version: string;
  public release: string;
  public constants: AppConstants;
  public isShowRequitsProgressBar: boolean = false;
  public requestsLength: number;
  public paginatorLength: number;
  public requestsDataSource: MatTableDataSource<any>;
  public tableColumns: string[] = [
    'SR_SCH_ID',
    'LOCATION',
    'DESCRIPTION',
    'ETA',
    'SCHEDULE',
    'NO_OF_EQUIPMENTS',
    'NO_OF_EQUIPMENTS_CATEGORIES',
    'NO_OF_PARTS',
    'UPDATED_BY_NAME',
    'UPDATED_AT',
    'Actions',
  ];
  public isAirlineCusromer: boolean = false;
  public appropriateClass:string = '';
  public events: string[] = [];
  
  @HostListener('window:resize', ['$event'])
  getScreenHeight(event?: any){
    if(window.innerHeight<=700){
      this.appropriateClass = 'bottomRelative';
    }else{
      this.appropriateClass = 'bottomStick';
    }
  }
  constructor(
    public authService: AuthService,
    private breakpointObserver: BreakpointObserver,
    public utilService: UtilService,
    public dialog: MatDialog,
    public router: Router,
    public sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    public scheduledServiceRequest: ScheduledServiceRequestService,
    public serviceRequestService: ServiceRequestService
  ) {
    this.getScreenHeight();
    this.constants = new AppConstants();
    this.version = this.constants.APP_RELEASE_NUMBER;
    this.release = this.constants.APP_RELEASE_DATE;
    this.requestsLength = 0;
    this.paginatorLength = this.constants.INITIAL_PAGINATOR_LIMIT;
    this.requestsDataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    let customerId: any = localStorage.getItem('customerId');
    let temp: any = localStorage.getItem('user-details');
    let user: any = JSON.parse(temp);
    this.isAirlineCusromer = user.CUSTOMER_TYPE == 'AIRLINE' ? true : false;
    if (customerId && customerId != null) {
      customerId == 1
        ? (this.authService.isAFSUser = true)
        : (this.authService.isAFSUser = false);
    }

    if (
      this.scheduledServiceRequest.scheduledServiceRequestPageStateDetails !=
      null
    ) {
      this.paginator.pageIndex =
        this.scheduledServiceRequest.scheduledServiceRequestPageStateDetails
          .pageIndex &&
        this.scheduledServiceRequest.scheduledServiceRequestPageStateDetails
          .pageIndex != ''
          ? this.scheduledServiceRequest.scheduledServiceRequestPageStateDetails
              .pageIndex
          : 0;
      this.paginatorLength = this.scheduledServiceRequest
        .scheduledServiceRequestPageStateDetails?.limit
        ? this.scheduledServiceRequest.scheduledServiceRequestPageStateDetails
            ?.limit
        : this.paginatorLength;
      let offset = this.scheduledServiceRequest
        .scheduledServiceRequestPageStateDetails?.offset
        ? this.scheduledServiceRequest.scheduledServiceRequestPageStateDetails
            ?.offset
        : '';
      this.getScheduledServiceRequests(
        this.paginatorLength,
        offset,
        '',
        '',
        ''
      );
      this.scheduledServiceRequest.scheduledServiceRequestPageStateDetails =
        null;
    } else {
      this.getScheduledServiceRequests(this.paginatorLength, 0);
    }
  }

  // Logout from the app
  logout() {
    this.authService.logout();
  }

  refreshScheduledServiceRequests() {
    this.serviceRequestService.filterDetails = null;
    this.paginator.pageIndex = 0;
    this.paginatorLength = this.constants.INITIAL_PAGINATOR_LIMIT;
    this.getScheduledServiceRequests(this.paginatorLength, 0);
  }

  getScheduledServiceRequests(
    limit: number,
    offset: number,
    searchText?: any,
    sortField?: any,
    sortDirection?: any,
    location?: any
  ) {
    this.isShowRequitsProgressBar = true;
    this.scheduledServiceRequest
      .getScheduledServiceRequests(
        limit,
        offset,
        searchText,
        sortField,
        sortDirection,
        location
      )
      .subscribe(
        (response: any) => {
          // Success callback
          if (response.status === 200) {
            if (response.body) {
              // Assign the data to the data source for the table to render
              this.requestsDataSource = new MatTableDataSource(
                response?.body?.SERVICE_REQUEST_SCHEDULES
              );
              this.requestsLength =
                response?.body?.SERVICE_REQUEST_SCHEDULES_COUNT;
            }
            this.isShowRequitsProgressBar = false;
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.isShowRequitsProgressBar = false;
            this.router.navigate(['dashboard']);
          } else {
            this.isShowRequitsProgressBar = false;
            this.showErrorDialog(response.error);
          }
        },
        (error) => {
          this.isShowRequitsProgressBar = false;
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.isShowRequitsProgressBar = false;
            this.router.navigate(['dashboard']);
          }else {
            this.showErrorDialog(error.message);
          }
        }
      );
  }

  showErrorDialog(msg: string) {
    const dialogRef = this.dialog.open(ErrorAlertComponent, {
      width: '400px',
      data: {
        errorMsg: msg,
      },
    });
  }

  addNewRequestSchedule() {
    const dialogRef = this.dialog.open(AddScheduleServiceRequestComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: { mode: ActionType.Add, item: {} },
      panelClass: 'add-customer-dialog',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.paginator.pageIndex = 0;
        this.paginatorLength = this.constants.INITIAL_PAGINATOR_LIMIT;
        this.getScheduledServiceRequests(this.paginatorLength, 0);
      }
    });
  }

  sortfields(e: any) {
    let offset = this.paginator._pageIndex * this.paginator._pageSize;
    let location = '';
    if (
      this.serviceRequestService.filterDetails?.location &&
      this.serviceRequestService.filterDetails?.location != ''
    ) {
      location = this.serviceRequestService.filterDetails?.location;
    }
    this.getScheduledServiceRequests(
      this.paginatorLength,
      offset,
      '',
      e.active,
      e.direction,
      location
    );
    this.paginator.pageIndex = this.paginator._pageIndex;
  }

  handlePaginator(e: any) {
    this.paginatorLength = e.pageSize;
    let limit = e.pageSize;
    let offset = e.pageIndex * limit;
    this.paginator.pageIndex = e.pageIndex;
    let location = '';
    if (
      this.serviceRequestService.filterDetails?.location &&
      this.serviceRequestService.filterDetails?.location != ''
    ) {
      location = this.serviceRequestService.filterDetails?.location;
    }
    if (this.sort.active) {
      this.getScheduledServiceRequests(
        limit,
        offset,
        '',
        this.sort.active,
        this.sort._direction,
        location
      );
    } else {
      this.getScheduledServiceRequests(limit, offset, '', '', '', location);
    }
  }

  goToServiceList(item: any) {
    this.scheduledServiceRequest.scheduledServiceRequestPageStateDetails = {
      scheduleId: item.SR_SCH_ID,
      pageName: 'timed-service-schedules',
      pageIndex: this.paginator._pageIndex ? this.paginator._pageIndex : '',
      offset: this.paginator?._pageIndex * this.paginator?._pageSize,
      limit: this.paginatorLength,
    };
    this.router.navigate(['/timed-services']);
  }

  updateAuditSchedule(item: any, status: string) {
    let msg =
      status == ''
        ? 'Resume timed service schedules'
        : 'Pause timed service schedules';
    const dialogRef = this.dialog.open(AlertComponent, {
      width: '400px',
      data: {
        errorMsg: `${msg}?`,
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result.data) {
        let body: any = {
          SR_SCH_ID: item.SR_SCH_ID,
          PAUSED: status,
        };

        await this.scheduledServiceRequest
          .updateSchedule(body)
          .toPromise()
          .then(
            (response: any) => {
              if (response.status === 204) {
                let offset =
                  this.paginator._pageIndex * this.paginator._pageSize;
                this.paginator.pageIndex = this.paginator._pageIndex;
                let location = '';
                if (
                  this.serviceRequestService.filterDetails?.location &&
                  this.serviceRequestService.filterDetails?.location != ''
                ) {
                  location = this.serviceRequestService.filterDetails?.location;
                }
                if (this.sort.active) {
                  this.getScheduledServiceRequests(
                    this.paginatorLength,
                    offset,
                    '',
                    this.sort.active,
                    this.sort._direction,
                    location
                  );
                } else {
                  this.getScheduledServiceRequests(
                    this.paginatorLength,
                    offset,
                    '',
                    '',
                    '',
                    location
                  );
                }
              } else {
                this.showErrorDialog(response.message);
              }
            },
            (error) => {
              this.showErrorDialog(error.error);
            }
          );
      }
    });
  }

  editAuditSchedule(item: any) {
    const dialogRef = this.dialog.open(AddScheduleServiceRequestComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: { mode: ActionType.Edit, item: item },
      panelClass: 'add-customer-dialog',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let offset = this.paginator._pageIndex * this.paginator._pageSize;
        this.paginator.pageIndex = this.paginator._pageIndex;
        let location = '';
        if (
          this.serviceRequestService.filterDetails?.location &&
          this.serviceRequestService.filterDetails?.location != ''
        ) {
          location = this.serviceRequestService.filterDetails?.location;
        }
        if (this.sort.active) {
          this.getScheduledServiceRequests(
            this.paginatorLength,
            offset,
            '',
            this.sort.active,
            this.sort._direction,
            location
          );
        } else {
          this.getScheduledServiceRequests(this.paginatorLength, offset, '', '', '', location);
        }
      }
    });
  }

  filterServiceRequests() {
    let tempFilterDetails;
    if (
      this.serviceRequestService.filterDetails &&
      this.serviceRequestService.filterDetails != null &&
      this.serviceRequestService.filterDetails != undefined
    ) {
      tempFilterDetails = this.serviceRequestService.filterDetails;
    }
    let dialogRef = this.dialog.open(ScheduledServiceRequestFilterComponent, {
      width: '350px',
      data: { item: tempFilterDetails },
      hasBackdrop: true,
      panelClass: 'service-request-filter',
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.requestsDataSource = new MatTableDataSource();
        this.paginator.pageIndex = 0;
        this.serviceRequestService.filterDetails = result.data;
        let location = '';
        if (result?.data?.location && result?.data?.location != '') {
          location = result?.data?.location;
        }

        this.getScheduledServiceRequests(this.paginatorLength, 0, '', '', '', location);
      }
    });
  }

  switchCompany() {
    let userTenants: any = localStorage.getItem('userTenants');
    if (userTenants && userTenants != null) {
      userTenants = JSON.parse(userTenants);
      const dialogRef = this.dialog.open(CustomerSelectComponent, {
        panelClass: 'add-customer-dialog',
        width: '550px',
        height: '380px',
        disableClose: false,
        data: { mode: 0, item: userTenants },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
        this.refreshScheduledServiceRequests();
        }
      });
    }
  }

  returnDate(date: any) {
    return this.utilService.dateTimeConversion(date);
  }
}
