import { SelectionModel } from '@angular/cdk/collections';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuditEquipmentsComponent } from 'src/app/components/audit-equipments/audit-equipments.component';
import { AuditFilterComponent } from 'src/app/components/audit-filter/audit-filter.component';
import { CustomerSelectComponent } from 'src/app/components/customer-select/customer-select.component';
import { ErrorAlertComponent } from 'src/app/components/error alert/error-alert.component';
import { WarningComponent } from 'src/app/components/warning/warning.component';
import { AuditScheduleService } from 'src/app/services/audit-schedule.service';
import { AuditService } from 'src/app/services/audit.service';
import { AuthService } from 'src/app/services/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { AppConstants } from 'src/app/shared/AppConstants';

@Component({
  selector: 'app-sub-audits',
  templateUrl: './sub-audits.component.html',
  styleUrls: ['./sub-audits.component.scss']
})
export class SubAuditsComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: any;
  @ViewChild(MatSort, { static: true }) sort!: any;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  public auditsDataSource: MatTableDataSource<any>;
  public auditSelection = new SelectionModel<any>(true, []);
  public constants: AppConstants;
  public isShowAuditsProgressBar: boolean = false;
  public auditStatus: string = 'OPEN';
  public isAirlineCusromer: boolean = false;
  public paginatorLength: number;
  public auditsLength: number;
  public auditId: string = '';
  public version: string;
  public release: string;
  public appropriateClass:string = '';
  public events: string[] = [];
  
  @HostListener('window:resize', ['$event'])
  getScreenHeight(event?: any){
    if(window.innerHeight<=700){
      this.appropriateClass = 'bottomRelative';
    }else{
      this.appropriateClass = 'bottomStick';
    }
  }
  public tableColumns: string[] = [
    'AUDIT_ID',
    'LOCATION',
    'SCHEDULE',
    'DESCRIPTION',
    'UPDATED_BY_NAME',
    'UPDATED_AT',
    'Equipment_Status',
    'NOTIFICATION_COUNT',
    'Actions',
  ];

  constructor(
    public authService: AuthService,
    private breakpointObserver: BreakpointObserver,
    public utilService: UtilService,
    public dialog: MatDialog,
    public router: Router,
    public auditService: AuditService,
    public auditScheduleService: AuditScheduleService
  ) {
    this.getScreenHeight();
    this.auditsDataSource = new MatTableDataSource();
    this.constants = new AppConstants();
    this.version = this.constants.APP_RELEASE_NUMBER;
    this.release = this.constants.APP_RELEASE_DATE;
    this.paginatorLength = this.constants.INITIAL_PAGINATOR_LIMIT;
    this.auditsLength = 0;
  }

  ngOnInit(): void {
    this.auditSelection.clear();
    let customerId: any = localStorage.getItem('customerId');
    if (customerId && customerId != null) {
      customerId == 1
        ? (this.authService.isAFSUser = true)
        : (this.authService.isAFSUser = false);
    }
    let temp: any = localStorage.getItem('user-details');
    let user: any = JSON.parse(temp);
    this.isAirlineCusromer = user.CUSTOMER_TYPE == 'AIRLINE' ? true : false;
    if (this.auditService.auditToSubAuditStateDetails != null) {
      this.auditId = this.auditService?.auditToSubAuditStateDetails?.auditId;
    }

    if (this.auditService.subAuditsToNotificationStateDetails != null) {
      this.auditsDataSource = new MatTableDataSource();
      this.paginator.pageIndex =
        this.auditService.subAuditsToNotificationStateDetails.pageIndex &&
          this.auditService.subAuditsToNotificationStateDetails.pageIndex != ''
          ? this.auditService.subAuditsToNotificationStateDetails.pageIndex
          : 0;
      this.paginatorLength = this.auditService.subAuditsToNotificationStateDetails?.limit
        ? this.auditService.subAuditsToNotificationStateDetails?.limit
        : this.paginatorLength;
      this.auditStatus = this.auditService?.subAuditsToNotificationStateDetails?.filterDetails
        ?.status
        ? this.auditService?.subAuditsToNotificationStateDetails?.filterDetails?.status
        : 'OPEN';

      this.getSubAudits(
        this.auditId,
        this.paginatorLength,
        (this.auditService.subAuditsToNotificationStateDetails?.offset != null && this.auditService.subAuditsToNotificationStateDetails?.offset != undefined) ? this.auditService.subAuditsToNotificationStateDetails?.offset : 0,
        '',
        '',
        '',
        this.auditStatus,
        this.auditService?.subAuditsToNotificationStateDetails?.filterDetails?.location
          ? this.auditService?.subAuditsToNotificationStateDetails?.filterDetails?.location
          : '',
        '');

      this.auditService.subAuditsFilterDetails = this.auditService?.subAuditsToNotificationStateDetails
        ?.filterDetails
        ? this.auditService?.subAuditsToNotificationStateDetails?.filterDetails
        : null;

      this.auditService.subAuditsToNotificationStateDetails = null;
    } else {
      this.getSubAudits(this.auditId, this.paginatorLength, 0);
    }
  }

  logout() {
    this.authService.logout();
  }

  getSubAudits(
    refAuditId: any,
    limit: number,
    offset: number,
    searchText?: any,
    sortField?: any,
    sortDirection?: any,
    status?: any,
    location?: any,
    scheduleId?: any
  ) {

    this.isShowAuditsProgressBar = true;
    this.auditService
      .geSubtAudits(
        refAuditId,
        limit,
        offset,
        searchText,
        sortField,
        sortDirection,
        status,
        location,
        scheduleId
      )
      .subscribe(
        (response: any) => {
          // Success callback
          if (response.status === 200) {
            if (response.body) {
              if (response.body.AUDITS && response.body.AUDITS.length > 0) {
                // Assign the data to the data source for the table to render
                this.auditsDataSource = new MatTableDataSource(
                  response.body.AUDITS
                );
                this.auditsLength = response.body.AUDITS_COUNT;
              } else {
                this.auditsDataSource = new MatTableDataSource();
                this.auditsLength = 0;
              }
            }
            this.isShowAuditsProgressBar = false;
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.isShowAuditsProgressBar = false;
            this.router.navigate(['dashboard']);
          } else {
            this.isShowAuditsProgressBar = false;
            this.showErrorDialog(response.error);
          }
        },
        (error) => {
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.isShowAuditsProgressBar = false;
            this.router.navigate(['dashboard']);
          } else {
            this.isShowAuditsProgressBar = false;
            if (error.error && error.error.length > 0) {
              this.showErrorDialog(error.error);
            } else {
              this.showErrorDialog(error.message);
            }
          }
        }
      );
  }

  refreshAudits() {
    this.auditSelection.clear();
    if (this.auditStatus == 'OPEN') {
      this.tableColumns = [
        'select',
        'AUDIT_ID',
        'LOCATION',
        'SCHEDULE',
        'DESCRIPTION',
        'UPDATED_BY_NAME',
        'UPDATED_AT',
        'Equipment_Status',
        'NOTIFICATION_COUNT',
        'Actions',
      ];
    } else {
      this.tableColumns = [
        'AUDIT_ID',
        'LOCATION',
        'SCHEDULE',
        'DESCRIPTION',
        'UPDATED_BY_NAME',
        'UPDATED_AT',
        'Equipment_Status',
        'NOTIFICATION_COUNT',
        'Actions',
      ];
    }
    this.auditService.filterDetails = null;
    this.auditStatus = 'OPEN';
    this.paginator.pageIndex = 0;
    this.paginatorLength = this.constants.INITIAL_PAGINATOR_LIMIT;
    this.getSubAudits(this.auditId, this.paginatorLength, 0);
  }

  sortfields(e: any) {
    let offset = this.paginator._pageIndex * this.paginator._pageSize;
    this.paginator.pageIndex = this.paginator._pageIndex;
    this.getSubAudits(this.auditId, this.paginatorLength, offset, '', e.active, e.direction, this.auditStatus, '', '');
  }

  handlePaginator(e: any) {
    this.paginatorLength = e.pageSize;
    let limit = e.pageSize;
    let offset = e.pageIndex * limit;
    this.paginator.pageIndex = e.pageIndex;
    let status = '';
    if (
      (this.auditService.subAuditsFilterDetails?.status &&
        this.auditService.subAuditsFilterDetails?.status != '' &&
        this.auditService.subAuditsFilterDetails?.status != undefined,
        this.auditService.subAuditsFilterDetails?.status != null)
    ) {
      status = this.auditService.subAuditsFilterDetails?.status;
      this.auditStatus = status;
    } else {
      this.auditStatus = 'OPEN';
    }

    let location = '';
    if (
      (this.auditService.subAuditsFilterDetails?.location &&
        this.auditService.subAuditsFilterDetails?.location != '' &&
        this.auditService.subAuditsFilterDetails?.location != undefined,
        this.auditService.subAuditsFilterDetails?.location != null)
    ) {
      location = this.auditService.subAuditsFilterDetails?.location;
    }

    this.getSubAudits(
      this.auditId,
      limit,
      offset,
      '',
      this.sort?.active ? this.sort.active : '',
      this.sort?.active ? this.sort._direction : '',
      status,
      location,
      '');
  }

  filterSubAudits(type: string) {
    this.auditSelection.clear();
    this.auditService.filterDetails = null;
    this.paginator.pageIndex = 0;
    this.paginatorLength = this.constants.INITIAL_PAGINATOR_LIMIT;
    this.getSubAudits(this.auditId, this.paginatorLength, 0, '', '', '', type, '', '');
  }

  auditsMasterToggle() {
    if (this.isAuditsAllSelected()) {
      this.deleteOnlyAuditsDatasourceItems();
    } else {
      this.auditsCheckAndSelect();
    }
  }

  auditsCheckAndSelect() {
    this.auditsDataSource.data.forEach((row) => {
      if (
        this.auditSelection.selected.some(
          (item) => item.AUDIT_ID === row.AUDIT_ID
        )
      ) {
      } else {
        this.auditSelection.select(row);
      }
    });
  }

  deleteOnlyAuditsDatasourceItems() {
    if (
      this.auditSelection.selected &&
      this.auditSelection.selected.length > 0
    ) {
      let temp = this.auditSelection.selected;
      this.auditSelection.clear();

      temp.forEach((element) => {
        let tempIndex = this.auditsDataSource.data.findIndex(
          (row) => row.AUDIT_ID == element.AUDIT_ID
        );
        if (tempIndex == -1) {
          this.auditSelection.select(element);
        }
      });
    }
  }

  isAuditsAllSelected() {
    if (
      this.auditSelection.selected &&
      this.auditSelection.selected.length > 0
    ) {
      const results = this.auditsDataSource.data.filter(
        ({ AUDIT_ID: id1 }) =>
          !this.auditSelection.selected.some(
            ({ AUDIT_ID: id2 }) => id2 === id1
          )
      );
      if (results && results.length > 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  toggeleAuditsSelection(ev: any, row: any) {
    if (ev.checked) {
      let ar = [
        ...new Map(
          this.auditSelection.selected.map((item) => [
            item.AUDIT_ID,
            item,
          ])
        ).values(),
      ];

      if (ar?.length > 0) {
        for (let i = 0; i < ar.length; i++) {
          this.auditSelection.select(row);
        }
      } else {
        this.auditSelection.select(row);
      }
    } else {
      let temp = this.auditSelection.selected;
      if (temp && temp.length > 0) {
        temp.splice(
          temp.findIndex((a) => a.AUDIT_ID === row.AUDIT_ID),
          1
        );
        this.auditSelection.clear();
        for (let t = 0; t < temp?.length; t++) {
          this.auditSelection.select(temp[t]);
        }
      }
    }
  }

  selectedAudit(row: any): boolean {
    if (
      this.auditSelection?.selected &&
      this.auditSelection.selected.length > 0
    ) {
      let temp = false;
      for (let i = 0; i < this.auditSelection.selected.length; i++) {
        if (
          this.auditSelection.selected[i].AUDIT_ID == row.AUDIT_ID
        ) {
          temp = true;
        }
      }
      return temp;
    } else {
      return false;
    }
  }

  deleteAuditsConfirmation() {
    const dialogRef = this.dialog.open(WarningComponent, {
      width: '400px',
      data: { errorMsg: 'Are you sure you want to delete the selected audits?' },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result.data) {
        await this.deleteAudits();
      }
    });
  }

  async deleteAudits() {
    let body: any = { AUDIT_IDS: [] };

    if (
      this.auditSelection?.selected &&
      this.auditSelection.selected.length > 0
    ) {
      for (let i = 0; i < this.auditSelection.selected.length; i++) {
        body.AUDIT_IDS.push(this.auditSelection.selected[i].AUDIT_ID);
      }
    }

    await this.auditService
      .deleteAudits(body)
      .toPromise()
      .then(
        (response: any) => {
          if (response.status === 204) {
            this.refreshAudits();
          } else {
            this.showErrorDialog(response?.message);
          }
        },
        (error) => {
          this.showErrorDialog(error?.message);
        }
      );
  }

  showErrorDialog(msg: string) {
    const dialogRef = this.dialog.open(ErrorAlertComponent, {
      width: '400px',
      data: {
        errorMsg: msg,
      },
    });
  }

  scoreCalculation(score: any) {
    let tempScore = 0;
    if (score && score != null) {
      if (score?.AUDIT_SCORE && score?.TOTAL_SCORE) {
        let temp = (tempScore =
          (score?.AUDIT_SCORE / score?.TOTAL_SCORE) * 100);
        tempScore = Number(temp.toFixed(2));
      }
    }
    return tempScore;
  }

  showAuditEquipments(item: any) {
    this.dialog.open(AuditEquipmentsComponent, {
      width: '650px',
      height: '500px',
      data: { item: item },
      panelClass: 'add-customer-dialog',
    });
  }

  tableColumnsTest() {
    if (this.isAirlineCusromer) {
      if (
        this.auditService.filterDetails &&
        this.auditService.filterDetails != null &&
        this.auditService.filterDetails != undefined
      ) {
        if (
          this.auditService?.filterDetails?.status &&
          this.auditService?.filterDetails?.status?.length > 0 &&
          this.auditService?.filterDetails?.status == 'CLOSED'
        ) {
          if (this.auditStatus == 'OPEN') {
            this.tableColumns = [
              'select',
              'AUDIT_ID',
              'LOCATION',
              'SCHEDULE',
              'DESCRIPTION',
              'UPDATED_BY_NAME',
              'UPDATED_AT',
              'Equipment_Status',
              'SCORE',
              'NOTIFICATION_COUNT',
              'Actions',
            ];
          } else {
            this.tableColumns = [
              'AUDIT_ID',
              'LOCATION',
              'SCHEDULE',
              'DESCRIPTION',
              'UPDATED_BY_NAME',
              'UPDATED_AT',
              'Equipment_Status',
              'SCORE',
              'NOTIFICATION_COUNT',
              'Actions',
            ];
          }

        } else {
          if (this.auditStatus == 'OPEN') {
            this.tableColumns = [
              'select',
              'AUDIT_ID',
              'LOCATION',
              'SCHEDULE',
              'DESCRIPTION',
              'UPDATED_BY_NAME',
              'UPDATED_AT',
              'Equipment_Status',
              'NOTIFICATION_COUNT',
              'Actions',
            ];
          }
          else {
            this.tableColumns = [
              'AUDIT_ID',
              'LOCATION',
              'SCHEDULE',
              'DESCRIPTION',
              'UPDATED_BY_NAME',
              'UPDATED_AT',
              'Equipment_Status',
              'NOTIFICATION_COUNT',
              'Actions',
            ];
          }

        }
      }
    } else {
      if (this.auditStatus == 'OPEN') {
        this.tableColumns = [
          'select',
          'AUDIT_ID',
          'LOCATION',
          'SCHEDULE',
          'DESCRIPTION',
          'UPDATED_BY_NAME',
          'UPDATED_AT',
          'Equipment_Status',
          'NOTIFICATION_COUNT',
          'Actions',
        ];
      } else {
        this.tableColumns = [
          'AUDIT_ID',
          'LOCATION',
          'SCHEDULE',
          'DESCRIPTION',
          'UPDATED_BY_NAME',
          'UPDATED_AT',
          'Equipment_Status',
          'NOTIFICATION_COUNT',
          'Actions',
        ];
      }
    }
    return this.tableColumns;
  }

  gotoNotifications(item: any) {
    this.auditService.subAuditsToNotificationStateDetails = {
      auditId: item.AUDIT_ID,
      pageName: 'sub-audits',
      pageIndex: this.paginator._pageIndex ? this.paginator._pageIndex : '',
      offset: this.paginator?._pageIndex * this.paginator?._pageSize,
      limit: this.paginatorLength,
      filterDetails: this.auditService.filterDetails
    };
    this.router.navigate(['/notifications']);
  }

  goBackToAudits() {
    this.auditService.subAuditsToNotificationStateDetails = null;
    this.router.navigate(['/audits'])
  }

  filterAudits() {
    this.auditSelection.clear();
    let tempFilterDetails;
    if (
      this.auditService.subAuditsFilterDetails &&
      this.auditService.subAuditsFilterDetails != null &&
      this.auditService.subAuditsFilterDetails != undefined
    ) {
      tempFilterDetails = this.auditService.subAuditsFilterDetails;
    }
    let dialogRef = this.dialog.open(AuditFilterComponent, {
      width: '300px',
      data: { item: tempFilterDetails },
      hasBackdrop: true,
      panelClass: 'service-request-filter',
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.auditsDataSource = new MatTableDataSource();
        this.paginator.pageIndex = 0;
        this.auditService.subAuditsFilterDetails = result.data;

        let status = '';
        if (
          (result?.data?.status &&
            result?.data?.status != '' &&
            result?.data?.status != undefined,
            result?.data?.status != null)
        ) {
          status = result?.data?.status;
          this.auditStatus = status;
        } else {
          this.auditStatus = 'OPEN';
        }

        let location = '';
        if (
          (result?.data?.location &&
            result?.data?.location != '' &&
            result?.data?.location != undefined,
            result?.data?.location != null)
        ) {
          location = result?.data?.location;
        }
        this.getSubAudits(this.auditId, this.paginatorLength, 0, '', '', '', status, location, '' );
      }
    })
  }

  switchCompany() {
    let userTenants: any = localStorage.getItem('userTenants');
    if (userTenants && userTenants != null) {
      userTenants = JSON.parse(userTenants);
      const dialogRef = this.dialog.open(CustomerSelectComponent, {
        panelClass: 'add-customer-dialog',
        width: '550px',
        height: '380px',
        disableClose: false,
        data: { mode: 0, item: userTenants },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
        this.refreshAudits();
        }
      });
    }
  }

  returnDate(date: any) {
    return this.utilService.dateTimeConversion(date);
  }
}
