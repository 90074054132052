import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { LocationService } from 'src/app/services/location.service';
import { PartService } from 'src/app/services/part.service';
import { ActionType, AppConstants } from 'src/app/shared/AppConstants';
import { ErrorAlertComponent } from '../error alert/error-alert.component';

export interface DialogData {
  mode: number;
  item: any;
}

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss'],
})
export class InventoryComponent implements OnInit {
  public constants: AppConstants;
  public title: string;
  public formGroup: FormGroup = new FormGroup({});
  public errorMsg: string;
  public isShowProgressBar: boolean = false;
  public inventoryLocations: any[] = [];
  public isAdd: boolean = true;
  public minValue: number = 0;

  constructor(
    public partService: PartService,
    public locationService: LocationService,
    public authService: AuthService,
    public router: Router,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<InventoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.constants = new AppConstants();
    this.errorMsg = '';
    this.title =
      this.data.mode == ActionType.Add ? 'Add Inventory' : 'Remove Inventory';
    this.isAdd = this.data.mode == ActionType.Add ? true : false;

    if (this.data.mode == ActionType.Add) {
      if (this.data?.item?.TOTAL < 0) {
        this.minValue = Math.abs(this.data?.item?.TOTAL);
        this.formGroup = new FormGroup({
          locationId: new FormControl('', [Validators.required]),
          quantity: new FormControl(this.minValue, [
            Validators.required,
            Validators.min(this.minValue + 1),
          ]),
          price: new FormControl('', [Validators.required]),
          comment: new FormControl(''),
        });
        this.formGroup.controls.quantity.markAsTouched({onlySelf: true})
      } else {
        this.formGroup = new FormGroup({
          locationId: new FormControl('', [Validators.required]),
          quantity: new FormControl('', [Validators.required]),
          price: new FormControl('', [Validators.required]),
          comment: new FormControl(''),
        });
      }
    } else {
      this.formGroup = new FormGroup({
        quantity: new FormControl('', [Validators.required]),
        comment: new FormControl('', [Validators.required]),
      });
    }
  }

  closeDialog(): void {
    this.dialogRef.close(false);
  }

  ngOnInit(): void {
    if (this.data.mode == ActionType.Add) {
      this.getInventoryLocations();
    }
    this.formGroup.valueChanges.subscribe((x) => {
      this.errorMsg = '';
    });
  }

  getInventoryLocations() {
    this.isShowProgressBar = true;
    this.inventoryLocations = [];
    this.partService.getInventoryLocations().subscribe(
      (response: any) => {
        // Success callback
        this.isShowProgressBar = false;
        if (response.status === 200) {
          if (response.body) {
            // Assign the data
            this.inventoryLocations = response.body;
            if (this.inventoryLocations.length > 0) {
              this.formGroup.controls.locationId.setValue(
                this.inventoryLocations[0].LOCATION_ID
              );
            }
          }
        } else if (response.status === 401) {
          // Redirect to login for unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        } else if (response.status === 403) {
          this.showErrorAlertDialog(response.message);
        } else {
          this.showErrorAlertDialog(response.message);
        }
      },
      (error) => {
        this.isShowProgressBar = false;
        if (error.status === 401) {
          // Redirect to login for unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        } else if (error.status === 403) {
          this.showErrorAlertDialog(error.error);
        } else {
          this.showErrorAlertDialog(error.error);
        }
      }
    );
  }

  showErrorAlertDialog(msg: string) {
    const dialogRef = this.dialog.open(ErrorAlertComponent, {
      width: '400px',
      data: {
        errorMsg: msg,
      },
    });
  }

  save() {
    let body = {};
    if (this.data.mode == ActionType.Add) {
      body = {
        PART_ID: this.data?.item?.PART_ID,
        LOCATION_ID: this.formGroup.controls.locationId.value,
        QUANTITY: this.formGroup.controls.quantity.value,
        PRICE: this.formGroup.controls.price.value,
        IN_OUT: 'IN',
        COMMENT: this.formGroup.controls.comment.value,
      };
    } else {
      body = {
        PART_ID: this.data?.item?.PART_ID,
        QUANTITY: this.formGroup.controls.quantity.value,
        IN_OUT: 'OUT',
        COMMENT: this.formGroup.controls.comment.value,
      };
    }

    this.isShowProgressBar = true;
    this.partService.updateInventory(body).subscribe(
      (response: any) => {
        // Success callback
        this.isShowProgressBar = false;
        if (response.status === 204) {
          this.dialogRef.close(true);
        } else if (response.status === 401) {
          // Redirect to login for unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        } else {
          this.errorMsg = response.message;
        }
      },
      (error) => {
        this.isShowProgressBar = false;
        if (error.status === 401) {
          // Redirect to login for unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        } else {
          this.errorMsg = error.error;
        }
      }
    );
  }
}
