import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LopaService } from 'src/app/services/lopa.service';

import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';

import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { MatStepper } from '@angular/material/stepper';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { AlertComponent } from '../alert/alert.component';
import { ErrorAlertComponent } from '../error alert/error-alert.component';
import { WarningComponent } from '../warning/warning.component';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

interface FleetNode {
  fleet: string;
  subfleets?: FleetNode[];
}

interface FleetFlatNode {
  expandable: boolean;
  fleet: string;
  level: number;
}

@Component({
  selector: 'app-upload-lopa-file',
  templateUrl: './upload-lopa-file.component.html',
  styleUrls: ['./upload-lopa-file.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class UploadLopaFileComponent implements OnInit {
  public file: File[] = [];
  public isShowProgressBar: boolean = false;
  public base64Data: string = '';
  public errorMsg: string = '';
  public fleetsList: any[] = [];
  public isFleetsShow: boolean = false;
  public isEditable: boolean;
  public selectedFleetsList: any;
  public submitProgressBar: boolean = false;

  private _transformer = (node: FleetNode, level: number) => {
    return {
      expandable: !!node.subfleets && node.subfleets.length > 0,
      fleet: node.fleet,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<FleetFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.subfleets
  );

  @ViewChild('stepper')
  public myStepper!: MatStepper;

  hasChild = (_: number, node: FleetFlatNode) => node.expandable;
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  getLevel = (node: FleetFlatNode) => node.level;
  checklistSelection = new SelectionModel<FleetFlatNode>(true);

  constructor(
    public lopaService: LopaService,
    public lopaUploadDialogRef: MatDialogRef<UploadLopaFileComponent>,
    public dialog: MatDialog,
    public router: Router,
    public authService: AuthService
  ) {
    this.isEditable = true;
  }

  ngOnInit(): void {}

  todoLeafItemSelectionToggle(node: FleetFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  checkAllParentsSelection(node: FleetFlatNode): void {
    let parent: FleetFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  todoItemSelectionToggle(node: FleetFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach((child) => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  descendantsPartiallySelected(node: FleetFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) =>
      this.checklistSelection.isSelected(child)
    );
    return result && !this.descendantsAllSelected(node);
  }

  descendantsAllSelected(node: FleetFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      });
    return descAllSelected;
  }

  checkRootNodeSelection(node: FleetFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  getParentNode(node: FleetFlatNode): FleetFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  closeDialog(): void {
    if (this.myStepper?.selectedIndex != 0 || this.file.length > 0) {
      const dialogRef = this.dialog.open(WarningComponent, {
        width: '400px',
        data: { errorMsg: 'Do you want to discard changes?' },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result.data) {
          this.lopaUploadDialogRef.close(false);
        }
      });
    } else {
      this.lopaUploadDialogRef.close(false);
    }
  }

  onSelect(event: any) {
    this.errorMsg = '';
    this.file = [];
    this.fleetsList = [];
    this.file.push(...event.addedFiles);
  }

  onRemove(event: any) {
    this.errorMsg = '';
    this.fleetsList = [];
    this.file.splice(this.file.indexOf(event), 1);
  }

  uploadFile() {
    this.errorMsg = '';
    this.isFleetsShow = false;
    this.isShowProgressBar = true;
    this.base64Data = '';
    this.fleetsList = [];
    this.checklistSelection.clear();
    this.fileToBase64(this.file[0])
      .then(async (base64) => {
        this.base64Data = base64;
        let body: any = {
          LOPA: this.base64Data,
        };

        await this.lopaService
          .uploadFile(body)
          .toPromise()
          .then(
            (response: any) => {
              this.isShowProgressBar = false;
              if (response.status === 200) {
                this.fleetsList = response.body;
                this.dataSource.data = response.body;
                if (response.body.length > 0) {
                  this.isFleetsShow = true;
                  this.myStepper.next();
                } else {
                  this.errorMsg =
                    'No fleets found, Please check the sheet and try again.';
                }
              } else if (response.status === 204) {
                this.isFleetsShow = true;
              } else {
                this.errorMsg = response.message;
              }
            },
            (error) => {
              this.isShowProgressBar = false;
              this.errorMsg = error.message;
            }
          );
      })
      .catch((err) => {
        this.errorMsg = err.message;
        this.isShowProgressBar = false;
      });
  }

  private fileToBase64(file: any): Promise<any> {
    const reader = new FileReader();
    const future = new Promise((resolve, reject) => {
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result);
        },
        false
      );
      reader.addEventListener(
        'error',
        function (event) {
          reject(event);
        },
        false
      );

      reader.readAsDataURL(file);
    });
    return future;
  }

  goBackToFileUpload() {
    this.myStepper.previous();
    this.isFleetsShow = false;
    this.isShowProgressBar = false;
  }

  goNext() {
    this.myStepper.next();
    let parentFleets = [];
    let childFleets = [];
    this.selectedFleetsList = [];
    let finalFleetList = this.checklistSelection.selected.filter(
      (v, i, a) => a.findIndex((t) => t.fleet === v.fleet) === i
    );
    if (finalFleetList && finalFleetList.length > 0) {
      for (let p = 0; p < finalFleetList.length; p++) {
        if (finalFleetList[p].expandable) {
          parentFleets.push(finalFleetList[p]);
        } else {
          childFleets.push(finalFleetList[p]);
        }
      }
    }

    if (childFleets?.length > 0) {
      for (let i = 0; i < childFleets.length; i++) {
        let parent: any = this.getParentNode(childFleets[i]);
        let obj: any = {};
        if (parent && parent != null) {
          obj = {
            fleet: parent.fleet,
            subfleets: [{ fleet: childFleets[i].fleet }],
          };
        } else {
          obj = {
            fleet: childFleets[i].fleet,
            subfleets: [],
          };
        }

        const found = this.selectedFleetsList.some(
          (item: { fleet: string }) => item.fleet === obj?.fleet
        );

        if (found) {
          const index = this.selectedFleetsList.findIndex(
            (item: { fleet: string }) => item.fleet === obj?.fleet
          );
          this.selectedFleetsList[index]?.subfleets.push({
            fleet: childFleets[i]?.fleet,
          });
        } else {
          this.selectedFleetsList.push(obj);
        }
      }
    }
  }

  goBackToFleetSelect() {
    this.submitProgressBar = false;
    this.myStepper.previous();
  }

  async uploadToServer() {
    this.submitProgressBar = true;
    let obj: any = {};
    for (let s = 0; s < this.selectedFleetsList.length; s++) {
      if (
        this.selectedFleetsList[s].subfleets.length &&
        this.selectedFleetsList[s].subfleets.length > 0
      ) {
        obj[`${this.selectedFleetsList[s].fleet}`] = [];
        for (
          let sf = 0;
          sf < this.selectedFleetsList[s].subfleets.length;
          sf++
        ) {
          obj[`${this.selectedFleetsList[s].fleet}`].push(
            this.selectedFleetsList[s].subfleets[sf].fleet
          );
        }
      } else {
        obj[`${this.selectedFleetsList[s].fleet}`] = [];
      }
    }

    let body: any = {
      LOPA: this.base64Data,
      FLEETS: obj,
    };

    await this.lopaService
      .uploadFile(body)
      .toPromise()
      .then(
        (response: any) => {
          this.submitProgressBar = false;
          if (response.status === 200) {
            this.lopaUploadDialogRef.close(true);
          } else if (response.status === 204) {
            this.lopaUploadDialogRef.close(true);
          } else {
            this.showErrorDialog(response.message);
          }
        },
        (error) => {
          this.submitProgressBar = false;
          this.showErrorDialog(error.error);
        }
      );
  }

  showErrorDialog(msg: string) {
    const dialogRef = this.dialog.open(ErrorAlertComponent, {
      width: '400px',
      data: {
        errorMsg: msg,
      },
    });
  }

  downloadSampleDoc() {
    this.isShowProgressBar = true;
    this.lopaService.downloadSampleDoc().subscribe(
      (response: any) => {
        this.isShowProgressBar = false;
        // Success callback
        if (response.status === 200) {
          if (response.body) {
            if (
              response?.body?.DOCUMENT_BLOB &&
              response?.body?.DOCUMENT_BLOB.length > 0
            ) {
              var b64Pdf = response?.body?.DOCUMENT_BLOB;
              const byteArray = new Uint8Array(
                atob(b64Pdf)
                  .split('')
                  .map((char) => char.charCodeAt(0))
              );
              var file = new Blob([byteArray], {
                type: response?.body?.CONTENT_TYPE,
              });
              var fileURL = URL.createObjectURL(file);
              var link = document.createElement('a');
              link.href = fileURL;
              link.download = `${response.body.DOCUMENT_NAME}.xlsx`;
              link.click();
            } else {
              this.showErrorDialog('Not a valid file.');
            }
          }
        } else if (response.status === 401) {
          // Redirect to login for unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId');
          localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        } else if (response.status === 403) {
          this.showErrorDialog(response.error);
        } else {
          this.showErrorDialog(response.error);
        }
      },
        (error) => {
          this.isShowProgressBar = false;
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.showErrorDialog(error.error);
          } else {
            this.showErrorDialog(error.message);
          }
        }
    );
  }
}
