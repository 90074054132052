import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { LopaService } from 'src/app/services/lopa.service';
import { AppConstants } from 'src/app/shared/AppConstants';
import { ErrorAlertComponent } from '../error alert/error-alert.component';

export interface DialogData {
  requestId: any;
  item: any;
}

@Component({
  selector: 'app-lopa-message',
  templateUrl: './lopa-message.component.html',
  styleUrls: ['./lopa-message.component.scss'],
})
export class LopaMessageComponent implements OnInit {
  public isShowProgressBar: boolean = false;
  public messages: any[] = [];
  public constants: AppConstants;
  public message: string = '';

  constructor(
    public lopaService: LopaService,
    public authService: AuthService,
    public router: Router,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<LopaMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.constants = new AppConstants();
  }

  ngOnInit(): void {
    this.dialogRef.updatePosition({
      top: `130px`,
      right: `300px`,
    });

    if (
      this.data?.requestId !== 'All' &&
      this.data?.requestId !== 'All parts are available'
    ) {
      this.getMessages(this.data?.requestId);
    }
  }

  getMessages(id: any) {
    this.isShowProgressBar = true;
    this.messages = [];
    this.lopaService.getServiceRequestMessages(id).subscribe(
      (response: any) => {
        // Success callback
        if (response.status === 200) {
          if (response?.body) {
            this.messages = response?.body;
          }
          this.isShowProgressBar = false;
        } else if (response.status === 401) {
          // Redirect to login for unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        } else if (response.status === 403) {
          this.isShowProgressBar = false;
          this.showErrorDialog(response.error);
        } else {
          this.isShowProgressBar = false;
          this.showErrorDialog(response.message);
        }
      },
      (error) => {
        if (error.status === 401) {
          // Redirect to login for unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        } else if (error.status === 403) {
          this.isShowProgressBar = false;
          this.showErrorDialog(error.error);
        } else {
          this.isShowProgressBar = false;
          this.showErrorDialog(error.error);
        }
      }
    );
  }

  showErrorDialog(msg: string) {
    const dialogRef = this.dialog.open(ErrorAlertComponent, {
      width: '400px',
      data: {
        errorMsg: msg,
      },
    });
  }

  saveMessage() {
    this.isShowProgressBar = true;
    this.lopaService
      .saveLopaMessage(
        this.data?.item.fleet,
        this.data?.item.subfleet,
        this.data?.item.tailno,
        this.data?.item.location,
        this.data?.item.requestid,
        this.data?.item.requesttype,
        this.message
      )
      .subscribe(
        (response: any) => {
          // Success callback
          if (response.status === 204) {
            this.message = '';
            this.getMessages(this.data?.requestId);
            this.isShowProgressBar = false;
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.isShowProgressBar = false;
            this.showErrorDialog(response.error);
          } else {
            this.isShowProgressBar = false;
            this.showErrorDialog(response.message);
          }
        },
        (error) => {
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.isShowProgressBar = false;
            this.showErrorDialog(error.error);
          } else {
            this.isShowProgressBar = false;
            this.showErrorDialog(error.error);
          }
        }
      );
  }
}
