import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { AuditScheduleDetailsComponent } from 'src/app/components/audit-schedule-details/audit-schedule-details.component';
import { CustomerSelectComponent } from 'src/app/components/customer-select/customer-select.component';
import { ErrorAlertComponent } from 'src/app/components/error alert/error-alert.component';
import { AuditScheduleService } from 'src/app/services/audit-schedule.service';
import { AuthService } from 'src/app/services/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { ActionType, AppConstants } from 'src/app/shared/AppConstants';

@Component({
  selector: 'app-audit-schedule',
  templateUrl: './audit-schedule.component.html',
  styleUrls: ['./audit-schedule.component.scss'],
})
export class AuditScheduleComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: any;
  @ViewChild(MatSort, { static: true }) sort!: any;

  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result: { matches: any }) => result.matches));

  public tableColumns: string[] = [
    'AUDIT_SCH_ID',
    'LOCATION',
    'SERVICE_TYPE',
    'DESCRIPTION',
    'SCHEDULE',
    // 'NO_OF_EQUIPMENTS',
    'NO_OF_EQUIPMENTS_CATEGORIES',
    'UPDATED_BY_NAME',
    'UPDATED_AT',
    'Actions',
  ];
  public isAirlineCusromer: boolean = false;
  public auditSchedulesDataSource: MatTableDataSource<any>;
  public isShowAuditSchedulesProgressBar: boolean;
  public auditSchedulesLength: number;
  public paginatorLength: number;
  public constants: AppConstants;
  public subscriptions: Subscription[] = [];
  public loggedInCustomerId: any;
  public version: string;
  public release: string;
  public appropriateClass:string = '';
  public events: string[] = [];
  
  @HostListener('window:resize', ['$event'])
  getScreenHeight(event?: any){
    if(window.innerHeight<=700){
      this.appropriateClass = 'bottomRelative';
    }else{
      this.appropriateClass = 'bottomStick';
    }
  }
  constructor(
    public authService: AuthService,
    private breakpointObserver: BreakpointObserver,
    public utilService: UtilService,
    public dialog: MatDialog,
    public router: Router,
    public auditScheduleService: AuditScheduleService
  ) {
    this.getScreenHeight();
    this.constants = new AppConstants();
    this.version = this.constants.APP_RELEASE_NUMBER;
    this.release = this.constants.APP_RELEASE_DATE;
    this.auditSchedulesLength = 0;
    this.paginatorLength = this.constants.INITIAL_PAGINATOR_LIMIT;
    this.isShowAuditSchedulesProgressBar = false;
    this.auditSchedulesDataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    let customerId: any = localStorage.getItem('customerId');
    let temp: any = localStorage.getItem('user-details');
    let user: any = JSON.parse(temp);
    this.isAirlineCusromer = user.CUSTOMER_TYPE == 'AIRLINE' ? true : false;
    if (customerId && customerId != null) {
      customerId == 1
        ? (this.authService.isAFSUser = true)
        : (this.authService.isAFSUser = false);
    }

    if (this.auditScheduleService.auditSchedulePageStateDetails != null) {
      this.getAuditSchedules(
        this.auditScheduleService.auditSchedulePageStateDetails?.limit,
        this.auditScheduleService.auditSchedulePageStateDetails?.offset,
        ''
      );
      this.paginatorLength = this.auditScheduleService
        .auditSchedulePageStateDetails?.limit
        ? this.auditScheduleService.auditSchedulePageStateDetails?.limit
        : this.paginatorLength;
      this.paginator.pageIndex = this.auditScheduleService
        .auditSchedulePageStateDetails?.pageIndex
        ? this.auditScheduleService.auditSchedulePageStateDetails?.pageIndex
        : 0;

      this.auditScheduleService.auditSchedulePageStateDetails = null;
    } else {
      this.getAuditSchedules(this.paginatorLength, 0);
    }
  }

  // Logout from app
  logout() {
    this.authService.logout();
  }

  handlePaginator(e: any) {
    this.paginatorLength = e.pageSize;
    let limit = e.pageSize;
    let offset = e.pageIndex * limit;
    this.paginator.pageIndex = e.pageIndex;
    if (this.sort.active) {
      this.getAuditSchedules(
        limit,
        offset,
        '',
        this.sort.active,
        this.sort._direction
      );
    } else {
      this.getAuditSchedules(limit, offset, '');
    }
  }

  sortfields(e: any) {
    let offset = this.paginator._pageIndex * this.paginator._pageSize;
    this.getAuditSchedules(
      this.paginatorLength,
      offset,
      '',
      e.active,
      e.direction
    );
    this.paginator.pageIndex = this.paginator._pageIndex;
  }

  refreshAuditSchedules() {
    this.auditScheduleService.auditSchedulePageStateDetails = null;
    this.paginator.pageIndex = 0;
    this.paginatorLength = this.constants.INITIAL_PAGINATOR_LIMIT;
    this.getAuditSchedules(this.paginatorLength, 0);
  }

  addNewAuditSchedule() {
    const dialogRef = this.dialog.open(AuditScheduleDetailsComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: { mode: ActionType.Add, item: {} },
      panelClass: 'add-customer-dialog',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.paginator.pageIndex = 0;
        this.getAuditSchedules(this.paginatorLength, 0);
      }
    });
  }

  updateAuditSchedule(item: any, status: string) {
    let msg = status == '' ? 'Resume scheduled services' : 'Pause scheduled services';
    const dialogRef = this.dialog.open(AlertComponent, {
      width: '400px',
      data: {
        errorMsg: `${msg}?`,
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result.data) {
        let body: any = {
          AUDIT_SCH_ID: item.AUDIT_SCH_ID,
          PAUSED: status == '' ? null : status,
        };

        await this.auditScheduleService
          .updateAuditSchedule(body)
          .toPromise()
          .then(
            (response: any) => {
              if (response.status === 204) {
                let offset =
                  this.paginator._pageIndex * this.paginator._pageSize;
                if (this.sort.active) {
                  this.getAuditSchedules(
                    this.paginatorLength,
                    offset,
                    '',
                    this.sort.active,
                    this.sort._direction
                  );
                } else {
                  this.getAuditSchedules(this.paginatorLength, offset);
                }
                this.paginator.pageIndex = this.paginator._pageIndex;
              } else {
                this.showErrorAlertDialog(response.message);
              }
            },
            (error) => {
              this.showErrorAlertDialog(error.error);
            }
          );
      }
    });
  }

  getAuditSchedules(
    limit: number,
    offset: number,
    searchText?: any,
    sortField?: any,
    sortDirection?: any
  ) {
    this.isShowAuditSchedulesProgressBar = true;
    let customerSubscription = this.auditScheduleService
      .getAuditSchedules(limit, offset, searchText, sortField, sortDirection)
      .subscribe(
        (response: any) => {
          // Success callback
          if (response.status === 200) {
            if (response.body) {
              // Assign the data to the data source for the table to render
              this.auditSchedulesDataSource = new MatTableDataSource(
                response.body.AUDIT_SCHEDULES
              );
              this.auditSchedulesLength = response.body.AUDIT_SCHEDULES_COUNT;
            }
            this.isShowAuditSchedulesProgressBar = false;
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.isShowAuditSchedulesProgressBar = false;
            // this.showErrorDialog(response.error);
            this.router.navigate(['dashboard']);
          } else {
            this.isShowAuditSchedulesProgressBar = false;
            this.showErrorAlertDialog(response.message);
          }
        },
        (error) => {
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.isShowAuditSchedulesProgressBar = false;
            // this.showErrorAlertDialog(error.error);
            this.router.navigate(['dashboard']);
          } else if (error.status === 0) {
            this.isShowAuditSchedulesProgressBar = false;
            this.showErrorAlertDialog(error.message);
          } else {
            this.isShowAuditSchedulesProgressBar = false;
            this.showErrorAlertDialog(error.error);
          }
        }
      );
    this.subscriptions.push(customerSubscription);
  }

  // Error dialog when any server erros
  showErrorAlertDialog(msg: string) {
    const dialogRef = this.dialog.open(ErrorAlertComponent, {
      width: '400px',
      data: {
        errorMsg: msg,
      },
    });
  }

  goToAuditsList(item: any) {
    this.auditScheduleService.auditSchedulePageStateDetails = {
      scheduleId: item.AUDIT_SCH_ID,
      pageName: 'audit-schedule',
      pageIndex: this.paginator._pageIndex ? this.paginator._pageIndex : '',
      offset: this.paginator?._pageIndex * this.paginator?._pageSize,
      limit: this.paginatorLength,
    };

    this.router.navigate(['/audits']);
  }

  editAuditSchedule(item: any) {
    const dialogRef = this.dialog.open(AuditScheduleDetailsComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: { mode: ActionType.Edit, item: item },
      panelClass: 'add-customer-dialog',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let offset = this.paginator._pageIndex * this.paginator._pageSize;
        this.paginator.pageIndex = this.paginator._pageIndex;
        if (this.sort.active) {
          this.getAuditSchedules(
            this.paginatorLength,
            offset,
            '',
            this.sort.active,
            this.sort._direction
          );
        } else {
          this.getAuditSchedules(this.paginatorLength, offset, '');
        }
      }
    });
  }

  switchCompany() {
    let userTenants: any = localStorage.getItem('userTenants');
    if (userTenants && userTenants != null) {
      userTenants = JSON.parse(userTenants);
      const dialogRef = this.dialog.open(CustomerSelectComponent, {
        panelClass: 'add-customer-dialog',
        width: '550px',
        height: '380px',
        disableClose: false,
        data: { mode: 0, item: userTenants },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.refreshAuditSchedules();
        }
      });
    }
  }

  returnDate(date: any) {
    return this.utilService.dateTimeConversion(date);
  }
}
