import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-test-email',
  templateUrl: './test-email.component.html',
  styleUrls: ['./test-email.component.scss']
})
export class TestEmailComponent implements OnInit {
  public email = new FormControl('', [Validators.required, Validators.email]);
  public errorMessage: string = '';
  public successMessage: string = '';
  public isShowProgressbar: boolean = false;

  constructor(public userDialogRef: MatDialogRef<TestEmailComponent>, public _UserService: UserService) { }

  ngOnInit(): void {
  }

  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'You must enter email';
    }

    return this.email.hasError('email') ? 'Not a valid email' : '';
  }

  closeDialog(): void {
    this.userDialogRef.close(false);
  }

  async testEmail() {
    this.isShowProgressbar = true;
    this.errorMessage = '';
    this.successMessage = '';
    let body = {
      email: this.email?.value
    };
    await this._UserService
      .testEmail(body)
      .toPromise()
      .then(
        (response: any) => {
          this.isShowProgressbar = false;
          if (response.status === 204) {
            this.errorMessage = '';
            this.successMessage = `A test email has been sent to ${this.email?.value}`;
          } else {
            this.successMessage = '';
            this.errorMessage = response?.error
          }
        },
        (error) => {
          this.isShowProgressbar = false;
          this.successMessage = '';
          this.errorMessage = error?.error;
        }
      );
  }
}
