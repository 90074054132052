import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from '../app.config';
import { AppConstants } from '../shared/AppConstants';

@Injectable({
  providedIn: 'root',
})
export class PartOrderService {
  public constants: AppConstants;
  constructor(
    private appConfigService: AppConfigService,
    private http: HttpClient
  ) {
    this.constants = new AppConstants();
  }

  getPartOrders(
    limit: number,
    offset: number,
    searchText?: any,
    sortField?: any,
    sortDirection?: any
  ) {
    let tempParams: any = {
      limit: limit,
      offset: offset,
    };
    if (searchText) {
      tempParams.search = searchText;
    }
    if (sortField) {
      tempParams.sortingField = sortField;
    }
    if (sortDirection) {
      tempParams.sortingOrder = sortDirection;
    }
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      params: tempParams,
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(url + this.constants.PART_ORDER_URL, httpOptions);
  }

  createOrder(body: any): Observable<any> {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.post<any>(
      url + this.constants.PART_ORDER_URL,
      body,
      httpOptions
    );
  }

  updateOrder(body: any) {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.put<any>(
      url + this.constants.PART_ORDER_URL,
      body,
      httpOptions
    );
  }


  getPartOrderById(id: any) {
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      // params: { client: 'admin' },
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(
      url + this.constants.PART_ORDER_URL + `/${id}`,
      httpOptions
    );
  }

  deletePartOrder(body: any): Observable<any> {
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      body: body,
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.delete<any>(
      url + this.constants.PART_ORDER_URL,
      httpOptions
    );
  }

  receivePartOrderOrder(body: any): Observable<any> {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.post<any>(
      url + this.constants.RECEIVE_PART_ORDER_URL,
      body,
      httpOptions
    );
  }
}
