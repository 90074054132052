import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from './services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(private authservice: AuthService, private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authservice.isLoggedIn.pipe(
      take(1),
      map((isuserLoggedIn: boolean) => {
        if (isuserLoggedIn) {
          const user = this.authservice.checkRoleBasedModuleAccess(
            next.data.role
          );
          if (user) {
            return true;
          } else {
            // navigate to not found page
            this.router.navigate(['/error']);
            return false;
          }
        } else {
          // navigate to not found page
          this.router.navigate(['/login']);
          return false;
        }
      })
    );
  }
}
