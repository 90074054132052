import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'auditEquipmentIconFilterPipe',
})
export class AuditEquipmentIconFilterPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) return null;

    let icon = '';
    switch (value) {
      case 'Movement':
        icon = 'local_shipping';
        break;
      case 'Inactive':
        icon = 'highlight_off';
        break;
      default:
        icon = 'error_outline';
        break;
    }
    return icon;
  }
}

@Pipe({
  name: 'auditEquipmentIconColorFilter',
})
export class AuditEquipmentIconColorFilterPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) return null;

    let color = '';
    switch (value) {
      case 'Movement':
        color = '#00629b';
        break;
      case 'Inactive':
        color = 'rgb(228, 0, 43)';
        break;
      default:
        color = '#ff9800';
        break;
    }
    return color;
  }
}
