<div class="centered">
    <div>
        <img class="logo" src="assets/logo/AFS_PrimaryLogo_FC.png">
    </div>

    <div class="login-txt-div">{{resetPasswordTitle}}</div>

    <div style="color: rgb(58, 168, 24); text-align:center" *ngIf="successMessage && successMessage.length > 0">
        <p>{{successMessage}} </p>
    </div>

    <div style="color: rgb(228, 0, 43); text-align:center" *ngIf="errorMessage && errorMessage.length > 0">
        <p>{{errorMessage}} </p>
    </div>

    <form [formGroup]="resetpasswordform">
        <mat-form-field appearance="legacy" style="margin-top:8px">
            <mat-label>Password</mat-label>
            <input matInput [type]="isPasswordHide ? 'password' : 'text'" formControlName="password" required>
            <mat-icon matSuffix style="font-size: 20px;" (click)="isPasswordHide = !isPasswordHide"
                [ngClass]="isPasswordHide ? 'mdi mdi-eye-off-outline': 'mdi mdi-eye-outline'"></mat-icon>
            <mat-error
                *ngIf="resetpasswordform.get('password')?.hasError('whitespace') && !(resetpasswordform.get('password')?.hasError('required'))">
                Whitespaces are not allowed
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="legacy" style="margin-top:8px">
            <mat-label>Confirm Password</mat-label>
            <input matInput [type]="isConfirmPasswordHide ? 'password' : 'text'" formControlName="confirmpassword"
                required>
            <mat-icon matSuffix style="font-size: 20px;" (click)="isConfirmPasswordHide = !isConfirmPasswordHide"
                [ngClass]="isConfirmPasswordHide ? 'mdi mdi-eye-off-outline': 'mdi mdi-eye-outline'"></mat-icon>

            <mat-error
                *ngIf="resetpasswordform.get('confirmpassword')?.hasError('mustMatch') && !(resetpasswordform.get('confirmpassword')?.hasError('required'))">
                passwords do not match
            </mat-error>
        </mat-form-field>

        <button (click)="resetPassword()" [disabled]="!resetpasswordform.valid" mat-raised-button
            class="login-button">Change
            Password</button>
    </form>
</div>