import { Component, HostListener, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AuthService } from 'src/app/services/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { AppConstants } from 'src/app/shared/AppConstants';
import { DomSanitizer } from '@angular/platform-browser';
import { AppConfigService } from 'src/app/app.config';
import { ChoicesService } from 'src/app/services/choices.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ErrorAlertComponent } from 'src/app/components/error alert/error-alert.component';
import { CustomerSelectComponent } from 'src/app/components/customer-select/customer-select.component';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  public version: string;
  public release: string;
  public constants: AppConstants;
  public userCompanyList: any[] = [];
  public selectedCustomer: any;
  public token: any;
  public url: string = '';
  public superSetUrl: any;
  public dashboardSlug: any;
  public isShowProgressBar: boolean = false;
  public appropriateClass:string = '';
  public events: string[] = [];
  
  @HostListener('window:resize', ['$event'])
  getScreenHeight(event?: any){
    if(window.innerHeight<=700){
      this.appropriateClass = 'bottomRelative';
    }else{
      this.appropriateClass = 'bottomStick';
    }
  }

  constructor(
    public authService: AuthService,
    private breakpointObserver: BreakpointObserver,
    public utilService: UtilService,
    private sanitizer: DomSanitizer,
    private appConfigService: AppConfigService,
    public choiceService: ChoicesService,
    public router: Router,
    public dialog: MatDialog,
    public configService: ConfigService
  ) {
    this.getScreenHeight();
    this.constants = new AppConstants();
    this.version = this.constants.APP_RELEASE_NUMBER;
    this.release = this.constants.APP_RELEASE_DATE;
    this.userCompanyList = [
      {
        CUSTOMER_NAME: 'Airline Fabricare Systems',
        CUSTOMER_ID: 1,
      },
      {
        CUSTOMER_NAME: 'American Airlines',
        CUSTOMER_ID: 2,
      },
      {
        CUSTOMER_NAME: 'Test Customer',
        CUSTOMER_ID: 3,
      },
    ];
  }

  async ngOnInit(): Promise<void> {
    this.dashboardSlug = localStorage.getItem('dashboardSlug');
    if (this.dashboardSlug == null) {
      await this.getCustomization();
    }

    await this.getConfigurations();
    
    this.superSetUrl = this.appConfigService.superSetUrl;
    this.selectedCustomer = localStorage.getItem('customerId');
    this.token = localStorage.getItem('token');

    this.url = `${this.superSetUrl}/login/?apitoken=${this.token}&redirect=${this.dashboardSlug}&standalone=2&expand_filters=0`;

    if (this.selectedCustomer && this.selectedCustomer != null) {
      this.selectedCustomer == 1
        ? (this.authService.isAFSUser = true)
        : (this.authService.isAFSUser = false);
    }
  }

  async getCustomization() {
    this.isShowProgressBar = true;
    await this.choiceService
      .getCustomization()
      .toPromise()
      .then(
        (response: any) => {
          // Success callback
          this.isShowProgressBar = false;
          if (response.status === 200) {
            if (response?.body?.DashboardUrl) {
              localStorage.setItem(
                'dashboardSlug',
                response?.body?.DashboardUrl
              );
            }
            this.dashboardSlug = localStorage.getItem('dashboardSlug');
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.showErrorDialog(response.error);
          } else {
            this.showErrorDialog(response.error);
          }
        },
        (error) => {
          this.isShowProgressBar = false;
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.showErrorDialog(error.error);
          } else {
            this.showErrorDialog(error.message);
          }
        }
      );
  }

  showErrorDialog(msg: string) {
    this.dialog.open(ErrorAlertComponent, {
      width: '400px',
      data: {
        errorMsg: msg,
      },
    });
  }

  logout() {
    this.authService.logout();
  }

  switchCompany() {
    let userTenants: any = localStorage.getItem('userTenants');
    if (userTenants && userTenants != null) {
      userTenants = JSON.parse(userTenants);
      const dialogRef = this.dialog.open(CustomerSelectComponent, {
        panelClass: 'add-customer-dialog',
        width: '550px',
        height: '380px',
        disableClose: false,
        data: { mode: 0, item: userTenants },
      });

      dialogRef.afterClosed().subscribe(async (result) => {
        if (result) {
          this.dashboardSlug = localStorage.getItem('dashboardSlug');
          if (this.dashboardSlug == null) {
            await this.getCustomization();
          }
      
          this.superSetUrl = this.appConfigService.superSetUrl;
          this.selectedCustomer = localStorage.getItem('customerId');
          this.token = localStorage.getItem('token');
      
          this.url = `${this.superSetUrl}/login/?apitoken=${this.token}&redirect=${this.dashboardSlug}&standalone=2&expand_filters=0`;
          console.log('Dashboard URL', this.url);
      
          if (this.selectedCustomer && this.selectedCustomer != null) {
            this.selectedCustomer == 1
              ? (this.authService.isAFSUser = true)
              : (this.authService.isAFSUser = false);
          }
        }
      });
    }
  }

  async getConfigurations() {
    this.isShowProgressBar = true;
    await this.configService
      .getConfigurations()
      .toPromise()
      .then(
        (response: any) => {
          this.isShowProgressBar = false;
          if (response.status === 200) {
            if (response?.body) {
              if (Object.keys(response?.body).length > 0) {
                let obj = response?.body;
                if (obj?.timezone) {
                  localStorage.removeItem('userTimeZone');
                  localStorage.setItem('userTimeZone', obj?.timezone || '');
                }
              }
            }
          }
        }
      );
  }
}
