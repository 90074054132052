import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  item: any;
}

@Component({
  selector: 'app-add-new-choice-category',
  templateUrl: './add-new-choice-category.component.html',
  styleUrls: ['./add-new-choice-category.component.scss'],
})
export class AddNewChoiceCategoryComponent implements OnInit {
  public errorMsg: string = '';
  public formGroup: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AddNewChoiceCategoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.formGroup = new FormGroup({
      name: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.formGroup.valueChanges.subscribe((x) => {
      this.errorMsg = '';
    });
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  add() {
    this.dialogRef.close(this.formGroup.controls.name.value);
  }
}
