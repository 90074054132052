import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeConvertFilter',
})
export class TimeConvertFilterPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) return null;
    if (!args) return value;
    let num = value;
    num = Math.abs(num);
    return this.convertMinutesToHoursMinutes(num);
  }
 
  convertMinutesToHoursMinutes(minutes: number): string {
    if (minutes < 0) return '';
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const rhours = hours < 10 ? `0${hours}` : hours;
    const rminutes = remainingMinutes < 10 ? `0${remainingMinutes}` : remainingMinutes;
    return `${rhours}:${rminutes}`;
  }
}
