import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from '../app.config';
import { AppConstants } from '../shared/AppConstants';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public constants: AppConstants;

  constructor(
    private appConfigService: AppConfigService,
    private http: HttpClient
  ) {
    this.constants = new AppConstants();
  }

  getConfigurations() {
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(
      url + this.constants.CONFIGURATION_URL,
      httpOptions
    );
  }

  saveConfigurations(body: any): Observable<any> {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.post<any>(
      url + this.constants.CONFIGURATION_URL,
      body,
      httpOptions
    );
  }

  getEquipmentCategories() {
    let tempParams: any = {
      limit: 999,
      offset: 0,
    };
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      params: tempParams,
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(
      url + this.constants.GET_EQUIMENT_CATEGORIES_URL,
      httpOptions
    );
  }

  getEquipmentCategoryAttributesByCategoryId(id: any) {
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      params: { CATEGORY_ID: id },
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(
      url + this.constants.GET_EQUIPMENT_CATEGORY_ATTRIBUTES_BY_CATEGORY_ID,
      httpOptions
    );
  }


  getAllEquipmentCategoryAttributes() {
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(
      url + this.constants.GET_EQUIPMENT_CATEGORY_ATTRIBUTES_BY_CATEGORY_ID,
      httpOptions
    );
  }

  saveAttribute(body: any): Observable<any> {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.post<any>(
      url + this.constants.ADD_NEW_ATTRIBUTE_URL,
      body,
      httpOptions
    );
  }

  updateAttribute(body: any): Observable<any> {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.put<any>(
      url + this.constants.ADD_NEW_ATTRIBUTE_URL,
      body,
      httpOptions
    );
  }

  getFYDateRange() {
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(
      url + this.constants.GET_FY_DATE_RANGE_URL,
      httpOptions
    );
  }

  updateSLA(body: any) {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.put<any>(url + '/sla', body, httpOptions);
  }

  deleteAttribute(body: any): Observable<any> {
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      body: body,
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.delete<any>(
      url + this.constants.DELETE_ATTRIBUTE_URL,
      httpOptions
    );
  }

  bomequipmentcategorymap(id: any) {
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      params: { EQUIPMENT_CATEGORY_ID: id },
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(
      url + this.constants.GET_BOM_EQUIPMENT_CATEGORY_MAP_URL,
      httpOptions
    );
  }

  addBomEquipmentCategoryMap(body: any): Observable<any> {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.post<any>(
      url + this.constants.GET_BOM_EQUIPMENT_CATEGORY_MAP_URL,
      body,
      httpOptions
    );
  }
}
