<div>
    <div class="icon">
        <mat-icon class="material-symbols-outlined">mark_email_read</mat-icon>
    </div>
    <div class="title">
        <p>Is this email id: <span style="font-weight: bold;">{{data.item}}</span> correct?</p>
    </div>
    <div mat-dialog-actions align="center" style="padding-top:10px;">
        <button class="mat-raised-button" (click)="dialogRef.close(false)">NO</button>
        <button mat-raised-button class="confirm-button" (click)="dialogRef.close(true)">YES</button>
    </div>
</div>