import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extnJsonParserFilter',
})
export class ExtnJsonParserFilterPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) return null;
    if (value.length && value.length > 0) {
      // let modifiedJson = [];
      // for (let i = 0; i < value.length; i++) {
      //   for (var type in value[i]) {
      //     let item: any = {};
      //     item.key = type;
      //     item.value = value[i][type];
      //     modifiedJson.push(item);
      //   }
      //   return modifiedJson;
      // }
    } else {
      let modifiedJson = [];
      for (var type in value) {
        let item: any = {};
        item.key = type;
        item.value = value[type];
        modifiedJson.push(item);
      }
      return modifiedJson;
    }
  }
}

@Pipe({
  name: 'extnPipe',
})
export class ExtnPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) return null;
    return value;
  }
}
