<div class="mat-elevation-z8 container">
  <mat-progress-bar *ngIf="isShowProgressBar" mode="indeterminate">
  </mat-progress-bar>

  <span *ngIf="messages.length > 0">
    <mat-card style="padding:10px !important;margin-bottom: 7px;border-radius: 5px;box-shadow: none !important;"
      *ngFor="let message of messages">
      <mat-card-content>
        <p>{{message.MESSAGE}}</p>
        <p style="font-size: smaller;text-align: end;color: darkgrey;">{{ message.CREATED_BY }},
          {{ message.CREATED_ON | date: constants.DATE_FORMAT}}</p>
      </mat-card-content>
    </mat-card>
  </span>

  <mat-toolbar
    style="position: sticky;bottom: 0;height:45px;background-color: white;font-size:15px;padding: 0px !important;border-radius: 10px;">
    <div fxLayout="row">
      <div fxFlex="auto" style="margin-left: 10px;margin-top: 5px;">
        <input [(ngModel)]="message" style="margin-left: 5px;" matInput placeholder="Type message here...">
      </div>
      <div fxFlex="1" style="margin-left:30px;">
        <button mat-icon-button matTooltip="Send" style="margin-right: 15px;" (click)="saveMessage()"
          [disabled]="message.length <= 0">
          <mat-icon style="font-size:30px;vertical-align: text-bottom; transform: rotate(90deg);margin-top: 5px;"
            [ngClass]="message.length > 0 ? 'success-color material-icons-outlined' : 'material-icons-outlined'">
            navigation
          </mat-icon>
        </button>
      </div>
    </div>
  </mat-toolbar>
</div>