import { SelectionModel } from '@angular/cdk/collections';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Router } from '@angular/router';
import { AuditScheduleService } from 'src/app/services/audit-schedule.service';
import { AuthService } from 'src/app/services/auth.service';
import { ScheduledServiceRequestService } from 'src/app/services/scheduled-service-request.service';
import { ActionType, AppConstants } from 'src/app/shared/AppConstants';
import { ErrorAlertComponent } from '../error alert/error-alert.component';
import { WarningComponent } from '../warning/warning.component';

export interface DialogData {
  mode: number;
  item: any;
}

interface Location {
  name: string;
  id: number;
  childrens?: Location[];
}

@Component({
  selector: 'app-add-schedule-service-request',
  templateUrl: './add-schedule-service-request.component.html',
  styleUrls: ['./add-schedule-service-request.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    },
  ],
})
export class AddScheduleServiceRequestComponent implements OnInit {
  @ViewChild('picker') picker: any;
  @ViewChild(MatPaginator, { static: false }) paginator: any;
  @ViewChild(MatSort, { static: false }) sort: any;
  public timeout: any = null;
  public isShowProgressBar: boolean = false;
  public title: string = '';
  public errorMsg: string;
  public selectedLocation: string = '';
  public isEditable: boolean;
  public locations: Location[] = [];
  public durationList: any[];
  public paginatorLength: number = 5;
  public constants: AppConstants;
  public locationDataSource = new MatTreeNestedDataSource<Location>();
  public detailsFormGroup: FormGroup = this._formBuilder.group({});
  public equipmentFormGroup: FormGroup = this._formBuilder.group({});
  public treeControl = new NestedTreeControl<Location>(
    (node) => node.childrens
  );
  public equipmentsLength = 0;
  public categoriesLength = 0;
  public partsLength = 0;
  public isShowEquipmentsProgressBar: boolean = false;
  public isShowCategoriesProgressBar: boolean = false;
  public isShowPartsProgressBar: boolean = false;
  public locationFormGroup: FormGroup = this._formBuilder.group({});
  public equipmentColumns: string[] = [
    'select',
    'EQUIPMENT_ID',
    'TAIL_NUMBER',
    'NAME',
    'CATEGORY_NAME',
    'DESCRIPTION',
  ];
  equipmentDataSource = new MatTableDataSource<any>([]);
  equipmentSelection = new SelectionModel<any>(true, []);
  categoriesDataSource = new MatTableDataSource<any>([]);
  categoriesSelection = new SelectionModel<any>(true, []);
  partDataSource = new MatTableDataSource<any>([]);
  partsSelection = new SelectionModel<any>(true, []);
  categoryColumns: string[] = ['select', 'CATEGORY_ID', 'NAME', 'DESCRIPTION'];
  partColumns: string[] = [
    'select',
    'PART_ID',
    'NAME',
    'DESCRIPTION',
    'MANUFACTURER',
  ];
  public minDate: Date = new Date();
  public customDurationValue: number = 0;

  constructor(
    private _formBuilder: FormBuilder,
    public auditScheduleService: AuditScheduleService,
    public servicerequestscheduleService: ScheduledServiceRequestService,
    public router: Router,
    public dialog: MatDialog,
    public authService: AuthService,
    public serviceRequestScheduleDialogRef: MatDialogRef<AddScheduleServiceRequestComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.constants = new AppConstants();
    this.isEditable = true;
    this.errorMsg = '';
    this.minDate.setDate(this.minDate.getDate() + 1);
    this.minDate.setUTCHours(0, 0, 0, 0);
    this.durationList = [
      { name: '15 Days', value: 15 },
      { name: '30 Days', value: 30 },
      { name: '60 Days', value: 60 },
      { name: '90 Days', value: 90 },
      { name: 'Custom', value: 0 }
    ];

    this.title =
      this.data.mode == ActionType.Add
        ? 'Create Timed Service Schedule'
        : 'Update Timed Service Schedule';

    if (this.data.mode == ActionType.Add) {
      this.locationFormGroup = this._formBuilder.group({
        locCtrl: ['', Validators.required],
      });

      this.detailsFormGroup = this._formBuilder.group({
        selectedLocationCtrl: [''],
        descriptionCtrl: ['', Validators.required],
        durationCtrl: [15, Validators.required],
        fireDateTime: [this.minDate, Validators.required],
      });

      // this.detailsFormGroup.controls.fireDateTime.setValue(new Date());

      this.equipmentFormGroup = this._formBuilder.group({
        equipmentCtrl: ['', Validators.required],
        equipmentSearchCtrl: [''],
        categorySearchCtrl: [''],
        partSearchCtrl: [''],
      });
    }
  }

  public hasChild = (_: number, node: Location) =>
    !!node.childrens && node.childrens.length > 0;

  ngOnInit(): void {
    if (this.data.mode == ActionType.Edit) {
      this.locationFormGroup = this._formBuilder.group({
        locCtrl: [this.data?.item?.LOCATION_ID, Validators.required],
      });

      this.detailsFormGroup = this._formBuilder.group({
        selectedLocationCtrl: [''],
        descriptionCtrl: [this.data?.item?.DESCRIPTION, Validators.required],
        durationCtrl: ['', Validators.required],
        fireDateTime: [this.data?.item?.FIRE_DATE_TIME.replace('Z', ''), Validators.required],
      });

      var item = this.durationList.find(item => item.value === this.data?.item?.ETA);
      console.log(item)
      if(item && item != undefined) { 
        this.detailsFormGroup.controls.durationCtrl.setValue(this.data?.item?.ETA);
        this.detailsFormGroup.addControl('customDuration', this._formBuilder.control('', {}));
      } 
      else { 
        this.detailsFormGroup.controls.durationCtrl.setValue(0);
        this.detailsFormGroup.addControl('customDuration', this._formBuilder.control(this.data?.item?.ETA, Validators.required));
      }

      let type = '';
      if (
        this.data?.item?.EQUIPMENTS != null &&
        this.data?.item?.EQUIPMENTS?.length > 0
      ) {
        type = 'equipment';
        this.equipmentDataSource = new MatTableDataSource<any>([]);
        this.equipmentSelection = new SelectionModel<any>(true, []);
        this.getEquipments(
          5,
          0,
          '',
          '',
          '',
          this.locationFormGroup.controls.locCtrl.value
        );
        if (
          this.data?.item?.EQUIPMENTS &&
          this.data?.item?.EQUIPMENTS != null &&
          this.data?.item?.EQUIPMENTS?.length > 0
        ) {
          for (let e = 0; e < this.data.item.EQUIPMENTS.length; e++) {
            this.equipmentSelection.select(this.data.item.EQUIPMENTS[e]);
          }
        }
      } else if (
        this.data?.item?.EQUIPMENT_CATEGORY != null &&
        this.data?.item?.EQUIPMENT_CATEGORY?.length > 0
      ) {
        type = 'categorie';
        this.categoriesDataSource = new MatTableDataSource<any>([]);
        this.categoriesSelection = new SelectionModel<any>(true, []);
        this.getCategories(5, 0, '', '', '');

        if (
          this.data?.item?.EQUIPMENT_CATEGORY &&
          this.data?.item?.EQUIPMENT_CATEGORY != null &&
          this.data?.item?.EQUIPMENT_CATEGORY?.length > 0
        ) {
          for (let c = 0; c < this.data.item.EQUIPMENT_CATEGORY.length; c++) {
            this.categoriesSelection.select({
              CATEGORY_ID: this.data.item.EQUIPMENT_CATEGORY[c],
            });
          }
        }
      } else if (
        this.data?.item?.PARTS != null &&
        this.data?.item?.PARTS?.length > 0
      ) {
        type = 'part';
        this.partDataSource = new MatTableDataSource<any>([]);
        this.partsSelection = new SelectionModel<any>(true, []);
        this.getParts(5, 0, '', '', '');

        if (
          this.data?.item?.PARTS &&
          this.data?.item?.PARTS != null &&
          this.data?.item?.PARTS?.length > 0
        ) {
          for (let c = 0; c < this.data.item.PARTS.length; c++) {
            this.partsSelection.select({
              PART_ID: this.data.item.PARTS[c],
            });
          }
        }
      } else if (
        this.data?.item?.EQUIPMENTS == null &&
        this.data?.item?.EQUIPMENT_CATEGORY == null &&
        this.data?.item?.PARTS == null
      ) {
        type = 'all';
        this.equipmentDataSource = new MatTableDataSource<any>([]);
        this.equipmentSelection = new SelectionModel<any>(true, []);
        this.categoriesDataSource = new MatTableDataSource<any>([]);
        this.categoriesSelection = new SelectionModel<any>(true, []);
        this.partDataSource = new MatTableDataSource<any>([]);
        this.partsSelection = new SelectionModel<any>(true, []);
      }

      this.equipmentFormGroup = this._formBuilder.group({
        equipmentCtrl: [type, Validators.required],
        equipmentSearchCtrl: [''],
        categorySearchCtrl: [''],
        partSearchCtrl: [''],
      });
    }

    this.getLocations();

    this.locationFormGroup.controls.locCtrl.valueChanges.subscribe((value) => {
      if (value) {
        this.data.item.EQUIPMENTS = null;
        this.equipmentDataSource = new MatTableDataSource<any>([]);
        this.equipmentSelection = new SelectionModel<any>(true, []);
        this.data.item.EQUIPMENTS = null;
        this.categoriesDataSource = new MatTableDataSource<any>([]);
        this.categoriesSelection = new SelectionModel<any>(true, []);
        this.data.item.PARTS = null;
        this.partDataSource = new MatTableDataSource<any>([]);
        this.partsSelection = new SelectionModel<any>(true, []);
        this.equipmentFormGroup.controls.equipmentCtrl.setValue('');
      }
    });
  }

  closeDialog(): void {
    if (
      this.locationFormGroup.controls['locCtrl']?.touched ||
      this.detailsFormGroup.controls['descriptionCtrl']?.touched ||
      this.detailsFormGroup.controls['durationCtrl']?.touched ||
      this.equipmentFormGroup.controls['equipmentSearchCtrl']?.touched ||
      this.equipmentFormGroup.controls['equipmentCtrl']?.touched ||
      this.equipmentFormGroup.controls['categorySearchCtrl']?.touched ||
      this.locationFormGroup.controls['fireDateTime']?.touched ||
      this.locationFormGroup.controls['partSearchCtrl']?.touched
    ) {
      const dialogRef = this.dialog.open(WarningComponent, {
        width: '400px',
        data: { errorMsg: 'Do you want to discard changes?' },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result.data) {
          this.serviceRequestScheduleDialogRef.close(false);
        }
      });
    } else {
      this.serviceRequestScheduleDialogRef.close(false);
    }
  }

  locationNext() {
    let fullLocationArray = this.getFullLocation(
      this.locationDataSource.data,
      this.locationFormGroup.controls.locCtrl.value
    );
    if (fullLocationArray && fullLocationArray.length > 0) {
      let loc = '';
      let reversedLocations = fullLocationArray?.reverse();
      for (let l = 0; l < reversedLocations.length; l++) {
        if (l > 0) {
          loc = loc + ', ' + reversedLocations[l].name;
        } else {
          loc = reversedLocations[l].name;
        }
      }
      this.detailsFormGroup.controls.selectedLocationCtrl.setValue(loc);
    }
  }

  getFullLocation(array: any, id: any) {
    if (typeof array !== 'undefined') {
      for (let i = 0; i < array.length; i++) {
        if (array[i].id === id) {
          return [array[i]];
        }
        const a: any = this.getFullLocation(array[i].childrens, id);
        if (a !== null) {
          a.unshift(array[i]);
          return a;
        }
      }
    }
    return null;
  }

  // Get Locations
  getLocations() {
    this.isShowProgressBar = true;
    this.locations = [];
    this.auditScheduleService.getLocations().subscribe(
      (response: any) => {
        // Success callback
        this.isShowProgressBar = false;
        if (response.status === 200) {
          if (response.body) {
            // Assign the data
            this.locations = response.body;
            this.locationDataSource.data = response.body;
          }
        } else if (response.status === 401) {
          // Redirect to login for unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId');
          localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        } else if (response.status === 403) {
          this.showErrorAlertDialog(response.message);
        } else {
          this.showErrorAlertDialog(response.message);
        }
      },
      (error) => {
        this.isShowProgressBar = false;
        if (error.status === 401) {
          // Redirect to login for unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId');
          localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        } else if (error.status === 403) {
          this.showErrorAlertDialog(error.error);
        } else {
          this.showErrorAlertDialog(error.error);
        }
      }
    );
  }

  // Error dialog when any server erros
  showErrorAlertDialog(msg: string) {
    const dialogRef = this.dialog.open(ErrorAlertComponent, {
      width: '400px',
      data: {
        errorMsg: msg,
      },
    });
  }

  equipmentsTypeChange(type: any) {
    this.equipmentDataSource = new MatTableDataSource<any>([]);
    this.equipmentSelection = new SelectionModel<any>(true, []);
    this.categoriesDataSource = new MatTableDataSource<any>([]);
    this.categoriesSelection = new SelectionModel<any>(true, []);
    this.partDataSource = new MatTableDataSource<any>([]);
    this.partsSelection = new SelectionModel<any>(true, []);
    if (type == 'equipment') {
      this.getEquipments(
        5,
        0,
        '',
        '',
        '',
        this.locationFormGroup.controls.locCtrl.value
      );
    } else if (type == 'categorie') {
      this.getCategories(5, 0, '', '', '');
    } else if (type == 'part') {
      this.getParts(
        5,
        0,
        '',
        '',
        '',
        this.locationFormGroup.controls.locCtrl.value
      );
    }
  }

  getEquipments(
    limit: number,
    offset: number,
    searchText?: any,
    sortField?: any,
    sortDirection?: any,
    locationId?: number
  ) {
    this.isShowEquipmentsProgressBar = true;
    this.isShowProgressBar = false;
    this.auditScheduleService
      .getEquipmentsByLocationId(
        limit,
        offset,
        searchText,
        sortField,
        sortDirection,
        locationId
      )
      .subscribe(
        (response: any) => {
          this.isShowEquipmentsProgressBar = false;
          // Success callback
          this.isShowProgressBar = false;
          if (response.status === 200) {
            if (response.body) {
              // Assign the data
              this.equipmentDataSource = new MatTableDataSource<any>(
                response.body.EQUIPMENTS
              );
              this.equipmentsLength = response.body.EQUIPMENTS_COUNT;
            }
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.showErrorAlertDialog(response.message);
          } else {
            this.showErrorAlertDialog(response.message);
          }
        },
        (error) => {
          this.isShowEquipmentsProgressBar = false;
          this.isShowProgressBar = false;
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.showErrorAlertDialog(error.error);
          } else {
            this.showErrorAlertDialog(error.error);
          }
        }
      );
  }

  getCategories(
    limit: number,
    offset: number,
    searchText?: any,
    sortField?: any,
    sortDirection?: any
  ) {
    this.isShowCategoriesProgressBar = true;
    this.isShowProgressBar = false;
    this.auditScheduleService
      .getCategories(limit, offset, searchText, sortField, sortDirection)
      .subscribe(
        (response: any) => {
          this.isShowCategoriesProgressBar = false;
          // Success callback
          this.isShowProgressBar = false;
          if (response.status === 200) {
            if (response.body) {
              // Assign the data
              this.categoriesDataSource = new MatTableDataSource<any>(
                response.body.EQUIPMENT_CATEGORIES
              );
              this.categoriesLength = response.body.EQUIPMENT_CATEGORIES_COUNT;
            }
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.showErrorAlertDialog(response.message);
          } else {
            this.showErrorAlertDialog(response.message);
          }
        },
        (error) => {
          this.isShowCategoriesProgressBar = false;
          this.isShowProgressBar = false;
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.showErrorAlertDialog(error.error);
          } else {
            this.showErrorAlertDialog(error.error);
          }
        }
      );
  }

  searchEquipment(event: any) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if ($this.sort.active) {
        $this.getEquipments(
          $this.paginatorLength,
          0,
          event.target.value,
          $this.sort.active,
          $this.sort._direction,
          $this.locationFormGroup.controls.locCtrl.value
        );
      } else {
        $this.getEquipments(
          $this.paginatorLength,
          0,
          event.target.value,
          '',
          '',
          $this.locationFormGroup.controls.locCtrl.value
        );
      }
      $this.paginator.pageIndex = 0;
    }, 1000);
  }

  clearEquipmentSerach() {
    this.equipmentFormGroup.controls.equipmentSearchCtrl.setValue('');
    this.getEquipments(
      this.paginatorLength,
      0,
      '',
      '',
      '',
      this.locationFormGroup.controls.locCtrl.value
    );
    this.paginator.pageIndex = 0;
  }

  clearCategorySerach() {
    this.equipmentFormGroup.controls.categorySearchCtrl.setValue('');
    this.getCategories(this.paginatorLength, 0, '', '', '');
    this.paginator.pageIndex = 0;
  }

  clearPartSerach() {
    this.equipmentFormGroup.controls.partSearchCtrl.setValue('');
    this.getParts(
      this.paginatorLength,
      0,
      '',
      '',
      '',
      this.locationFormGroup.controls.locCtrl.value
    );
    this.paginator.pageIndex = 0;
  }

  //Paginator page items handle
  handleEquipmentPaginator(e: any) {
    this.paginatorLength = e.pageSize;
    let limit = e.pageSize;
    let offset = e.pageIndex * limit;
    this.paginator.pageIndex = e.pageIndex;
    if (this.sort.active) {
      this.getEquipments(
        limit,
        offset,
        this.equipmentFormGroup.controls.equipmentSearchCtrl.value != null ||
          this.equipmentFormGroup.controls.equipmentSearchCtrl.value != ''
          ? this.equipmentFormGroup.controls.equipmentSearchCtrl.value
          : '',
        this.sort.active,
        this.sort._direction,
        this.locationFormGroup.controls.locCtrl.value
      );
    } else {
      this.getEquipments(
        limit,
        offset,
        this.equipmentFormGroup.controls.equipmentSearchCtrl.value != null ||
          this.equipmentFormGroup.controls.equipmentSearchCtrl.value != ''
          ? this.equipmentFormGroup.controls.equipmentSearchCtrl.value
          : '',
        '',
        '',
        this.locationFormGroup.controls.locCtrl.value
      );
    }
  }

  //Paginator page items handle
  handleCategoriesPaginator(e: any) {
    this.paginatorLength = e.pageSize;
    let limit = e.pageSize;
    let offset = e.pageIndex * limit;
    this.paginator.pageIndex = e.pageIndex;
    if (this.sort.active) {
      this.getCategories(
        limit,
        offset,
        this.equipmentFormGroup.controls.categorySearchCtrl.value != null ||
          this.equipmentFormGroup.controls.categorySearchCtrl.value != ''
          ? this.equipmentFormGroup.controls.categorySearchCtrl.value
          : '',
        this.sort.active,
        this.sort._direction
      );
    } else {
      this.getCategories(
        limit,
        offset,
        this.equipmentFormGroup.controls.categorySearchCtrl.value != null ||
          this.equipmentFormGroup.controls.categorySearchCtrl.value != ''
          ? this.equipmentFormGroup.controls.categorySearchCtrl.value
          : '',
        '',
        ''
      );
    }
  }

  handlePartsPaginator(e: any) {
    this.paginatorLength = e.pageSize;
    let limit = e.pageSize;
    let offset = e.pageIndex * limit;
    this.paginator.pageIndex = e.pageIndex;
    if (this.sort.active) {
      this.getParts(
        limit,
        offset,
        this.equipmentFormGroup.controls.partSearchCtrl.value != null ||
          this.equipmentFormGroup.controls.partSearchCtrl.value != ''
          ? this.equipmentFormGroup.controls.partSearchCtrl.value
          : '',
        this.sort.active,
        this.sort._direction,
        this.locationFormGroup.controls.locCtrl.value
      );
    } else {
      this.getParts(
        limit,
        offset,
        this.equipmentFormGroup.controls.partSearchCtrl.value != null ||
          this.equipmentFormGroup.controls.partSearchCtrl.value != ''
          ? this.equipmentFormGroup.controls.partSearchCtrl.value
          : '',
        '',
        '',
        this.locationFormGroup.controls.locCtrl.value
      );
    }
  }

  sortEquipmentfields(e: any) {
    let offset = this.paginator._pageIndex * this.paginator._pageSize;
    this.getEquipments(
      this.paginatorLength,
      offset,
      this.equipmentFormGroup.controls.equipmentSearchCtrl.value != null ||
        this.equipmentFormGroup.controls.equipmentSearchCtrl.value != ''
        ? this.equipmentFormGroup.controls.equipmentSearchCtrl.value
        : '',
      e.active,
      e.direction,
      this.locationFormGroup.controls.locCtrl.value
    );
    this.paginator.pageIndex = this.paginator._pageIndex;
  }

  sortCategoriesfields(e: any) {
    let offset = this.paginator._pageIndex * this.paginator._pageSize;
    this.getCategories(
      this.paginatorLength,
      offset,
      this.equipmentFormGroup.controls.categorySearchCtrl.value != null ||
        this.equipmentFormGroup.controls.categorySearchCtrl.value != ''
        ? this.equipmentFormGroup.controls.categorySearchCtrl.value
        : '',
      e.active,
      e.direction
    );
    this.paginator.pageIndex = this.paginator._pageIndex;
  }

  sortPartsfields(e: any) {
    let offset = this.paginator._pageIndex * this.paginator._pageSize;
    this.getParts(
      this.paginatorLength,
      offset,
      this.equipmentFormGroup.controls.partSearchCtrl.value != null ||
        this.equipmentFormGroup.controls.partSearchCtrl.value != ''
        ? this.equipmentFormGroup.controls.partSearchCtrl.value
        : '',
      e.active,
      e.direction,
      this.locationFormGroup.controls.locCtrl.value
    );
    this.paginator.pageIndex = this.paginator._pageIndex;
  }

  equipmentsMasterToggle() {
    if (this.isEquipmentsAllSelected()) {
      this.deleteOnlyEquipmentsDatasourceItems();
    } else {
      this.equipmentsCheckAndSelect();
    }
  }

  equipmentsCheckAndSelect() {
    this.equipmentDataSource.data.forEach((row) => {
      if (
        this.equipmentSelection.selected.some(
          (item) => item.EQUIPMENT_ID === row.EQUIPMENT_ID
        )
      ) {
      } else {
        this.equipmentSelection.select(row);
      }
    });
  }

  deleteOnlyEquipmentsDatasourceItems() {
    if (
      this.equipmentSelection.selected &&
      this.equipmentSelection.selected.length > 0
    ) {
      let temp = this.equipmentSelection.selected;
      this.equipmentSelection.clear();

      temp.forEach((element) => {
        let tempIndex = this.equipmentDataSource.data.findIndex(
          (row) => row.EQUIPMENT_ID == element.EQUIPMENT_ID
        );
        if (tempIndex == -1) {
          this.equipmentSelection.select(element);
        }
      });
    }
  }

  isEquipmentsAllSelected() {
    if (
      this.equipmentSelection.selected &&
      this.equipmentSelection.selected.length > 0
    ) {
      const results = this.equipmentDataSource.data.filter(
        ({ EQUIPMENT_ID: id1 }) =>
          !this.equipmentSelection.selected.some(
            ({ EQUIPMENT_ID: id2 }) => id2 === id1
          )
      );
      if (results && results.length > 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  selectedEquipment(row: any): boolean {
    if (
      this.equipmentSelection?.selected &&
      this.equipmentSelection.selected.length > 0
    ) {
      let temp = false;
      for (let i = 0; i < this.equipmentSelection.selected.length; i++) {
        if (
          this.equipmentSelection.selected[i].EQUIPMENT_ID == row.EQUIPMENT_ID
        ) {
          temp = true;
        }
      }
      return temp;
    } else {
      return false;
    }
  }

  categoriesMasterToggle() {
    if (this.isCategoriesAllSelected()) {
      this.deleteOnlyCategoriesDatasourceItems();
    } else {
      this.categoriesCheckAndSelect();
    }
  }

  isCategoriesAllSelected() {
    if (
      this.categoriesSelection.selected &&
      this.categoriesSelection.selected.length > 0
    ) {
      const results = this.categoriesDataSource.data.filter(
        ({ CATEGORY_ID: id1 }) =>
          !this.categoriesSelection.selected.some(
            ({ CATEGORY_ID: id2 }) => id2 === id1
          )
      );
      if (results && results.length > 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  categoriesCheckAndSelect() {
    this.categoriesDataSource.data.forEach((row) => {
      if (
        this.categoriesSelection.selected.some(
          (item) => item.CATEGORY_ID === row.CATEGORY_ID
        )
      ) {
      } else {
        this.categoriesSelection.select(row);
      }
    });
  }

  deleteOnlyCategoriesDatasourceItems() {
    if (
      this.categoriesSelection.selected &&
      this.categoriesSelection.selected.length > 0
    ) {
      let temp = this.categoriesSelection.selected;
      this.categoriesSelection.clear();

      temp.forEach((element) => {
        let tempIndex = this.categoriesDataSource.data.findIndex(
          (row) => row.CATEGORY_ID == element.CATEGORY_ID
        );
        if (tempIndex == -1) {
          this.categoriesSelection.select(element);
        }
      });
    }
  }

  selectedCategory(row: any): boolean {
    if (
      this.categoriesSelection?.selected &&
      this.categoriesSelection.selected.length > 0
    ) {
      let temp = false;
      for (let i = 0; i < this.categoriesSelection.selected.length; i++) {
        if (
          this.categoriesSelection.selected[i].CATEGORY_ID == row.CATEGORY_ID
        ) {
          temp = true;
        }
      }
      return temp;
    } else {
      return false;
    }
  }

  partsMasterToggle() {
    if (this.isPartsAllSelected()) {
      this.deleteOnlyPartsDatasourceItems();
    } else {
      this.partsCheckAndSelect();
    }
  }

  isPartsAllSelected() {
    if (
      this.partsSelection.selected &&
      this.partsSelection.selected.length > 0
    ) {
      const results = this.partDataSource.data.filter(
        ({ PART_ID: id1 }) =>
          !this.partsSelection.selected.some(({ PART_ID: id2 }) => id2 === id1)
      );
      if (results && results.length > 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  partsCheckAndSelect() {
    this.partDataSource.data.forEach((row) => {
      if (
        this.partsSelection.selected.some(
          (item) => item.PART_ID === row.PART_ID
        )
      ) {
      } else {
        this.partsSelection.select(row);
      }
    });
  }

  deleteOnlyPartsDatasourceItems() {
    if (
      this.partsSelection.selected &&
      this.partsSelection.selected.length > 0
    ) {
      let temp = this.partsSelection.selected;
      this.partsSelection.clear();

      temp.forEach((element) => {
        let tempIndex = this.partDataSource.data.findIndex(
          (row) => row.PART_ID == element.PART_ID
        );
        if (tempIndex == -1) {
          this.partsSelection.select(element);
        }
      });
    }
  }

  selectedPart(row: any): boolean {
    if (
      this.partsSelection?.selected &&
      this.partsSelection.selected.length > 0
    ) {
      let temp = false;
      for (let i = 0; i < this.partsSelection.selected.length; i++) {
        if (this.partsSelection.selected[i].PART_ID == row.PART_ID) {
          temp = true;
        }
      }
      return temp;
    } else {
      return false;
    }
  }

  toggeleEquipmetSelection(ev: any, row: any) {
    if (ev.checked) {
      let ar = [
        ...new Map(
          this.equipmentSelection.selected.map((item) => [
            item.EQUIPMENT_ID,
            item,
          ])
        ).values(),
      ];

      if (ar?.length > 0) {
        for (let i = 0; i < ar.length; i++) {
          this.equipmentSelection.select(row);
        }
      } else {
        this.equipmentSelection.select(row);
      }
    } else {
      let temp = this.equipmentSelection.selected;
      if (temp && temp.length > 0) {
        temp.splice(
          temp.findIndex((a) => a.EQUIPMENT_ID === row.EQUIPMENT_ID),
          1
        );
        this.equipmentSelection.clear();
        for (let t = 0; t < temp?.length; t++) {
          this.equipmentSelection.select(temp[t]);
        }
      }
    }
  }

  toggeleCategorySelection(ev: any, row: any) {
    if (ev.checked) {
      let ar = [
        ...new Map(
          this.categoriesSelection.selected.map((item) => [
            item.CATEGORY_ID,
            item,
          ])
        ).values(),
      ];

      if (ar?.length > 0) {
        for (let i = 0; i < ar.length; i++) {
          this.categoriesSelection.select(row);
        }
      } else {
        this.categoriesSelection.select(row);
      }
    } else {
      let temp = this.categoriesSelection.selected;
      if (temp && temp.length > 0) {
        temp.splice(
          temp.findIndex((a) => a.CATEGORY_ID === row.CATEGORY_ID),
          1
        );
        this.categoriesSelection.clear();
        for (let t = 0; t < temp?.length; t++) {
          this.categoriesSelection.select(temp[t]);
        }
      }
    }
  }

  toggelePartsSelection(ev: any, row: any) {
    if (ev.checked) {
      let ar = [
        ...new Map(
          this.partsSelection.selected.map((item) => [item.PART_ID, item])
        ).values(),
      ];

      if (ar?.length > 0) {
        for (let i = 0; i < ar.length; i++) {
          this.partsSelection.select(row);
        }
      } else {
        this.partsSelection.select(row);
      }
    } else {
      let temp = this.partsSelection.selected;
      if (temp && temp.length > 0) {
        temp.splice(
          temp.findIndex((a) => a.PART_ID === row.PART_ID),
          1
        );
        this.partsSelection.clear();
        for (let t = 0; t < temp?.length; t++) {
          this.partsSelection.select(temp[t]);
        }
      }
    }
  }

  searchCategory(event: any) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if ($this.sort.active) {
        $this.getCategories(
          $this.paginatorLength,
          0,
          event.target.value,
          $this.sort.active,
          $this.sort._direction
        );
      } else {
        $this.getCategories($this.paginatorLength, 0, event.target.value, '', '');
      }
      $this.paginator.pageIndex = 0;
    }, 1000);
  }

  searchPart(event: any) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if ($this.sort.active) {
        $this.getParts(
          $this.paginatorLength,
          0,
          event.target.value,
          $this.sort.active,
          $this.sort._direction,
          $this.locationFormGroup.controls.locCtrl.value
        );
      } else {
        $this.getParts(
          $this.paginatorLength,
          0,
          event.target.value,
          '',
          '',
          $this.locationFormGroup.controls.locCtrl.value
        );
      }
      $this.paginator.pageIndex = 0;
    }, 1000);
  }

  getParts(
    limit: number,
    offset: number,
    searchText?: any,
    sortField?: any,
    sortDirection?: any,
    locationid?: any
  ) {
    this.isShowPartsProgressBar = true;
    this.servicerequestscheduleService
      .getParts(limit, offset, searchText, sortField, sortDirection, locationid)
      .subscribe(
        (response: any) => {
          // Success callback
          if (response.status === 200) {
            if (response.body) {
              // Assign the data to the data source for the table to render
              if (response.body && response.body?.PARTS.length > 0) {
                for (let p = 0; p < response.body.PARTS.length; p++) {
                  if (!response.body.PARTS[p].hasOwnProperty('BOM_PART_ID')) {
                    response.body.PARTS[p]['BOM_PART_ID'] =
                      response.body.PARTS[p]['PART_ID'];
                  }
                }
              }
              this.partDataSource = new MatTableDataSource(
                response.body?.PARTS
              );
              this.partsLength = response.body?.PARTS_COUNT;
            }
            this.isShowPartsProgressBar = false;
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.isShowPartsProgressBar = false;
            this.showErrorAlertDialog(response.error);
          } else {
            this.isShowPartsProgressBar = false;
            this.showErrorAlertDialog(response.message);
          }
        },
        (error) => {
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.isShowPartsProgressBar = false;
            this.showErrorAlertDialog(error.error);
          } else {
            this.isShowPartsProgressBar = false;
            this.showErrorAlertDialog(error.error);
          }
        }
      );
  }

  async createServiceRequestScheduleByType() {
    let type = this.equipmentFormGroup.controls.equipmentCtrl.value;
    let body: any;
    let date = new Date(this.detailsFormGroup.controls.fireDateTime.value);
    let removedDate = this.removeTime(date);
    switch (type) {
      case 'all':
        body = {
          LOCATION_ID: this.locationFormGroup.controls.locCtrl.value,
          DESCRIPTION: this.detailsFormGroup.controls.descriptionCtrl.value,
          SCHEDULE: 'ONCE',
          FIRE_DATE_TIME: removedDate,
          ETA: this.detailsFormGroup.controls.durationCtrl.value == 0 ? 
          this.detailsFormGroup.controls.customDuration.value : 
          this.detailsFormGroup.controls.durationCtrl.value,
        };
        break;
      case 'equipment':
        body = {
          LOCATION_ID: this.locationFormGroup.controls.locCtrl.value,
          DESCRIPTION: this.detailsFormGroup.controls.descriptionCtrl.value,
          SCHEDULE: 'ONCE',
          FIRE_DATE_TIME: removedDate,
          ETA: this.detailsFormGroup.controls.durationCtrl.value == 0 ? 
          this.detailsFormGroup.controls.customDuration.value : 
          this.detailsFormGroup.controls.durationCtrl.value,
          EQUIPMENTS: [],
        };

        if (
          this.equipmentSelection?.selected &&
          this.equipmentSelection.selected.length > 0
        ) {
          for (let i = 0; i < this.equipmentSelection.selected.length; i++) {
            let sel: any = {
              EQUIPMENT_ID: this.equipmentSelection.selected[i].EQUIPMENT_ID,
              CATEGORY_ID: this.equipmentSelection.selected[i].CATEGORY_ID,
            };
            body.EQUIPMENTS.push(sel);
          }
        }
        break;
      case 'categorie':
        body = {
          LOCATION_ID: this.locationFormGroup.controls.locCtrl.value,
          DESCRIPTION: this.detailsFormGroup.controls.descriptionCtrl.value,
          ETA: this.detailsFormGroup.controls.durationCtrl.value == 0 ? 
          this.detailsFormGroup.controls.customDuration.value : 
          this.detailsFormGroup.controls.durationCtrl.value,
          SCHEDULE: 'ONCE',
          FIRE_DATE_TIME: removedDate,
          EQUIPMENT_CATEGORY: [],
        };

        if (
          this.categoriesSelection?.selected &&
          this.categoriesSelection.selected.length > 0
        ) {
          for (let i = 0; i < this.categoriesSelection.selected.length; i++) {
            body.EQUIPMENT_CATEGORY.push(
              this.categoriesSelection.selected[i].CATEGORY_ID
            );
          }
        }
        break;

      case 'part':
        body = {
          LOCATION_ID: this.locationFormGroup.controls.locCtrl.value,
          DESCRIPTION: this.detailsFormGroup.controls.descriptionCtrl.value,
          ETA: this.detailsFormGroup.controls.durationCtrl.value == 0 ? 
          this.detailsFormGroup.controls.customDuration.value : 
          this.detailsFormGroup.controls.durationCtrl.value,
          SCHEDULE: 'ONCE',
          FIRE_DATE_TIME: removedDate,
          PARTS: [],
        };

        if (
          this.partsSelection?.selected &&
          this.partsSelection.selected.length > 0
        ) {
          for (let i = 0; i < this.partsSelection.selected.length; i++) {
            body.PARTS.push(this.partsSelection.selected[i].PART_ID);
          }
        }
        break;
      default:
        break;
    }

    await this.createServiceRequestSchedule(body);
  }

  async createServiceRequestSchedule(body: any) {
    await this.servicerequestscheduleService
      .createSchedule(body)
      .toPromise()
      .then(
        (response: any) => {
          if (response.status === 204) {
            this.serviceRequestScheduleDialogRef.close(true);
          } else {
            this.showErrorAlertDialog(response.message);
          }
        },
        (error) => {
          this.showErrorAlertDialog(error.error);
        }
      );
  }

  async updateAuditScheduleByType() {
    let type = this.equipmentFormGroup.controls.equipmentCtrl.value;
    let body: any;
    let date = new Date(this.detailsFormGroup.controls.fireDateTime.value);
    let removedDate = this.removeTime(date);
    switch (type) {
      case 'all':
        body = {
          SR_SCH_ID: this.data?.item?.SR_SCH_ID,
          LOCATION_ID: this.locationFormGroup.controls.locCtrl.value,
          DESCRIPTION: this.detailsFormGroup.controls.descriptionCtrl.value,
          ETA: this.detailsFormGroup.controls.durationCtrl.value == 0 ? 
          this.detailsFormGroup.controls.customDuration.value : 
          this.detailsFormGroup.controls.durationCtrl.value,
          SCHEDULE: 'ONCE',
          FIRE_DATE_TIME: removedDate,
          EQUIPMENT_CATEGORY: null,
          EQUIPMENTS: null,
          PARTS: null,
        };
        break;
      case 'equipment':
        body = {
          SR_SCH_ID: this.data?.item?.SR_SCH_ID,
          LOCATION_ID: this.locationFormGroup.controls.locCtrl.value,
          DESCRIPTION: this.detailsFormGroup.controls.descriptionCtrl.value,
          ETA: this.detailsFormGroup.controls.durationCtrl.value == 0 ? 
          this.detailsFormGroup.controls.customDuration.value : 
          this.detailsFormGroup.controls.durationCtrl.value,
          SCHEDULE: 'ONCE',
          FIRE_DATE_TIME: removedDate,
          EQUIPMENT_CATEGORY: null,
          EQUIPMENTS: [],
          PARTS: null,
        };

        if (
          this.equipmentSelection?.selected &&
          this.equipmentSelection.selected.length > 0
        ) {
          for (let i = 0; i < this.equipmentSelection.selected.length; i++) {
            let sel: any = {
              EQUIPMENT_ID: this.equipmentSelection.selected[i].EQUIPMENT_ID,
              CATEGORY_ID: this.equipmentSelection.selected[i].CATEGORY_ID,
            };
            body.EQUIPMENTS.push(sel);
          }
        }
        break;
      case 'categorie':
        body = {
          SR_SCH_ID: this.data?.item?.SR_SCH_ID,
          LOCATION_ID: this.locationFormGroup.controls.locCtrl.value,
          DESCRIPTION: this.detailsFormGroup.controls.descriptionCtrl.value,
          ETA: this.detailsFormGroup.controls.durationCtrl.value == 0 ? 
          this.detailsFormGroup.controls.customDuration.value : 
          this.detailsFormGroup.controls.durationCtrl.value,
          SCHEDULE: 'ONCE',
          FIRE_DATE_TIME: removedDate,
          EQUIPMENT_CATEGORY: [],
          EQUIPMENTS: null,
          PARTS: null,
        };

        if (
          this.categoriesSelection?.selected &&
          this.categoriesSelection.selected.length > 0
        ) {
          for (let i = 0; i < this.categoriesSelection.selected.length; i++) {
            body.EQUIPMENT_CATEGORY.push(
              this.categoriesSelection.selected[i].CATEGORY_ID
            );
          }
        }
        break;

      case 'part':
        body = {
          SR_SCH_ID: this.data?.item?.SR_SCH_ID,
          LOCATION_ID: this.locationFormGroup.controls.locCtrl.value,
          DESCRIPTION: this.detailsFormGroup.controls.descriptionCtrl.value,
          ETA: this.detailsFormGroup.controls.durationCtrl.value == 0 ? 
          this.detailsFormGroup.controls.customDuration.value : 
          this.detailsFormGroup.controls.durationCtrl.value,
          SCHEDULE: 'ONCE',
          FIRE_DATE_TIME: removedDate,
          EQUIPMENT_CATEGORY: null,
          EQUIPMENTS: null,
          PARTS: [],
        };

        if (
          this.partsSelection?.selected &&
          this.partsSelection.selected.length > 0
        ) {
          for (let i = 0; i < this.partsSelection.selected.length; i++) {
            body.PARTS.push(this.partsSelection.selected[i].PART_ID);
          }
        }
        break;
      default:
        break;
    }

    await this.updateAuditSchedule(body);
  }

  async updateAuditSchedule(body: any) {
    await this.servicerequestscheduleService
      .updateSchedule(body)
      .toPromise()
      .then(
        (response: any) => {
          if (response.status === 204) {
            this.serviceRequestScheduleDialogRef.close(true);
          } else {
            this.showErrorAlertDialog(response.message);
          }
        },
        (error) => {
          this.showErrorAlertDialog(error.error);
        }
      );
  }

  removeTime(date = new Date()) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const twoDigitMonth = month.toString().padStart(2, '0');
    const twoDigitDay = day.toString().padStart(2, '0')
    let finalDate = `${year}-${twoDigitMonth}-${twoDigitDay}T00:00:00Z`;
    return finalDate;
  }

  durationChange(ev: any) {
    if(ev?.value == 0) { 
      this.detailsFormGroup.addControl('customDuration', this._formBuilder.control('', Validators.required));
    } else {
      this.detailsFormGroup.removeControl('customDuration');
    }
  }
}
