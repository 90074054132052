import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AuthService } from 'src/app/services/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppConstants } from 'src/app/shared/AppConstants';
import { ServiceLogsComponent } from 'src/app/components/service-logs/service-logs.component';
import { EquipmentDetailsComponent } from 'src/app/components/equipment-details/equipment-details.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorComponent } from 'src/app/components/error/error.component';
import { NotificationsService } from 'src/app/services/notifications.service';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { FullScreenImageComponent } from 'src/app/components/full-screen-image/full-screen-image.component';
import { DomSanitizer } from '@angular/platform-browser';
import { ErrorAlertComponent } from 'src/app/components/error alert/error-alert.component';
import { AuditService } from 'src/app/services/audit.service';
import { NofificationsFilterComponent } from 'src/app/components/nofifications-filter/nofifications-filter.component';
import { SelectionModel } from '@angular/cdk/collections';
import { CustomerSelectComponent } from 'src/app/components/customer-select/customer-select.component';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator!: any;
  @ViewChild(MatSort, { static: true }) sort!: any;
  @ViewChild(MatPaginator, { static: false }) paginator1!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort1!: MatSort;
  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  public tableColumns: string[] = [
    'REQUEST_ID',
    'EQUIPMENT_NAME',
    'TASK_TYPE',
    'LOCATION',
    'STATUS',
    'CREATED_BY',
    'CREATED_AT',
    'Actions',
  ];
  public requestType: string;
  public typeDropdownOptions = [
    {
      key: 'NOTIFICATION',
      value: 'Notification',
    },
    {
      key: 'NEW_BARCODE',
      value: 'Barcode Change',
    },
    {
      key: 'MOVEMENT',
      value: 'Equipment Movement',
    },
    {
      key: 'EQUIPMENT',
      value: 'Create Equipment',
    },
  ];
  public selectedItem: any = {};
  public expandedElement: any;
  public notificationsDataSource: MatTableDataSource<any>;
  public pickListDataSource:  MatTableDataSource<any>;
  public pickList:any[] = [];
  public searchTerm: string;
  public isSearchHasValue: boolean;
  public isShowNotificationsProgressBar: boolean;
  public notificationsLength: number;
  public paginatorLength: number;
  public constants: AppConstants;
  public subscriptions: Subscription[] = [];
  public loggedInCustomerId: any;
  public serviceRequestDetails: any;
  public timeout: any = null;
  public auditId: string = '';
  public isAuditIdPresent: boolean;
  public version: string;
  public release: string;
  public notificationFilter: string = 'OPEN';
  public partDataSource: MatTableDataSource<any>;
  public partsColumns: string[] = [
    'PART_ID',
    'NAME',
    'MANUFACTURER',
    'MFG_PART_NUMBER',
    'PART_TYPE',
    'UOM',
  ];
  public pickListColumns: string[] = ['NAME','PART_ID', 'TOTAL_COUNT','BALANCE', 'DIFFERENCE', 'UNIT_PRICE', 'PRICE'];
  public isAirlineCusromer: boolean = false;
  public notificationSelection = new SelectionModel<any>(true, []);
  public isTableEditable: boolean = false;
  public navigatedFromSubAudits:boolean = false;
  public appropriateClass:string = '';
  public events: string[] = [];
  
  @HostListener('window:resize', ['$event'])
  getScreenHeight(event?: any){
    if(window.innerHeight<=700){
      this.appropriateClass = 'bottomRelative';
    }else{
      this.appropriateClass = 'bottomStick';
    }
  }
  constructor(
    public authService: AuthService,
    private breakpointObserver: BreakpointObserver,
    public utilService: UtilService,
    public dialog: MatDialog,
    public router: Router,
    public notificationsService: NotificationsService,
    public sanitizer: DomSanitizer,
    public auditService: AuditService
  ) {
    this.getScreenHeight();
    this.constants = new AppConstants();
    this.version = this.constants.APP_RELEASE_NUMBER;
    this.release = this.constants.APP_RELEASE_DATE;
    this.searchTerm = '';
    this.requestType = 'NOTIFICATION';
    this.notificationsLength = 0;
    this.paginatorLength = this.constants.INITIAL_PAGINATOR_LIMIT;
    this.isSearchHasValue = false;
    this.isShowNotificationsProgressBar = false;
    this.notificationsDataSource = new MatTableDataSource();
    this.pickListDataSource = new MatTableDataSource();
    this.isAuditIdPresent = false;
    this.partDataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    let temp: any = localStorage.getItem('user-details');
    let user: any = JSON.parse(temp);
    this.isAirlineCusromer = user.CUSTOMER_TYPE == 'AIRLINE' ? true : false;

    if(!this.isAirlineCusromer){
      this.tableColumns= [
        'REQUEST_ID',
        'TAG',
        'EQUIPMENT_NAME',
        'TASK_TYPE',
        'LOCATION',
        'STATUS',
        'CREATED_BY',
        'CREATED_AT',
        'Actions',
      ];
    } else{
      this.tableColumns= [
        'REQUEST_ID',
        'EQUIPMENT_NAME',
        'TASK_TYPE',
        'LOCATION',
        'STATUS',
        'CREATED_BY',
        'CREATED_AT',
        'Actions',
      ];
    }
    let customerId: any = localStorage.getItem('customerId');
    if (customerId && customerId != null) {
      customerId == 1
        ? (this.authService.isAFSUser = true)
        : (this.authService.isAFSUser = false);
    }

    this.navigatedFromSubAudits = this.auditService.subAuditsToNotificationStateDetails != null ? true : false;

    if (this.auditService.auditPageStateDetails != null) {
      this.isAuditIdPresent = true;
      this.auditId = this.auditService?.auditPageStateDetails?.auditId;
      this.getServiceRequests(
        this.paginatorLength,
        0,
        '',
        'CREATED_AT',
        'desc',
        this.requestType,
        '',
        this.auditId != null ? this.auditId : ''
      );
    } else if (
      this.notificationsService.notificationsPageStateDetails != null
    ) {
      this.paginator.pageIndex =
        this.notificationsService.notificationsPageStateDetails.pageIndex &&
          this.notificationsService.notificationsPageStateDetails.pageIndex != ''
          ? this.notificationsService.notificationsPageStateDetails.pageIndex
          : 0;
      this.paginatorLength =
        this.notificationsService.notificationsPageStateDetails.limit;
      this.notificationFilter = this.notificationsService
        ?.notificationsPageStateDetails?.status
        ? this.notificationsService?.notificationsPageStateDetails?.status
        : '';
      this.getServiceRequests(
        this.paginatorLength,
        this.notificationsService.notificationsPageStateDetails.offset,
        '',
        'CREATED_AT',
        'desc',
        this.requestType,
        this.notificationFilter
      );
      this.notificationsService.notificationsPageStateDetails = null;
    } else {
      this.getServiceRequests(
        this.paginatorLength,
        0,
        '',
        'CREATED_AT',
        'desc',
        this.requestType
      );
    }
  }

  // Destroy all the http calls when page leaving
  ngOnDestroy() {
    if (this.router?.url && this.router?.url?.length > 0) {
      if (this.router?.url != '/audits') {
        this.auditService.auditPageStateDetails = null;
      }
    }
    this.notificationsService.filterDetails = null;
    this.cancelOutstandingRequests();
  }

  getPartColumn(element: any) {
    if (this.notificationFilter == 'OPEN') {
      this.partsColumns = [
        'PART_ID',
        'NAME',
        'MANUFACTURER',
        'MFG_PART_NUMBER',
        'PART_TYPE',
        'UOM',
        'COUNT',
        'AVAILABILITY',
        'COST'
      ];
      return this.partsColumns;
    } else {
      this.partsColumns = [
        'PART_ID',
        'NAME',
        'MANUFACTURER',
        'MFG_PART_NUMBER',
        'PART_TYPE',
        'UOM'
      ];
      return this.partsColumns;
    }
  }

  logout() {
    this.authService.logout();
  }

  // Get service requests
  getServiceRequests(
    limit: number,
    offset: number,
    searchText?: any,
    sortField?: any,
    sortDirection?: any,
    type?: any,
    status?: any,
    auditId?: any,
    location?: any,
    barcode?: any,
    part?: any,
    unit?: any,
    task?: any,
  ) {
    this.isShowNotificationsProgressBar = true;
    let serviceRequestsSubscription = this.notificationsService
      .getServiceRequests(
        limit,
        offset,
        searchText,
        sortField,
        sortDirection,
        type,
        status,
        auditId,
        location,
        barcode,
        part,
        unit,
        task,
      )
      .subscribe(
        (response: any) => {
          // Success callback
          if (response.status === 200) {
            if (response.body) {
              // Assign the data to the data source for the table to render
              this.notificationsDataSource = new MatTableDataSource(
                response.body.SERVICE_REQUESTS
              );
              this.notificationsLength = response.body.SERVICE_REQUESTS_COUNT;
            }
            this.isShowNotificationsProgressBar = false;
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.isShowNotificationsProgressBar = false;
            this.router.navigate(['dashboard']);
          } else {
            this.isShowNotificationsProgressBar = false;
            this.errorDialog(response.error);
          }
        },
        (error) => {
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.isShowNotificationsProgressBar = false;
            this.router.navigate(['dashboard']);
          } else {
            this.isShowNotificationsProgressBar = false;
            this.errorDialog(error.message);
          }
        }
      );
    this.subscriptions.push(serviceRequestsSubscription);
  }

  errorDialog(msg: string) {
    const dialogRef = this.dialog.open(ErrorAlertComponent, {
      width: '400px',
      data: {
        errorMsg: msg,
      },
    });
  }

  refreshServiceRequests() {
    this.isTableEditable = false;
    this.notificationSelection.clear();
    this.pickListDataSource.data = []; 
    this.pickList=[];
    this.notificationsService.filterDetails = null;
    this.notificationFilter = 'OPEN';
    this.paginator.pageIndex = 0;
    if (this.auditId) {
      this.isAuditIdPresent = true;
      this.getServiceRequests(
        this.constants.INITIAL_PAGINATOR_LIMIT,
        0,
        '',
        'CREATED_AT',
        'desc',
        this.requestType,
        this.auditId
      );
    } else {
      this.getServiceRequests(
        this.constants.INITIAL_PAGINATOR_LIMIT,
        0,
        '',
        'CREATED_AT',
        'desc',
        this.requestType
      );
    }
  }

  // Error dialog when any server erros
  showErrorDialog(msg: string) {
    const dialogRef = this.dialog.open(ErrorComponent, {
      width: '400px',
      data: {
        msg: msg,
        mode: null,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        if (this.auditId) {
          this.isAuditIdPresent = true;
          this.getServiceRequests(
            this.constants.INITIAL_PAGINATOR_LIMIT,
            0,
            '',
            'CREATED_AT',
            'desc',
            this.requestType,
            this.auditId
          );
        } else {
          this.getServiceRequests(
            this.constants.INITIAL_PAGINATOR_LIMIT,
            0,
            '',
            'CREATED_AT',
            'desc',
            this.requestType
          );
        }
      }
    });
  }

  requestTypeChange(ev: any) {
    this.isTableEditable = false;
    this.notificationSelection.clear();
    this.pickListDataSource.data = []; 
    this.pickList=[];
    this.notificationsService.filterDetails = null;
    this.notificationsDataSource = new MatTableDataSource();
    this.notificationsLength = 0;
    switch (this.requestType) {
      case 'NOTIFICATION':
        if(!this.isAirlineCusromer){
          this.tableColumns = [
            'REQUEST_ID',
            'TAG',
            'EQUIPMENT_NAME',
            'TASK_TYPE',
            'LOCATION',
            'STATUS',
            'CREATED_BY',
            'CREATED_AT',
            'Actions',
          ];
        } else {
          this.tableColumns = [
            'REQUEST_ID',
            'EQUIPMENT_NAME',
            'TASK_TYPE',
            'LOCATION',
            'STATUS',
            'CREATED_BY',
            'CREATED_AT',
            'Actions',
          ];
        }

        break;
      case 'NEW_BARCODE':
        this.tableColumns = [
          'REQUEST_ID',
          'EQUIPMENT_NAME',
          'LOCATION',
          'NEW_BARCODE',
          'CREATED_BY',
          'CREATED_AT',
          'Actions',
        ];
        break;
      case 'MOVEMENT':
        if (!this.isAirlineCusromer) {
          this.tableColumns = [
            'REQUEST_ID',
            'TAG',
            'EQUIPMENT_NAME',
            'LOCATION',
            'NEW_LOCATION',
            'CREATED_BY',
            'CREATED_AT',
            'Actions',
          ];
        } else {
          this.tableColumns = [
            'REQUEST_ID',
            'EQUIPMENT_NAME',
            'LOCATION',
            'NEW_LOCATION',
            'CREATED_BY',
            'CREATED_AT',
            'Actions',
          ];
        }

        break;
      case 'EQUIPMENT':
        if (!this.isAirlineCusromer) {
          this.tableColumns = [
            'REQUEST_ID',
            'TAG',
            'EQUIPMENT_NAME',
            'EQUIPMENT_CATEGORY_NAME',
            'LOCATION',
            'CREATED_BY',
            'CREATED_AT',
            'Actions',
          ];
        } else {
          this.tableColumns = [
            'REQUEST_ID',
            'EQUIPMENT_NAME',
            'EQUIPMENT_CATEGORY_NAME',
            'LOCATION',
            'CREATED_BY',
            'CREATED_AT',
            'Actions',
          ];
        }

        break;
      default:
        break;
    }

    this.paginator.pageIndex = 0;
    this.getServiceRequests(
      this.constants.INITIAL_PAGINATOR_LIMIT,
      0,
      '',
      'CREATED_AT',
      'desc',
      this.requestType
    );
  }

  handlePaginator(e: any) {
    this.paginatorLength = e.pageSize;
    let limit = e.pageSize;
    let offset = e.pageIndex * limit;
    this.paginator.pageIndex = e.pageIndex;
    let status = '';
    if (
      this.notificationsService.filterDetails?.status &&
      this.notificationsService.filterDetails?.status != ''&&
      this.notificationsService.filterDetails?.status != null && 
      this.notificationsService.filterDetails?.status != undefined
    ) {
      status = this.notificationsService.filterDetails?.status;
    } else { status = 'OPEN' }

    let location = '';
    if (
      (this.notificationsService.filterDetails?.location &&
        this.notificationsService.filterDetails?.location != '' &&
        this.notificationsService.filterDetails?.location != undefined,
        this.notificationsService.filterDetails?.location != null)
    ) {
      location = this.notificationsService.filterDetails?.location;
    }

    let barcode = '';
    if (
      this.notificationsService.filterDetails?.barcode &&
      this.notificationsService.filterDetails?.barcode != '' &&
      this.notificationsService.filterDetails?.barcode != undefined,
      this.notificationsService.filterDetails?.barcode != null
    ) {
      barcode = this.notificationsService.filterDetails?.barcode;
    }

    let part = '';
    if (
      this.notificationsService.filterDetails?.part &&
      this.notificationsService.filterDetails?.part != '' &&
      this.notificationsService.filterDetails?.part != undefined,
      this.notificationsService.filterDetails?.part != null
    ) {
      part = this.notificationsService.filterDetails?.part;
    }

    let unit = '';
    if (
      this.notificationsService.filterDetails?.unit &&
      this.notificationsService.filterDetails?.unit != '' &&
      this.notificationsService.filterDetails?.unit != undefined,
      this.notificationsService.filterDetails?.unit != null
    ) {
      unit = this.notificationsService.filterDetails?.unit;
    }

    let task = '';
    if (
      this.notificationsService.filterDetails?.task &&
      this.notificationsService.filterDetails?.task != '' &&
      this.notificationsService.filterDetails?.task != undefined,
      this.notificationsService.filterDetails?.task != null
    ) {
      task = this.notificationsService.filterDetails?.task;
    }

    (this.auditId && this.auditId.length > 0) ? this.isAuditIdPresent = true : this.auditId = ''; this.isAuditIdPresent = false;

    this.getServiceRequests(
      this.paginatorLength,
      offset,
      '',
      this.sort.active == true ? this.sort.active : 'CREATED_AT',
      this.sort._direction == true ? this.sort._direction : 'desc',
      this.requestType,
      status = this.requestType == 'NOTIFICATION' ? status : '',
      this.auditId,
      location = this.requestType != 'MOVEMENT' ? location : '', 
      barcode, 
      part, 
      unit, 
      task
    )
    this.paginator.pageIndex = this.paginator._pageIndex;
  }

  sortfields(e: any) {
    let offset = this.paginator._pageIndex * this.paginator._pageSize;
    let status = '';
    if (
      this.auditService.filterDetails?.status &&
      this.auditService.filterDetails?.status != '' &&
      this.auditService.filterDetails?.status != null &&
      this.auditService.filterDetails?.status != undefined
    ) {
      status = this.auditService.filterDetails?.status;
    } else { status = 'OPEN' }

    let location = '';
    if (
      (this.notificationsService.filterDetails?.location &&
        this.notificationsService.filterDetails?.location != '' &&
        this.notificationsService.filterDetails?.location != undefined,
        this.notificationsService.filterDetails?.location != null)
    ) {
      location = this.notificationsService.filterDetails?.location;
    }

    let barcode = '';
    if (
      this.notificationsService.filterDetails?.barcode &&
      this.notificationsService.filterDetails?.barcode != ''
    ) {
      barcode = this.notificationsService.filterDetails?.barcode;
    }

    let part = '';
    if (
      this.notificationsService.filterDetails?.part &&
      this.notificationsService.filterDetails?.part != ''
    ) {
      part = this.notificationsService.filterDetails?.part;
    }

    let unit = '';
    if (
      this.notificationsService.filterDetails?.unit &&
      this.notificationsService.filterDetails?.unit != ''
    ) {
      unit = this.notificationsService.filterDetails?.unit;
    }

    let task = '';
    if (
      this.notificationsService.filterDetails?.task &&
      this.notificationsService.filterDetails?.task != ''
    ) {
      task = this.notificationsService.filterDetails?.task;
    }

    this.getServiceRequests(
      this.paginatorLength,
      offset,
      '',
      e.active,
      e.direction,
      this.requestType,
      status = this.requestType == 'NOTIFICATION' ? status : '',
      this.auditId,
      location = this.requestType != 'MOVEMENT' ? location : '', barcode, part, unit, task
    )
    this.paginator.pageIndex = this.paginator._pageIndex;
  }

  // Search filter
  searchServiceRequestsFilter(event: any) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      let status = '';
      if (
        $this.notificationsService.filterDetails?.status &&
        $this.notificationsService.filterDetails?.status != ''
      ) {
        status = $this.notificationsService.filterDetails?.status;
      }

      let barcode = '';
      if (
        $this.notificationsService.filterDetails?.barcode &&
        $this.notificationsService.filterDetails?.barcode != ''
      ) {
        barcode = $this.notificationsService.filterDetails?.barcode;
      }

      let part = '';
      if (
        $this.notificationsService.filterDetails?.part &&
        $this.notificationsService.filterDetails?.part != ''
      ) {
        part = $this.notificationsService.filterDetails?.part;
      }

      let unit = '';
      if (
        $this.notificationsService.filterDetails?.unit &&
        $this.notificationsService.filterDetails?.unit != ''
      ) {
        unit = $this.notificationsService.filterDetails?.unit;
      }

      let task = '';
      if (
        $this.notificationsService.filterDetails?.task &&
        $this.notificationsService.filterDetails?.task != ''
      ) {
        task = $this.notificationsService.filterDetails?.task;
      }

      let location = '';
      if (
        $this.notificationsService.filterDetails?.location &&
        $this.notificationsService.filterDetails?.location != ''
      ) {
        location = $this.notificationsService.filterDetails?.location;
      }

      if ($this.auditId) {
        $this.isAuditIdPresent = true;
        $this.getServiceRequests(
          $this.constants.INITIAL_PAGINATOR_LIMIT,
          0,
          event.target.value,
          'CREATED_AT',
          'desc',
          $this.requestType,
          $this.notificationFilter,
          $this.auditId,location,barcode, part, unit, task
        );
      } else {
        $this.getServiceRequests(
          $this.constants.INITIAL_PAGINATOR_LIMIT,
          0,
          event.target.value,
          'CREATED_AT',
          'desc',
          $this.requestType,
          $this.notificationFilter,'', location,barcode, part, unit, task
        );
      }
      $this.paginator.pageIndex = 0;
    }, 1000);
  }

  // Clear search
  clearSearch() {
    this.searchTerm = '';
    let status = '';
    if (
      this.notificationsService.filterDetails?.status &&
      this.notificationsService.filterDetails?.status != '' &&
      this.notificationsService.filterDetails?.status != null &&
      this.notificationsService.filterDetails?.status != undefined
    ) {
      status = this.notificationsService.filterDetails?.status;
    } else { status = 'OPEN' }

    let location = '';
    if (
      (this.notificationsService.filterDetails?.location &&
        this.notificationsService.filterDetails?.location != '' &&
        this.notificationsService.filterDetails?.location != undefined,
        this.notificationsService.filterDetails?.location != null)
    ) {
      location = this.notificationsService.filterDetails?.location;
    }

    let barcode = '';
    if (
      this.notificationsService.filterDetails?.barcode &&
      this.notificationsService.filterDetails?.barcode != ''
    ) {
      barcode = this.notificationsService.filterDetails?.barcode;
    }

    let part = '';
    if (
      this.notificationsService.filterDetails?.part &&
      this.notificationsService.filterDetails?.part != ''
    ) {
      part = this.notificationsService.filterDetails?.part;
    }

    let unit = '';
    if (
      this.notificationsService.filterDetails?.unit &&
      this.notificationsService.filterDetails?.unit != ''
    ) {
      unit = this.notificationsService.filterDetails?.unit;
    }

    let task = '';
    if (
      this.notificationsService.filterDetails?.task &&
      this.notificationsService.filterDetails?.task != ''
    ) {
      task = this.notificationsService.filterDetails?.task;
    }

    this.getServiceRequests(
      this.paginatorLength,
      0,
      '',
      'CREATED_AT',
      'desc',
      this.requestType,
      status = this.requestType == 'NOTIFICATION' ? status : '',
      this.auditId,
      location = this.requestType != 'MOVEMENT' ? location : '', barcode, part, unit, task
    )
    this.paginator.pageIndex = 0;
  }

  // Cancel on going http calls when page leaving
  cancelOutstandingRequests() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  // Show service request details
  showServiceRequestDetails(request: any, type: string) {
    if (type === 'expand') {
      this.serviceRequestDetails = false;
      this.selectedItem = request;
      this.cancelOutstandingRequests();
      // Make get service request by id call
      let serviceRequestByIdSubscription = this.notificationsService
        .getServiceRequestById(request.REQUEST_ID)
        .subscribe(
          (response: any) => {
            // Success callback
            if (response.status === 200) {
              if (response.body) {
                // Assign the data to the data source for the table to render
                this.serviceRequestDetails = response.body;
                if (this.serviceRequestDetails?.PARTS) {
                  if (
                    Array.isArray(this.serviceRequestDetails?.PARTS)
                  ) {
                    this.partDataSource = new MatTableDataSource(
                      this.serviceRequestDetails?.PARTS
                    );
                  } else {
                    // if (
                    //   Object.keys(this.serviceRequestDetails?.REPAIR?.PARTS)
                    //     .length > 0
                    // ) {
                    //   this.airlinePartsObj =
                    //     this.serviceRequestDetails?.REPAIR?.PARTS;
                    // } else {
                    //   this.airlinePartsObj = {};
                    // }
                  }
                }
              }
            } else if (response.status === 401) {
              // Redirect to login for unauthorized
              localStorage.removeItem('token');
              localStorage.removeItem('user-details');
              localStorage.removeItem('customerName');
              localStorage.removeItem('customerId');
              localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
              this.authService.loggedIn.next(false);
              this.router.navigate(['login']);
            } else if (response.status === 403) {
              this.showErrorDialog(response.error);
            } else {
              this.showErrorDialog(
                'Error while downloading service requests, do you want to retry again?'
              );
            }
          },
          (error) => {
            if (error.status === 401) {
              // Redirect to login for unauthorized
              localStorage.removeItem('token');
              localStorage.removeItem('user-details');
              localStorage.removeItem('customerName');
              localStorage.removeItem('customerId');
              localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
              this.authService.loggedIn.next(false);
              this.router.navigate(['login']);
            } else if (error.status === 403) {
              this.showErrorDialog(error.error);
            } else {
              this.showErrorDialog(
                'Error while downloading service requests, do you want to retry again?'
              );
            }
          }
        );
      this.subscriptions.push(serviceRequestByIdSubscription);
    } else {
      this.selectedItem = null;
    }
  }

  // Show equipment details for selected service request
  showEquipmentDetails(item: any) {
    const dialogRef = this.dialog.open(EquipmentDetailsComponent, {
      width: '700px',
      height: '500px',
      data: { item: item },
      panelClass: 'add-customer-dialog',
    });
  }

  // Show service logs
  showServiceLogs(item: any) {
    this.dialog.open(ServiceLogsComponent, {
      width: '720px',
      maxHeight: '600px',
      height: '500px',
      data: { item: item },
      panelClass: 'add-customer-dialog',
    });
  }

  // Cancel or convert to service request
  modifyServiceRequest(item: any, type: string) {
    let msg =
      type == 'CANCEL'
        ? 'Cancel notification'
        : 'Convert to service request';

    const dialogRef = this.dialog.open(AlertComponent, {
      width: '400px',
      data: {
        errorMsg: `${msg}?`,
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result.data) {
        this.isShowNotificationsProgressBar = true;
        let body: any = {
          REQUEST_ID: item.REQUEST_ID,
          STATUS: type,
        };

        await this.notificationsService
          .updateServiceRequest(body)
          .toPromise()
          .then(
            (response: any) => {
              this.isShowNotificationsProgressBar = false;
              if (response.status === 204) {
                let offset = this.paginator._pageIndex * this.paginator._pageSize;
                let status = '';
                if (
                  this.notificationsService.filterDetails?.status &&
                  this.notificationsService.filterDetails?.status != '' &&
                  this.notificationsService.filterDetails?.status != null &&
                  this.notificationsService.filterDetails?.status != undefined
                ) {
                  status = this.notificationsService.filterDetails?.status;
                } else { status = 'OPEN' }

                let location = '';
                if (
                  (this.notificationsService.filterDetails?.location &&
                    this.notificationsService.filterDetails?.location != '' &&
                    this.notificationsService.filterDetails?.location != undefined,
                    this.notificationsService.filterDetails?.location != null)
                ) {
                  location = this.notificationsService.filterDetails?.location;
                }

                let barcode = '';
                if (
                  this.notificationsService.filterDetails?.barcode &&
                  this.notificationsService.filterDetails?.barcode != ''
                ) {
                  barcode = this.notificationsService.filterDetails?.barcode;
                }
            
                let part = '';
                if (
                  this.notificationsService.filterDetails?.part &&
                  this.notificationsService.filterDetails?.part != ''
                ) {
                  part = this.notificationsService.filterDetails?.part;
                }
            
                let unit = '';
                if (
                  this.notificationsService.filterDetails?.unit &&
                  this.notificationsService.filterDetails?.unit != ''
                ) {
                  unit = this.notificationsService.filterDetails?.unit;
                }
            
                let task = '';
                if (
                  this.notificationsService.filterDetails?.task &&
                  this.notificationsService.filterDetails?.task != ''
                ) {
                  task = this.notificationsService.filterDetails?.task;
                }

                this.getServiceRequests(
                  this.paginatorLength,
                  offset,
                  '',
                  this.sort.active == true ? this.sort.active : 'CREATED_AT',
                  this.sort._direction == true ? this.sort._direction : 'desc',
                  this.requestType,
                  status = this.requestType == 'NOTIFICATION' ? status : '',
                  this.auditId,
                  location = this.requestType != 'MOVEMENT' ? location : '', barcode, part, unit, task
                )
                this.paginator.pageIndex = this.paginator._pageIndex;
              } else {
                this.isShowNotificationsProgressBar = false;
                this.showErrorAlertDialog(response.message);
              }
            },
            (error) => {
              this.isShowNotificationsProgressBar = false;
              this.showErrorAlertDialog(error.error);
            }
          );
      }
    });
  }

  // Sanitize base64 to show thumbnail
  displayImage(type: string, content: any) {
    return (
      `data:${type};base64,` +
      (this.sanitizer.bypassSecurityTrustResourceUrl(content) as any)
        .changingThisBreaksApplicationSecurity
    );
  }

  // View thumbnail in full screen view
  viewFullScreenImage(imageBase64: string, mimetype: string) {
    this.dialog.open(FullScreenImageComponent, {
      data: { item: { imageBase64: imageBase64, mimetype: mimetype } },
    });
  }

  // Show Service Request
  gotoServiceRequest(requestId: number) {
    this.notificationsService.notificationsPageStateDetails = {
      requestId: requestId,
      pageName: 'service-requests',
      pageIndex: this.paginator._pageIndex ? this.paginator._pageIndex : '',
      offset: this.paginator?._pageIndex * this.paginator?._pageSize,
      limit: this.paginatorLength,
      filterDetails: this.auditService.filterDetails,
      status: this.notificationFilter,
    };
    this.router.navigate(['/service-requests']);
  }

  // Error dialog when any server erros
  showErrorAlertDialog(msg: string) {
    const dialogRef = this.dialog.open(ErrorAlertComponent, {
      width: '400px',
      data: {
        errorMsg: msg,
      },
    });
  }

  goBackToAudits() {
    this.router.navigate(['/audits']);
  }

  isArray(item: any) {
    return Array.isArray(item);
  }

  getTotalCost() {
    return this.partDataSource.data.map(t => t.COST).reduce((acc, value) => acc + value, 0);
  }

  filterNotifications() {
    let tempFilterDetails;
    if (
      this.notificationsService.filterDetails &&
      this.notificationsService.filterDetails != null &&
      this.notificationsService.filterDetails != undefined
    ) {
      tempFilterDetails = this.notificationsService.filterDetails;
    }
    let dialogRef = this.dialog.open(NofificationsFilterComponent, {
      width: '350px',
      data: { item: tempFilterDetails, type:  this.requestType},
      hasBackdrop: true,
      panelClass: 'service-request-filter',
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.notificationsDataSource = new MatTableDataSource();
        console.log(this.paginator)
        this.paginator.pageIndex = 0;
        this.notificationsService.filterDetails = result.data;
        let status = '';
        if (
          (result?.data?.status &&
            result?.data?.status != '' &&
            result?.data?.status != undefined,
            result?.data?.status != null)
        ) {
          status = result?.data?.status;
        }

        let location = '';
        if (
          (result?.data?.location &&
            result?.data?.location != '' &&
            result?.data?.location != undefined,
            result?.data?.location != null)
        ) {
          location = result?.data?.location;
        }

        let barcode = '';
        if (
          result?.data?.barcode &&
          result?.data?.barcode != ''
        ) {
          barcode = result?.data?.barcode;
        }
    
        let part = '';
        if (
          result?.data?.part &&
          result?.data?.part != ''
        ) {
          part = result?.data?.part;
        }
    
        let unit = '';
        if (
          result?.data?.unit &&
          result?.data?.unit != ''
        ) {
          unit = result?.data?.unit;
        }
    
        let task = '';
        if (
          result?.data?.task &&
          result?.data?.task != ''
        ) {
          task = result?.data?.task;
        }


        if (this.auditId) {
          this.isAuditIdPresent = true;
          this.getServiceRequests(
            this.paginatorLength,
            0,
            '',
            'CREATED_AT',
            'desc',
            this.requestType,
            status,
            this.auditId,
            location, barcode, part, unit, task
          );
        } else {
          this.getServiceRequests(
            this.paginatorLength,
            0,
            '',
            'CREATED_AT',
            'desc',
            this.requestType,
            status,
            '',
            location, barcode, part, unit, task
          );
        }
      }
    });
  }

  bulkCancel() {
    let filteredItems: any[] = this.notificationSelection.selected.filter(
      item => (item.STATUS === 'OPEN'));

    if (filteredItems?.length > 0) {
      let dialogRef = this.dialog.open(AlertComponent, {
        width: '400px',
        data: {
          errorMsg: `Are you sure you want to cancel ${filteredItems?.length} notifications?`,
        },
      });

      dialogRef.afterClosed().subscribe(async (result) => {
        if (result.data) {
          this.isShowNotificationsProgressBar = true;
          let body: any[] = [];
          filteredItems.forEach(element => {
            body.push({ 'REQUEST_ID': element.REQUEST_ID, 'STATUS': 'CANCEL', 'COMMENT': '' })
          });
          await this.notificationsService
            .updateServiceRequest({ 'SERVICE_REQUESTS': body })
            .toPromise()
            .then(
              (response: any) => {
                this.isShowNotificationsProgressBar = false;
                if (response.status === 204) { this.reloadToInitialState(); }
                else { this.dispalyErrorAlert(response?.message); }
              },
              (error) => { this.isShowNotificationsProgressBar = false; this.dispalyErrorAlert(error?.error); }
            );
        }
      });
    } else { this.isShowNotificationsProgressBar = false; this.dispalyErrorAlert('No notifications were found for the cancel'); }
  }

  reloadToInitialState() {
    this.isTableEditable = false;
    this.notificationSelection.clear();
    this.pickListDataSource.data = []; 
    this.pickList=[];
    this.displayedColumns();
    this.refreshServiceRequests();
  }

  dispalyErrorAlert(msg: string) {
    this.dialog.open(ErrorAlertComponent, {
      width: '400px',
      data: {
        errorMsg: msg,
      },
    });
  }

  bulkConvert() {
    let filteredItems: any[] = this.notificationSelection.selected.filter(
      item => (item.STATUS === 'OPEN'));

    if (filteredItems?.length > 0) {
      let dialogRef = this.dialog.open(AlertComponent, {
        width: '400px',
        data: {
          errorMsg: `Are you sure you want to convert ${filteredItems?.length} notifications to service request?`,
        },
      });

      dialogRef.afterClosed().subscribe(async (result) => {
        if (result.data) {
          this.isShowNotificationsProgressBar = true;
          let body: any[] = [];
          filteredItems.forEach(element => {
            body.push({ 'REQUEST_ID': element.REQUEST_ID, 'STATUS': 'CLOSED', 'COMMENT': '' })
          });
          await this.notificationsService
            .updateServiceRequest({ 'SERVICE_REQUESTS': body })
            .toPromise()
            .then(
              (response: any) => {
                this.isShowNotificationsProgressBar = false;
                if (response.status === 204) { this.reloadToInitialState(); }
                else { this.dispalyErrorAlert(response?.message); }
              },
              (error) => { this.isShowNotificationsProgressBar = false; this.dispalyErrorAlert(error?.error); }
            );
        }
      });
    } else { this.isShowNotificationsProgressBar = false; this.dispalyErrorAlert('No notifications were found for convert'); }
   }

  displayedColumns() {
    if (this.isTableEditable) {
      if (!this.isAirlineCusromer) {
        this.tableColumns = [
          'SELECT',
          'REQUEST_ID',
          'TAG',
          'EQUIPMENT_NAME',
          'TASK_TYPE',
          'LOCATION',
          'STATUS',
          'CREATED_BY',
          'CREATED_AT',
          'Actions',
        ];
      } else {
        this.tableColumns = [
          'SELECT',
          'REQUEST_ID',
          'EQUIPMENT_NAME',
          'TASK_TYPE',
          'LOCATION',
          'STATUS',
          'CREATED_BY',
          'CREATED_AT',
          'Actions',
        ]
      }

    } else {
      if (!this.isAirlineCusromer) { 
        this.tableColumns = [
          'REQUEST_ID',
          'TAG',
          'EQUIPMENT_NAME',
          'TASK_TYPE',
          'LOCATION',
          'STATUS',
          'CREATED_BY',
          'CREATED_AT',
          'Actions',
        ];
      } else {
        this.tableColumns = [
          'REQUEST_ID',
          'EQUIPMENT_NAME',
          'TASK_TYPE',
          'LOCATION',
          'STATUS',
          'CREATED_BY',
          'CREATED_AT',
          'Actions',
        ];
      }
      }
  }

  isShowSelectButton(): boolean {
    let status = '';
    if (
      this.notificationsService.filterDetails?.status &&
      this.notificationsService.filterDetails?.status != ''
    ) {
      status = this.notificationsService.filterDetails?.status;
    }
    if (status == '') { return true }
    else if (status != '' && status.length > 0 && (status == 'OPEN')) {
      return true
    } else return false;
  }

  serviceRequestsMasterToggle() {
    if (this.isServiceRequestsAllSelected()) {
      this.deleteOnlyServiceRequestsDatasourceItems();
    } else {
      this.serviceRequestsCheckAndSelect();
    }
  }

  serviceRequestsCheckAndSelect() {
    this.notificationsDataSource.data.forEach((row) => {
      if (
        this.notificationSelection.selected.some(
          (item) => item.REQUEST_ID === row.REQUEST_ID
        )
      ) {
      } else {
        this.notificationSelection.select(row);
      }
    });
  }

  deleteOnlyServiceRequestsDatasourceItems() {
    if (
      this.notificationSelection.selected &&
      this.notificationSelection.selected.length > 0
    ) {
      let temp = this.notificationSelection.selected;
      this.notificationSelection.clear();
      this.pickListDataSource.data = []; 
      this.pickList=[];
      temp.forEach((element) => {
        let tempIndex = this.notificationsDataSource.data.findIndex(
          (row) => row.REQUEST_ID == element.REQUEST_ID
        );
        if (tempIndex == -1) {
          this.notificationSelection.select(element);
        }
      });
    }
  }

  isServiceRequestsAllSelected() {
    if (
      this.notificationSelection.selected &&
      this.notificationSelection.selected.length > 0
    ) {
      const results = this.notificationsDataSource.data.filter(
        ({ REQUEST_ID: id1 }) =>
          !this.notificationSelection.selected.some(
            ({ REQUEST_ID: id2 }) => id2 === id1
          )
      );
      if (results && results.length > 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  toggeleServiceRequestSelection(ev: any, row: any) {
    if (ev.checked) {
      let ar = [
        ...new Map(
          this.notificationSelection.selected.map((item) => [
            item.REQUEST_ID,
            item,
          ])
        ).values(),
      ];

      if (ar?.length > 0) {
        for (let i = 0; i < ar.length; i++) {
          this.notificationSelection.select(row);
        }
      } else {
        this.notificationSelection.select(row);
      }
    } else {
      let temp = this.notificationSelection.selected;
      if (temp && temp.length > 0) {
        temp.splice(
          temp.findIndex((a) => a.REQUEST_ID === row.REQUEST_ID),
          1
        );
        this.notificationSelection.clear();
        this.pickListDataSource.data = []; 
        this.pickList=[];
        for (let t = 0; t < temp?.length; t++) {
          this.notificationSelection.select(temp[t]);
        }
      }
    }
  }

  selectedServiceRequests(row: any): boolean {
    if (
      this.notificationSelection?.selected &&
      this.notificationSelection.selected.length > 0
    ) {
      let temp = false;
      for (let i = 0; i < this.notificationSelection.selected.length; i++) {
        if (
          this.notificationSelection.selected[i].REQUEST_ID == row.REQUEST_ID
        ) {
          temp = true;
        }
      }
      return temp;
    } else {
      return false;
    }
  }

  goBackToSubAudits() { this.router.navigate(['/sub-audits']); }

  getTotal() {
    let cost = 0;
    if(this.serviceRequestDetails?.PARTS && Object.keys(this.serviceRequestDetails?.PARTS).length > 0) {
      for(let key in this.serviceRequestDetails?.PARTS) {
        let child = this.serviceRequestDetails?.PARTS[key];
        child.forEach((element: { Cost: any; }) => {
          cost = cost + element.Cost;
        });
        }
    }
    return cost;
  }

  async getPicklist() {
    this.isShowNotificationsProgressBar = true;
    let filteredItems: any[] = this.notificationSelection.selected.filter(
      item => (item.STATUS === 'OPEN'));
    let requestIds: any[] = [];
    filteredItems.forEach(element => {
      requestIds.push(element.REQUEST_ID);
    });

    await this.notificationsService
      .getPickListByRequestId(requestIds.toString())
      .toPromise()
      .then(
        (response: any) => {
          this.isShowNotificationsProgressBar = false;
          if (response.status === 200) {
            this.pickList = response.body;
            this.pickListDataSource = new MatTableDataSource(response.body);
          } else {
            this.isShowNotificationsProgressBar = false;
            this.showErrorAlertDialog(response.message);
          }
        },
        (error) => {
          this.isShowNotificationsProgressBar = false;
          this.showErrorAlertDialog(error.error);
        }
      );
  }


  async downloadPicklist() {
    return new Promise((resolve, reject) => {
      console.log('Preparing CSV file from JSON data...');
      try {
        var structureRowString =
          '' +
          'Name' +
          ',' +
          'ID' +
          ',' +
          'Available' +
          ',' +
          'Difference' +
          ',' +
          'Unit Price' +
          ',' +
          'Price' +
          '\n';

        for (let row of this.pickList) {
          structureRowString =
            structureRowString +
            row.NAME +
            ',' +
            row.PART_ID +
            ',' +
            row.BALANCE +
            ',' +
            row.DIFFERENCE +
            ',' +
            row.UNIT_PRICE +
            ',' +
            row.PRICE +
            '\n';
        }
        return resolve(
          this.downloadAsCSV(structureRowString, 'picklist.csv')
        );
      } catch (error) {
        return reject(error);
      }
    });
  }

  async downloadAsCSV(csvString: any, filenames: string) {
    return new Promise((resolve, reject) => {
      try {
        console.log('CSV file is prepared. Downloading to the client');
        var csvFile;
        var downloadLink: any;
        csvFile = new Blob([csvString], { type: 'text/csv' });
  
        // IE10+ : (has Blob, but not a[download] or URL)
        if (navigator.msSaveBlob) {
          return navigator.msSaveBlob(csvFile, filenames);
        }
  
        downloadLink = document.createElement('a');
        downloadLink.download = filenames;
        let url = window.URL.createObjectURL(csvFile);
        downloadLink.href = url;
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
  
        /**
         * Delay the download process to make sure that the DOM is built.
         */
        setTimeout(() => {
          downloadLink.click();
  
          document.body.removeChild(downloadLink);
          window.URL.revokeObjectURL(url);
          return resolve('Download process is complete');
        }, 1000);
      } catch (error) {
        return reject(error);
      }
    });
  }

  getPicklistTotalCost() {
    return this.pickList.map(t => t.PRICE).reduce((acc, value) => acc + value, 0);
  }

  checkKeyPresent(obj: any) {
    if(obj != null && obj != undefined) {
      if (Object.keys(obj).length > 0) {
        return true;
      } else return false;
    } else return false;
  }

  switchCompany() {
    let userTenants: any = localStorage.getItem('userTenants');
    if (userTenants && userTenants != null) {
      userTenants = JSON.parse(userTenants);
      const dialogRef = this.dialog.open(CustomerSelectComponent, {
        panelClass: 'add-customer-dialog',
        width: '550px',
        height: '380px',
        disableClose: false,
        data: { mode: 0, item: userTenants },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
        this.refreshServiceRequests();
        }
      });
    }
  }

  returnDate(date: any) {
    return this.utilService.dateTimeConversion(date);
  }
}
