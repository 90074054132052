import { animate, state, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddNewPartOrderComponent } from 'src/app/components/add-new-part-order/add-new-part-order.component';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { CustomerSelectComponent } from 'src/app/components/customer-select/customer-select.component';
import { ErrorAlertComponent } from 'src/app/components/error alert/error-alert.component';
import { ErrorComponent } from 'src/app/components/error/error.component';
import { ReceivePartComponent } from 'src/app/components/receive-part/receive-part.component';
import { AuthService } from 'src/app/services/auth.service';
import { PartOrderService } from 'src/app/services/part-order.service';
import { UtilService } from 'src/app/services/util.service';
import { ActionType, AppConstants } from 'src/app/shared/AppConstants';

@Component({
  selector: 'app-part-orders',
  templateUrl: './part-orders.component.html',
  styleUrls: ['./part-orders.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class PartOrdersComponent implements OnInit {
  public appropriateClass: string = '';
  public version: string;
  public release: string;
  public paginatorLength: number;
  public constants: AppConstants;
  public subscriptions: Subscription[] = [];
  public loggedInCustomerId: any;
  public isAirlineCusromer: boolean = false;
  public isSearchHasValue: boolean;
  public searchTerm: string;
  public isTableEditable: boolean = false;
  public ordersLength: number;
  public timeout: any = null;
  public selectedItem: any = {};
  public partOrderDetails: any;
  public tableColumns: string[] = [
    'ORDER_NUMBER',
    'ORDER_DATE',
    'SHIP_DATE',
    'RECEIVED_DATE',
    'ORDER_AMOUNT',
    'MANUFACTURER',
    'Actions'
  ];

  public isShowProgressBar: boolean = false;

  @ViewChild(MatPaginator, { static: true }) paginator!: any;
  @ViewChild(MatSort, { static: true }) sort!: any;
  @ViewChild(MatPaginator, { static: false }) paginator1!: any;

  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result: { matches: any }) => result.matches));

  @HostListener('window:resize', ['$event'])
  getScreenHeight(event?: any) {
    if (window.innerHeight <= 700) {
      this.appropriateClass = 'bottomRelative';
    } else {
      this.appropriateClass = 'bottomStick';
    }
  }
  public ordersDataSource: MatTableDataSource<any>;
  public partDataSource: MatTableDataSource<any>;

  partsColumns = ['PART_ID', 'NAME', 'MANUFACTURER', 'RECEIVED_QUANTITY', 'UNIT_PRICE', 'Extended_Price'];
  columnsToDisplayWithExpand = [...this.partsColumns, 'expand'];
  expandedElement: any | null;


  constructor(
    public authService: AuthService,
    private breakpointObserver: BreakpointObserver,
    public utilService: UtilService,
    public dialog: MatDialog,
    public router: Router,
    public partOrderService: PartOrderService
  ) {
    this.getScreenHeight();
    this.constants = new AppConstants();
    this.version = this.constants.APP_RELEASE_NUMBER;
    this.release = this.constants.APP_RELEASE_DATE;
    this.paginatorLength = this.constants.INITIAL_PAGINATOR_LIMIT;
    this.isSearchHasValue = false;
    this.searchTerm = '';
    this.ordersLength = 0;
    this.ordersDataSource = new MatTableDataSource();
    this.partDataSource = new MatTableDataSource();
    this.partDataSource.paginator = this.paginator1;

  }

  ngOnInit(): void {
    let customerId: any = localStorage.getItem('customerId');
    let temp: any = localStorage.getItem('user-details');
    let user: any = JSON.parse(temp);
    this.isAirlineCusromer = user.CUSTOMER_TYPE == 'AIRLINE' ? true : false;
    if (customerId && customerId != null) {
      customerId == 1
        ? (this.authService.isAFSUser = true)
        : (this.authService.isAFSUser = false);
    }
    this.getPartOrders(this.paginatorLength, 0, '', 'ORDER_DATE', 'desc');
  }

  getPartOrders(
    limit: number,
    offset: number,
    searchText?: any,
    sortField?: any,
    sortDirection?: any
  ) {
    this.isShowProgressBar = true;
    let usersSubscription = this.partOrderService
      .getPartOrders(limit, offset, searchText, sortField, sortDirection)
      .subscribe(
        (response: any) => {
          // Success callback
          if (response.status === 200) {
            if (response.body) {
              // Assign the data to the data source for the table to render
              response?.body?.PART_ORDERS.forEach((order: any) => {
                order.ORDER_DATE =  order.ORDER_DATE.replace('Z', '');
                order.SHIP_DATE =  order.SHIP_DATE.replace('Z', '');
              });

              this.ordersDataSource = new MatTableDataSource(
                response.body.PART_ORDERS
              );
              this.ordersLength = response.body.PART_ORDER_COUNT;
            }
            this.isShowProgressBar = false;
          } else if (response.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug'); localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (response.status === 403) {
            this.isShowProgressBar = false;
            this.router.navigate(['dashboard']);
          } else {
            this.isShowProgressBar = false;
            this.errorDialog(response.error);
          }
        },
        (error: any) => {
          if (error.status === 401) {
            // Redirect to login for unauthorized
            localStorage.removeItem('token');
            localStorage.removeItem('user-details');
            localStorage.removeItem('customerName');
            localStorage.removeItem('customerId');
            localStorage.removeItem('dashboardSlug'); localStorage.removeItem('userTenants');
            this.authService.loggedIn.next(false);
            this.router.navigate(['login']);
          } else if (error.status === 403) {
            this.isShowProgressBar = false;
            this.router.navigate(['dashboard']);
          } else {
            this.isShowProgressBar = false;
            this.errorDialog(error.message);
          }
        }
      );
    this.subscriptions.push(usersSubscription);
  }

  errorDialog(msg: string) {
    const dialogRef = this.dialog.open(ErrorAlertComponent, {
      width: '400px',
      data: {
        errorMsg: msg,
      },
    });
  }

  logout() {
    this.authService.logout();
  }

  searchPartOrdersFilter(event: any) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if ($this.sort.active) {
        $this.getPartOrders(
          $this.paginatorLength,
          0,
          event.target.value,
          $this.sort.active,
          $this.sort._direction
        );
      } else {
        $this.getPartOrders($this.paginatorLength, 0, event.target.value, 'ORDER_DATE', 'desc');
      }
      $this.paginator.pageIndex = 0;
    }, 1000);
  }

  clearSearch() {
    this.searchTerm = '';
  }

  switchCompany() {
    let userTenants: any = localStorage.getItem('userTenants');
    if (userTenants && userTenants != null) {
      userTenants = JSON.parse(userTenants);
      const dialogRef = this.dialog.open(CustomerSelectComponent, {
        panelClass: 'add-customer-dialog',
        width: '550px',
        height: '380px',
        disableClose: false,
        data: { mode: 0, item: userTenants },
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          this.refreshPartOrders();
        }
      });
    }
  }

  addNewOrder() {
    const dialogRef = this.dialog.open(AddNewPartOrderComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: { mode: ActionType.Add, item: {} },
      panelClass: 'add-user-dialog',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refreshPartOrders();
      }
    });
  }

  cancelOutstandingRequests() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  refreshPartOrders() {
    this.searchTerm = '';
    this.cancelOutstandingRequests();
    this.paginator.pageIndex = 0;
    this.paginatorLength = this.constants.INITIAL_PAGINATOR_LIMIT;
    this.getPartOrders(this.paginatorLength, 0, '', 'ORDER_DATE', 'desc');
  }

  handlePaginator(e: any) {
    this.paginatorLength = e.pageSize;
    let limit = e.pageSize;
    let offset = e.pageIndex * limit;
    this.paginator.pageIndex = e.pageIndex;
    if (this.sort.active) {
      this.getPartOrders(
        limit,
        offset,
        this.searchTerm != null && this.searchTerm != '' ? this.searchTerm : '',
        this.sort.active,
        this.sort._direction
      );
    } else {
      this.getPartOrders(
        limit,
        offset,
        this.searchTerm != null && this.searchTerm != '' ? this.searchTerm : '', 'ORDER_DATE', 'desc'
      );
    }
  }

  sortfields(e: any) {
    let offset = this.paginator._pageIndex * this.paginator._pageSize;
    this.getPartOrders(
      this.paginatorLength,
      offset,
      this.searchTerm != null && this.searchTerm != '' ? this.searchTerm : '',
      e.active,
      e.direction
    );
    this.paginator.pageIndex = this.paginator._pageIndex;
  }

  showPartOrderDetails(order: any, type: string) {
    if (type === 'expand') {
      this.partOrderDetails = false;
      this.selectedItem = order;
      this.cancelOutstandingRequests();
      // this.partDataSource = new MatTableDataSource();

      let partOrderByIdSubscription = this.partOrderService
        .getPartOrderById(order.ORDER_ID)
        .subscribe(
          (response: any) => {
            // Success callback
            if (response.status === 200) {
              if (response.body) {
                this.partOrderDetails = response.body;
                this.partDataSource = new MatTableDataSource(
                  response.body.PART_ORDER_DETAILS
                );
              }
            } else if (response.status === 401) {
              // Redirect to login for unauthorized
              localStorage.removeItem('token');
              localStorage.removeItem('user-details');
              localStorage.removeItem('customerName');
              localStorage.removeItem('customerId');
              localStorage.removeItem('dashboardSlug'); localStorage.removeItem('userTenants');
              this.authService.loggedIn.next(false);
              this.router.navigate(['login']);
            } else if (response.status === 403) {
              this.showErrorDialog(response.error);
            } else {
              this.showErrorDialog(
                'Error while downloading part orders, do you want to retry again?'
              );
            }
          },
          (error) => {
            if (error.status === 401) {
              // Redirect to login for unauthorized
              localStorage.removeItem('token');
              localStorage.removeItem('user-details');
              localStorage.removeItem('customerName');
              localStorage.removeItem('customerId');
              localStorage.removeItem('dashboardSlug'); localStorage.removeItem('userTenants');
              this.authService.loggedIn.next(false);
              this.router.navigate(['login']);
            } else if (error.status === 403) {
              this.showErrorDialog(error.error);
            } else {
              this.showErrorDialog(
                'Error while downloading part orders, do you want to retry again?'
              );
            }
          }
        );
      this.subscriptions.push(partOrderByIdSubscription);
    } else {
      this.selectedItem = null;
    }
  }

  showErrorDialog(msg: string) {
    const dialogRef = this.dialog.open(ErrorComponent, {
      width: '400px',
      data: {
        msg: msg,
        mode: null,
      },
    });
  }

  downloadPdf(row: any) {
    var b64Pdf = row.ORDER_DOCUMENT;
    const byteArray = new Uint8Array(
      atob(b64Pdf)
        .split('')
        .map((char) => char.charCodeAt(0))
    );
    var file = new Blob([byteArray], {
      type: 'application/pdf',
    });
    var fileURL = URL.createObjectURL(file);
    var link = document.createElement('a');
    link.href = fileURL;
    window.open(fileURL, '_blank');
  }

  editOrder(item: any) {
    const dialogRef = this.dialog.open(AddNewPartOrderComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: { mode: ActionType.Edit, item: item },
      panelClass: 'add-user-dialog',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refreshPartOrders();
      }
    });
  }

  deletePartOrder(order: any) {
    const dialogRef = this.dialog.open(AlertComponent, {
      width: '400px',
      data: { errorMsg: 'Are you sure you want to delete this order?' },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result.data) {
        let body = {
          ORDER_ID: order.ORDER_ID
        };
        await this.partOrderService
          .deletePartOrder(body)
          .toPromise()
          .then(
            (response: any) => {
              if (response.status === 204) {
                let offset =
                  this.paginator._pageIndex * this.paginator._pageSize;
                this.paginator.pageIndex = this.paginator._pageIndex;
                if (this.sort.active) {
                  this.getPartOrders(
                    this.paginatorLength,
                    offset,
                    this.searchTerm != null && this.searchTerm != ''
                      ? this.searchTerm
                      : '',
                    this.sort.active,
                    this.sort._direction
                  );
                } else {
                  this.getPartOrders(
                    this.paginatorLength,
                    offset,
                    this.searchTerm != null && this.searchTerm != ''
                      ? this.searchTerm
                      : '', 'ORDER_DATE', 'desc'
                  );
                }
              } else {
                this.showErrorDialog(response?.message);
              }
            },
            (error) => {
              this.showErrorDialog(error?.message);
            }
          );
      }
    });
  }

  getTotalCost() {
    let totalCost = 0;
    let cost: number = 0;
    if (this.partDataSource.data.length > 0) {
      this.partDataSource.data.forEach(element => {
        cost = cost + element.QUANTITY * element.UNIT_PRICE
      });
      totalCost = this.selectedItem?.SHIPPING_COST + cost + this.selectedItem?.MISC_COST;
    }
    return totalCost;
  }

  receiveParts(order: any) {
    const dialogRef = this.dialog.open(ReceivePartComponent, {
      maxWidth: '65vw',
      maxHeight: '85vh',
      minHeight: '75vh',
      width: '65%',
      data: { mode: null, item: order },
      panelClass: 'add-user-dialog',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refreshPartOrders();
      }
    });
  }
}
