import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuditService } from 'src/app/services/audit.service';
import { AuthService } from 'src/app/services/auth.service';
import { LocationService } from 'src/app/services/location.service';
import { ServiceRequestService } from 'src/app/services/service-request.service';
import { AuditFilterComponent } from '../audit-filter/audit-filter.component';

export interface DialogData {
  item: any;
  type: string;
}

@Component({
  selector: 'app-nofifications-filter',
  templateUrl: './nofifications-filter.component.html',
  styleUrls: ['./nofifications-filter.component.scss']
})
export class NofificationsFilterComponent implements OnInit {
  public isShowProgressBar: boolean = false;
  public filterData: any;
  public formGroup: FormGroup = new FormGroup({});
  public gateList: any = [];
  public statusList: any = [];
  public locations: any[] = [];
  public filterdLocations: any = [];
  public subLocationsFormGroup: FormGroup = new FormGroup({});
  public isNotofications: boolean = false;
  public isAirlineCusromer: boolean = false;

  constructor(
    public authService: AuthService,
    public auditService: AuditService,
    public router: Router,
    public dialogRef: MatDialogRef<NofificationsFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public locationService: LocationService,
    public serviceRequestService: ServiceRequestService,
  ) {
    this.statusList = [
      { name: 'Open', value: 'OPEN' },
      { name: 'Closed', value: 'CLOSED' },
      { name: 'Cancel', value: 'CANCEL' }
    ];
  }

  ngOnInit(): void {
    const rightMostPos = window.innerWidth - Number(0);
    this.dialogRef.updatePosition({
      top: `110px`,
      right: `45px`,
    });
    this.isNotofications = this.data.type == 'NOTIFICATION' ? true : false;
    let temp: any = localStorage.getItem('user-details');
    let user: any = JSON.parse(temp);
    this.isAirlineCusromer = user.CUSTOMER_TYPE == 'AIRLINE' ? true : false;
    this.initializeFormControls();
    this.getServiceRequestFilterData();
    this.getLocationHeirarchy();
  }

  ngAfterViewInit() {
    this.setFormControlValues();
  }

  initializeFormControls() {
    this.isAirlineCusromer == true ?
      // For Airline Customer
      this.formGroup = new FormGroup({
        location: new FormControl(''),
        status: new FormControl(''),
        equipmentNo: new FormControl(''),
        part: new FormControl(''),
        unit: new FormControl(''),
        task: new FormControl('')
      }) :
      // For Airport Customer
      this.formGroup = new FormGroup({
        location: new FormControl(''),
        status: new FormControl(''),
        barcode: new FormControl(''),
        part: new FormControl(''),
        unit: new FormControl(''),
        task: new FormControl('')
      })
  }

  setFormControlValues() {
    if (this.isAirlineCusromer) {
      if (this.data?.item && this.data?.item != null && this.data?.item != undefined) {
        this.formGroup = new FormGroup({
          location: new FormControl(this.data.item?.location),
          status: new FormControl(this.data.item?.status),
          equipmentNo: new FormControl(this.data?.item?.equipmentNo),
          part: new FormControl(this.data.item?.part),
          unit: new FormControl(this.data.item?.unit),
          task: new FormControl(this.data.item?.task),
          isServiceRequestSchedule: new FormControl(
            this.data.item?.requestType
          ),
        });
      }
    } else if (!this.isAirlineCusromer) {
      if (this.data?.item && this.data?.item != null && this.data?.item != undefined) {
        this.filterdLocations = [];
        this.formGroup = new FormGroup({
          location: new FormControl(''),
          status: new FormControl(this.data.item.status),
          barcode: new FormControl(this.data.item.barcode),
          part: new FormControl(this.data.item.part),
          unit: new FormControl(this.data.item.unit),
          task: new FormControl(this.data.item.task),
          isServiceRequestSchedule: new FormControl(this.data.item.requestType),
        });
      }
    }
    if (this.data?.item && this.data?.item != null && this.data?.item != undefined) {
      this.filterdLocations = this.data?.item?.locationsList;
      this.formGroup.controls['location'].setValue(this.filterdLocations[0]?.id);
      for (let index = 0; index < this.filterdLocations.length; index++) {
        this.subLocationsFormGroup.addControl(this.filterdLocations[index].formControlName, new FormControl(this.filterdLocations[index + 1]?.id))
      }
    }
  }

  getLocationHeirarchy() {
    this.isShowProgressBar = true;
    this.locationService.getLocations().subscribe(
      (response: any) => {
        // Success callback
        this.isShowProgressBar = false;
        if (response.status === 200) {
          if (response?.body && response?.body?.length > 0) {
            // Assign the data
            this.locations = response.body;
            if (this.locations.length > 0 && this.data?.item == undefined) {
              this.formGroup.controls['location'].setValue(this.locations[0].id);
              if (this.locations[0]?.childrens && this.locations[0]?.childrens?.length > 0) {
                this.subLocationsFormGroup = new FormGroup({ 'a': new FormControl('') });
                this.filterdLocations.push(this.locations[0]);
                this.filterdLocations[0].formControlName = 'a';
              }
            }
          }
        } else if (response.status === 401) {
          // Redirect to login for unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        }
      },
      (error) => {
        this.isShowProgressBar = false;
        if (error.status === 401) {
          // Redirect to login for unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        } else if (error.status === 403) {
          //this.showErrorAlertDialog(error.error);
        } else {
          //this.showErrorAlertDialog(error.message);
        }
      }
    );
  }

  clearFilter() {
    this.formGroup.reset();
    this.subLocationsFormGroup.reset();
    this.filterdLocations = [];
    setTimeout(() => {
      if (this.locations.length > 0) {
        this.formGroup.controls['location'].setValue(this.locations[0].id);
        if (this.locations[0]?.childrens && this.locations[0]?.childrens?.length > 0) {
          this.subLocationsFormGroup = new FormGroup({
            a: new FormControl('')
          });
          this.filterdLocations.push(this.locations[0]);
          this.filterdLocations[0].formControlName = 'a';
        }
      }
    }, 300);
  }

  applyFilter() {
    let filterDetails = {};
    this.isAirlineCusromer == true ?
      filterDetails = {
        location: this.filterdLocations[this.filterdLocations?.length - 1]?.id,
        equipmentNo: this.formGroup.controls['equipmentNo'].value,
        status: this.formGroup.controls['status'].value,
        unit: this.formGroup.controls['unit'].value,
        task: this.formGroup.controls['task'].value,
        locationsList: this.filterdLocations
      } :
      filterDetails = {
        location: this.filterdLocations[this.filterdLocations?.length - 1]?.id,
        status: this.formGroup.controls['status'].value,
        barcode: this.formGroup.controls['barcode'].value,
        part: this.formGroup.controls['part'].value,
        unit: this.formGroup.controls['unit'].value,
        task: this.formGroup.controls['task'].value,
        locationsList: this.filterdLocations
      };
    this.dialogRef.close({ data: filterDetails });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  parentLocationsChange(item: any, index: number) {
    this.filterdLocations = [];
    this.subLocationsFormGroup = new FormGroup({});
    setTimeout(() => {
      this.filterdLocations.push(item);
      this.filterdLocations[0].formControlName = 'a';
      if (item?.childrens) { this.subLocationsFormGroup.addControl('a', new FormControl('')); }
    }, 100);
  }

  childLocationsChange(item: any, index: number) {
    this.filterdLocations = this.filterdLocations.slice(0, index + 1);
    const findedIndex = this.filterdLocations.findIndex((x: { id: any; }) => x.id == item.id);
    if (findedIndex == -1) {
      let name = Math.floor(1000 + Math.random() * 9000).toString();
      item.formControlName = name;
      this.filterdLocations.push(item);
      if (item?.childrens) { this.subLocationsFormGroup.addControl(name, new FormControl('')); }
    } else {
      this.filterdLocations = this.filterdLocations.slice(0, findedIndex);
    }
  }

  resetLocations() {
    this.filterdLocations = [];
    setTimeout(() => {
      if (this.locations.length > 0) {
        this.formGroup.controls['location'].setValue(this.locations[0].id);
        if (this.locations[0]?.childrens && this.locations[0]?.childrens?.length > 0) {
          this.subLocationsFormGroup = new FormGroup({
            a: new FormControl('')
          });
          this.filterdLocations.push(this.locations[0]);
          this.filterdLocations[0].formControlName = 'a';
        }
      }
    }, 300);
  }

  getServiceRequestFilterData() {
    this.isShowProgressBar = true;
    this.serviceRequestService.getServiceRequestFilterData().subscribe(
      (response: any) => {
        // Success callback
        this.isShowProgressBar = false;
        if (response.status === 200) {
          if (response.body) {
            // Assign the data
            this.filterData = response.body;
          }
        } else if (response.status === 401) {
          // Redirect to login for unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        } else if (response.status === 403) {
          console.log(response.error);
        } else {
        }
      },
      (error) => {
        this.isShowProgressBar = false;
        if (error.status === 401) {
          // Redirect to login for unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('user-details');
          localStorage.removeItem('customerName');
          localStorage.removeItem('customerId'); localStorage.removeItem('dashboardSlug');    localStorage.removeItem('userTenants');
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
        } else if (error.status === 403) {
          console.log(error.error);
        } else {
          console.log(
            'Error while downloading service requests filter data, do you want to retry again?'
          );
        }
      }
    );
  }

}
