import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from '../app.config';
import { AppConstants } from '../shared/AppConstants';

@Injectable({
  providedIn: 'root',
})
export class LopaService {
  public constants: AppConstants;
  constructor(
    private appConfigService: AppConfigService,
    private http: HttpClient
  ) {
    this.constants = new AppConstants();
  }

  uploadFile(body: any): Observable<any> {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.post<any>(
      url + this.constants.LOPA_URL,
      body,
      httpOptions
    );
  }

  getLopaList(
    limit: number,
    offset: number,
    searchText?: any,
    sortField?: any,
    sortDirection?: any
  ) {
    let tempParams: any = {
      app: 'admin',
      limit: limit,
      offset: offset,
    };
    if (searchText) {
      tempParams.search = searchText;
    }
    if (sortField) {
      tempParams.sortingField = sortField;
    }
    if (sortDirection) {
      tempParams.sortingOrder = sortDirection;
    }
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      params: tempParams,
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(url + this.constants.GET_FLEETS_URL, httpOptions);
  }

  downloadSampleDoc() {
    let tempParams: any = {
      documentType: 'SAMPLE_LOPA',
    };
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      params: tempParams,
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(
      url + this.constants.DOWNLOAD_SAMPLE_DOC_URL,
      httpOptions
    );
  }

  downloadLopaDocs(lopaIds: any) {
    let tempParams: any = {
      lopaIds: lopaIds,
    };
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      params: tempParams,
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(url + this.constants.LOPA_URL, httpOptions);
  }

  uploadFleetDocument(body: any) {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.post<any>(
      url + this.constants.FLEET_DOCUMENT_URL,
      body,
      httpOptions
    );
  }

  getLopaDetails(fleet?: string, subFleet?: string) {
    let tempParams = {};
    if (subFleet && subFleet.length > 0) {
      tempParams = {
        subfleet: subFleet,
      };
    }

    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      params: tempParams,
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(
      url + `${this.constants.GET_FLEETS_URL}/${fleet}`,
      httpOptions
    );
  }

  getLopaInventory(
    fleet?: string,
    subFleet?: string,
    tailno?: string,
    location?: string,
    requestid?: string,
    requesttype?: string
  ) {
    let tempParams = {};
    if (subFleet && subFleet.length > 0) {
      tempParams = {
        subfleet: subFleet,
        fleet: fleet,
        tailno: tailno,
        location: location,
        requestid: requestid,
        requesttype: requesttype,
      };
    } else {
      tempParams = {
        fleet: fleet,
        tailno: tailno,
        location: location,
        requestid: requestid,
        requesttype: requesttype,
      };
    }

    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      params: tempParams,
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(
      url + `${this.constants.GET_LOPA_INVENTORY_URL}`,
      httpOptions
    );
  }

  getLopaInitialData(fleet?: string, subFleet?: string) {
    let tempParams = {};
    if (subFleet && subFleet.length > 0) {
      tempParams = {
        subfleet: subFleet,
        fleet: fleet,
      };
    } else {
      tempParams = {
        fleet: fleet,
      };
    }

    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      params: tempParams,
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(
      url + `${this.constants.GET_LOPA_INITIAL_DATA_URL}`,
      httpOptions
    );
  }

  getCustomization() {
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(
      url + this.constants.GET_CUSTOMIZATION_URL,
      httpOptions
    );
  }

  getServiceRequestMessages(requestid?: string) {
    let tempParams = {
      requestid: requestid,
    };
    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      params: tempParams,
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.get<any>(
      url + this.constants.GET_SERVICE_REQUEST_MESSAGE_URL,
      httpOptions
    );
  }

  saveLopaMessage(
    fleet?: string,
    subFleet?: string,
    tailno?: string,
    location?: string,
    requestid?: string,
    requesttype?: string,
    message?: string
  ) {
    let body = {};
    if (subFleet && subFleet.length > 0) {
      body = {
        subfleet: subFleet,
        fleet: fleet,
        tailno: tailno,
        location: location,
        requestid: requestid,
        requesttype: requesttype,
        message: message,
      };
    } else {
      body = {
        fleet: fleet,
        tailno: tailno,
        location: location,
        requestid: requestid,
        requesttype: requesttype,
        message: message,
      };
    }

    let httpOptions: any = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.post<any>(
      url + this.constants.GET_SERVICE_REQUEST_MESSAGE_URL,
      body,
      httpOptions
    );
  }

  convertServiceRequest(body: any) {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }),
    };
    httpOptions.observe = 'response';
    let url = this.appConfigService.apiServerUrl;
    return this.http.post<any>(
      url + this.constants.CONVERT_TO_SERVICE_REQUEST_URL,
      body,
      httpOptions
    );
  }
}
